<div class="modal_wrapper modal-body dark_theme text-center">
	<div class="close_icon" (click)="closemodal()">
		<svg xmlns="http://www.w3.org/2000/svg" width="11.689" height="11.689" viewBox="0 0 11.689 11.689">
			<g class="Group_251" data-name="Group 251" transform="translate(84.329 -65.195) rotate(135)">
				<g class="Group_249" data-name="Group 249" transform="translate(98.464 -2)">
					<path
						class="Path_1"
						data-name="Path 1"
						d="M24.331,20.516V5.986"
						transform="translate(-17.066 -5.986)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
					<path
						class="Path_81"
						data-name="Path 81"
						d="M0,14.53V0"
						transform="translate(14.53 7.265) rotate(90)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
				</g>
			</g>
		</svg>
	</div>
	<!-- end close_icon -->

	<h1 class="page_title mb-2 pt-1">Create your account</h1>

	<div class="sign_in_link">Already have an account? <a (click)="login()">Log in</a></div>

	<form class="form_wrapper form_modal" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
		<div class="form-row">
			<div class="form-group col-sm-6">
				<input
					appAutofocus
					type="text"
					placeholder="First name"
					formControlName="firstName"
					class="form-control"
					[ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
				/>
				<div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
					<div *ngIf="f.firstName.errors.required">First name is required</div>
					<div *ngIf="f.firstName.errors.pattern">First name is not valid</div>
				</div>
			</div>
			<div class="form-group col-sm-6">
				<input
					type="text"
					placeholder="Last name"
					formControlName="lastName"
					class="form-control"
					[ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
				/>
				<div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
					<div *ngIf="f.lastName.errors.required">Last name is required</div>
					<div *ngIf="f.lastName.errors.pattern">Last name is not valid</div>
				</div>
			</div>
			<div class="form-group col-sm-6">
				<input
					type="text"
					placeholder="Email address"
					formControlName="email"
					class="form-control"
					[ngClass]="{ 'is-invalid': submitted && f.email.errors }"
				/>
				<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
					<div *ngIf="f.email.errors.required">Email is required</div>
					<div *ngIf="f.email.errors.pattern">Email is invalid</div>
					<div *ngIf="f.email.errors.response">Email has already been taken</div>
				</div>
			</div>

			<div class="form-group col-sm-6">
				<input
					type="phone"
					placeholder="Phone number (optional)"
					formControlName="phone"
					class="form-control"
				/>
			</div>

			<div class="form-group col-12 state">
				<select formControlName="state" name="state" class="form-control">
					<option value="" [selected]="true" disabled>- Select state (optional) -</option>
					<option value="ACT">ACT</option>
					<option value="NSW">NSW</option>
					<option value="QLD">QLD</option>
					<option value="SA">SA</option>
					<option value="TAS">TAS</option>
					<option value="VIC">VIC</option>
					<option value="WA">WA</option>
				</select>
			</div>

			<div class="form-group col-12 col_dob">
				<div class="label_wrap">
					<label>Date of Birth</label>
					<span class="why" ngbTooltip="This site is for 18+ only.">
						<span>?</span>
					</span>
				</div>
				<!-- end label_wrap -->

				<div class="inner">
					<div class="field-inline-block">
						<input
							formControlName="dd"
							placeholder="DD"
							type="text"
							maxlength="2"
							size="2"
							autocomplete="off"
							(input)="autoTab($event, true)"
							class="date-field form-control"
							[ngClass]="{ 'is-invalid': submitted && f.dd.errors }"
							pattern="[0-9]*"
						/>
					</div>
					<span>/</span>
					<div class="field-inline-block">
						<input
							formControlName="mm"
							placeholder="MM"
							type="text"
							pattern="[0-9]*"
							maxlength="2"
							size="2"
							autocomplete="off"
							(input)="autoTab($event, true)"
							class="date-field form-control"
							[ngClass]="{ 'is-invalid': submitted && f.mm.errors }"
						/>
					</div>
					<span>/</span>
					<div class="field-inline-block">
						<input
							formControlName="yyyy"
							placeholder="YYYY"
							type="text"
							pattern="[0-9]*"
							maxlength="4"
							size="4"
							autocomplete="off"
							(input)="autoTab($event, false)"
							class="date-field date-field--year form-control"
							[ngClass]="{ 'is-invalid': submitted && f.yyyy.errors }"
						/>
					</div>
				</div>
				<!-- end inner -->

				<div class="row">
					<div class="col-4">
						<div
							*ngIf="submitted && f.dd.errors"
							class="invalid-feedback"
							[ngClass]="{ 'd-block': submitted && f.mm.errors }"
						>
							<div *ngIf="f.dd.errors.required">Date is required</div>
							<div *ngIf="f.dd.errors.pattern">Date is Invalid</div>
						</div>
					</div>
					<div class="col-4">
						<div
							*ngIf="submitted && f.mm.errors"
							class="invalid-feedback"
							[ngClass]="{ 'd-block': submitted && f.dd.errors }"
						>
							<div *ngIf="f.mm.errors.required">Month is required</div>
							<div *ngIf="f.mm.errors.pattern">Month is invalid</div>
						</div>
					</div>
					<div class="col-4">
						<div
							*ngIf="submitted && f.yyyy.errors"
							class="invalid-feedback"
							[ngClass]="{ 'd-block': submitted && f.yyyy.errors }"
						>
							<div *ngIf="f.yyyy.errors.required">Year is required</div>
							<div *ngIf="f.yyyy.errors.pattern">Year is invalid</div>
						</div>
					</div>
				</div>
				<!-- end row -->
				<div class="row">
					<div class="col-12">
						<div
							*ngIf="submitted && f.yyyy.errors && f.mm.errors && f.dd.errors"
							class="invalid-feedback"
							[ngClass]="{ 'd-block': submitted && (f.yyyy.errors || f.mm.errors || f.dd.errors) }"
						>
							<div *ngIf="f.yyyy.errors.notcorrect || f.mm.errors.notcorrect || f.dd.errors.notcorrect">
								Please enter correct date
							</div>
						</div>
					</div>
					<!-- <div class="col-4">
                        <div *ngIf="submitted && f.dd.errors " class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.dd.errors }">
                            <div *ngIf="f.dd.errors.notcorrect">please enter correct date</div>
                        </div>
                    </div> -->
				</div>
				<!--  end row -->

				<!-- 18 years validation -->
				<div class="row">
					<div class="col-12">
						<div
							*ngIf="submitted && f.yyyy.errors && f.mm.errors && f.dd.errors"
							class="invalid-feedback"
							[ngClass]="{ 'd-block': submitted && f.yyyy.errors }"
						>
							<div *ngIf="f.yyyy.errors.illegal && f.mm.errors.illegal && f.dd.errors.illegal">
								You must be above 18 to sign up
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end form-group col-12 -->

			<div class="form-group col-12">
				<input
					type="password"
					placeholder="Password"
					formControlName="password"
					class="form-control"
					id="password-field"
					[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
				/>
				<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
					<div *ngIf="f.password.errors.required">Password is required</div>
					<div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
				</div>
			</div>

			<div class="form-group form-check col-12">
				<input
					type="checkbox"
					formControlName="acceptTerms"
					id="acceptTerms"
					class="form-check-input"
					[ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }"
				/>
				<label for="acceptTerms" class="form-check-label"
					>I have read and agree to the <a (click)="navigateToTerms()">Terms & Conditions</a></label
				>
				<div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
					You must accept terms and conditions.
				</div>
			</div>

			<div class="form-group col-12">
				<button class="btn blue arrow_large" [disabled]="loading" type="submit">
					<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
					Sign up
				</button>
			</div>
		</div>
	</form>
</div>
<!-- end inner -->
