import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class BlackbookService {
	apiUrl = environment.serverUrl;
	constructor(private httpClient: HttpClient) {}

	/**
	 * get blackboook
	 */
	public getBlackBook(token: any, data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'get-blackbook?token=' + token, data);
	}

	/**
	 * get blackboook
	 */
	public saveBlackBook(data: any, token: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'save-blackbook?token=' + token, data);
	}

	/**
	 * Add blackboook
	 */
	public addBlackBookNote(data: any, token: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'add-blackbook-note?token=' + token, data);
	}

	/**
	 * Edit blackboook
	 */
	public editBlackBookNote(data: any, token: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'edit-blackbook-note?token=' + token, data);
	}

	/**
	 * get blackboook
	 */
	public removeBlackBook(data: any, token: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'remove-blackbook?token=' + token, data);
	}

	/**
	 * get Blackboook Notes
	 */
	public getblackbookNotes(data: any, token: any): Observable<any> {
		let search = {
			horse_id: data['horse_id'] ? data['horse_id'] : null,
			horse_name: data['horse_name'] ? data['horse_name'] : null,
			jockey: data['jockey'] ? data['jockey'] : null,
			trainer: data['trainer'] ? data['trainer'] : null,
		};
		return this.httpClient.post(this.apiUrl + 'get-blackbook-notes?token=' + token, search);
	}
}
