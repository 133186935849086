<div class="onboarding_wrapper subscribe_wrapper section_wrapper">
	<div class="floating_logo">
		<a routerLink="/">
			<img src="assets/images/logos/logo-vertical-light.png" />
		</a>
	</div>

	<div class="floating_skip">
		<a class="skip" routerLink="/" (click)="skip()">Skip</a>
	</div>
	<!-- end floating_skip -->

	<div class="bg_image"></div>
	<!-- end bg_image -->
	<div class="bg_image mob"></div>
	<!-- end bg_image -->

	<div class="onboard_wrap">
		<div class="row">
			<div class="col-12 text-center">
				<h2 class="heading">Select your Racing Central Package</h2>
				<div class="subheading">
					<p> We offer several packages in order to cater to your racing form and analysis needs.
					</p>
				</div>
			</div>
			<!-- end col-12 -->
		</div>
		<!-- end row -->

		<div class="row premium_wrapper" *ngIf="premium_plan && !feature_loading">
			<div class="col-12 col_or text-center"></div><!-- end col-12 col_or -->

			<div class="col-12">
				<div class="panel">
					<div class="row row-eq-height">
						<div class="col-md-10 col_content">
							<div class="bg_image"></div>
							<!-- end bg_image -->

							<div class="inner">
								<div class="row">
									<div class="col-12 col_heading">
										<h2 class="heading small">
											<span>{{ premium_plan?.title }}</span>
											<div class="badge white">
												<div class="svg_wrapper">
													<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
														viewBox="0 0 12 12">
														<path id="Path_8396" data-name="Path 8396"
															d="M751.07,1362.637l2.485-1.227.89,2.625,2.625.89-1.227,2.485,1.227,2.485-2.625.89-.89,2.625-2.485-1.227-2.485,1.227-.89-2.625-2.625-.89,1.227-2.485-1.227-2.485,2.625-.89.89-2.625Z"
															transform="translate(-745.07 -1361.41)" />
													</svg>
												</div>
												<!-- end svg_wrapper -->

												<span>All inclusive</span>
											</div>
											<div class="badge light_blue ml-2" *ngIf="premium_plan?.selected_plan?.trialDays">
												<span>{{premium_plan?.selected_plan.trialDays}} trial period</span>
											</div>
										</h2>
										
										<div class="price d-block d-md-none" *ngIf="
											premium_plan &&
											premium_plan?.selected_plan &&
											premium_plan?.selected_plan.price
										">
											<span class="cost"><small class="dollar">$</small>{{
												premium_plan?.selected_plan.price | number: '1.0-2' }}</span>
											<span class="frequency">/{{ premium_plan?.selected_plan?.duration
												}}</span>
										</div>
										<!-- end price -->
									</div>
									<!-- end col-12 -->
								</div>
								<!-- end row -->

								<div class="row">
									<!--<div class="col-md-12 col_description">
										<div class="body">
											<p>
												Subscribe to our Platinum package to access all areas of Racing Central.
												Includes the following features plus exclusive analysis and trial data*
											</p>
										</div>
									</div>-->
									<!-- end col-6 -->

									<div class="col-md-12 col_features">
										<ul class="features">
											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Tips and suggested bets</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Easy form<!--<small>(minimum 6 meetings per week)</small>--></span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Easy form plus</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Live yard premium</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Previews & selections</span>
											</li>
										</ul>

										<ul class="features">											
											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Jump outs</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Blackbook</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Chatroom</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Tournament access</span>
											</li>
										</ul>

										<div class="badge white">
											<div class="svg_wrapper">
												<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
													viewBox="0 0 12 12">
													<path id="Path_8396" data-name="Path 8396"
														d="M751.07,1362.637l2.485-1.227.89,2.625,2.625.89-1.227,2.485,1.227,2.485-2.625.89-.89,2.625-2.485-1.227-2.485,1.227-.89-2.625-2.625-.89,1.227-2.485-1.227-2.485,2.625-.89.89-2.625Z"
														transform="translate(-745.07 -1361.41)" />
												</svg>
											</div>
											<!-- end svg_wrapper -->

											<span>All inclusive</span>
										</div>
										<div class="badge light_blue ml-2" *ngIf="premium_plan?.selected_plan?.trialDays">
											<span>{{premium_plan?.selected_plan.trialDays}} trial period</span>
										</div>
									</div>
									<!-- end col-6 -->
								</div>
								<!-- end row -->

								<div class="row">
									<div class="cta col-12">
										<p class="first">
											Don’t miss out. Once sold, this offer is closed.
										</p>
										<a (click)="premiumCheckOut()" *ngIf="!premium_subscribed"
											class="btn blue large">Subscribe</a>
										<a (click)="unSubscribe()" *ngIf="premium_subscribed"
											class="btn blue large">Unsubscribe</a>
										<p class="second" style="font-size: 12px;">
											Don’t miss out. Once sold, this offer is closed.
										</p>
									</div>
									<!-- end cta -->
								</div>
								<!-- end row -->
							</div>
							<!-- end panel -->
						</div>
						<!-- end col_content -->

						<div class="col-md-2 col_price_info d-none d-sm-block">
							<div class="inside">
								<div class="top_area" *ngIf="discount">
									<div class="badge light_blue">
										<!--<span>Save {{ discount | number: '1.0-0' }}%</span>-->
										<span>7 Days Free Trial!!</span>
									</div>
								</div>
								<!-- end top_area -->

								<div class="middle_area" *ngIf="premium_plan">
									<div class="price">
										<span class="cost"><small class="dollar">$</small>{{
											premium_plan?.prices[1].price | number: '1.0-2' }}</span>
										<span class="frequency">/week</span>
										<!-- <span class="cost"><small
											class="dollar">$</small>{{getPlanPrice(premium_plan?.prices,plan_duration) | number: '1.0-2'}}</span>
										<span class="frequency" *ngIf="plan_duration == 'weekly'">/week</span>
										<span class="frequency" *ngIf="plan_duration == 'monthly'">/month</span>
										<span class="frequency" *ngIf="plan_duration == 'yearly'">/year</span> -->
									</div>
									<!-- end price -->
								</div>
								<!-- end middle_area -->
							</div>
							<!-- end inside -->
						</div>
						<!-- end col_price_info -->
					</div>
					<!-- end row -->
				</div>
				<!-- end panel -->
			</div>
			<!-- end col-12 -->
		</div>
		<!-- end row premium_wrapper -->

		<div class="row pt-5 mt-3" *ngIf="!feature_loading">
			<div class="radio_wrap">
				<input type="radio" [checked]="plan_duration == 'weekly'" name="weekly" id="weekly"
					class="input-hidden" />
				<label for="weekly" (click)="setPlanDuration('weekly')">
					<span class="btn">Weekly</span>
				</label>

				<input type="radio" [checked]="plan_duration == 'monthly'" name="monthly" id="monthly"
					class="input-hidden" />
				<label for="monthly" class="m-0" (click)="setPlanDuration('monthly')">
					<span class="btn">Monthly</span>
				</label>

				<input type="radio" [checked]="plan_duration == 'yearly'" name="yearly" id="yearly"
					class="input-hidden" />
				<label for="yearly" class="m-0" (click)="setPlanDuration('yearly')">
					<span class="btn">Yearly</span>
				</label>

				<div class="slider"></div>
			</div>
			<!-- end radio_wrap -->
		</div>
		<!-- end row -->

		<div class="row">
			<div class="col-12 text-left" *ngIf="!feature_loading && display_checkout">
				<br>
				<h2 class="heading">Select Base Package</h2>
			</div>
			<!-- <div class="col-12 text-left" *ngIf="!feature_loading && display_checkout">
				<br>
				<h2 class="heading">Select Add on Package</h2>
			</div> -->
			<div class="col-12 text-left" *ngIf="feature_loading">
				<br>
				<h2 class="heading">&nbsp;</h2>
			</div>
			<!-- end col-12 -->
		</div>

		<div class="row features_wrapper slides" *ngIf="!feature_loading && display_checkout"
			[ngClass]="{ active: activeSlide == 'slide-1' }">

			<div class="features_links_box py-2"
				[ngClass]="{ 'col-lg-4 col-md-12': features_count >= 2, 'col-lg-6 col-md-6': features_count == 1 }">
				<div class="panel active" [ngClass]="{'active': freePlanSelected}">
					<div class="head">
						<h3 class="title">RC Standard</h3>
						<div class="price">
							<span class="cost"><small class="dollar">$</small>0</span>
							<span class="frequency" *ngIf="plan_duration == 'weekly'">/week</span>
							<span class="frequency" *ngIf="plan_duration == 'monthly'">/month</span>
							<span class="frequency" *ngIf="plan_duration == 'yearly'">/year</span>
						</div>
						<!-- end price -->
					</div>
					<!-- end head -->

					<div class="inner">
						<!--<p class="description p-2" [innerHTML]="feature.short_description | nl2br"></p>-->

						<ul class="features">
							<li>
								<img src="assets/images/icons/tick.svg" alt="Checkmark">
								<span>Form Guide</span>
							</li>
							<li>
								<img src="assets/images/icons/tick.svg" alt="Checkmark">
								<span>Easy Form</span>
							</li>
							<li>
								<img src="assets/images/icons/tick.svg" alt="Checkmark">
								<span>Tournament Access</span>
							</li>
							<li>
								<img src="assets/images/icons/tick.svg" alt="Checkmark">
								<span>Chat Room</span>
							</li>
							<li>
								<img src="assets/images/icons/tick.svg" alt="Checkmark">
								<span>Blackbook</span>
							</li>
						</ul>
					</div>
					<!-- end inner -->

					<div class="bottom_selection" (click)="selectFreePlan()" [ngClass]="{'active': freePlanSelected}">
						<p>Select</p>
						<div class="svg_wrapper">
							<svg xmlns="http://www.w3.org/2000/svg" width="16.08" height="11.395"
								viewBox="0 0 16.08 11.395">
								<path id="Path_8401" data-name="Path 8401" d="M1.436,11.283,6.115,6.371-3.7-3.758"
									transform="translate(11.8 4.24) rotate(90)" fill="none" stroke="#000"
									stroke-miterlimit="10" stroke-width="1.5" />
							</svg>
						</div>
						<!-- end svg_wrapper -->
					</div>

					<!-- end bottom_selection -->
				</div>
				<!-- end panel -->
			</div>
			<div *ngFor="let item of basePlans" class="features_links_box py-2"
				[ngClass]="{ 'col-lg-4 col-md-12': features_count >= 2, 'col-lg-6 col-md-6': features_count == 1 }">
				<div class="panel active disabled">
					<div class="head">
						<span class="tag" *ngIf="item?.title == 'Silver'">Most Popular</span>
						<h3 class="title">{{item?.title}}</h3>
						<div class="price">
							<span class="cost"><small
									class="dollar">$</small>{{getPlanPrice(item?.prices,plan_duration)}}</span>
							<span class="frequency" *ngIf="plan_duration == 'weekly'">/week</span>
							<span class="frequency" *ngIf="plan_duration == 'monthly'">/month</span>
							<span class="frequency" *ngIf="plan_duration == 'yearly'">/year</span>
						</div>
						<!-- end price -->
						<span class="trial badge light_blue" *ngIf="getTrialDays(item?.prices, plan_duration)">
							{{ getTrialDays(item?.prices, plan_duration) }}  Free Trial
						</span>
					</div>
					<!-- end head -->

					<div class="inner">
						<!--<p class="description p-2" [innerHTML]="feature.short_description | nl2br"></p>-->

						<ul class="features">

							<li *ngFor="let feature of convertToArray(item?.short_description)">
								<img src="assets/images/icons/tick.svg" alt="Checkmark">
								<span *ngIf="feature?.title">
									{{feature?.title}}
									<span *ngIf="feature?.description" class="feat_desc">{{feature?.description}}</span>
								</span>
							</li>

						</ul>
					</div>
					<!-- end inner -->

					<div class="bottom_selection" [ngClass]="{'active': isActiveBase(item)}"
						(click)="toggleBasePlan(item, plan_duration)">
						<p>Select</p>
						<div class="svg_wrapper">
							<svg xmlns="http://www.w3.org/2000/svg" width="16.08" height="11.395"
								viewBox="0 0 16.08 11.395">
								<path id="Path_8401" data-name="Path 8401" d="M1.436,11.283,6.115,6.371-3.7-3.758"
									transform="translate(11.8 4.24) rotate(90)" fill="none" stroke="#000"
									stroke-miterlimit="10" stroke-width="1.5" />
							</svg>
						</div>
						<!-- end svg_wrapper -->
					</div>

					<!-- end bottom_selection -->
				</div>
				<!-- end panel -->
			</div>
			<!-- end features_links_box -->
		</div>
		<!-- end features_wrapper -->

		<div class="row features_wrapper slides" *ngIf="!feature_loading"
			[ngClass]="{ active: activeSlide == 'slide-1' }">

			<ng-container *ngIf="display_checkout">
				<div *ngFor="let item of addOnPlans" class="features_links_box py-2"
					[ngClass]="{ 'col-lg-4 col-md-12': features_count >= 2, 'col-lg-6 col-md-6': features_count == 1 }">
					<div class="panel active disabled">
						<div class="head">
							<h3 class="title">{{item?.title}}</h3>
							<div class="price">
								<span class="cost"><small
										class="dollar">$</small>{{getPlanPrice(item?.prices,plan_duration)}}</span>
								<span class="frequency" *ngIf="plan_duration == 'weekly'">/week</span>
								<span class="frequency" *ngIf="plan_duration == 'monthly'">/month</span>
								<span class="frequency" *ngIf="plan_duration == 'yearly'">/year</span>
							</div>
							<!-- end price -->
						</div>
						<!-- end head -->

						<div class="inner">
							<!--<p class="description p-2" [innerHTML]="feature.short_description | nl2br"></p>-->

							<ul class="features">
								<li *ngFor="let feature of convertToArray(item?.short_description)">
									<img src="assets/images/icons/tick.svg" alt="Checkmark">
									<span *ngIf="feature?.title">
										{{feature?.title}}
										<span *ngIf="feature?.description" class="feat_desc">{{feature?.description}}</span>
									</span>
								</li>
							</ul>
						</div>
						<!-- end inner -->

						<div class="bottom_selection" [ngClass]="{'active': isActiveAddon(item)}"
							(click)="toggleAddonPlan(item, plan_duration)">
							<p>Select</p>
							<div class="svg_wrapper">
								<svg xmlns="http://www.w3.org/2000/svg" width="16.08" height="11.395"
									viewBox="0 0 16.08 11.395">
									<path id="Path_8401" data-name="Path 8401" d="M1.436,11.283,6.115,6.371-3.7-3.758"
										transform="translate(11.8 4.24) rotate(90)" fill="none" stroke="#000"
										stroke-miterlimit="10" stroke-width="1.5" />
								</svg>
							</div>

							<!-- end bottom_selection -->
						</div>
						<!-- end panel -->
					</div>
				</div>
				<!-- end features_links_box -->
			</ng-container>
		</div>
		<!-- end features_wrapper -->

		<div class="btn_wrap btns mt-4"  *ngIf="!feature_loading">
			<!-- <a class="btn large" *ngIf="display_checkout" (click)="handleBackClick()">Back</a> -->

			<a class="btn blue large float-lg-right float-md-right"
				*ngIf="(activeSlide == 'slide-1' || activeSlide == 'slide-2' || activeSlide == 'slide-4') && display_checkout"
				(click)="checkOut('step2')">Next</a>
			<!-- <a class="btn blue large float-lg-right float-md-right"
				*ngIf="(activeSlide == 'slide-1' || activeSlide == 'slide-2' || activeSlide == 'slide-4') && display_checkout && (selectedBasePlan.length || selectedAddonPlan.length)"
				(click)="checkOut('step2')">Next</a> -->
			<a class="btn blue large float-lg-right float-md-right"
				*ngIf="(activeSlide == 'slide-1' || activeSlide == 'slide-2' || activeSlide == 'slide-4') && display_checkout && (selectedAddonPlan.length === 0 || !selectedAddonPlan) &&  (selectedBasePlan.length === 0 || !selectedBasePlan)"
				(click)="checkOut('step2')">No Thanks</a>
		</div>

		<div class="row features_wrapper slides" *ngIf="feature_loading"
			[ngClass]="{ active: activeSlide == 'slide-1' }">
			<div class="features_links_box py-2 col-lg-6 col-md-6" *ngFor="let number of [0, 1, 2]">
				<div class="panel">
					<div class="head">
						<h3 class="title loading-text"></h3>
						<div class="price loading-text"></div>
					</div>
					<div class="inner">
						<ul class="features">
							<li class="loading-text" *ngFor="let number of [0, 1, 2, 3, 4, 5]"></li>
						</ul>
					</div>

					<div class="bottom_selection">
						<p>&nbsp;</p>
						<div class="svg_wrapper">
							<svg xmlns="http://www.w3.org/2000/svg" width="16.08" height="11.395"
								viewBox="0 0 16.08 11.395">
								<path id="Path_8401" data-name="Path 8401" d="M1.436,11.283,6.115,6.371-3.7-3.758"
									transform="translate(11.8 4.24) rotate(90)" fill="none" stroke="#000"
									stroke-miterlimit="10" stroke-width="1.5" />
							</svg>
						</div>
					</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end features_links_box -->
		</div>
	</div>
	<!-- end onboard_wrap -->
</div>
<!-- section_wrapper -->