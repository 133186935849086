<div class="modal_wrapper text-center dark_theme">
	<div class="close_icon" (click)="closemodal()">
		<svg xmlns="http://www.w3.org/2000/svg" width="11.689" height="11.689" viewBox="0 0 11.689 11.689">
			<g class="Group_251" data-name="Group 251" transform="translate(84.329 -65.195) rotate(135)">
				<g class="Group_249" data-name="Group 249" transform="translate(98.464 -2)">
					<path
						class="Path_1"
						data-name="Path 1"
						d="M24.331,20.516V5.986"
						transform="translate(-17.066 -5.986)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
					<path
						class="Path_81"
						data-name="Path 81"
						d="M0,14.53V0"
						transform="translate(14.53 7.265) rotate(90)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
				</g>
			</g>
		</svg>
	</div>
	<!-- end close_icon -->

	<h1 class="page_title mb-2">Log in</h1>

	<div class="sign_in_link">Don’t have an account? <a (click)="signup()">Sign up</a></div>

	<form class="form_wrapper form_modal" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
		<div class="form-row">
			<div class="form-group col-12">
				<input
					appAutofocus
					type="text"
					class="form-control"
					placeholder="Email Address"
					formControlName="email"
					[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
				/>
				<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
					<div *ngIf="f.email.errors.required">Email is required</div>
					<div *ngIf="f.email.errors.pattern">Email is invalid</div>
				</div>
			</div>

			<div class="form-group col-12">
				<input
					type="password"
					class="form-control"
					placeholder="Password"
					formControlName="password"
					[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
				/>
				<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
					<div *ngIf="f.password.errors.required">Password is required</div>
					<div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
				</div>
			</div>
			<!-- <div class="form-group col-12"> -->
			<!-- <recaptcha
                [formControl]="myRecaptcha"
                (scriptLoad)="onScriptLoad()"
                (scriptError)="onScriptError()"
                ></recaptcha> -->
			<!-- </div> -->
			<div class="form-group col-12">
				<button class="btn blue arrow_large" type="submit">Log in</button>
			</div>

			<div class="forgot_password col-12" (click)="navigateToForgotPassword()">
				<a>Forgot Password?</a>
			</div>
		</div>
	</form>
</div>
<!-- end inner -->
