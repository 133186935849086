import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: '[jumpouts-horses-list]',
	templateUrl: './jumpouts-horses-list.component.html',
	styleUrls: ['./jumpouts-horses-list.component.scss'],
})
export class JumpoutsHorsesListComponent implements OnInit {
	@Input() user: any;
	@Input() jumpouts_horses: any;

	constructor() {}

	ngOnInit(): void {}

	formatSubtitle = (percent: number) => {
		return percent;
	};

	percentToColor(percent: number, min: number, max: number): string {
		if (percent < 54) {
			return '#FF0000';
		} else if (percent >= 55 && percent <= 74) {
			return '#F5C61C';
		} else if (percent > 75) {
			return '#67BE53';
		}
		return '#67BE53';
	}
}
