<tbody class="horse-panel-header">
	<!-- DESKTOP LAYOUT -->
	<tr
		[ngClass]="{ Scratched: row.horse_status == 'Scratched' || row.horse_status == 'LateScratched' }"
		class="d-none d-lg-block"
		*ngIf="screenWidth > 991"
	>
		<td style="width: 8%" class="tabno">
			<img class="img-fluid jockey-img" [src]="row.silks" (error)="onImgError($event, row.tabno)" alt="TRC" />
			<h1 class="index_no">{{ row.tabno }}.</h1>
			<!-- <h1 class="index_no">{{ row.tabno }}.</h1> -->
		</td>

		<td style="width: 27%" class="td_name">
			<div class="player_name">
				<h3>
					<span class="index_no">{{ row.tabno }}.</span>{{ row.horse_name }}
					<small>({{ row.barrier }})</small>
					<div
						class="yard_pick circle"
						placement="top"
						ngbTooltip="Yard pick"
						*ngIf="row.yard_pick && user && user?.hasLiveYard"
					>
						<span>Y</span>
					</div>
					<!-- end yard_pick circle -->
					<div
						class="preview circle"
						placement="top"
						ngbTooltip="Preview pick (win)"
						*ngIf="row.win_bet && user && user?.hasPreviews"
					>
						<span>W</span>
						<!--<span>P</span>-->
						<!-- SHOW P IF THE PICK IS A PLACE -->
					</div>
					<!-- end yard_pick circle -->
					<div
						class="preview_place circle"
						placement="top"
						ngbTooltip="Preview pick (place)"
						*ngIf="row.place_bet && user && user?.hasPreviews"
					>
						<span>P</span>
						<!--<span>P</span>-->
						<!-- SHOW P IF THE PICK IS A PLACE -->
					</div>
					<!-- end yard_pick circle -->
					<div
					class="best_bet image_circle"
					placement="top"
					ngbTooltip="Best Bet"
					*ngIf="row.best_bet && user && user?.hasPreviews"
				>
					<span><img
						alt="TRC"
						class="img-fluid"
						src="assets/images/icons/best_bet.png"
					/></span>
					<!--<span>P</span>-->
					<!-- SHOW P IF THE PICK IS A PLACE -->
				</div>
				<div
					class="value_bet image_circle"
					placement="top"
					ngbTooltip="Value Bet"
					*ngIf="row.value_bet && user && user?.hasPreviews"
				>
					<span><img
						alt="TRC"
						class="img-fluid"
						src="assets/images/icons/value_bet.png"
					/></span>
					<!--<span>P</span>-->
					<!-- SHOW P IF THE PICK IS A PLACE -->
				</div>
					<div
						class="dual_nomination circle"
						placement="top"
						ngbTooltip="Dual acceptance"
						*ngIf="row.dual_nom == 'D'"
					>
						<span>D</span>
					</div>
					<!-- end yard_pick circle -->
				</h3>
			</div>
			<span class="player_attributes"
				>{{ row.age }}YO{{ row.sex }} {{ row.colour }} {{ row.sire }} x {{ row.dam }}</span
			>
		</td>

		<td style="width: 15%" class="form">
			<span class="small_space"><strong>Last Race:</strong> {{ row.formLast4 }}</span>
			<span><strong>Career:</strong> {{ row.st_all }}</span>
		</td>

		<td style="width: 18%" class="trainer">
			<span class="small_space"><strong>T:</strong> {{ row.trainer }}</span>
			<span><strong>J:</strong> {{ formatName(row.jockey) }} ({{ row?.weight | number: '1.0-0' }}kg)</span>
		</td>

		<td style="width: 12%" *ngIf="user && user.hasPreviews" class="trc_rating">
			<span class="rating active" *ngIf="row.last_race_trc_rating">{{ row.last_race_trc_rating }}</span>
			<span class="rating active" *ngIf="!row.last_race_trc_rating">NYR</span>
			<span><strong>TRC Rating</strong></span>
		</td>

		<td
			style="width: 12%"
			*ngIf="!user || !user.hasPreviews"
			class="trc_rating locked"
			(click)="showUpgradeModal('previews')"
		>
			<span class="lock-icon">
				<img src="assets/images/icons/lock.svg" alt="locked" />
			</span>
			<span class="trc_rating">TRC Rating</span>
		</td>

		<td style="width: 15%" class="prices">
			<img class="img-fluid bookmaker" src="assets/images/bookmakers/unibet-tile2.png" alt="TRC" />
			<span class="bookmaker-img small_space"
				><strong>Win:</strong> {{ row.win3_current | currency: 'USD' }}</span
			>
			<span class="bookmaker-img"><strong>Place:</strong> {{ row.plc3_current | currency: 'USD' }}</span>
		</td>

		<td style="width: 5%" class="plus">
			<span class="toggleBtn" (click)="getRaceHorseDetailsInner($event, row.horse_id, i)"></span>
		</td>
	</tr>

	<!-- MOBILE LAYOUT -->
	<tr
		[ngClass]="{ Scratched: row.horse_status == 'Scratched' || row.horse_status == 'LateScratched' }"
		class="d-lg-none mobile_layout"
		(click)="getRaceHorseDetailsInner($event, row.horse_id, i)"
		*ngIf="screenWidth <= 991"
	>
		<td style="width: 10%" class="tabno">
			<img class="img-fluid jockey-img" [src]="row.silks" (error)="onImgError($event, row.tabno)" alt="TRC" />
			<h1 class="index_no">{{ row.tabno }}.</h1>
			<!-- <h1 class="index_no">{{ row.tabno }}.</h1> -->
		</td>

		<td style="width: 90%" class="td_name">
			<div class="player_name">
				<h3>
					<span class="index_no">{{ row.tabno }}.</span>{{ row.horse_name }}
					<small>({{ row.barrier }})</small>
					<div
						class="yard_pick circle"
						placement="top"
						ngbTooltip="Yard pick"
						*ngIf="row.yard_pick && user && user?.hasLiveYard"
					>
						<span>Y</span>
					</div>
					<!-- end yard_pick circle -->
					<div
						class="preview circle"
						placement="top"
						ngbTooltip="Preview pick (win)"
						*ngIf="row.win_bet && user && user?.hasPreviews"
					>
						<span>W</span>
						<!--<span>P</span>-->
						<!-- SHOW P IF THE PICK IS A PLACE -->
					</div>
					<!-- end yard_pick circle -->

					<div
						class="preview_place circle"
						placement="top"
						ngbTooltip="Preview pick (place)"
						*ngIf="row.place_bet && user && user?.hasPreviews"
					>
						<span>P</span>
						<!--<span>P</span>-->
						<!-- SHOW P IF THE PICK IS A PLACE -->
					</div>

					<div
					class="best_bet image_circle"
					placement="top"
					ngbTooltip="Best Bet"
					*ngIf="row.best_bet && user && user?.hasPreviews"
				>
					<span><img
						alt="TRC"
						class="img-fluid"
						src="assets/images/icons/best_bet.png"
					/></span>
					<!--<span>P</span>-->
					<!-- SHOW P IF THE PICK IS A PLACE -->
				</div>
				<div
					class="value_bet image_circle"
					placement="top"
					ngbTooltip="Value Bet"
					*ngIf="row.value_bet && user && user?.hasPreviews"
				>
					<span><img
						alt="TRC"
						class="img-fluid"
						src="assets/images/icons/value_bet.png"
					/></span>
					<!--<span>P</span>-->
					<!-- SHOW P IF THE PICK IS A PLACE -->
				</div>
					<!-- end yard_pick circle -->
					<div
						class="dual_nomination circle"
						placement="top"
						ngbTooltip="Dual nomination"
						*ngIf="row.dual_nom == 'D'"
					>
						<span>D</span>
					</div>
					<!-- end yard_pick circle -->
				</h3>

				<span class="plus">
					<span class="toggleBtn"></span>
				</span>
			</div>
			<div class="inner">
				<div class="content">
					<table>
						<tr>
							<td>
								<span class="player_attributes">{{ row.age }}YO{{ row.sex }} {{ row.colour }}</span>
							</td>
							<td>
								<span class="small_space"><strong>L4:</strong> {{ row.formLast4 }}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span><strong>J:</strong> {{ row.jockey }}</span>
							</td>
							<td>
								<span class="small_space"><strong>T:</strong> {{ row.trainer }}</span>
							</td>
						</tr>
					</table>
				</div>
				<!-- end content -->

				<div
					class="price_widget"
					[ngClass]="{ up: row.win3_movement === 'UP', down: row.win3_movement == 'DOWN' }"
				>
					<div class="gauge"></div>
					<!-- end gauge -->

					<div class="price">
						<span class="win">{{ row?.win3_current | currency: 'USD' }}</span>
					</div>
					<!-- end price -->
				</div>
				<!-- end price -->
			</div>
		</td>
	</tr>
</tbody>
