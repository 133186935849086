<ul class="accr-section">
	<li class="collapsable-section closed" *ngFor="let row of form_guide_horses; let i = index">
		<table
			horse-panel-header
			class="table"
			[row]="row"
			[i]="[i]"
			[user]="user"
			(getRaceHorseDetailsIn)="getRaceHorseDetailsInner($event)"
		></table>
		<!-- table -->

		<div
			horse-details
			class="collapsed-content"
			[row]="row"
			[user]="user"
			[bb_count]="bb_count"
			(openBlackbookIn)="OpenBlackbook($event)"
			(toggleAccordionIn)="toggleAccordionInner($event)"
		></div>
	</li>
	<!-- end collapsable-section -->
</ul>
<!-- end accr-section -->
