<header class="banner" (window:resize)="onResize()">
	<div class="topbar_wrapper">
		<div class="container">
			<div class="row">
				<div class="col-lg-10 offset-lg-1">
					<div class="row">
					
						<div class="col-lg-2 col-6 p-lg-0 col_logo align-self-center">
							<div class="d-lg-none hamburger-container" (click)="animateMenu()">
								<div id="hamburger-icon" [ngClass]="{ open: menuOpen }">
									<span></span>
								</div>
							</div>

							<a routerLink="/">
								<img src="assets/images/logos/logo-vertical-light.png"/>
							</a>
						</div>
						<!-- end col_logo -->

						<div class="col-lg-10 col-6 col_links align-self-center">
							<ul class="links_nav" [ngClass]="{ disable: searchOpen }">
								<li *ngIf="!user"><a class="btn" (click)="login()">Log in</a></li>
								<li *ngIf="!user"><a class="btn blue" (click)="signup()">Sign up</a></li>

								<li *ngIf="user && user.avatar">
									<a class="btn myaccount" routerLink="/account">
										<div
											class="profile-pic"
											[ngStyle]="{ 'background-image': 'url(' + user?.avatar_url + ')' }"
										></div>
										<span>My account</span>
									</a>
								</li>

								<li *ngIf="user && !user.avatar">
									<a class="btn" routerLink="/account"> My account </a>
								</li>
>
								<li *ngIf="user"><a class="btn white" (click)="logout()">Log Out</a></li>
							</ul>

							<!-- <div class="search-box" [ngClass]="{ active: searchOpen }">
								<form action="/" method="GET">
									<input
										type="text"
										name="s"
										id="search2"
										value=""
										placeholder="Start typing..."
										autocomplete="off"
									/>
								</form>
								<span class="search-close" (click)="searchToggle()"
									><img src="assets/images/icons/plus-white.png" alt="Search" class="img-fluid"
								/></span>
							</div> -->
							<!-- search-box -->
						</div>
						<!-- end col_links -->

						<div class="col-lg-5 col-6 col_links_mobile align-self-center">
							<div class="right-mobile-header-content" [ngClass]="{ logged_in: user }">
								<svg
									*ngIf="!user"
									xmlns="http://www.w3.org/2000/svg"
									width="18.767"
									height="24.093"
									viewBox="0 0 18.767 24.093"
								>
									<g id="Group_9074" data-name="Group 9074" transform="translate(18133 -1874.18)">
										<g
											id="Group_9072"
											data-name="Group 9072"
											transform="translate(-18133 1874.18)"
											style="mix-blend-mode: lighten; isolation: isolate"
										>
											<path
												id="Path_8325"
												data-name="Path 8325"
												d="M109.221,11.383c2.588,0,4.686-2.548,4.686-5.692S113.218,0,109.221,0s-4.686,2.548-4.686,5.692S106.633,11.383,109.221,11.383Z"
												transform="translate(-100.369)"
												fill="aqua"
											/>
											<path
												id="Path_8326"
												data-name="Path 8326"
												d="M41.9,300.47c0-.192,0-.054,0,0Z"
												transform="translate(-41.895 -280.397)"
												fill="aqua"
											/>
											<path
												id="Path_8327"
												data-name="Path 8327"
												d="M308.085,301.66c0-.052,0-.364,0,0Z"
												transform="translate(-290.383 -281.437)"
												fill="aqua"
											/>
											<path
												id="Path_8328"
												data-name="Path 8328"
												d="M59.6,185.764c-.087-5.476-.8-7.036-6.274-8.024a3.844,3.844,0,0,1-5.132,0c-5.413.977-6.171,2.514-6.271,7.846-.008.435-.012.458-.013.408,0,.095,0,.27,0,.575,0,0,1.3,2.627,8.85,2.627s8.85-2.627,8.85-2.627c0-.2,0-.333,0-.426A3.514,3.514,0,0,1,59.6,185.764Z"
												transform="translate(-41.907 -165.92)"
												fill="aqua"
											/>
										</g>
										<g
											id="Group_9073"
											data-name="Group 9073"
											transform="translate(-18131.936 1874.997)"
											style="mix-blend-mode: lighten; isolation: isolate"
										>
											<path
												id="Path_8325-2"
												data-name="Path 8325"
												d="M109.221,11.383c2.588,0,4.686-2.548,4.686-5.692S113.218,0,109.221,0s-4.686,2.548-4.686,5.692S106.633,11.383,109.221,11.383Z"
												transform="translate(-100.369)"
												fill="red"
											/>
											<path
												id="Path_8326-2"
												data-name="Path 8326"
												d="M41.9,300.47c0-.192,0-.054,0,0Z"
												transform="translate(-41.895 -280.397)"
												fill="red"
											/>
											<path
												id="Path_8327-2"
												data-name="Path 8327"
												d="M308.085,301.66c0-.052,0-.364,0,0Z"
												transform="translate(-290.383 -281.437)"
												fill="red"
											/>
											<path
												id="Path_8328-2"
												data-name="Path 8328"
												d="M59.6,185.764c-.087-5.476-.8-7.036-6.274-8.024a3.844,3.844,0,0,1-5.132,0c-5.413.977-6.171,2.514-6.271,7.846-.008.435-.012.458-.013.408,0,.095,0,.27,0,.575,0,0,1.3,2.627,8.85,2.627s8.85-2.627,8.85-2.627c0-.2,0-.333,0-.426A3.514,3.514,0,0,1,59.6,185.764Z"
												transform="translate(-41.907 -165.92)"
												fill="red"
											/>
										</g>
									</g>
								</svg>

								<div
									*ngIf="user && user.avatar"
									class="avatar profile-pic-mob"
									[ngStyle]="{ 'background-image': 'url(' + user.avatar_url + ')' }"
								></div>

								<div *ngIf="user && !user.avatar" class="avatar">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="18.767"
										height="24.093"
										viewBox="0 0 18.767 24.093"
									>
										<g id="Group_9074" data-name="Group 9074" transform="translate(18133 -1874.18)">
											<g
												id="Group_9072"
												data-name="Group 9072"
												transform="translate(-18133 1874.18)"
												style="mix-blend-mode: lighten; isolation: isolate"
											>
												<path
													id="Path_8325"
													data-name="Path 8325"
													d="M109.221,11.383c2.588,0,4.686-2.548,4.686-5.692S113.218,0,109.221,0s-4.686,2.548-4.686,5.692S106.633,11.383,109.221,11.383Z"
													transform="translate(-100.369)"
													fill="aqua"
												/>
												<path
													id="Path_8326"
													data-name="Path 8326"
													d="M41.9,300.47c0-.192,0-.054,0,0Z"
													transform="translate(-41.895 -280.397)"
													fill="aqua"
												/>
												<path
													id="Path_8327"
													data-name="Path 8327"
													d="M308.085,301.66c0-.052,0-.364,0,0Z"
													transform="translate(-290.383 -281.437)"
													fill="aqua"
												/>
												<path
													id="Path_8328"
													data-name="Path 8328"
													d="M59.6,185.764c-.087-5.476-.8-7.036-6.274-8.024a3.844,3.844,0,0,1-5.132,0c-5.413.977-6.171,2.514-6.271,7.846-.008.435-.012.458-.013.408,0,.095,0,.27,0,.575,0,0,1.3,2.627,8.85,2.627s8.85-2.627,8.85-2.627c0-.2,0-.333,0-.426A3.514,3.514,0,0,1,59.6,185.764Z"
													transform="translate(-41.907 -165.92)"
													fill="aqua"
												/>
											</g>
											<g
												id="Group_9073"
												data-name="Group 9073"
												transform="translate(-18131.936 1874.997)"
												style="mix-blend-mode: lighten; isolation: isolate"
											>
												<path
													id="Path_8325-2"
													data-name="Path 8325"
													d="M109.221,11.383c2.588,0,4.686-2.548,4.686-5.692S113.218,0,109.221,0s-4.686,2.548-4.686,5.692S106.633,11.383,109.221,11.383Z"
													transform="translate(-100.369)"
													fill="red"
												/>
												<path
													id="Path_8326-2"
													data-name="Path 8326"
													d="M41.9,300.47c0-.192,0-.054,0,0Z"
													transform="translate(-41.895 -280.397)"
													fill="red"
												/>
												<path
													id="Path_8327-2"
													data-name="Path 8327"
													d="M308.085,301.66c0-.052,0-.364,0,0Z"
													transform="translate(-290.383 -281.437)"
													fill="red"
												/>
												<path
													id="Path_8328-2"
													data-name="Path 8328"
													d="M59.6,185.764c-.087-5.476-.8-7.036-6.274-8.024a3.844,3.844,0,0,1-5.132,0c-5.413.977-6.171,2.514-6.271,7.846-.008.435-.012.458-.013.408,0,.095,0,.27,0,.575,0,0,1.3,2.627,8.85,2.627s8.85-2.627,8.85-2.627c0-.2,0-.333,0-.426A3.514,3.514,0,0,1,59.6,185.764Z"
													transform="translate(-41.907 -165.92)"
													fill="red"
												/>
											</g>
										</g>
									</svg>
								</div>
								<!-- end avatar -->

								<a *ngIf="!user" (click)="loginorsignup()">
									<span>Log in / Sign up</span>
								</a>

								<a class="username" *ngIf="user" routerLink="/account">
									<span *ngIf="user.display_name">{{ user.display_name }}</span>
									<span *ngIf="!user.display_name">{{ user.name }}</span>
								</a>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end container -->
	</div>
	<!-- end topbar_wrapper -->

	<!-- <div class="primary_header d-none d-lg-block" style="display: none;">
		<div class="container">
			<div class="row">
				<div class="col-lg-10 offset-lg-1">
					<div class="row">
						<div class="col_links col-12">
							<ul class="nav">
								<li>
									<a
										[routerLinkActive]="['active']"
										[routerLinkActiveOptions]="{ exact: true }"
										[routerLink]="['/']"
										>Home</a
									>
								</li>
								<li>
									<a
										[routerLinkActive]="['active']"
										[routerLinkActiveOptions]="{ exact: true }"
										[routerLink]="['news']"
										>News</a
									>
								</li>
								<li>
									<a
										[routerLinkActive]="['active']"
										[routerLinkActiveOptions]="{ exact: true }"
										[routerLink]="['form-guide']"
										>Form Guide</a
									>
								</li>
								<li>
									<a
										[routerLinkActive]="['active']"
										[routerLinkActiveOptions]="{ exact: true }"
										[routerLink]="['liveyard']"
										>Live Yard</a
									>
								</li>
								<li>
									<a
										[routerLinkActive]="['active']"
										[routerLinkActiveOptions]="{ exact: true }"
										[routerLink]="['results']"
										>Results</a
									>
								</li>
								<li>
									<a
										[routerLinkActive]="['active']"
										[routerLinkActiveOptions]="{ exact: true }"
										[routerLink]="['jumpouts']"
										>Jump Outs</a
									>
								</li>
								<li>
									<a
										[routerLinkActive]="['active']"
										[routerLinkActiveOptions]="{ exact: true }"
										[routerLink]="['blackbook']"
										>Blackbook</a
									>
								</li>
								<li>
									<a
										[routerLinkActive]="['active']"
										[routerLinkActiveOptions]="{ exact: true }"
										[routerLink]="['resources']"
										>Resources</a
									>
								</li>
								<li>
									<a
										[routerLinkActive]="['active']"
										[routerLinkActiveOptions]="{ exact: true }"
										[routerLink]="['tournaments']"
										>Tournaments</a
									>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> -->
	<!-- end primary_header -->

	<!-- <div class="overlay" id="overlay" style="display: none;" [ngClass]="{ open: menuOpen }">
		<perfect-scrollbar id="mobile-menu-scroller">

			<ul class="nav" id="mobile-menu">
				<li class="menu-element">
					<a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/']"
						>Home
						<svg xmlns="http://www.w3.org/2000/svg" width="5.76" height="9.815" viewBox="0 0 5.76 9.815">
							<path
								id="Path_1"
								data-name="Path 1"
								d="M24.331,14.766l4.181-4.39-4.181-4.39"
								transform="translate(-23.788 -5.469)"
								fill="none"
								stroke="#777"
								stroke-miterlimit="10"
								stroke-width="1.5"
							/>
						</svg>
					</a>
				</li>

				<li class="menu-element">
					<a
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						[routerLink]="['news']"
						>News
						<svg xmlns="http://www.w3.org/2000/svg" width="5.76" height="9.815" viewBox="0 0 5.76 9.815">
							<path
								id="Path_1"
								data-name="Path 1"
								d="M24.331,14.766l4.181-4.39-4.181-4.39"
								transform="translate(-23.788 -5.469)"
								fill="none"
								stroke="#777"
								stroke-miterlimit="10"
								stroke-width="1.5"
							/>
						</svg>
					</a>
				</li>

				<li class="menu-element">
					<a
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						[routerLink]="['form-guide']"
						>Form Guide
						<svg xmlns="http://www.w3.org/2000/svg" width="5.76" height="9.815" viewBox="0 0 5.76 9.815">
							<path
								id="Path_1"
								data-name="Path 1"
								d="M24.331,14.766l4.181-4.39-4.181-4.39"
								transform="translate(-23.788 -5.469)"
								fill="none"
								stroke="#777"
								stroke-miterlimit="10"
								stroke-width="1.5"
							/>
						</svg>
					</a>
				</li>

				<li class="menu-element">
					<a
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						[routerLink]="['liveyard']"
						>Live Yard
						<svg xmlns="http://www.w3.org/2000/svg" width="5.76" height="9.815" viewBox="0 0 5.76 9.815">
							<path
								id="Path_1"
								data-name="Path 1"
								d="M24.331,14.766l4.181-4.39-4.181-4.39"
								transform="translate(-23.788 -5.469)"
								fill="none"
								stroke="#777"
								stroke-miterlimit="10"
								stroke-width="1.5"
							/>
						</svg>
					</a>
				</li>

				<li class="menu-element">
					<a
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						[routerLink]="['results']"
						>Results
						<svg xmlns="http://www.w3.org/2000/svg" width="5.76" height="9.815" viewBox="0 0 5.76 9.815">
							<path
								id="Path_1"
								data-name="Path 1"
								d="M24.331,14.766l4.181-4.39-4.181-4.39"
								transform="translate(-23.788 -5.469)"
								fill="none"
								stroke="#777"
								stroke-miterlimit="10"
								stroke-width="1.5"
							/>
						</svg>
					</a>
				</li>

				<li class="menu-element">
					<a
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						[routerLink]="['jumpouts']"
						>Jump Outs
						<svg xmlns="http://www.w3.org/2000/svg" width="5.76" height="9.815" viewBox="0 0 5.76 9.815">
							<path
								id="Path_1"
								data-name="Path 1"
								d="M24.331,14.766l4.181-4.39-4.181-4.39"
								transform="translate(-23.788 -5.469)"
								fill="none"
								stroke="#777"
								stroke-miterlimit="10"
								stroke-width="1.5"
							/>
						</svg>
					</a>
				</li>

				<li class="menu-element">
					<a
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						[routerLink]="['blackbook']"
						>Blackbook
						<svg xmlns="http://www.w3.org/2000/svg" width="5.76" height="9.815" viewBox="0 0 5.76 9.815">
							<path
								id="Path_1"
								data-name="Path 1"
								d="M24.331,14.766l4.181-4.39-4.181-4.39"
								transform="translate(-23.788 -5.469)"
								fill="none"
								stroke="#777"
								stroke-miterlimit="10"
								stroke-width="1.5"
							/>
						</svg>
					</a>
				</li>

				<li class="menu-element">
					<a
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						[routerLink]="['resources']"
						>Resources
						<svg xmlns="http://www.w3.org/2000/svg" width="5.76" height="9.815" viewBox="0 0 5.76 9.815">
							<path
								id="Path_1"
								data-name="Path 1"
								d="M24.331,14.766l4.181-4.39-4.181-4.39"
								transform="translate(-23.788 -5.469)"
								fill="none"
								stroke="#777"
								stroke-miterlimit="10"
								stroke-width="1.5"
							/>
						</svg>
					</a>
				</li>

				<li class="menu-element">
					<a
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						[routerLink]="['tournaments']"
						>Tournaments
						<svg xmlns="http://www.w3.org/2000/svg" width="5.76" height="9.815" viewBox="0 0 5.76 9.815">
							<path
								id="Path_1"
								data-name="Path 1"
								d="M24.331,14.766l4.181-4.39-4.181-4.39"
								transform="translate(-23.788 -5.469)"
								fill="none"
								stroke="#777"
								stroke-miterlimit="10"
								stroke-width="1.5"
							/>
						</svg>
					</a>
				</li>

				<li class="menu-element">
					<a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['livechat']">Chat Room
						<svg xmlns="http://www.w3.org/2000/svg" width="5.76" height="9.815" viewBox="0 0 5.76 9.815">
  						<path id="Path_1" data-name="Path 1" d="M24.331,14.766l4.181-4.39-4.181-4.39" transform="translate(-23.788 -5.469)" fill="none" stroke="#777" stroke-miterlimit="10" stroke-width="1.5"/>
						</svg>
					</a>
				</li>-->

				<!-- <li class="menu-element">
					<a
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						[routerLink]="['contact']"
						>Contact Us
						<svg xmlns="http://www.w3.org/2000/svg" width="5.76" height="9.815" viewBox="0 0 5.76 9.815">
							<path
								id="Path_1"
								data-name="Path 1"
								d="M24.331,14.766l4.181-4.39-4.181-4.39"
								transform="translate(-23.788 -5.469)"
								fill="none"
								stroke="#777"
								stroke-miterlimit="10"
								stroke-width="1.5"
							/>
						</svg>
					</a>
				</li>

			</ul>

			<div class="row menu-element">
				<div
					class="col_race_info col-12 align-self-center"
					*ngIf="nextRace"
					routerLink="/form-guide/{{ nextRace?.slug }}/{{ nextRace?.raceno }}"
				>
					<div class="inner">
						<div class="mobile-race-row row">
							<div class="col-6 col_race race-col align-self-center">
								<span
									>{{
										nextRace?.track_name.length > 15
											? (nextRace?.track_name | slice: 0:14) + '...'
											: nextRace?.track_name
									}}
									Race {{ nextRace?.raceno }}</span
								><br />

								<span *ngIf="nextRace?.state">{{ nextRace?.state }}</span>
							</div>

							<div class="col-6 col_time race-col align-self-center text-right">
								<div
									class="time-pills disable auto"
									[ngClass]="[nextRace.seconds <= 300 ? 'red' : 'grey']"
								>
									<span [counter]="nextRace.local_time"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row menu-element logout">
				<a *ngIf="user" class="btn white large" (click)="logout()"> Log out </a>
			</div>
		</perfect-scrollbar>
	</div> -->
	<!-- overlay -->
</header>
<div class="persistant_banner_notification" id="BannerPC" *ngIf="user && user.subscription && user.subscription.manually_cancelled">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col_banner_content">
				<p>
					Please upgrade your plans to avoid interruptions and maintain uninterrupted service.
				</p>
			</div>
		</div>
	</div>
	<div class="close_icon" (click)="closePersistantBanner()"><svg xmlns="http://www.w3.org/2000/svg" width="11.689" height="11.689" viewBox="0 0 11.689 11.689"><g data-name="Group 251" transform="translate(84.329 -65.195) rotate(135)" class="Group_251"><g data-name="Group 249" transform="translate(98.464 -2)" class="Group_249"><path data-name="Path 1" d="M24.331,20.516V5.986" transform="translate(-17.066 -5.986)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2" class="Path_1"></path><path data-name="Path 81" d="M0,14.53V0" transform="translate(14.53 7.265) rotate(90)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2" class="Path_81"></path></g></g></svg></div>
</div>
