import { Component, OnInit } from '@angular/core';
import { TournamentService } from '../services/tournaments.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-round-robin-tournaments',
	templateUrl: './round-robin-tournaments.component.html',
	styleUrls: ['./round-robin-tournaments.component.scss'],
})
export class RoundRobinTournamentsComponent implements OnInit {
	tournaments: any;
	roundRobinTournamentId: any;
	roundRobinTournament: any;
	roundRobinTournamentLeaderboard: any;
	loading: boolean = true;
	disabledButton: boolean = true;
	getRoute: any;
	user:any;
	token:any;
	storageUrl = environment.storage_serverUrl;
	roundRobinTournamentUser:any;

	constructor(private TournamentService: TournamentService, private route: ActivatedRoute, private router: Router) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		if (this.user != null && this.user != 'undefined') this.token = this.user.token;
	}

	ngOnInit() {
		this.getRoute = this.router.url.split('/');
		//console.log(this.getRoute);
		this.route.params.subscribe((params) => {
			this.roundRobinTournamentId = params.id;
			//console.log(this.roundRobinTournamentId);
			this.user = localStorage.getItem('trc_user');
			this.user = JSON.parse(this.user);
			if (this.user != null && this.user != 'undefined') this.token = this.user.token;
			this.getRounRobinTournaments();
			this.showRounRobinTournamentLeaderboard();
		});
	}

	getRounRobinTournaments() {
		let round_robin_data = {
			id: this.roundRobinTournamentId,
			user_id: this.user.id,
		};

		this.TournamentService.showRounRobinTournaments(round_robin_data).subscribe((data: any) => {
			//console.log(data);
			if (data) {
				if (data.round_robin_tournament) {
					this.roundRobinTournament = data.round_robin_tournament;
					this.roundRobinTournamentUser = this.roundRobinTournament.roundrobin_tournamentUser;
					console.log(this.roundRobinTournamentUser )

					// if (!this.roundRobinTournament.cut_off_date_over) {
					// 	this.roundRobinTournament.status = 'Open';
					// } else if (this.roundRobinTournament.show_results) {
					// 	this.roundRobinTournament.status = 'Complete';
					// } else {
					// 	this.roundRobinTournament.status = 'In Progress';
					// }

					if (this.roundRobinTournament.show_results && this.roundRobinTournament.cut_off_date_over) {
						this.roundRobinTournament.status = 'Complete';
					} else if(!this.roundRobinTournament.show_results && this.roundRobinTournament.cut_off_date_over) {
						this.roundRobinTournament.status = 'In Progress';
					} else {
						this.roundRobinTournament.status = 'Open';
					}

					

					if (!this.roundRobinTournament.cut_off_date_over) {
						this.roundRobinTournament.alert = 'grey';
						this.roundRobinTournament.status = 'Open';
						var now = moment.utc().format('YYYY MM DD HH:mm:ss');

						// var diff = moment.duration(moment(this.roundRobinTournament.cut_off_date_time).diff(moment(now)));

						this.roundRobinTournament.cut_off_date_time_local = moment.utc(this.roundRobinTournament.cut_off_date_time).local();
						var diff = moment.duration(moment(this.roundRobinTournament.cut_off_date_time_local).diff(moment.utc()));


						var duration = moment.duration(diff);
						var years = duration.years(),
							days = duration.days(),
							months = duration.months(),
							hrs = duration.hours(),
							mins = duration.minutes(),
							secs = duration.seconds();
						if (years > 0) {
							this.roundRobinTournament.time_remaining = this.calculateTime(years, 'year');
						} else if (months > 0) {
							this.roundRobinTournament.time_remaining = this.calculateTime(months, 'month');
						} else if (days > 0) {
							this.roundRobinTournament.time_remaining = this.calculateTime(days, 'day');
						} else if (hrs > 0) {
							this.roundRobinTournament.time_remaining = this.calculateTime(hrs, 'hour') + this.calculateTime(mins, 'min');
						} else if (mins > 0) {
							this.roundRobinTournament.time_remaining = this.calculateTime(mins, 'min') + this.calculateTime(secs, 'sec');
							if (mins < 5) {
								this.roundRobinTournament.alert = 'red';
							}
						} else {
							this.roundRobinTournament.time_remaining = this.calculateTime(secs, 'sec');
							this.roundRobinTournament.alert = 'red';
						}
					} else {
						this.roundRobinTournament.alert = '';
						this.roundRobinTournament.time_remaining = null;
						
						if (this.roundRobinTournament.show_results && this.roundRobinTournament.cut_off_date_over) {
							this.roundRobinTournament.status = 'Complete';
						} else if(!this.roundRobinTournament.show_results && this.roundRobinTournament.cut_off_date_over) {
							this.roundRobinTournament.status = 'In Progress';
						} else {
							this.roundRobinTournament.status = 'Open';
						}

						
					}


                    if(!this.roundRobinTournament.image) {
                        this.roundRobinTournament.image_url = '/assets/images/the-race-club-cranbourne-lg-med.png';
                    }
                    
				}
				if (data.tournaments) {
					this.tournaments = data.tournaments;
					this.parseTournamentsData();
				}
				this.loading = false;
			}
		});
	}

	showRounRobinTournamentLeaderboard() {
		let round_robin_data = {
			id: this.roundRobinTournamentId,
			user_id: this.user.id,
		};

		this.TournamentService.showRounRobinTournamentLeaderboard(round_robin_data).subscribe((data: any) => {
			//console.log(data);
			if (data.leaderboard) {
				this.roundRobinTournamentLeaderboard = data.leaderboard;
			}
		});
	}

	parseTournamentsData() {
		var now = moment.utc().format('YYYY MM DD HH:mm:ss');
		this.tournaments.forEach((element: any) => {
			if (!element.cut_off_date_over) {
				element.alert = 'white';
				element.status = 'Open';

				// var diff = moment.duration(moment(element.cut_off_date_time).diff(moment(now)));
				element.cut_off_date_time_local = moment.utc(element.cut_off_date_time).local();
				var diff = moment.duration(moment(element.cut_off_date_time_local).diff(moment.utc()));

				
				var duration = moment.duration(diff);
				var years = duration.years(),
					days = duration.days(),
					months = duration.months(),
					hrs = duration.hours(),
					mins = duration.minutes(),
					secs = duration.seconds();
				if (years > 0) {
					element.time_remaining = this.calculateTime(years, 'year');
				} else if (months > 0) {
					element.time_remaining = this.calculateTime(months, 'month');
				} else if (days > 0) {
					element.time_remaining = this.calculateTime(days, 'day');
				} else if (hrs > 0) {
					element.time_remaining = this.calculateTime(hrs, 'hour') + this.calculateTime(mins, 'min');
				} else if (mins > 0) {
					element.time_remaining = this.calculateTime(mins, 'min') + this.calculateTime(secs, 'sec');
					if (mins < 5) {
						element.alert = 'red';
					}
				} else {
					element.time_remaining = this.calculateTime(secs, 'sec');
					element.alert = 'red';
				}
			} else {
				element.alert = 'grey';
				element.time_remaining = null;
				if (element.show_results) {
					element.status = 'Complete';
				} else {
					element.status = 'In Progress';
				}
			}
			element.imageType = 'jpg';
            if(element.image_url != undefined && element.image_url.indexOf('png') != -1) {
                element.imageType = 'png';
            }
            if(element.image_url == undefined) {
                element.image_url = '/assets/images/horse.png';
                element.imageType = 'png';
            }
			// element.cut_off_date_time = moment(element.cut_off_date_time).local().format('YYYY MM DD HH:mm:ss');
		});

		//console.log(this.tournaments);
	}

	calculateTime(counter: number, timeUnit: string) {
		if (counter === 1) {
			return counter + ' ' + timeUnit; // singular (1 hours)
		} else {
			let toReturn = counter + ' ' + timeUnit + 's ';
			return toReturn; // plural (2 hours)
		}
	}

	gotoSingleTournament(tournament: any) {
		this.router.navigate(['/tournament/' + tournament.id + '/' + this.slugify(tournament.name)]);
	}

    gotoSingleTournamentView(tournamentView: any) {
		console.log(tournamentView)
		this.router.navigate(['/tournament/view/' + tournamentView.id + '/' + this.slugify(tournamentView.name)]);
	}

	async agreeTerms(tournament: any) {
		let data = {
			id: tournament.id,
			type: 'tournament',
		};
		try {
			await this.TournamentService.agreeTerms(this.token, data).subscribe((data: any) => {
				if (data && data.status) {
					this.router.navigate(['/tournament/' + tournament.id + '/' + this.slugify(tournament.name)]);
				}
			});
		} catch (error) {
		//	console.log(error);
		}
	}

	
	slugify(title: any) {
		// console.log(title, title.replace(/\s+/g, '-').toLowerCase());
		return title.replace(/\s+/g, '-').toLowerCase();
	}

	getName(name: any) {
		return name
			.split(' ')
			.map((n: any) => n[0])
			.join('');
	}
}
