import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { JumpoutsService } from '../services/jumpouts.service';
import { Input, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { TweenLite, Cubic, Power4, TweenMax, TimelineMax } from 'gsap';
import { BlackbookPopupComponent } from './../shared/blackbook-popup/blackbook-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlackbookService } from '../services/blackbook.service';
import { EventService } from '../services/event.service';
import { LoginPopupComponent } from './../shared/login-popup/login-popup.component';

@Component({
	selector: 'app-jumpouts',
	templateUrl: './jumpouts.component.html',
	styleUrls: ['./jumpouts.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class JumpoutsComponent implements OnInit {
	loading: boolean = true;
	jumpouts_horses: any = [];
	page: any = 1;
	search_txt: any = '';
	user: any;
	jumpouts_count: any;
	blackbook_entry: any;
	bb_count: any;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private JumpoutsService: JumpoutsService,
		private modalService: NgbModal,
		private blackbookService: BlackbookService,
		private EventService: EventService
	) {
		this.EventService.userLogin.subscribe((state: any) => {
			if (state) {
				this.getUser();
				//console.log('login');
			} else {
				this.getUser();
				//console.log('loggedout');
			}
		});
	}

	ngOnInit() {
		this.getUser();
	}

	getUser() {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);

		if (this.user && this.user.subscription) {
			if (this.user.subscription.plan_name === 'Platinum' || this.user.subscription.platinum_cancelling_or_override) this.user.hasPremiumSubscription = true;
			this.user.subscription.active_features.forEach((element: any) => {
				if (element.slug == 'previews-and-reviews') this.user.hasPreviews = true;
				if (element.slug == 'liveyard') this.user.hasLiveYard = true;
			});
			if (this.user.subscription.legacy_plan) this.user.hasPreviews = true;
			if (this.user.day_pass) this.user.hasPremiumSubscription = true;
		}

		if (this.user) {
			this.getBlackBook(this.user);
		}
		this.getJumpoutsData();
	}

	getJumpoutsData() {
		this.getJumouts(this.page);
	}

	//Get Jumoouts Training Data
	async getJumouts(page: any) {
		let data = {
			search: this.search_txt,
		};

		this.JumpoutsService.getJumpouts(page, data).subscribe((data: any) => {
			if (data.status) {
				this.jumpouts_horses = data.jumpouts;
				this.jumpouts_count = data.total;

				this.jumpouts_horses.forEach((jumpouts: any, i: any) => {
					let bb_count = 0;
					if (this.blackbook_entry) {
						this.blackbook_entry.forEach((element: any) => {
							// //console.log(i);
							if (
								element.type == 'horse' &&
								element.name == jumpouts.horse &&
								element.name != null &&
								jumpouts.horse != null
							) {
								bb_count++;
							}
							if (
								element.type == 'jockey' &&
								element.name == jumpouts.jockey &&
								element.name != null &&
								jumpouts.horse != null
							) {
								bb_count++;
							}
							if (
								element.type == 'trainer' &&
								element.name == jumpouts.trainer &&
								element.name != null &&
								jumpouts.trainer != null
							) {
								bb_count++;
							}
						});
					}

					this.jumpouts_horses[i].bb_count = bb_count;
				});
			} else {
				if(page == 1) {
					this.jumpouts_horses = [];
					this.jumpouts_count = 0;
				}
			
	
			}
			this.loading = false;
		});
	}

	pageChanged(event: any) {
		this.loading = true;
		this.page = event;
		this.getJumouts(event);
	}

	async toggleAccordion(event: any) {
		var $this = $(event.target),
			$content = $(event.target).closest('.collapsable-section').find('.collapsed-content');

		if ($this.closest('.collapsable-section').hasClass('closed')) {
			TweenLite.set($content, {
				height: 'auto',
				opacity: 1,
			});
			TweenLite.from($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
			$this.closest('.collapsable-section').removeClass('closed');
			$this.parents('.horse-panel-header').find('.toggleBtn').addClass('expand');
		} else {
			$this.closest('.collapsable-section').addClass('closed');
			$this.parents('.horse-panel-header').find('.toggleBtn').removeClass('expand');
			TweenLite.to($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
		}
	}

	async OpenBlackbook(horse: any) {
		if (!this.user) {
			this.modalService.open(LoginPopupComponent, { size: 'custom', windowClass: 'login-modal' });
		} else {
			horse.horse_name = horse.horse;
			const modalRef = this.modalService.open(BlackbookPopupComponent, {
				size: 'custom',
				windowClass: 'blackbook-modal',
				scrollable: true
			});
			//console.log(horse);
			let data = {
				horse: horse,
				user: this.user,
			};

			modalRef.componentInstance.fromParent = data;
			modalRef.result.then(
				(result) => {
					//console.log(result);
				},
				(reason) => {
					this.getJumpoutsData();
				}
			);
		}
	}

	getBlackBook(user: any) {
		//console.log('getting black book');
		let data = '';
		this.blackbookService.getBlackBook(user.token, data).subscribe((data) => {
			//console.log(data);
			this.blackbook_entry = data.blackbook_entries;
			//console.log(this.blackbook_entry);
		});
	}
}
