import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class GeoIpService {
	apiUrl = environment.serverUrl;

	constructor(private httpClient: HttpClient) {}

	getUserLocation(): Observable<any> {
		return this.httpClient.get('https://get.geojs.io/v1/ip/geo.json', { observe: 'response' });
	}
}
