<div class="breadcrumbs_wrapper bg-image" style="background-image: url('assets/images/horse-portrait.jpg')">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title" *ngIf="!loading">{{ news?.title }}</h1>
					<h1 class="page_title loading-text" *ngIf="loading">&nbsp;</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/" routerLink="/">Home</a></li>
							<li><a routerLink="/news" routerLink="/news">News</a></li>
							<!--<li>{{ news?.title }}</li>-->
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end breadcrumbs_wrapper -->

<div class="singlenewspage_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-lg-10 offset-lg-1">
				<div class="row">


					<div class="col-xl-8 col_left" *ngIf="loading">
						<div class="image_wrap bottom-border gradient loading-text">
							
						</div>
				
						<div class="content pad-left-right">
							<div class="row">
								<h3 class="content_title col-6">
									<strong class="loading-text"></strong>
								</h3>
								<div class="date col-6 loading-text" >
									
								</div>
							</div>
				
							<div class="body loading-text" >
							</div>
						</div>
						<!-- end content -->
					</div><!-- GHOST ELEMENTS -->
					
					<div class="col-xl-8 col_left" *ngIf="!news?.is_race_review && !loading">
						<div class="image_wrap bottom-border gradient" *ngIf="news?.image_url">
							<img src="{{ news?.image_url }}" alt="{{ news?.title }}" class="news-image img-fluid" />
						</div>

						<div class="content pad-left-right">
							<div class="row">
								<h3 class="content_title col-6">
									<strong>{{ news?.author_name }}</strong>
								</h3>
								<div class="date col-6" *ngIf="news?.created_at">
									{{ news?.created_at | date: 'd MMMM y' }}
								</div>
							</div>

							<div class="body semibold large" *ngIf="news?.short_description">
								{{ news?.short_description }}
							</div>

							<div class="body" [innerHTML]="news?.long_description"></div>
						</div>
						<!-- end content -->
					</div>
					<!-- end col_left -->


					<div class="col-xl-8 col_left is_race_review" *ngIf="news?.is_race_review && !loading">
						<div class="image_wrap bottom-border gradient" *ngIf="news?.image_url">
							<img src="{{ news?.image_url }}" alt="{{ news?.title }}" class="news-image img-fluid" />
						</div>
						<div class="content pad-left-right">
							<div class="row">
								<h3 class="content_title col-6">
									<strong>{{ news?.author_name }}</strong>
								</h3>
								<div class="date col-6" *ngIf="news?.created_at">
									{{ news?.created_at | date: 'd MMMM y' }}
								</div>
							</div>

							<div class="body semibold large">
								{{ news?.short_description }}
							</div>

							<div class="seperator"></div>

							<div class="race_preview_single" *ngFor="let row of news?.race_review">
								<h3 class="content_title">
									<strong>Race {{ row.raceno }}</strong> {{ row.name }} {{ row.distance }}m
								</h3>

								<div class="body">
									{{ row.trc_review }}
								</div>

								<h3 class="content_title" *ngIf="row.race_horses.length > 0">
									<strong>Runners to watch</strong>
								</h3>

								<div class="result-tables-wrap">
									<div class="result-tables row" *ngFor="let horse of row.race_horses">
										<div class="t-index col-4" [ngClass]="{'green': horse.horse_fp == 1,'yellow': horse.horse_fp == 2 || horse.horse_fp == 3,'grey': horse.horse_fp > 3}">
											<div class="inner">
												<div class="inner">
													<span
														><strong>{{ horse.horse_fp }}</strong></span
													>
													<span>of 9</span>
												</div>
												<!-- end inner -->
											</div>
											<!-- end inner -->
										</div>
										<!-- end t-index -->

										<div class="t-conents col-8">
											<div class="panel grey">
												<table class="table">
													<tbody>
														<tr>
															<td class="silk_wrap">
																<img
																	class="img-fluid jockey-img"
																	src="{{ horse.silks }}"
																	alt="TRC"
																/>
																<h1 class="index_no">{{ horse.tabno }}.</h1>
															</td>
															<td class="player_wrap">
																<h3 class="player_name">
																	{{ horse.horse_name }} ({{ horse.barrier }})
																</h3>
																<span
																	>{{ horse.age }}yo{{ horse.sex }} ({{ horse.colour }})
																	{{ horse.sire }} x {{ horse.dam }}</span
																>
															</td>
															<td class="text-right tbl_btn">
																<a
																	*ngIf="user"
																	class="btn large"
																	(click)="OpenBlackbook(horse)"
																	>Add to Blackbook</a
																>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<!-- end t-conents -->
									</div>
									<!-- 	end result-tables -->
								</div>

								<div class="seperator"></div>
							</div>
							<!-- end race_preview_single -->

							<div class="body">
								{{ news?.conclusion }}
							</div>
						</div>
					</div>
					<!-- end col_left -->

					<div class="col-xl-4 col_right">
						<div class="row">
							<div class="col-12 shared_links" *ngIf="!loading">
								<div class="panel">
									<span class="text">Share to:</span>

									<div class="link_wrap">
										<span>
											<a
												href="http://www.facebook.com/share.php?u={{ pageUrl }}&amp;t={{
													news?.title
												}}"
												title="Share on Facebook."
												target="_blank"
												><i class="fa fa-facebook-square"></i
											></a>
										</span>
										<span>
											<a
												href="http://www.linkedin.com/shareArticle?mini=true&amp;title={{
													news?.title
												}}&amp;url={{ pageUrl }}"
												title="Share on LinkedIn"
												target="_blank"
												><i class="fa fa-linkedin-square"></i
											></a>
										</span>
										<span>
											<a
												href="http://twitter.com/intent/tweet?text={{ news?.title }} - {{ pageUrl }}"
												title="Tweet this!"
												target="_blank"
												><i class="fa fa-twitter"></i
											></a>
										</span>
										<span>
											<a
												href="mailto:?Subject={{ news?.title }}&Body={{
													news?.short_description
												}} {{ pageUrl }}"
												><i class="fa fa-envelope" aria-hidden="true"></i
											></a>
										</span>
									</div>
									<!-- end link_wrap -->
								</div>
							</div>
							
							<!-- GHOST ELEMENTS -->
							<div class="col-12 shared_links" *ngIf="loading">
								<div class="panel d-flex justify-content-between align-items-center">
									<span class="text loading-text"></span>
				
									<div class="link_wrap d-flex">
										<span class="loading-text" *ngFor="let number of [0, 1, 2, 3]"></span>
									</div>
									<!-- end link_wrap -->
								</div>
							</div>
				
							<div class="col-xl-12 col-md-6 featured_news" *ngIf="featuredNews && !loadingFeaturedNews">
								<article class="d-flex flex-column featured">
									<a routerLink="/news/{{ featuredNews.id }}/{{ featuredNews.slug }}">
										<div
											class="news_img"
											[style.backgroundImage]="'url(' + featuredNews.image_url + ')'"
										>
											<h4 class="pill">Featured Article</h4>
										</div>
									</a>
									<div class="content flex-grow-1 d-flex flex-column">
										<div class="row title_wrap">
											<div class="col col-12 align-self-center">
												<h3>
													<a
														routerLink="/news/{{ featuredNews.id }}/{{ featuredNews.slug }}"
														>{{ featuredNews?.title }}</a
													>
												</h3>
											</div>
										</div>

										<div class="read-link mt-auto">
											<a
												class="link small"
												routerLink="/news/{{ featuredNews.id }}/{{ featuredNews.slug }}"
												>Read more</a
											>
										</div>
									</div>
									<!-- end content -->
								</article>
							</div>
							<!-- end featured_news -->

							<!-- GHOST ELEMENT -->
							<div class="col-xl-12 col-md-6 featured_news" *ngIf="loadingFeaturedNews">
								<article class="d-flex flex-column featured">
							
									<div class="news_img loading-text">
										<h4 class="pill">
											<span class="loading-text"></span>
										</h4>
									</div>
							
									<div class="content flex-grow-1 d-flex flex-column">
										<div class="row title_wrap">
											<div class="col col-12 align-self-center">
												<h3 class="loading-text">
												</h3>
											</div>
										</div>
							
										<div class="read-link mt-auto">
											<span class="loading-text"></span>
										</div>
									</div>
									<!-- end content -->
								</article>
							</div><!-- End featured news Ghost element -->

							<div class="col-xl-12 col-md-6 limited_time_offer sidebar" *ngIf="!loadingOffers && offer">
								<div class="panel">
									<div class="col_content">
										<h2 class="title">{{ offer?.heading }}</h2>

										<div class="body white">
											{{ offer?.description }}
										</div>

										<a *ngFor="let row of offer?.buttons" (click)="OfferClicked(row)" class="btn">{{
											row?.key
										}}</a>
									</div>
								</div>
							</div>
							<!-- end limited_time_offer -->
				
							<!-- GHOST ELEMENTS -->
							<div class="col-xl-12 col-md-6 limited_time_offer sidebar" *ngIf="loadingOffers">
								<div class="panel">
									<div class="col_content">
										<h2 class="title loading-text"></h2>
				
										<div class="body white loading-text">
											
										</div>
				
										<a class="btn loading-text"></a>
									</div>
								</div>
							</div>
							<!-- end limited_time_offer -->
						</div>
					</div>

					<div class="col-12 other_news_wrapper">
						<div class="row">
							<h2 class="heading col-lg-6 align-self-center">Other news</h2>
							<div class="view_links col-lg-6 align-self-center">
								<a class="link" routerLink="/news">See all articles</a>
							</div>
						</div>

						<div class="row">
							<div class="col-lg-6 news_single" *ngFor="let row of latest_news">
								<div class="panel h-100">
									<div class="row h-100">
										<div class="col_image col-sm-5">
											<a routerLink="/news/{{ row?.id }}/{{ row?.slug }}"
												><div
													class="news_img"
													[style.backgroundImage]="'url(' + row?.image_url + ')'"
												></div
											></a>
										</div>
										<!-- end col_image -->

										<div class="col_content col-sm-7 d-flex flex-column align-self-center">
											<a routerLink="/news/{{ row?.id }}/{{ row?.slug }}"
												><h3 class="new_title">{{ row?.title }}</h3></a
											>

											<div class="body" *ngIf="row?.short_description">
												{{
													row?.short_description.length > 100
														? (row?.short_description | slice: 0:100) + '..'
														: row?.short_description
												}}
											</div>
											<div class="read-link mt-auto">
												<a class="link small" routerLink="/news/{{ row?.id }}/{{ row?.slug }}"
													>Read more</a
												>
											</div>
										</div>
										<!-- end content -->
									</div>
								</div>
							</div>
							<!-- end news_single -->
						</div>

						<!-- GHOST ELEMENT -->
						<div class="row" *ngIf="loading">
							<div class="col-md-6 news_single">
								<div class="panel h-100">
									<div class="row h-100">
										<div class="col_image col-sm-5">
											<div class="news_img loading-text"></div>
										</div>
										<!-- end col_image -->
										<div class="col_content col-sm-7 d-flex flex-column">
											<h3 class="new_title"><div class="loading-text"></div></h3>
											<div class="body">
												<div class="loading-text"></div>
												<div class="loading-text"></div>
											</div>
											<div class="loading-text"></div>
										</div>
										<!-- end content -->
									</div>
								</div>
							</div>
							<!-- end news_single -->

							<div class="col-md-6 news_single">
								<div class="panel h-100">
									<div class="row h-100">
										<div class="col_image col-sm-5">
											<div class="news_img loading-text"></div>
										</div>
										<!-- end col_image -->
										<div class="col_content col-sm-7 d-flex flex-column">
											<h3 class="new_title"><div class="loading-text"></div></h3>
											<div class="body">
												<div class="loading-text"></div>
												<div class="loading-text"></div>
											</div>
											<div class="loading-text"></div>
										</div>
										<!-- end content -->
									</div>
								</div>
							</div>
							<!-- end news_single -->

							<div class="col-md-6 news_single">
								<div class="panel h-100">
									<div class="row h-100">
										<div class="col_image col-sm-5">
											<div class="news_img loading-text"></div>
										</div>
										<!-- end col_image -->
										<div class="col_content col-sm-7 d-flex flex-column">
											<h3 class="new_title"><div class="loading-text"></div></h3>
											<div class="body">
												<div class="loading-text"></div>
												<div class="loading-text"></div>
											</div>
											<div class="loading-text"></div>
										</div>
										<!-- end content -->
									</div>
								</div>
							</div>
							<!-- end news_single -->

							<div class="col-md-6 news_single">
								<div class="panel h-100">
									<div class="row h-100">
										<div class="col_image col-sm-5">
											<div class="news_img loading-text"></div>
										</div>
										<!-- end col_image -->
										<div class="col_content col-sm-7 d-flex flex-column">
											<h3 class="new_title"><div class="loading-text"></div></h3>
											<div class="body">
												<div class="loading-text"></div>
												<div class="loading-text"></div>
											</div>
											<div class="loading-text"></div>
										</div>
										<!-- end content -->
									</div>
								</div>
							</div>
							<!-- end news_single -->
						</div>
					</div>
					<!-- end other_news_wrapper -->
				</div>
				<!-- end row -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>
