<div class="breadcrumbs_wrapper bg-image" style="background-image: url('assets/images/news-banner.jpg')">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title">News</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/">Home</a></li>
							<li><a routerLink="/news">News</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end breadcrumbs_wrapper -->

<div class="newspage_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-lg-10 offset-lg-1">
				<div class="row">
					<div class="col-xl-8 order-xl-5 news_wrapper" *ngIf="news?.length > 0 && !loadingNewsList">
						<div class="panel">
							<div class="row">
								<div
									class="col-md-6 news_single"
									*ngFor="
										let row of news
											| paginate
												: { itemsPerPage: 10, currentPage: page, totalItems: totalnewsCount }
									"
								>
									<article class="d-flex flex-column">
										<a routerLink="/news/{{ row.id }}/{{ row.slug }}">
											<div
												class="news_img"
												[style.backgroundImage]="'url(' + row.image_url + ')'"
											></div>
										</a>
										<div class="content flex-grow-1 d-flex flex-column">
											<div class="row title_wrap">
												<div class="col col-8 align-self-center">
													<h3>
														<a routerLink="/news/{{ row.id }}/{{ row.slug }}">{{
															row?.title
														}}</a>
													</h3>
												</div>
												<div class="col col-4 pl-0 align-self-top text-right">
													<div class="date">{{ row?.created_at | date: 'd MMM y' }}</div>
												</div>
											</div>
											<div class="body" *ngIf="row?.short_description">
												{{
													row?.short_description.length > 80
														? (row?.short_description | slice: 0:80) + '..'
														: row?.short_description
												}}
											</div>
											<div class="read-link mt-auto">
												<a class="link small" routerLink="/news/{{ row.id }}/{{ row.slug }}"
													>Read more</a
												>
											</div>
										</div>
										<!-- end content -->
									</article>
								</div>
								<!-- end news_single -->
							</div>

							<div class="row pagination_wrapper">
								<pagination-controls (pageChange)="pageChanged($event)"> </pagination-controls>
							</div>
						</div>
						<!-- end inner -->
					</div>
					<!-- end news_wrapper-->

					<!-- GHOST ELEMENT -->
					<div class="col-xl-8 order-xl-5 news_wrapper" *ngIf="loadingNewsList">
						<div class="panel">
							<div class="row">
								<div class="col-md-6 news_single">
									<article>
										<div class="news_img loading-text"></div>
										<div class="content">
											<div class="row">
												<div class="col col-8 align-self-center">
													<h3><div class="loading-text"></div></h3>
												</div>
												<div class="col col-4 pl-0 align-self-top text-right">
													<div class="date"><div class="loading-text"></div></div>
												</div>
											</div>
											<div class="body">
												<div class="loading-text"></div>
											</div>
											<div class="loading-text"></div>
										</div>
										<!-- end content -->
									</article>
								</div>
								<!-- end news_single -->

								<div class="col-md-6 news_single">
									<article>
										<div class="news_img loading-text"></div>
										<div class="content">
											<div class="row">
												<div class="col col-8 align-self-center">
													<h3><div class="loading-text"></div></h3>
												</div>
												<div class="col col-4 pl-0 align-self-top text-right">
													<div class="date"><div class="loading-text"></div></div>
												</div>
											</div>
											<div class="body">
												<div class="loading-text"></div>
											</div>
											<div class="loading-text"></div>
										</div>
										<!-- end content -->
									</article>
								</div>
								<!-- end news_single -->

								<div class="col-md-6 news_single">
									<article>
										<div class="news_img loading-text"></div>
										<div class="content">
											<div class="row">
												<div class="col col-8 align-self-center">
													<h3><div class="loading-text"></div></h3>
												</div>
												<div class="col col-4 pl-0 align-self-top text-right">
													<div class="date"><div class="loading-text"></div></div>
												</div>
											</div>
											<div class="body">
												<div class="loading-text"></div>
											</div>
											<div class="loading-text"></div>
										</div>
										<!-- end content -->
									</article>
								</div>
								<!-- end news_single -->

								<div class="col-md-6 news_single">
									<article>
										<div class="news_img loading-text"></div>
										<div class="content">
											<div class="row">
												<div class="col col-8 align-self-center">
													<h3><div class="loading-text"></div></h3>
												</div>
												<div class="col col-4 pl-0 align-self-top text-right">
													<div class="date"><div class="loading-text"></div></div>
												</div>
											</div>
											<div class="body">
												<div class="loading-text"></div>
											</div>
											<div class="loading-text"></div>
										</div>
										<!-- end content -->
									</article>
								</div>
								<!-- end news_single -->
							</div>
						</div>
						<!-- end inner -->
					</div>
					<!-- end news_wrapper-->

					<div class="col-xl-4 order-xl-1 col_left">
						<div class="row">
							<div
								class="col-xl-12 col-md-6 featured_news d-none d-md-block bottom-border gradient"
								*ngIf="featuredNews && !loadingFeaturedNews"
							>
								<article class="d-flex flex-column featured">
									<a routerLink="/news/{{ featuredNews.id }}/{{ featuredNews.slug }}">
										<div
											class="news_img"
											[style.backgroundImage]="'url(' + featuredNews.image_url + ')'"
										>
											<h4 class="pill">Featured Article</h4>
										</div>
									</a>
									<div class="content flex-grow-1 d-flex flex-column">
										<div class="row title_wrap">
											<div class="col col-12 align-self-center">
												<h3>
													<a
														routerLink="/news/{{ featuredNews.id }}/{{ featuredNews.slug }}"
														>{{ featuredNews?.title }}</a
													>
												</h3>
											</div>
										</div>

										<div class="read-link mt-auto">
											<a
												class="link small"
												routerLink="/news/{{ featuredNews.id }}/{{ featuredNews.slug }}"
												>Read more</a
											>
										</div>
									</div>
									<!-- end content -->
								</article>
							</div>
							<!-- end featured_news -->

							<!-- GHOST ELEMENT -->
							<div class="col-xl-12 col-md-6 featured_news" *ngIf="loadingFeaturedNews">
								<article>
									<div class="news_img loading-text"></div>
									<div class="content">
										<div class="row">
											<div class="col col-8 align-self-center">
												<h3><div class="loading-text"></div></h3>
											</div>
											<div class="col col-4 pl-0 align-self-top text-right">
												<div class="date"><div class="loading-text"></div></div>
											</div>
										</div>
										<div class="body">
											<div class="loading-text"></div>
										</div>
										<div class="loading-text"></div>
									</div>
									<!-- end content -->
								</article>
							</div>
							<!-- end news_single -->

							<div class="col-xl-12 col-md-6 limited_time_offer sidebar" *ngIf="!loadingOffers && offer">
								<div class="panel">
									<div class="col_content">
										<h2 class="title">{{ offer?.heading }}</h2>

										<div class="body white">
											{{ offer?.description }}
										</div>

										<a *ngFor="let row of offer?.buttons" (click)="OfferClicked(row)" class="btn">{{
											row?.key
										}}</a>
									</div>
								</div>
							</div>
							<!-- end limited_time_offer -->

							<div class="previews_widget" *ngIf="previews?.length > 0 && !loadingTodaysPreviews">
								<div class="col-12">
									<div class="row">
										<h2 class="heading col-sm-7 align-self-center">Upcoming previews</h2>
										<div class="view_links col-sm-5 align-self-center text-right">
											<a
												class="title_link black no_after small d-none d-sm-block"
												routerLink="/form-guide"
											>
												View all
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="7.46"
													height="12.625"
													viewBox="0 0 7.46 12.625"
												>
													<path
														class="line_arrow"
														data-name="Path 393"
														d="M24.331,17.232l5.355-5.623L24.331,5.986"
														transform="translate(-23.607 -5.296)"
														fill="none"
														stroke="#000"
														stroke-miterlimit="10"
														stroke-width="2"
													/>
												</svg>
											</a>
										</div>
									</div>

									<div class="row">
										<div class="col-12 previews_single" *ngFor="let row of previews">
											<div class="panel">
												<div
													class="p-row row"
													routerLink="form-guide/{{ row.slug }}/{{ row.raceno }}"
												>
													<div
														class="col_title_link col align-self-center"
														routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}"
													>
														<a
															routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}"
															class="title_link small"
															>{{ row.venue }}</a
														>
													</div>

													<div class="col_next_race text-right col align-self-center" routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}">
														<a>
															<div class="next_race">
																<span class="next_text">Next Race</span>
																<span class="race_no align-middle"
																	><p>{{ row.raceno }}</p></span
																>
															</div>
														</a>
													</div>
												</div>
											</div>
										</div>
										<!-- end previews_single -->

										<div class="col-12 mobile_link_bottom">
											<a class="title_link black no_after small" routerLink="/form-guide">
												View all
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="7.46"
													height="12.625"
													viewBox="0 0 7.46 12.625"
												>
													<path
														class="line_arrow"
														data-name="Path 393"
														d="M24.331,17.232l5.355-5.623L24.331,5.986"
														transform="translate(-23.607 -5.296)"
														fill="none"
														stroke="#000"
														stroke-miterlimit="10"
														stroke-width="2"
													/>
												</svg>
											</a>
										</div>
										<!-- end col-12 -->
									</div>
								</div>
							</div>
							<!-- end previews_wrapper -->

							<!-- GHOST ELEMENT -->
							<div class="row previews_widget" *ngIf="loadingTodaysPreviews">
								<div class="col-12">
									<div class="row">
										<h2 class="heading col-lg-6 align-self-center"></h2>
									</div>
									<div class="row">
										<div class="col-12 previews_single">
											<div class="panel">
												<div class="p-row row">
													<div class="col_title_link col align-self-center">
														<div class="loading-text"></div>
													</div>
													<div class="col_next_race text-right col align-self-center">
														<div class="loading-text"></div>
													</div>
												</div>
											</div>
										</div>
										<!-- end previews_single -->

										<div class="col-12 previews_single">
											<div class="panel">
												<div class="p-row row">
													<div class="col_title_link col align-self-center">
														<div class="loading-text"></div>
													</div>
													<div class="col_next_race text-right col align-self-center">
														<div class="loading-text"></div>
													</div>
												</div>
											</div>
										</div>
										<!-- end previews_single -->

										<div class="col-12 previews_single">
											<div class="panel">
												<div class="p-row row">
													<div class="col_title_link col align-self-center">
														<div class="loading-text"></div>
													</div>
													<div class="col_next_race text-right col align-self-center">
														<div class="loading-text"></div>
													</div>
												</div>
											</div>
										</div>
										<!-- end previews_single -->

										<div class="col-12 previews_single">
											<div class="panel">
												<div class="p-row row">
													<div class="col_title_link col align-self-center">
														<div class="loading-text"></div>
													</div>
													<div class="col_next_race text-right col align-self-center">
														<div class="loading-text"></div>
													</div>
												</div>
											</div>
										</div>
										<!-- end previews_single -->
									</div>
								</div>
							</div>
							<!-- end previews_wrapper -->
						</div>
					</div>
					<!-- end col_left -->
				</div>
				<!-- end row -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>
