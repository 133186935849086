import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGuideService } from '../../services/form-guide.service';

@Component({
	selector: 'app-track-profile',
	templateUrl: './track-profile.component.html',
	styleUrls: ['./track-profile.component.scss'],
})
export class TrackProfileComponent implements OnInit {
	eventDetails: any;
	@Input() fromParent: any;
	event : any;
	race:any;

	constructor(private modalService: NgbModal, private FormGuideService: FormGuideService) {}

	ngOnInit(): void {
		//console.log(this.fromParent);
		this.event = this.fromParent.event;
		this.race = this.fromParent.selectedrace;
		
	}

	close() {
		this.modalService.dismissAll();
	}

	async getTrackProfile() {
		let data: any;
		this.FormGuideService.getTrackProfile(data).subscribe((data: any) => {
			//console.log(data);
			if (data) {
			}
		});
	}
}
