<div class="section_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-lg-10 offset-lg-1">
				<div class="race_info_device d-lg-none">
					<div class="row">
						<div class="venue col-7">
							<ng-select2
								[value]="selectedRaceEvent"
								*ngIf="race_events"
								id="venue_dropdown"
								(valueChanged)="changeRaceEvent($event)"
								[options]="select2options"
							>
								<option value="{{ row.meeting_id }}" *ngFor="let row of race_events; let i = index">
									{{ row.venue }}
								</option>
							</ng-select2>
						</div>

						<div class="col_profile view_links col-5"> 
							<a class="link" (click)="OpenTrackProfile()">Track profile</a>
						</div>
						<!-- end col_profile -->
					</div>
					<!-- end row -->

					<div class="body" *ngIf="user && user.hasPreviews && event?.meeting_analysis">
						<p><strong>Meeting Analysis: </strong>{{ event?.meeting_analysis }}</p>
					</div>
				</div>
				<!-- end race_info_device -->

				<div class="race_no_wrap device d-lg-none">
					<div class="align-self-center col_race_numbers">
						<div class="cell label"><span>Race</span></div>
						<div class="racenos-slider">
							<ngx-slick-carousel [config]="slideRaceNosConfig" #slickModal>
								<!-- <div class="cell raceno" ngxSlickItem *ngFor="let row of event_races; let i = index" (click)="changeEventRace(row, i)"> -->
								<div
									class="cell raceno"
									ngxSlickItem
									*ngFor="let row of event_races; let i = index"
									(click)="changeEventRace(row, i)"
									routerLink="/form-guide/{{ race_event_slug }}/{{ row?.raceno }}"
								>
									<span
										[ngClass]="{
											active: row.isSelect,
											previewed: row.trc_preview,
											grey: row.race_status == 'FINAL'
										}"
										>{{ row.raceno }}</span
									>
									<div *ngIf="row.trc_preview" class="previewed_bg"></div>
									<!-- end previewed_bg -->
								</div>
							</ngx-slick-carousel>
						</div>
					</div>
				</div>

				<div class="race_wrapper row row-eq-height">
					<div class="col-lg-3 col-sm-4 d-none d-sm-block col_race" *ngIf="selectedrace && !loading">
						<div
							class="race_img"
							*ngIf="!event?.image"
							style="background-image: url('assets/images/form-single-generic.jpg')"
						></div>
						<div
							class="race_img"
							*ngIf="event?.image"
							[style.backgroundImage]="'url(' + event?.image + ')'"
						></div>

						<div class="content">
							<div class="row">
								<h3 class="race_title col-9 align-self-center">Race {{ selectedrace?.raceno }}</h3>
								<!--<div class="col-6 temperature" *ngIf="selectedrace?.weather">-->
								<div class="col-3 col_temperature">
									<img src="/assets/images/weather/partly-cloudy.svg" class="weather" />
									<!--<strong>{{selectedrace?.weather}}</strong>-->
								</div>
							</div>
						</div>
						<!-- end content -->
					</div>
					<!-- end col_race -->

					<div class="col-lg-9 col-sm-8 col-12 col_right" *ngIf="selectedrace && !loading">
						<div class="panel h-100">
							<div *ngIf="selectedrace?.race_status != 'FINAL'" class="venue d-none d-lg-block">
								<ng-select2
									[value]="selectedRaceEvent"
									*ngIf="race_events"
									(valueChanged)="changeRaceEvent($event)"
									[options]="select2options"
									id="venue_dropdown"
								>
									<option value="{{ row.meeting_id }}" *ngFor="let row of race_events; let i = index">
										{{ row.venue }}
									</option>
								</ng-select2>
							</div>
							<div *ngIf="selectedrace?.race_status == 'FINAL'" class="venue d-none d-lg-block">
								<span class="completed_venue">{{ event?.venue }}</span>
							</div>
							<div
								class="body d-none d-lg-block"
								*ngIf="user && user.hasPreviews && event?.meeting_analysis"
							>
								<p><strong>Meeting analysis: </strong>{{ event?.meeting_analysis }}</p>
							</div>

							<div class="row race_no_wrap">
								<div class="col-xl-8 col-lg-12 align-self-center col_race_numbers">
									<div class="cell">Race</div>
									<div
										class="cell raceno"
										*ngFor="let row of event_races; let i = index"
										(click)="changeEventRace(row)"
									>
										<span
											[ngClass]="{
												active: row.isSelect,
												previewed: row.trc_preview,
												grey: row.race_status == 'FINAL'
											}"
											>{{ row.raceno }}</span
										>
										<div *ngIf="row.trc_preview" class="previewed_bg"></div>
										<!-- end previewed_bg -->
									</div>
								</div>
								<div class="view_links col-xl-4 col-lg-12 align-self-center">
									<a class="link" (click)="OpenTrackProfile()">View track profile</a>
								</div>
							</div>

							<div class="race_info_wrap" *ngIf="selectedrace">
								<span class="selected d-block d-sm-none">Race {{ selectedrace?.raceno }}</span>

								<div class="cell">
									<span
										>{{ selectedrace?.name }} {{ selectedrace?.class_code
										}}{{ selectedrace?.class_other }} {{ selectedrace?.distance }}m</span
									>
								</div>
								<!-- <div class="cell"><span>{{selectedrace?.race_start_time | amFromUtc  | amLocal | amDateFormat: 'hh:mm a'}}</span></div> -->
								<div class="cell">
									<span>{{ selectedrace?.formattedDate }}</span>
								</div>
								<div class="cell" *ngIf="selectedrace?.race_prizemoney">
									<span
										>Prize:
										{{ selectedrace?.race_prizemoney | currency: 'USD':'symbol':'1.0-0' }}
										total</span
									>
								</div>
								<div class="cell" *ngIf="selectedrace?.track_going">
									<span class="button rating {{ selectedrace?.track_going | lowercase }}"
										>{{ selectedrace?.track_going | titlecase }}
										{{ selectedrace?.track_rating | lowercase }}
									</span>
								</div>
							</div>
							<div *ngFor="let race of event_races">
								<h4
								class="pill time-pills"
								[attr.data-counter-id]="race?.event_id"
								*ngIf="race.event_id == selectedrace?.event_id"
								[ngClass]="{
									red: selectedrace?.seconds <= 300 && selectedrace?.seconds > 0,
									grey: selectedrace?.seconds <= 0,
									black: selectedrace?.seconds > 300
								}"
							>
								<!-- // FIX COUNTER NOT LOADING IF ? is added selectedrace?.race_start_time -->
								<span
									*ngIf="race?.race_status != 'FINAL'"
									[counter]="race?.race_start_time"
								></span>
								<span *ngIf="race?.race_start_time">{{
									selectedrace?.race_start_time | amFromUtc | amLocal | amDateFormat: 'h:mma'
								}}</span>
							</h4>
							</div>

							<h4 class="pill green" *ngIf="selectedrace?.race_status == 'FINAL'">Completed</h4>
						</div>
					</div>
					<!-- end col_right -->

					<!-- GHOST ELEMENT -->
					<div class="col-lg-3 col-sm-4 d-none d-sm-block col_race" *ngIf="loading">
						<div class="race_img loading-text"></div>
						<div class="content">
							<div class="row">
								<h3 class="race_title col-12"><div class="loading-text"></div></h3>
							</div>
							<div class="body">
								<div class="loading-text"></div>
								<div class="loading-text"></div>
							</div>
						</div>
						<!-- end content -->
					</div>
					<!-- end col_race -->
					
					<!-- GHOST ELEMENT -->
					<div class="col-lg-9 col-sm-8 col-12 col_right" *ngIf="loading">
						<div class="panel h-100">
							<div class="venue">
								<div class="loading-text"></div>
							</div>

							<div class="body">
								<div class="loading-text"></div>
								<div class="loading-text"></div>
							</div>

							<div class="row race_no_wrap">
								<div class="col-12"><div class="loading-text"></div></div>
							</div>

							<div class="race_info_wrap loading-text"></div>
						</div>
					</div>
					<!-- end col_right -->
				</div>
				<!-- end race_wrapper -->

				<div class="form_guide_wrapper">
					<ul class="trc-tabs nav nav-tabs d-md-flex d-none" id="formGuidePageTab" role="tablist" *ngIf="!loading">
						<li class="nav-item" *ngIf="selectedrace?.race_status != 'FINAL'">
							<a
								class="nav-link"
								[ngClass]="{ active: activeTab == 'form-guide-tab' }"
								id="form-guide-tab"
								data-toggle="tab"
								href="#form-guide"
								role="tab"
								aria-controls="home"
								aria-selected="true"
								(click)="changeFormGuidePageTab($event, 'form-guide-tab')"
							>
								<span>Form Guide</span>
							</a>
						</li>
						<li class="nav-item" *ngIf="selectedrace?.race_status == 'FINAL'">
							<a
								class="nav-link"
								[ngClass]="{ active: activeTab == 'result-tab' }"
								id="result-tab"
								data-toggle="tab"
								href="#result"
								role="tab"
								aria-controls="home"
								aria-selected="true"
								(click)="changeFormGuidePageTab($event, 'result-tab')"
							>
								<span>Result</span>
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								[ngClass]="{ active: activeTab == 'speed-maps-tab' }"
								id="speed-maps-tab"
								data-toggle="tab"
								href="#speed-maps"
								role="tab"
								aria-controls="profile"
								aria-selected="false"
								(click)="changeFormGuidePageTab($event, 'speed-maps-tab')"
							>
								<span>Speed Map</span>
							</a>
						</li>
						<li class="nav-item" *ngIf="selectedrace?.trc_preview">
							<a
								class="nav-link confidence-link"
								[ngClass]="{ active: activeTab == 'preview-tab' }"
								id="preview-tab"
								data-toggle="tab"
								href="#preview"
								role="tab"
								aria-controls="contact"
								aria-selected="false"
								(click)="changeFormGuidePageTab($event, 'preview-tab')"
							>
								<div class="wrapped">
									<span>Preview</span>
									<span
										class="confidence"
										[ngClass]="{
											yellow: race_preview?.confidence >= 55 && race_preview?.confidence < 79,
											green: race_preview?.confidence > 79
										}"
									>
										<p>{{ selectedrace?.confidence }}</p>
									</span>
								</div>
								<!-- end wrapped -->
							</a>
						</li>
						<li class="nav-item" [ngClass]="{ disabled: !liveyard }">
							<a
								class="nav-link"
								[ngClass]="{ active: activeTab == 'live-yard-tab' }"
								id="live-yard-tab"
								data-toggle="tab"
								href="#live-yard"
								role="tab"
								aria-controls="contact"
								aria-selected="false"
								(click)="changeFormGuidePageTab($event, 'live-yard-tab')"
							>
								<span>Live Yard</span>
							</a>
						</li>
						<li class="nav-item" *ngIf="selectedrace?.trc_review">
							<a
								class="nav-link"
								[ngClass]="{ active: activeTab == 'review-tab' }"
								id="review-tab"
								data-toggle="tab"
								href="#review"
								role="tab"
								aria-controls="contact"
								aria-selected="false"
								(click)="changeFormGuidePageTab($event, 'review-tab')"
							>
								<span>Review</span>
							</a>
						</li>
					</ul>

					<ul class="trc-tabs nav nav-tabs d-md-flex d-none" *ngIf="loading">
						<li class="nav-item" *ngFor="let numbers of [0,1,2,3]">
							<a href="" class="nav-link">&nbsp;</a>
						</li>
					</ul>

					<div
						class="trc-tabs tab-content"
						id="formGuidePageContent"
						[ngClass]="{ 'no-padding': activeTab == 'preview-tab' }"
					>
						<div class="radio_wrap grey d-flex d-md-none mt-0 mb-4" [ngClass]="mobileTabClass">
							<input
								[checked]="activeTab == 'form-guide-tab'"
								*ngIf="selectedrace?.race_status != 'FINAL'"
								type="radio"
								name="all"
								id="form-guide-radio"
								class="input-hidden"
								(click)="changeFormGuidePageTab($event, 'form-guide-tab')"
							/>
							<label *ngIf="selectedrace?.race_status != 'FINAL'" for="form-guide-radio" class="m-0">
								<span class="btn">Form Guide</span>
							</label>

							<input
								[checked]="activeTab == 'result-tab'"
								*ngIf="selectedrace?.race_status == 'FINAL'"
								type="radio"
								name="all"
								id="result-radio"
								class="input-hidden"
								(click)="changeFormGuidePageTab($event, 'result-tab')"
							/>
							<label *ngIf="selectedrace?.race_status == 'FINAL'" for="result-radio" class="m-0">
								<span class="btn">Result</span>
							</label>

							<input
								[checked]="activeTab == 'speed-maps-tab'"
								type="radio"
								name="all"
								id="speed-map-radio"
								class="input-hidden"
								(click)="changeFormGuidePageTab($event, 'speed-maps-tab')"
							/>
							<label for="speed-map-radio" class="m-0">
								<span class="btn">Speed Map</span>
							</label>

							<input
								[checked]="activeTab == 'preview-tab'"
								*ngIf="!loading && selectedrace?.trc_preview"
								type="radio"
								name="all"
								id="preview-radio"
								class="input-hidden"
								(click)="changeFormGuidePageTab($event, 'preview-tab')"
							/>
							<label *ngIf="!loading && selectedrace?.trc_preview" for="preview-radio" class="m-0">
								<span class="btn">Preview</span>
							</label>

							<input
								[checked]="activeTab == 'review-tab'"
								*ngIf="!loading && selectedrace?.trc_review"
								type="radio"
								name="all"
								id="review-radio"
								class="input-hidden"
								(click)="changeFormGuidePageTab($event, 'review-tab')"
							/>
							<label *ngIf="!loading && selectedrace?.trc_review" for="review-radio" class="m-0">
								<span class="btn">Review</span>
							</label>

							<input
								[checked]="activeTab == 'live-yard-tab'"
								type="radio"
								name="all"
								id="live-yard-radio"
								class="input-hidden"
								(click)="changeFormGuidePageTab($event, 'live-yard-tab')"
							/>
							<label for="live-yard-radio" class="m-0" [ngClass]="{ disabled: !liveyard }">
								<span class="btn">Live Yard</span>
							</label>

							<div class="slider"></div>
						</div>
						<!-- end radio_wrap -->

						<ghost-element type="form-guide" *ngIf="loading_horses"></ghost-element><!-- GHOST ELEMENT -->

						<div
							formguide-tab
							*ngIf="selectedrace?.race_status != 'FINAL' && !loading_horses"
							[ngClass]="{ active: activeTab == 'form-guide-tab' }"
							class="tab-pane"
							id="form-guide"
							role="tabpanel"
							aria-labelledby="form-guide"
							[form_guide_horses]="form_guide_horses"
							[user]="user"
							[scratched_horses]="scratched_horses"
							[bb_count]="bb_count"
							(openBlackbookIn)="OpenBlackbook($event)"
							(toggleAccordionIn)="toggleAccordion($event)"
							(getRaceHorseDetailsIn)="getRaceHorseDetails($event)"
						></div>
						<!-- end tab-pane -->


						<div
							results-tab
							*ngIf="selectedrace?.race_status == 'FINAL' && !loading_horses"
							[ngClass]="{ active: activeTab == 'result-tab' }"
							class="tab-pane"
							id="result"
							[form_guide_horses]="form_guide_horses"
							[user]="user"
							[scratched_horses]="scratched_horses"
							[bb_count]="bb_count"
							role="tabpanel"
							aria-labelledby="result"
							(openBlackbookIn)="OpenBlackbook($event)"
							(toggleAccordionIn)="toggleAccordion($event)"
							(getRaceHorseDetailsIn)="getRaceHorseDetails($event)"
						></div>
						<!-- end tab-pane -->

						<div
							speedmaps-tab
							*ngIf="!speedmap_loading"
							[ngClass]="{ active: activeTab == 'speed-maps-tab' }"
							class="tab-pane"
							id="speed-maps"
							role="tabpanel"
							[selectedrace]="selectedrace"
							[speed_maps_horses]="speed_maps_horses"
							[speedmap_loading]="speedmap_loading"
							aria-labelledby="speed-maps"
						></div>
						<!-- end tab-pane -->

						<ghost-element type="speed-maps-list" [direction]="selectedrace?.map_direction" *ngIf="activeTab == 'speed-maps-tab' && speedmap_loading"></ghost-element><!-- GHOST ELEMENT -->

						<div
							liveyard-tab
							[ngClass]="{ active: activeTab == 'live-yard-tab' }"
							class="tab-pane"
							id="live-yard"
							role="tabpanel"
							aria-labelledby="live-yard"
							[user]="user"
							[liveyard_loading]="liveyard_loading"
							[live_yard_horses]="live_yard_horses"
							[liveyard]="liveyard"
						></div>
						<!-- end tab-pane -->

						<ghost-element type="form-guide" *ngIf="liveyard_loading"></ghost-element><!-- GHOST ELEMENT -->


						<div
							previews-tab
							*ngIf="!loading && selectedrace?.trc_preview"
							[ngClass]="{ active: activeTab == 'preview-tab' }"
							class="tab-pane"
							id="preview"
							role="tabpanel"
							aria-labelledby="preview"
							[race_preview]="race_preview"
							[preview_loading]="preview_loading"
							[user]="user"
							[suggested_bets]="suggested_bets"
							[top_selections]="top_selections"
						></div>
						<!-- end tab-pane -->

						<div
							reviews-tab
							*ngIf="!loading && selectedrace?.trc_review"
							[ngClass]="{ active: activeTab == 'review-tab' }"
							class="tab-pane"
							id="review"
							role="tabpanel"
							aria-labelledby="review"
							[race_review]="race_review"
							[user]="user"
							[runners_to_watch]="runners_to_watch"
						></div>
					</div>
					<!-- end tab-content -->
				</div>
				<!-- end form_guide_wrapper -->

				<!-- <div
					speedmaps-horses-list
					class="speed_maps_horses_list_table panel"
					*ngIf="activeTab == 'speed-maps-tab' && !loading"
					[speed_maps_horses]="speed_maps_horses"
					[form_guide_horses]="form_guide_horses"
					[user]="user"
					[bb_count]="bb_count"
					(openBlackbookIn)="OpenBlackbook($event)"
					(getSpeedMapsRaceHorseDetailsIn)="getSpeedMapsRaceHorseDetails($event)"
				></div> -->
				<!-- end speed_maps_horses_list_table -->

				<ghost-element type="speed-maps-list" *ngIf="activeTab == 'speed-maps-tab' && loading"></ghost-element><!-- GHOST ELEMENT -->

				<div
					previews-tab
					class="previews-tab-external"
					*ngIf="
						!loading &&
						selectedrace?.trc_preview &&
						activeTab != 'preview-tab' &&
						activeTab != 'liveyard-tab'
					"
					[race_preview]="race_preview"
					[preview_loading]="preview_loading"
					[user]="user"
					[suggested_bets]="suggested_bets"
					[top_selections]="top_selections"
				></div>

				<!-- GHOST ELEMENT -->
				<ghost-element type="preview" *ngIf="user && loading"></ghost-element>

				<!-- GHOST ELEMENT -->
				<ghost-element type="preview-2" *ngIf="!user || !user.hasPreviews" [user]="user"></ghost-element>
			</div>
			<!-- end col-10 offset-md-1 -->
		</div>
		<!-- row -->
	</div>
	<!-- container -->
</div>
<!-- section_wrapper -->
