import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PlanService } from '../services/plan.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalService } from '../shared/confirmation-modal/confirmation-modal.service';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '../services/event.service';

import * as moment from 'moment';
import { SegmentAnalyticsService } from '../services/segment-analysis.service';
declare var pinApi: any;

@Component({
	selector: 'app-subscribe-plan',
	templateUrl: './subscribe-plan.component.html',
	styleUrls: ['./subscribe-plan.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SubscribePlanComponent implements OnInit {
	features: any;
	plans: any;
	feature_loading: boolean = true;
	plans_loading: boolean = true;
	plans_selected: boolean = false;
	selectedPlan: any;
	freePlanSelected:any=false;
	activeSlide: any = 'slide-1';
	name: any;
	cardnumber: any;
	expirationdate: any;
	cvc: any;
	output: any;
	ccicon: any;
	ccsingle: any;
	generatecard: any;
	selection: any;
	submitted: any = false;
	payment_gateway_response: any;
	pinpayment_response: any;
	user: any;
	selected_plan: any;
	token: any;
	plan_id: any;
	loading_features: any = false;
	loading_plans: any = false;
	payment_progress: any = 0;
	features_count: any = 0;
	plans_count: any = 0;
	selected_features: any = [];
	basePlans: any = [];
	addOnPlans: any = [];
	combinePlans: any = [];
	selectedBasePlan: any = [];
	selectedAddonPlan: any = [];
	plan_duration: any = 'weekly';
	premium_plan: any;
	discount: any;
	total_price: any;
	premium_subscribed: any = false;
	display_checkout: any = true;
	subscription_in_cancellation: any = false;
	activeBasePlan: any = null;
	activeAddonPlan: any = null;
	paymentForm: FormGroup = this.formBuilder.group({
		number: ['', [Validators.required]],
		name: ['', [Validators.required]],
		expiry_month: ['', [Validators.required]],
		cvc: ['', [Validators.required]],
		expiry_year: ['', [Validators.required]],
	});

	pinpayment_errors: any = {
		number: null,
		name: null,
		expiry_month: null,
		cvc: null,
		expiry_year: null,
	};

	constructor(
		private PlanService: PlanService,
		private route: Router,
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toastr: ToastrService,
		private ConfirmationModalService: ConfirmationModalService,
		private AlertModalService: AlertModalService,
		private activatedroute: ActivatedRoute,
		private eventService: EventService,
		private segmentAnalyticsService: SegmentAnalyticsService
	) {
		if (activatedroute.params) {
			this.activatedroute.params.subscribe((params) => {
				this.token = params.token;
				if (this.token) this.user = this.getUser();
			});
		}

		if (!this.user) {
			this.user = localStorage.getItem('trc_user');
			this.user = JSON.parse(this.user);
			this.getUserSubscription();
		}
	//	console.log(this.premium_subscribed);
	}

	ngOnInit(): void {
		// this.getFeaturesPlans();
		this.getFeaturesPlansRc()
		// this.getPromotionalPlans();

	}

	async getPromotionalPlans() {
		this.feature_loading = true;
		await this.PlanService.getPromotionalPlans().subscribe((data: any) => {
			if (data) {
				if (data.plans) {
					this.premium_plan = data.plans[0];
					if (this.premium_plan) {
						this.premium_plan.prices.forEach((price: any) => {
							if (price.duration === 'weekly') {
								this.premium_plan.selected_plan = price;
							}
						});
						this.discount = ((this.total_price - this.premium_plan.selected_plan.price) / this.total_price) * 100;
					}
					// this.setPlanDuration('monthly');
				}
			}
			this.feature_loading = false;
		});
	}

	get f() {
		return this.paymentForm.controls;
	}

	toggleBasePlan(item: any, planDuration: any) {
		if (this.activeBasePlan === item) {
		  this.activeBasePlan = null;
		  this.selectedBasePlan = [];
		} else {
		  this.activeBasePlan = item;
		  this.selectedBasePlan = [item];
		  this.segmentAnalyticsService.track('Subscription Package Selected', {
			"subscription_tier": item.title,
		  })
		}
		this.selectBasePlan(item, planDuration);
		this.freePlanSelected = false;
	  }
	
	  toggleAddonPlan(item: any, planDuration: any) {
		if (this.activeAddonPlan === item) {
			
		  this.activeAddonPlan = null;
		  this.selectedAddonPlan = [];
		} else {
		  this.activeAddonPlan = item;
		  
		  this.selectedAddonPlan = [item];
		  this.segmentAnalyticsService.track('Addon Package Selected', {
			"add_on_tier": item.title,
		  })
		}
		this.selectAddonPlan(item, planDuration);
	  }
	
	  isActiveBase(item: any): boolean {
		return this.activeBasePlan === item;
	  }
	
	  isActiveAddon(item: any): boolean {
		return this.activeAddonPlan === item;
	  }
	
	  selectBasePlan(item: any, planDuration: any) {
		// Your existing logic for selecting the base plan
		// Ensure to only add if not already included
		if (!this.selectedBasePlan.includes(item)) {
		  this.selectedBasePlan.push(item);
		}
	  }
	
	  selectAddonPlan(item: any, planDuration: any) {
		// Your existing logic for selecting the addon plan
		// Ensure to only add if not already included
		if (!this.selectedAddonPlan.includes(item)) {
		  this.selectedAddonPlan.push(item);
		}
	  }
	getUserSubscription() {
		if (this.user != null && this.user != 'undefined' && this.user.token) {
			this.token = this.user.token;
			this.selected_plan = localStorage.getItem('selected_plan');
			if (this.selected_plan) {
				this.selected_plan = JSON.parse(this.selected_plan);
				this.plan_duration = this.selected_plan.duration.duration ? this.selected_plan.duration.duration : 'weekly';
			}
			if (this.user && this.user.subscription && this.user.subscription.plan && this.user.subscription.status) {
				if (this.user.subscription.plan.premium_plan) {
					this.premium_subscribed = true;
				}
				if (this.user.subscription.cancelled) {
					this.subscription_in_cancellation = true;
				}
				if (this.user.subscription && this.user.subscription.pricing_plan && this.user.subscription.pricing_plan.duration) {
					this.plan_duration = this.user.subscription.pricing_plan.duration;
				}
			}
		}
	}
	getPlanPrice(prices:any,plan_duration:string) {
		let plan_price = prices.filter((item:any)=>{
			return item.duration == plan_duration;
		})
		return plan_price[0].price;
	}

	getTrialDays(prices: any[], planDuration: string): string | null {
		const matchingPrice = prices.find(price => price.duration === planDuration);
		return matchingPrice?.trialDays || null;
	}

	async getUser() {
		await this.userService.getAuthUser(this.token).subscribe((data: any) => {
			if (data) {
				if (data.result) {
					localStorage.setItem('trc_user', JSON.stringify(data.result));
					this.user = data.result;
					this.getUserSubscription();
					this.eventService.userLoggedin();
				}
			}
		});
	}

	async getFeaturesPlans() {
		this.feature_loading = true;
		await this.PlanService.getFeaturesPlans().subscribe((data: any) => {
			if (data) {
				if (data.features) {
					this.features = data.features;
					this.premium_plan = data.premium_plan;
					
					this.features.forEach((element: any) => {
						element.points = JSON.parse(element.short_description);
						if (this.selected_plan && !this.selected_plan.premium_plan && this.selected_plan.selected_features) {
							this.selected_plan.selected_features.forEach((selected_feature: any) => {
								if (selected_feature.id === element.id) element.checked = true;
							});
						}

						if (
							this.user &&
							this.user.subscription &&
							this.user.subscription.plan &&
							!this.premium_subscribed
						) {
							this.user.subscription.features_array.forEach((selected_feature: any) => {
								if (selected_feature === element.id) element.checked = true;
							});
						}
					});
					this.features_count = this.features.length;
					this.setPlanDuration(this.plan_duration);
				}
			}
			this.feature_loading = false;
		});
	}
	async getFeaturesPlansRc(){
		this.feature_loading = true;

	
		await this.PlanService.getFeaturesPlansWithItunesRc().subscribe(async (data: any) => {
		  if (data) {
			this.feature_loading = false;

			if(data?.status){
				this.basePlans = data?.plans.filter((item:any)=>{
					return item?.base_plan === 1 && item?.is_active === 1
				});
				this.addOnPlans =data?.plans.filter((item:any)=>{
					return item?.base_plan === 0 && item?.is_active === 1
				});
				this.combinePlans =data?.plans.filter((item:any)=>{
					return item?.base_plan === 1 && item?.is_active === 0
				});
				console.log("this.basePlans : updated", this.basePlans)
				console.log("this.addOnPlans : updated", this.addOnPlans)
				console.log("this.combinePlans : updated", this.combinePlans)
			}
	
		  }else{
		  }
		})
	  }
	
	async getPlans(selected_features: any) {
		this.plans_loading = true;
		let premium_plan_selected;
		if (this.selected_features.length == this.features.length) {
			premium_plan_selected = true;
		} else {
			premium_plan_selected = false;
		}

		if (!premium_plan_selected) {
			await this.PlanService.getPlans(selected_features).subscribe((data: any) => {
				if (data) {

					if (data.plans) {
						this.plans = data.plans;
						// if (this.plans.length == 1) this.plans[0].premium_plan = 0;
						this.plans.forEach((element: any) => {
							element.prices.forEach((price: any) => {
								if (price.duration === this.plan_duration && !element.premium_plan) {
									this.selected_plan = element;
									this.selected_plan.duration = price;
									this.selected_plan.selected_features = [];
									this.features.forEach((feature: any) => {
										if (feature.checked) this.selected_plan.selected_features.push(feature);
									});
									this.validatePlanandCheckout(this.selected_plan);
								}
							});
						});

						if (this.plans.length == 1 && this.plans[0].premium_plan) {
							this.premium_plan.prices.forEach((price: any) => {
								if (price.duration === this.plan_duration) {
									this.selected_plan = this.premium_plan;
									this.selected_plan.duration = price;
									this.validatePlanandCheckout(this.selected_plan);
								}
							});
						}
					}
				}
				this.plans_loading = false;
			});
		} else {
			this.premium_plan.prices.forEach((price: any) => {
				if (price.duration === this.plan_duration) {
					this.selected_plan = this.premium_plan;
					this.selected_plan.duration = price;
					this.validatePlanandCheckout(this.selected_plan);
				}
			});
		}
	}

	async validatePlanandCheckout(plan: any) {
		if (this.premium_subscribed && !plan.premium_plan) {
			let message =
				'<div>' +
				'<p>Do you want to downgrade your package from ' +
				this.user.subscription.plan_name +
				' subscription?</p>' +
				'</div>';

			await this.ConfirmationModalService.confirm('Downgrade Package', message)
				.then((confirmed) => {
					if (confirmed) {
						localStorage.setItem('selected_plan', JSON.stringify(plan));
						localStorage.setItem('final_selections', JSON.stringify(plan));
						this.route.navigate(['/checkout-rc']);
					} else {
						// this.route.navigate(['/'])
					}
				})
				.catch(() => this.route.navigate(['/']));
		} else {
			if (
				plan.duration &&
				this.user.subscription && this.user.subscription.pricing_plan &&
				plan.duration.id === this.user.subscription.pricing_plan.id &&
				!this.user.subscription.cancelled
			) {
				if (plan.premium_plan) {
					let message =
						'<div>' +
						'<p>You are already subscribed to the Premium package, click the button below to return to the dashboard</p>' +
						'</div>';

					await this.AlertModalService.confirm('Already Subscribed', message)
						.then((confirmed) => {
							if (confirmed) {
								this.route.navigate(['/']);
							} else {
								// this.route.navigate(['/'])
							}
						})
						.catch(() => this.route.navigate(['/']));
				} else {
					this.toastr.warning('You have already subscribed to this plan');
				}
			} else {
				localStorage.setItem('selected_plan', JSON.stringify(plan));
				this.route.navigate(['/checkout-rc']);
			}
		}
	}
	skip(){
		if(this.display_checkout){
			this.segmentAnalyticsService.track('Subscription Skipped', {
				"skipped_on_screen": "Level Up Subscription",
			  })
			
		}else{
			this.segmentAnalyticsService.track('Subscription Skipped', {
				"skipped_on_screen": "Select Subscription",
			  })
		}
	}
	async setPlanDuration(duration: any) {
		this.plan_duration = duration;
		this.total_price = 0;

		if (this.premium_plan) {
			this.premium_plan.prices.forEach((price: any) => {
				if (price.duration === 'weekly') {
					this.premium_plan.selected_plan = price;
				}
			});
			this.discount = ((this.total_price - this.premium_plan.selected_plan.price) / this.total_price) * 100;
		}

	//	console.log(this.features);
	}
	filterPricesByDurationForAllPlans(planData:any, duration:string) {
		return planData.map((plan:any) => ({
			...plan,
			prices: plan.prices.filter((price:any) => price.duration === duration),
			duration: plan.prices.filter((price:any) => price.duration === duration)[0]
		}));
	}
	convertToArray(jsonArray:any){
		return JSON.parse(jsonArray);
	  }

	async checkOut(step:any) {
		if(step == 'step1'){
			this.display_checkout = true;
			if(this.selectedBasePlan.length>0 && this.user?.id){
				this.segmentAnalyticsService.identify({
					'userId': this.user?.id, 
					'subscription_tier': this.selectedBasePlan[0].title 
				  })
			}
			
			return
		}
		let selected_features: any = [];
		let selected_plans: any = [...this.selectedBasePlan,...this.selectedAddonPlan];
		// if(this.selectedAddonPlan.length>0 && this.user?.id){
		// 	this.segmentAnalyticsService.identify({
		// 		'userId': this.user?.id, 
		// 		'add_on_tier': this.selectedAddonPlan[0].title 
		// 	  })
		// }
		if(selected_plans.length>0){
			if(selected_plans.length>1){
				let concatenatedTitles = selected_plans.map((plan:any) => plan.title).join(', ');

				let combinePlan = this.combinePlans.filter((item:any)=>{
					return item.title === concatenatedTitles;
				})
				
				let finalPlans = [...selected_plans,...combinePlan]
				
				finalPlans =  this.filterPricesByDurationForAllPlans(finalPlans, this.plan_duration);
				localStorage.setItem('selected_plan', JSON.stringify(finalPlans[finalPlans.length-1]));

				localStorage.setItem('final_selections', JSON.stringify(finalPlans));
				this.route.navigate(['/checkout-rc']);
			}else
			{
				selected_plans =  this.filterPricesByDurationForAllPlans(selected_plans, this.plan_duration);
				localStorage.setItem('selected_plan', JSON.stringify(selected_plans[0]));
				localStorage.setItem('final_selections', JSON.stringify(selected_plans));
				this.route.navigate(['/checkout-rc']);

			}
			// console.log("interval",this.plan_duration)
			return;
		}
	
		if(!this.freePlanSelected && this.selectedBasePlan.length === 0 && this.selectedAddonPlan.length === 0){
			this.toastr.warning('Please select one more more plans to proceed.');	
			return;	
		}
		localStorage.removeItem('selected_plan')
		this.route.navigate(['/thank-you']);

	}

	async premiumCheckOut() {
		this.premium_plan.prices.forEach((price: any) => {
			if (price.duration === 'weekly') {
				this.selected_plan = this.premium_plan;
				this.selected_plan.duration = price;
				localStorage.setItem('selected_plan', JSON.stringify(this.selected_plan));
				this.route.navigate(['/checkout-rc']);
			}
		});
	}

	async selectFeature(index: any) {
		// if (this.premium_subscribed) {
		// 	// this.toastr.warning('You are already subscribed to the Platinum package');
		// }
	//	console.log(index);
		if (this.features[index].checked) {
			this.features[index].checked = false;
		} else {
			this.features[index].checked = true;
		}
	}

	async nextSlide() {
		let selected_features: any = [];
		if(this.features) {
			this.features.forEach((element: any) => {
				if (element.checked) selected_features.push(element.id);
			});
		}
		if (selected_features.length > 0) {
			this.getPlans(selected_features);
		} else {
			this.toastr.warning('Please select one or more features so that we get best plans for you..');
		}
	}

	async unSubscribe() {
		let message =
			'<div>' +
			'<p>Do you want to unsubscribe from ' +
			this.user.subscription.plan_name +
			' package?</p>' +
			'</div>';

		await this.ConfirmationModalService.confirm('Unsubscribe', message)
			.then((confirmed) => {
				if (confirmed) {
					//cancel-subscription

					let data = {
						subscr_id: this.user.subscription.id,
					};

					this.userService.cancelSubscription(data, this.token).subscribe(
						(response: any) => {
							if (response.status) {
								this.toastr.success(response.message);
								this.getUser();
								this.premium_subscribed = true;
								this.subscription_in_cancellation = true;
							} else {
								this.toastr.error(response.message);
							}
						},
						(error) => {
							console.log(error);
						}
					);
				} else {
					// this.route.navigate(['/'])
				}
			})
			.catch(() => this.route.navigate(['/']));
	}

	async reactivatePremium() {
		let message =
			'<div>' + '<p>Do you want to Reactivate  ' + this.user.subscription.plan_name + ' package?</p>' + '</div>';

		let data = {
			subscr_id: this.user.subscription.id,
		};

		this.userService.reactivateSubscription(data, this.token).subscribe(
			(response: any) => {
				if (response.status) {
					this.toastr.success(response.message);
					this.getUser();
					this.premium_subscribed = true;
					this.subscription_in_cancellation = false;
				} else {
					this.toastr.error(response.message);
				}
			},
			(error) => {
				console.log(error);
			}
		);
		//TODO:: should this be done for all subscriptions including premium
		this.premium_subscribed = true;
	}

	handleBackClick() {
		this.display_checkout = true;
	}

	selectFreePlan(){
		this.freePlanSelected = !this.freePlanSelected;
		this.activeBasePlan = null;
		this.selectedBasePlan = [];
	}
}
