<div class="onboarding_wrapper change_password">
	<div class="floating_logo">
		<a routerLink="/">
			<img src="assets/images/logos/logo-vertical-light.png"/>
		</a>
	</div>

	<div class="container-fluid outer">
		<div class="row">
			<div class="col_form text-center">
				<div class="inner">
					<h1 class="page_title white">Change Password</h1>

					<form class="form_wrapper" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
						<div class="form-row">
							<div class="form-group col-12">
								<div *ngIf="errors && errors?.error" class="alert alert-danger mt-3">
									{{ errors?.error }}
								</div>
							</div>

							<div class="form-group col-12" style="display: none">
								<label>Email address</label>
								<input
									type="email"
									class="form-control"
									formControlName="email"
									[(ngModel)]="email"
									readonly
								/>
							</div>

							<div class="form-group col-12">
								<label>New Password</label>
								<input
									type="password"
									class="form-control"
									formControlName="password"
									placeholder="New Password"
								/>
							</div>

							<div class="form-group col-12">
								<label>Confirm New Password</label>
								<input
									type="password"
									class="form-control"
									formControlName="password_confirmation"
									placeholder="Confirm new password"
								/>
							</div>
						</div>
						<button class="btn blue arrow_large" type="submit">Reset Password</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
