import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
	selector: '[speedmaps-tab]',
	templateUrl: './speedmaps-tab.component.html',
	styleUrls: ['./speedmaps-tab.component.scss'],
})
export class SpeedMapsTabComponent implements OnInit {
	@Input() selectedrace: any;
	@Input() speed_maps_horses: any;
	@Input() speedmap_loading: boolean;

	constructor() {}

	ngOnInit(): void {}

	onImgError(event: any, seq_no: any) {
		event.target.src = 'assets/images/jockeys/jockey' + seq_no + '.png';
		//Do other stuff with the event.target
	}
}
