<div class="breadcrumbs_wrapper">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title">Resources</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/">Home</a></li>
							<li><a routerLink="/resources">Resources</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end breadcrumbs_wrapper -->

<div class="resourcespage_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-lg-10 offset-lg-1">
				<div class="row">
					<div
						class="col-xl-8 order-xl-5 resources_wrapper"
						*ngIf="resources?.length > 0 && !loadingResourcesList"
					>
						<div class="panel">
							<div class="row">
								<div class="col-md-6 resources_single d-flex flex-column" *ngFor="let row of resources | paginate
								: { itemsPerPage: 10, currentPage: page, totalItems: totalResourcesCount }">
									<a (click)="gotoSingleResource(row)">
										<div
											class="resources_img"
											[style.backgroundImage]="'url(' + row.image_url + ')'"
										></div>
									</a>
									<div class="content flex-grow-1 d-flex flex-column">
										<div class="row title_wrap">
											<div class="col col-8 align-self-center">
												<h3 class="new_title">
													<a (click)="gotoSingleResource(row)">{{ row?.title }}</a>
												</h3>
											</div>
											<div class="col col-4 pl-0 align-self-center text-right">
												<div class="date">{{ row?.created_at | date: 'd MMM y' }}</div>
											</div>
										</div>
										<div class="body" *ngIf="row?.short_description">
											{{
												row?.short_description.length > 100
													? (row?.short_description | slice: 0:100) + '..'
													: row?.short_description
											}}
										</div>
										<div class="read-link mt-auto">
											<a class="link small" (click)="gotoSingleResource(row)">Read more</a>
										</div>
									</div>
									<!-- end content -->
								</div>
								<!-- end resources_single -->
							</div>
							<div class="row pagination_wrapper">
								<pagination-controls (pageChange)="pageChanged($event)"> </pagination-controls>
							</div>
						</div>
						<!-- end inner -->
					</div>
					<!-- end resources_wrapper-->

					<!-- GHOST ELEMENT -->
					<div class="col-xl-8 order-xl-5 resources_wrapper" *ngIf="loadingResourcesList">
						<div class="panel">
							<div class="row">
								<div class="col-md-6 resources_single">
									<div class="resources_img loading-text"></div>
									<div class="content">
										<h3 class="new_title"><div class="loading-text"></div></h3>
										<div class="body">
											<div class="loading-text"></div>
											<div class="loading-text"></div>
										</div>
										<div class="loading-text"></div>
									</div>
									<!-- end content -->
								</div>
								<!-- end resources_single -->

								<div class="col-md-6 resources_single">
									<div class="resources_img loading-text"></div>
									<div class="content">
										<h3 class="new_title"><div class="loading-text"></div></h3>
										<div class="body">
											<div class="loading-text"></div>
											<div class="loading-text"></div>
										</div>
										<div class="loading-text"></div>
									</div>
									<!-- end content -->
								</div>
								<!-- end resources_single -->

								<div class="col-md-6 resources_single">
									<div class="resources_img loading-text"></div>
									<div class="content">
										<h3 class="new_title"><div class="loading-text"></div></h3>
										<div class="body">
											<div class="loading-text"></div>
											<div class="loading-text"></div>
										</div>
										<div class="loading-text"></div>
									</div>
									<!-- end content -->
								</div>
								<!-- end resources_single -->

								<div class="col-md-6 resources_single">
									<div class="resources_img loading-text"></div>
									<div class="content">
										<h3 class="new_title"><div class="loading-text"></div></h3>
										<div class="body">
											<div class="loading-text"></div>
											<div class="loading-text"></div>
										</div>
										<div class="loading-text"></div>
									</div>
									<!-- end content -->
								</div>
								<!-- end resources_single -->
							</div>
						</div>
						<!-- end inner -->
					</div>
					<!-- end news_wrapper-->

					<div class="col-xl-4 order-xl-1 col_left">
						<div class="row">
							<div
								class="col-xl-12 col-md-6 featured_resources"
								*ngIf="featured_resource && !loadingFeaturedResource"
							>
								<a (click)="gotoSingleResource(featured_resource)">
									<div
										class="resources_img"
										[style.backgroundImage]="'url(' + featured_resource?.image_url + ')'"
									>
										<h4 class="pill">Featured Article</h4>
									</div>
								</a>
								<div class="content">
									<h3 class="new_title">
										<a (click)="gotoSingleResource(featured_resource)">{{
											featured_resource?.title
										}}</a>
									</h3>
									<div class="body" *ngIf="featured_resource?.short_description">
										{{
											featured_resource?.short_description.length > 100
												? (featured_resource?.short_description | slice: 0:100) + '..'
												: featured_resource?.short_description
										}}
									</div>
									<a class="link small" (click)="gotoSingleResource(featured_resource)">Read more</a>
								</div>
								<!-- end content -->
							</div>
							<!-- end featured_resources -->

							<!-- GHOST ELEMENT -->
							<div class="col-xl-12 col-md-6 featured_resources" *ngIf="loadingFeaturedResource">
								<div class="resources_img loading-text"></div>
								<div class="content">
									<h3 class="new_title"><div class="loading-text"></div></h3>
									<div class="body">
										<div class="loading-text"></div>
										<div class="loading-text"></div>
									</div>
									<div class="loading-text"></div>
								</div>
								<!-- end content -->
							</div>
							<!-- end featured_resources -->

							<div class="col-xl-12 col-md-6 limited_time_offer sidebar" *ngIf="!loadingOffers && offer">
								<div class="panel">
									<div class="col_content">
										<h2 class="title">{{ offer?.heading }}</h2>

										<div class="body white">
											{{ offer?.description }}
										</div>

										<a *ngFor="let row of offer?.buttons" (click)="OfferClicked(row)" class="btn">{{
											row?.key
										}}</a>
									</div>
								</div>
							</div>
							<!-- end limited_time_offer -->

							<div class="previews_widget" *ngIf="previews?.length > 0 && !loadingTodaysPreviews">
								<div class="col-12">
									<div class="row">
										<h2 class="heading col-sm-7 align-self-center">Upcoming previews</h2>
										<div class="view_links col-sm-5 align-self-center text-right">
											<a
												class="title_link black no_after small d-none d-sm-block"
												routerLink="/form-guide"
											>
												View all
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="7.46"
													height="12.625"
													viewBox="0 0 7.46 12.625"
												>
													<path
														class="line_arrow"
														data-name="Path 393"
														d="M24.331,17.232l5.355-5.623L24.331,5.986"
														transform="translate(-23.607 -5.296)"
														fill="none"
														stroke="#000"
														stroke-miterlimit="10"
														stroke-width="2"
													/>
												</svg>
											</a>
										</div>
									</div>

									<div class="row">
										<div class="col-12 previews_single" *ngFor="let row of previews">
											<div class="panel">
												<div
													class="p-row row"
													routerLink="form-guide/{{ row.slug }}/{{ row.raceno }}"
												>
													<div
														class="col_title_link col align-self-center"
														routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}"
													>
														<a
															routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}"
															class="title_link small"
															>{{ row.venue }}</a
														>
													</div>

													<div class="col_next_race text-right col align-self-center" routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}">
														<a>
															<div class="next_race">
																<span class="next_text">Next Race</span>
																<span class="race_no align-middle"
																	><p>{{ row.raceno }}</p></span
																>
															</div>
														</a>
													</div>
												</div>
											</div>
										</div>
										<!-- end previews_single -->

										<div class="col-12 mobile_link_bottom">
											<a class="title_link black no_after small" routerLink="/form-guide">
												View all
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="7.46"
													height="12.625"
													viewBox="0 0 7.46 12.625"
												>
													<path
														class="line_arrow"
														data-name="Path 393"
														d="M24.331,17.232l5.355-5.623L24.331,5.986"
														transform="translate(-23.607 -5.296)"
														fill="none"
														stroke="#000"
														stroke-miterlimit="10"
														stroke-width="2"
													/>
												</svg>
											</a>
										</div>
										<!-- end col-12 -->
									</div>
								</div>
							</div>
							<!-- end previews_wrapper -->

							<!-- GHOST ELEMENT -->
							<div class="row previews_widget" *ngIf="loadingTodaysPreviews">
								<div class="col-12">
									<div class="row">
										<h2 class="heading col-lg-6 align-self-center"></h2>
									</div>
									<div class="row">
										<div class="col-12 previews_single">
											<div class="panel">
												<div class="p-row row">
													<div class="col_title_link col align-self-center">
														<div class="loading-text"></div>
													</div>
													<div class="col_next_race text-right col align-self-center">
														<div class="loading-text"></div>
													</div>
												</div>
											</div>
										</div>
										<!-- end previews_single -->

										<div class="col-12 previews_single">
											<div class="panel">
												<div class="p-row row">
													<div class="col_title_link col align-self-center">
														<div class="loading-text"></div>
													</div>
													<div class="col_next_race text-right col align-self-center">
														<div class="loading-text"></div>
													</div>
												</div>
											</div>
										</div>
										<!-- end previews_single -->

										<div class="col-12 previews_single">
											<div class="panel">
												<div class="p-row row">
													<div class="col_title_link col align-self-center">
														<div class="loading-text"></div>
													</div>
													<div class="col_next_race text-right col align-self-center">
														<div class="loading-text"></div>
													</div>
												</div>
											</div>
										</div>
										<!-- end previews_single -->

										<div class="col-12 previews_single">
											<div class="panel">
												<div class="p-row row">
													<div class="col_title_link col align-self-center">
														<div class="loading-text"></div>
													</div>
													<div class="col_next_race text-right col align-self-center">
														<div class="loading-text"></div>
													</div>
												</div>
											</div>
										</div>
										<!-- end previews_single -->
									</div>
								</div>
							</div>
							<!-- end previews_wrapper -->
						</div>
					</div>
					<!-- end col_left -->
				</div>
				<!-- end row -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>
