<div class="breadcrumbs_wrapper">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title">Terms & Conditions</h1>
					<div class="breadcrumbs">
						<ul>
							<li>
								<a routerLink="/">Home</a>
							</li>
							<li>
								<a routerLink="/terms-and-conditions">Terms & Conditions</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end container -->
</div>
<!-- end breadcrumbs_wrapper -->
<div class="terms_and_conditions_wrapper">
	<div class="container narrow">
		<div class="row">
			<div class="col-md-10 offset-md-1">
				<p>
					By downloading or using the app, these terms will automatically apply to you – you should make sure 
					therefore that you read them carefully before using the app. You’re not allowed to copy, or modify 
					the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract 
					the source code of the app, and you also shouldn’t try to translate the app into other languages, or 
					make derivative versions. The app itself, and all the trade marks, copyright, database rights and other 
					intellectual property rights related to it, still belong to theraceclub.com.au.
				</p>
				<p>
					racingcentral.com.au is committed to ensuring that the app is as useful and efficient as possible. 
					For that reason, we reserve the right to make changes to the app or to charge for its services, at 
					any time and for any reason. We will never charge you for the app or its services without making 
					it very clear to you exactly what you’re paying for.
				</p>
				<p>
					The Racing Central app stores and processes personal data that you have provided to us, in order to 
					provide our Service. It’s your responsibility to keep your phone and access to the app secure. We 
					therefore recommend that you do not jailbreak or root your phone, which is the process of removing 
					software restrictions and limitations imposed by the official operating system of your device. It could 
					make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security 
					features and it could mean that the The Racing Central app won’t work properly or at all.
				</p>
				<p>
					You should be aware that there are certain things that racingcentral.com.au will not take responsibility 
					for. Certain functions of the app will require the app to have an active internet connection. The connection 
					can be Wi-Fi, or provided by your mobile network provider, but theraceclub.com.au cannot take responsibility 
					for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of 
					your data allowance left.
				</p>
				<p>
					If you’re using the app outside of an area with Wi-Fi, you should remember that your terms of the agreement 
					with your mobile network provider will still apply. As a result, you may be charged by your mobile provider 
					for the cost of data for the duration of the connection while accessing the app, or other third party charges. 
					In using the app, you’re accepting responsibility for any such charges, including roaming data charges if you 
					use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are 
					not the bill payer for the device on which you’re using the app, please be aware that we assume that you have 
					received permission from the bill payer for using the app.
				</p>
				<p>
					Along the same lines, racingcentral.com.au cannot always take responsibility for the way you use the app i.e. 
					You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to 
					avail the Service, theraceclub.com.au cannot accept responsibility.
				</p>
				<p>
					With respect to racingcentral.com.au’s responsibility for your use of the app, when you’re using the app, 
					it’s important to bear in mind that although we endeavor to ensure that it is updated and correct at all times, 
					we do rely on third parties to provide information to us so that we can make it available to you. theraceclub.com.au 
					accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this 
					functionality of the app.
				</p>
				<p>
					At some point, we may wish to update the app. The app is currently available on Android &amp; iOS –
					the requirements for both systems(and for any additional systems we decide to extend the availability
					of the app to) may change, and you’ll need to download the updates if you want to keep using the
					app. theraceclub.com.au does not promise that it will always update the app so that it is relevant
					to you and/or works with the Android &amp; iOS version that you have installed on your device.
					However, you promise to always accept updates to the application when offered to you, We may also wish to
					stop providing the app, and may terminate use of it at any time without giving notice of termination
					to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to
					you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your
					device.
				</p>
				<p><strong>Changes to This Terms and Conditions</strong></p>
				<p> 
					We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically 
					for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page. These 
					changes are effective immediately after they are posted on this page.
				</p>
				<p><strong>Contact Us</strong></p>
				<p>If you have any questions or suggestions about our Terms and Conditions, do not hesitate
					to contact us.
				</p>
				<br />
				<h4 class="display-2">Membership Terms and Conditions</h4>
				<p>Welcome to The Race Club. The Terms & Conditions listed below apply whenever you access the Site on
					any device in any form.</p>
				<h4>General</h4>
				<ul class="list-inline">
					<li>Gambling is a risk. The Race Club Australia is not responsible for any persons gambling losses.
						The Race Club Australia provide a ‘point of view’ or ‘opinion’ as part of our service and in no way
						or manner are we providing advice, financial or other. Any monetary investment based on information
						provided on the site (or affiliated sites) is at the risk of you or third party on behalf of you,
						the investor. All quoted data including horse names, numbers, trainers, jockeys, odds, statistics
						and sectionals should be checked against any official source in state or body of relevance
					</li>
					<li>This website in all forms is restricted to users that are 18 years old or over.</li>
					<li>If anyone brings a claim against us related to your actions or on our Site, you will hold us
						harmless from and against all damages incurred from your actions on our Site.
					</li>
				</ul>

				<h4>Use of the site</h4>
				<ul class="list-inline">
					<li>
						The owner (Racing Central) is the operator of this Site in conjunction with our technical and development 
						team ‘Pink Panda’.
					</li>
					<li>
						The term ‘Site’ is used and is meant as the features and services that are provided on our website at 
						www.theraceclub.com.au including mobile, Tablet and app versions.
					</li>
					<li>
						Your use of and access to services on this Site means you agree and accept the terms and conditions 
						laid out in this document. Consequently, you agree to be bound by these terms and conditions.
					</li>
					<li>
						Racing Central may amend these terms and conditions at any time. If you use the Site, it is deemed 
						you have accepted any amendments to these terms and conditions. Racing Central will notify on 
						the Site any amendments made to this document.
					</li>
					<li>
						Racing Central may change or discontinue services on the Site and/or its functionality and/or the 
						content made available through the Site at any time, without notice to users.
					</li>
					<li>
						In no circumstances does Racing Central permit Users to share paid content to non paying members 
						of Racing Central product and service/s. If said User is identified in anyway sharing paid content, 
						Racing Central  reserves the right to abolish the said Users account without any notice and 
						ramifications toward us. If User is found to have received financial remuneration by sharing Racing 
						Central’s paid content, Racing Central reserves the right to pursue legal action. Paid content: Content 
						that is only available to paying subscribers of Racing Central service and/or product in any tier.
					</li>
					<li>
						All intellectual property on this Site belongs to Racing Central or it’s affiliates. You obtain no 
						interest in that intellectual property as a user of this Site. You may not do anything which 
						interferes with or breaches intellectual property rights in the content.
					</li>
					<li>
						You are not permitted to copy or republish anything you find on the Site without the copyright 
						or trademark owners written permission.
					</li>
					<li>
						The logos of the Site are trademarks of Racing Central. Nothing displayed on the Site should be 
						construed as granting right of use of any logo, trademark or heading displayed on the Site, 
						without the written permission from Racing Central.
					</li>
				</ul>

				<h4>Liability & Indemnity</h4>
				<ul class="list-inline">
					<li>
						Racing Central provides content, product, data and race analysis but does not guarantee or ensure 
						the accuracy of this said content which you access through this Site. The content is provided to you 
						on an ‘opinion/interpretive’ basis and we advise that you are responsible for assessing the risk of 
						using the content in any form you choose. All content which you access through this Site may be 
						changed at Racing Central’s discretion and without notice.
					</li>
					<li>
						Racing Central will have no responsibility or liability in relation to any loss or damage that you 
						may incur from your use of or access to this Site.
					</li>
					<li>
						Racing Central does not guarantee that functions of our Site will be uninterrupted or that there will 
						be no technical problems which prevent or delay delivery of any content which is generally accessible 
						through the Site.
					</li>
					<li>
						This Site may include third party links to sites which are not related to this Site. Racing Central has 
						no control over the terms and conditions or ramifications associated with third party links.
					</li>
				</ul>

				<h4>Pricing</h4>
				<ul class="list-inline">
					<li>
						The price of the subscription, including where applicable, any introductory pricing for a specified 
						period, will be displayed at the time of purchase. All prices include GST unless otherwise specified.
					</li>
					<li>
						Racing Central may amend the pricing schedule by giving 14 days prior notice to you. Once new pricing 
						takes effect, Racing Central may alter your automatic payment arrangements accordingly.
					</li>
					<li>
						Where your Subscription is for a specified plan period or a fixed term with regular, periodic payments, 
						then the total price that you will pay is the total sum of all regular periodic payments throughout 
						the specified plan period or fixed term.
					</li>
					<li>
						Information about your current Subscription, including its price is available in My Account > View/Edit 
						Subscription or you can contact: admin@racingcentral.com.au
					</li>
					<li>
						Renewal of your subscription will occur unless you cancel your membership prior to your existing 
						maturity date. See cancellations.
					</li>
				</ul>

				<h4>Payments</h4>
				<ul class="list-inline">
					<li>
						Payments are made in advance. Your nominated credit or debit card will be debited immediately 
						upon subscription to Racing Central, whereby, you accept the outlined Terms and Conditions. 
						Your subscription will commence from the date of payment, reoccurring according to your selected 
						subscription period, unless canceled. See cancellations.
					</li>
					<li>
						By providing us with your credit or debit card details, you authorise us to charge all amounts 
						payable in relation to your subscription to the nominated payment account in accordance with 
						the payment terms section above.
					</li>
					<li>
						You must ensure that you have sufficient funds in your account to honour the payment. If there 
						are insufficient funds, we may continue to attempt to process the payment a number of times.
					</li>
					<li>
						It is your obligation to advise us of any changes to your payment account details.
					</li>
					<li>
						We may, on providing you with reasonable notice in the circumstances, cancel this direct debit 
						arrangement at any time. This might happen where there have been dishonoured or rejected payments, 
						or if we have a reasonable suspicion that fraudulent information has been provided in relation 
						to your direct debit arrangement.
					</li>
				</ul>

				<h4>Cancellations</h4>
				<ul class="list-inline">
					<li>
						You may cancel your membership at any time. Upon canceling, you will still have access to 
						the Racing Central Website and App until the expiry date of your membership. Therefore, the 
						cancellation will take effect at the end of the current subscription period. You can cancel 
						your account by visiting: My Account > View/Edit Membership > Cancel.
					</li>
				</ul>

				<h4>Refunds</h4>
				<ul class="list-inline">
					<li>
						If you are not satisfied with your membership or the service is not what you understood it to be, 
						notify us within three days from your sign up date and we will happily refund your subscription fee 
						in full.
					</li>
					<li>
						Racing Central may cancel your subscription at any time on reasonable notice. Where cancellation by 
						us is due to your breach of our terms and conditions, we are under no obligation to provide any 
						refund or notice.
					</li>
				</ul>

				<h4>Contact</h4>
				<ul class="list-inline">
					<li>
						You can contact Racing Centeral via our contact form at: <a href="https://racingcentral.com.au/contact">https://racingcentral.com.au/contact</a> or email: <a href="mailto:admin@racingcentral.com.au">admin@racingcentral.com.au</a>
					</li>
				</ul>
				
				<h4>Tournament</h4>
				<p>Definition: A tournament is a single event or group of events bundled together.</p>
				<ul class="list-inline">
					<li>
						A ONE OFF (common) tournament is defined as a group of races that relate to a set timeframe. 
						This will consist of all or randomly selected thoroughbred races on a specific date, start 
						at a specified time and end on the same day.
					</li>
					<li>
						A ROUND ROBIN tournament is defined as a series of ‘one off’ tournaments where you compete in 
						and qualify to the subsequent round based on your performance. This tournament will consist of 
						a series of randomly selected thoroughbred races beginning on a specific date, with entrants 
						meeting the benchmark set, progressing to the next round. This series will continue until 
						the specified end date of tournament is reached.
					</li>
					<li>
						JACKPOT TOURNAMENT. This tournament is defined the same as the ‘One Off’ tournament - but there 
						will be a benchmark set. At the end of each set time frame, any entrant (s) to reach the benchmark 
						take the stated prize pool. If the benchmark is not met, the prize pool jackpots 50% of the initial 
						prize pool value and the process starts again at the next specified time frame. This repeats until 
						an entrant (s) reaches the set benchmark by the end of specified time frame. If an entrant achieves 
						the set benchmark during the set timeframe, the tournament is not over until the final race inside 
						the tournament is completed. It is NOT first to achieve the benchmark by race order. It is on 
						completion of set time frame, the entrant (s) to have reached the benchmark will be declared the 
						winner (s).
					</li>
					<li>
						To enter any of our tournaments, you must be a registered member of our Site. Therefore adhering 
						to our terms and conditions laid out on our site. The tier of your membership will validate 
						which tournaments you are eligible to enter.
					</li>
					<li>
						The outcome of each tournament is determined by official results governed by the relevant body. 
						Official result: Protest inclusive, the official winner is the runner deemed ‘1st’ by the 
						governing body post interim results.
					</li>
					<li>
						Scratching: If your runner is scratched, your selection will be changed to the official substitute 
						runner - unless otherwise stated in tournament details.
					</li>
					<li>
						Level staking: Each selection in each tournament will be seen as value one (1) unit.
					</li>
					<li>
						Points: Finishing positions: 1st = 3 points, 2nd = 2 points, 3rd = 1 point. Unless otherwise stated in tournament details.
					</li>
					<li>
						Tournaments will have a defined start and end time/date. There is a lockout time, which means you can no longer pick 
						your selections after this time. Selections will be able to be edited up until this said ‘lockout’ time. Late entries will 
						NOT be accepted.
					</li>
					<li>
						Prizes (Cash and Credits) on offer for said tournament will be displayed on our site prior to each tournament commencing.
					</li>
					<li>
						ONE OFF and ROUND ROBIN tournaments will have a stated guaranteed prize pool and identify the guaranteed amounts to be 
						paid to winner through to third (3rd) place getter. This will be displayed for each individual tournament.
					</li>
					<li>
						ONE OFF and ROUND ROBIN tournaments, multiple places will be paid out as per the following payout structure: 1st: 75% 
						2nd: 20% 3rd: 5% totalling 100% Of prize pool. The dead heat rule applies here (15).
					</li>
					<li>
						WINNER TAKES ALL tournament. In this tournament, there is only one winner. They receive the entire prize pool advertised 
						on tournament completion. The dead heat rule applies here (15).
					</li>
					<li>
						JACKPOT tournament. In this tournament, the winner will be determined by reaching the set benchmark. In the case of 
						multiple entrants reaching the set benchmark, the dead heat rule applies here (15).
					</li>
					<li>
						In the case of a ‘dead heat’, the payout will be split between the parties relevant to their place positions and pool 
						accordingly. Ie, in ONE OFF and ROUND ROBIN tournament, 1st and 2nd dead heat, split 90% (75+25%) of prize pool evenly.
					</li>
					<li>
						If for any unknown reason, a winner can not be decided, the tournament will be deemed cancelled.
					</li>
					<li>
						All members who receive a prize from participation in a tournament will receive an email notification and 
						public announcement on our site informing them of their result.
					</li>
					<li>
						For whatever reason, if a member due to be paid a prize becomes ineligible, the members below them in the ladder shall move up and receive the relevant prize.
					</li>
					<li>
						Each tournament will have the entry rules clearly displayed on our tournament page.
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
<!-- end contact_us_wrapper -->