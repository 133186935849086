import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'ghost-element',
	templateUrl: './ghost-element.component.html',
	styleUrls: ['./ghost-element.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class GhostElementComponent implements OnInit {
	@Input() type: any;
	@Input() user: any;
	@Input() direction: any;

	constructor() {}

	ngOnInit(): void {}
}
