import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, SimpleChanges } from '@angular/core';

@Component({
	selector: '[formguide-tab]',
	templateUrl: './formguide-tab.component.html',
	styleUrls: ['./formguide-tab.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FormGuideTabComponent implements OnInit {
	@Input() type: boolean;
	@Input() user: any;
	@Input() form_guide_horses: any;
	@Input() scratched_horses: any;
	@Input() bb_count: any;
	@Output() openBlackbookIn: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	@Output() toggleAccordionIn: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	@Output() getRaceHorseDetailsIn = new EventEmitter<{ e: object; horseId: any; i: any }>();

	constructor() {}

	ngOnInit(): void {}

	formatSubtitle = (percent: number) => {
		return percent;
	};

	percentToColor(percent: number, min: number, max: number): string {
		if (percent < 54) {
			return '#FF0000';
		} else if (percent >= 55 && percent <= 74) {
			return '#F5C61C';
		} else if (percent > 70) {
			return '#67BE53';
		}
		return '#67BE53';
	}

	OpenBlackbook(horse: any) {
		this.openBlackbookIn.emit(horse);
	}

	getRaceHorseDetailsInner($event: any) {
		this.getRaceHorseDetailsIn.emit($event);
	}

	toggleAccordionInner($event: any) {
		this.toggleAccordionIn.emit($event);
	}
}
