<div class="preview_wrapper" *ngIf="!preview_loading && race_preview?.trc_preview">
	<div class="panel" *ngIf="user && user?.hasPreviews">
		<h2 class="preview_title heading">Preview:</h2>

		<div class="text_wrap row">
			<div class="col-text col-xl-11 col-lg-11 col-md-11 col-sm-12 order-md-2">
				<div class="body panel grey bubble_chat bubble_white_bg bubble_left">
					{{ race_preview?.trc_preview }}
					<span class="author_name">{{ race_preview?.trc_preview_author }}</span>

					<div class="bottom_bar align-self-center">
						<div class="author_wrap">
							<div class="avatar_inner" *ngIf="race_preview?.avatar_url" [ngStyle]="{'background-image': 'url(' + race_preview?.avatar_url + ')'}"></div>
							<div class="avatar_inner" *ngIf="!race_preview?.avatar_url" [ngStyle]="{'background-image': 'url(assets/images/profile.png)'}"></div>
											
							<!-- <span>{{ race_preview?.trc_preview_author }}</span> -->
						</div>
						<!-- end author_wrap -->

						<div class="confidence_wrap align-self-center">
							<h4
								class="pill normal confidence"
								[ngClass]="{
									yellow: race_preview?.confidence >= 55 && race_preview?.confidence < 79,
									green: race_preview?.confidence > 79
								}"
							>
								<span>Confidence: </span>
								<span class="number">{{ race_preview?.confidence }}</span>
							</h4>
							<!-- end pill -->
						</div>
						<!-- end confidence_wrap -->
					</div>
					<!-- end bottom_bar -->
				</div>
			</div>
			<div class="col-img col-xl-1 col-lg-1 col-md-1 col-sm-12 order-md-1 align-self-end">
				<div class="avatar_inner" *ngIf="race_preview?.avatar_url" [ngStyle]="{'background-image': 'url(' + race_preview?.avatar_url + ')'}"></div>
				<div class="avatar_inner" *ngIf="!race_preview?.avatar_url" [ngStyle]="{'background-image': 'url(assets/images/profile.png)'}"></div>
			</div>
		</div>
		<!-- end text_wrap -->


		
	

		<div class="row bets" *ngIf="suggested_bets?.length > 0">
			<div class="col-12 title-wrap"><span class="small_title">Suggested bets</span></div>

			<div class="col-xl-6 single_bet" *ngFor="let row of suggested_bets">
				<div class="panel grey h-100">
					<div class="col-12 p-0">
						<div class="row no-gutters">
							<div class="col_left align-self-center">
								<div class="inner">
									<div class="inner">
										<span class="index">{{ row.tabno }}</span>
										<div class="name_wrap">
											<h4 class="name">
												{{ row.horse_name }} <small>({{ row.barrier }})</small>
											</h4>
											<span><strong>J: </strong>{{ formatName(row.jockey) }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col_right align-self-center" *ngIf="row.win_bet">
								<div class="inner">
									<div class="inner">
										<img
											alt="TRC"
											class="img-fluid bookmaker"
											src="assets/images/bookmakers/unibet-tile2.png"
										/>
										<div class="text_wrap">
											<!--<span>Win <span class="button positive">{{ row.win3_current | currency : 'USD'  }}</span></span>-->
											<span *ngIf="row.win_cut_off_price"
												>Win
												<span
													class="button"
													[ngClass]="{
														positive: row.win3_current >= row.win_cut_off_price,
														negative: row.win3_current < row.win_cut_off_price
													}"
													>{{ row.win3_current | currency: 'USD' }}</span
												></span
											>
											<span class="cutoff" *ngIf="row.win_cut_off_price"
												>(Cutoff: {{ row.win_cut_off_price | currency: 'USD' }})</span
											>

											<span *ngIf="!row.win_cut_off_price"
												>Win <span class="button">{{ row.win3_current | currency: 'USD' }}</span></span
											>
										</div>
									</div>
								</div>
							</div>
							<div class="col_right align-self-center" *ngIf="row.place_bet">
								<div class="inner">
									<div class="inner">
										<img
											alt="TRC"
											class="img-fluid bookmaker"
											src="assets/images/bookmakers/unibet-tile2.png"
										/>
										<div class="text_wrap race_winning">
											<!--<span>Place <span class="button negative">{{ row.plc_current | currency : 'USD'  }}</span></span>-->
											<!-- <span>Place <span class="button negative">$1.40</span></span> -->
											<span *ngIf="row.place_cut_off_price"
												>Place
												<span
													class="button"
													[ngClass]="{
														positive: row.plc3_current >= row.place_cut_off_price,
														negative: row.plc3_current < row.place_cut_off_price
													}"
													>{{ row.plc3_current | currency: 'USD' }}</span
												></span
											>
											<span class="cutoff" *ngIf="row.place_cut_off_price"
												>(Cutoff: {{ row.place_cut_off_price | currency: 'USD' }})</span
											>

											<span *ngIf="!row.place_cut_off_price"
												>Place
												<span class="button">{{ row.plc3_current | currency: 'USD' }}</span></span
											>
											<!-- <span class="cutoff">(Cutoff: $1.50)</span> -->
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row no-gutters" *ngIf="row.trc_review">
							<div class="col_wide col-12 align-self-center pt-4 accr-section">
								<div class="collapsable-section closed">
									<div class="row">
										<div class="col col_avatar">
											<div class="avatar_inner" *ngIf="row?.avatar" [ngStyle]="{'background-image': 'url(' + row?.avatar + ')'}"></div>
											<div class="avatar_inner" *ngIf="!row?.avatar" [ngStyle]="{'background-image': 'url(assets/images/profile.png)'}"></div>
											<span class="avatar_name">{{ row.name }}{{ row.last_name }}</span>
										</div>
										<div class="col col_toggle">
											<span (click)="toggleAccordion($event)" class="show-comment">Show comment <span class="toggleBtn showMore"></span></span>
										</div>
										<div class="col-12 collapsed-content">
											<div class="body panel grey bubble_chat bubble_left">
												<span>{{row.trc_review }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end single_bet -->
		</div>
		<!-- end row bets -->

		<div class="row bets" *ngIf="top_selections?.length > 0">
			<div class="col-12 title-wrap"><span class="small_title">Top selections</span></div>
			<div class="col-xl-6 single_bet top_selection_flex" *ngFor="let row of top_selections">
				<div class="panel grey h-100">
					<div class="col-12 p-0">
						<div class="row no-gutters">
							<div class="col_left align-self-center">
								<div class="inner">
									<div class="inner">
										<span class="index" [ngStyle]="{'background-color': 'transparent'}">
											<img class="img-fluid jockey-img" [src]="row.silks" (error)="onImgError($event, row.tabno)" alt="TRC" />
										</span>
										<div class="name_wrap">
											<h4 class="name">
												{{ row.tabno }}. {{ row.horse_name }} <small>({{ row.barrier }})</small>
											</h4>
											<span><strong>J: </strong>{{ formatName(row.jockey) }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col_right align-self-center">
								<div class="inner">
									<div class="inner">
										<div class="text_wrap">	
											<span>Win:
												<span
													class="button" *ngIf="row.win3_current"
													>{{ row.win3_current | currency : 'USD' }}</span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end single_bet -->
			
	
		</div>

		<div class="top-selection" *ngIf="top_selections?.length > 0">
			<div class="col single_bet" *ngFor="let row of top_selections;" [ngClass]="{'Scratched' : row?.horse_status == 'Scratched'}">
				<span class="index" [ngStyle]="{'background-color': 'transparent'}">
					{{ row.tabno }} <img class="img-fluid jockey-img" [src]="row.silks" (error)="onImgError($event, row.tabno)" alt="TRC" />
				</span>
				<span class="button" *ngIf="row.win3_current && row?.horse_status != 'Scratched'">{{ row.win3_current | currency : 'USD' }}</span>
			</div><!-- end single_bet -->
		</div>


		<h4
			class="pill confidence top"
			[ngClass]="{
				yellow: race_preview?.confidence >= 55 && race_preview?.confidence < 79,
				green: race_preview?.confidence > 79
			}"
		>
			<p>Confidence:</p>
			<span class="number">{{ race_preview?.confidence }}</span>
		</h4>
		<!-- end pill -->
	</div>
	<!-- end panel -->
	<div class="panel locked_panel" *ngIf="(user && !user?.hasPreviews) || !user">
		<div class="top_text_wrapper">
			<h2 class="col-12 text-center heading">Unlock professional analysis & insights</h2>

			<div class="body text-center">
				<p>
					Subscribe to the 'Platinum' or 'Previews & Analysis' package to access this as well as other
					exclusive content.
				</p>
				<a class="btn blue arrow_large" routerLink="/subscribe-plan" *ngIf="user">Unlock</a>
				<a class="btn blue arrow_large" routerLink="/signup" *ngIf="!user">Sign up</a>
			</div>

			<div class="placeholder">
				<img class="img-fluid" src="assets/images/placeholders/previews-large.svg" alt="Upgrade" />
			</div>
		</div>
	</div>
</div>
<!-- end preview_wrapper -->
