import { Component, OnInit } from '@angular/core';
import { BlackbookService } from '../services/blackbook.service';

import * as $ from 'jquery';
import { TweenLite, Cubic } from 'gsap';
import * as moment from 'moment';
import { ConfirmationModalService } from '../shared/confirmation-modal/confirmation-modal.service';
import { EventService } from '../services/event.service';

@Component({
	selector: 'app-blackbook',
	templateUrl: './blackbook.component.html',
	styleUrls: ['./blackbook.component.scss'],
})
export class BlackbookComponent implements OnInit {
	user: any;
	token: any;
	blackbook_all_entries: any;
	blackbook_horses: any;
	blackbook_jockeys: any;
	blackbook_trainers: any;
	loading: boolean = true;
	filter: any = 'all';
	dayfilter: any = 'today';
	today: any;
	tomorrow: any;
	upcoming_count: any = 0;
	search_txt: any;

	constructor(
		private BlackbookService: BlackbookService,
		private ConfirmationModalService: ConfirmationModalService,
		private EventService: EventService
	) {
		this.user = localStorage.getItem('trc_user');
		let todayDate = moment().local();
		let tomorrowDate = moment().local().add(1, 'day');

		this.today = {
			date: todayDate.format('YYYY-MM-DD'),
			day: todayDate.format('dddd'),
			blackbook_entries: [],
		};

		this.tomorrow = {
			date: tomorrowDate.format('YYYY-MM-DD'),
			day: tomorrowDate.format('dddd'),
			blackbook_entries: [],
		};
	}

	ngOnInit() {
		this.getUser();
	}

	getUser() {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		if (this.user) {
			this.getBlackBook(true);
		}
	}

	toggleAccordion(event: any) {
		var $this = $(event.currentTarget),
			$content = $(event.currentTarget).closest('.collapsable-section').find('.collapsed-content');
		if ($this.closest('.collapsable-section').hasClass('closed')) {
			TweenLite.set($content, {
				height: 'auto',
				opacity: 1,
			});
			TweenLite.from($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
			$this.closest('.collapsable-section').removeClass('closed');
			$this.addClass('expand');
		} else {
			$this.closest('.collapsable-section').addClass('closed');
			$this.removeClass('expand');
			TweenLite.to($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
		}
	}

	async filterBlackbook(type: any) {
		this.filter = type;
	}

	async filterBlackbookbyDay(day: any) {
		this.dayfilter = day;
	}

	async editNote(row: any, r_note: any) {
		alert('here');
		this.blackbook_all_entries.forEach((element: any) => {
			if (element.id == row.id) {
				element.notes.forEach((note: any) => {
					if (note.id == r_note.id) {
						note.editNote = !note.editNote;
					}
				});
				element.editNote = !element.editNote;
			}
		});
	}

	async todayeditNote(row: any, r_note: any) {
		this.today.blackbook_entries.forEach((element: any) => {
			if (element.id == row.id) {
				element.notes.forEach((note: any) => {
					if (note.id == r_note.id) {
						note.editNote = !note.editNote;
					}
				});
				element.editNote = !element.editNote;
			}
		});
	}

	async tomorroweditNote(row: any, r_note: any) {
		this.tomorrow.blackbook_entries.forEach((element: any) => {
			if (element.id == row.id) {
				element.notes.forEach((note: any) => {
					if (note.id == r_note.id) {
						note.editNote = !note.editNote;
					}
				});
				element.editNote = !element.editNote;
			}
		});
	}


	async getBlackBook(loading:any) {
		let today_blackbook_entries : any = [];
		let tomorrow_blackbook_entries : any = [];
		this.upcoming_count = 0;

		let data = {
			search: this.search_txt,
		};

		await this.BlackbookService.getBlackBook(this.user.token, data).subscribe((data: any) => {
			this.loading = false;
			if (data && data.blackbook_entries) {
				this.blackbook_all_entries = data.blackbook_entries ? data.blackbook_entries : [];
			}
			//console.log(this.blackbook_all_entries[0].id);

			if (data && data.horses) {
				this.blackbook_horses = data.horses ? data.horses : [];
			}
			if (data && data.jockeys) {
				this.blackbook_jockeys = data.jockeys ? data.jockeys : [];
			}
			if (data && data.trainers) {
				this.blackbook_trainers = data.trainers ? data.trainers : [];
			}

			this.blackbook_all_entries.forEach((element: any) => {

				if (element.type == 'horse') {
					let horse_added = false;
					let tomorrow_horse_added = false;

					this.blackbook_horses.forEach((horse: any) => {
						if (element.horse_id == horse.horse_id || element.name == horse.horse) {
							let clone_element = JSON.parse(JSON.stringify(element));

							if (moment.utc(horse.race_start_time).local().format('YYYY-MM-DD') == this.today.date) {
								if (!horse_added) {

									clone_element.upcoming_race = [];
									clone_element.upcoming_race.push(horse);
									today_blackbook_entries.push(clone_element);
									this.upcoming_count = this.upcoming_count + 1;
									horse_added = true;
								} else {
									today_blackbook_entries.forEach((today_element: any) => {
										//console.log(horse)
										if (today_element.name == horse.horse) today_element.upcoming_race.push(horse);
									});
								}
							}
							if (moment.utc(horse.race_start_time).local().format('YYYY-MM-DD') == this.tomorrow.date) {
								if (!tomorrow_horse_added) {
									clone_element.upcoming_race = [];
									clone_element.upcoming_race.push(horse);
									tomorrow_blackbook_entries.push(clone_element);
									this.upcoming_count = this.upcoming_count + 1;
									tomorrow_horse_added = true;
								} else {
									tomorrow_blackbook_entries.forEach((tomorrow_element: any) => {
										if (tomorrow_element.name == horse.horse)
											tomorrow_element.upcoming_race.push(horse);
									});
								}
							}
						}
					});
				}
				if (element.type == 'jockey') {
					let jockey_added = false;
					let tomorrow_jockey_added = false;

					this.blackbook_jockeys.forEach((jockey: any) => {
						if (element.name == jockey.jockey) {
							let clone_element = JSON.parse(JSON.stringify(element));

							if (moment.utc(jockey.race_start_time).local().format('YYYY-MM-DD') == this.today.date) {
								if (!jockey_added) {
									clone_element.upcoming_race = [];
									clone_element.upcoming_race.push(jockey);
									today_blackbook_entries.push(clone_element);
									this.upcoming_count = this.upcoming_count + 1;
									jockey_added = true;
								} else {
									today_blackbook_entries.forEach((today_element: any) => {
										if (today_element.name == jockey.jockey)
											today_element.upcoming_race.push(jockey);
									});
								}
							}
							if (moment.utc(jockey.race_start_time).local().format('YYYY-MM-DD') == this.tomorrow.date) {
								if (!tomorrow_jockey_added) {
									clone_element.upcoming_race = [];
									clone_element.upcoming_race.push(jockey);
									tomorrow_blackbook_entries.push(clone_element);
									this.upcoming_count = this.upcoming_count + 1;
									tomorrow_jockey_added = true;
								} else {
									tomorrow_blackbook_entries.forEach((tomorrow_element: any) => {
										if (tomorrow_element.name == jockey.jockey)
											tomorrow_element.upcoming_race.push(jockey);
									});
								}
							}
						}
					});
				}
				if (element.type == 'trainer') {
					let trainer_added = false;
					let tomorrow_trainer_added = false;

					this.blackbook_trainers.forEach((trainer: any) => {
						if (element.name == trainer.trainer) {
							let clone_element = JSON.parse(JSON.stringify(element));

							if (moment.utc(trainer.race_start_time).local().format('YYYY-MM-DD') == this.today.date) {
								if (!trainer_added) {
									clone_element.upcoming_race = [];
									clone_element.upcoming_race.push(trainer);
									today_blackbook_entries.push(clone_element);
									this.upcoming_count = this.upcoming_count + 1;
									trainer_added = true;
								} else {
									today_blackbook_entries.forEach((today_element: any) => {
										if (today_element.name == trainer.trainer)
											today_element.upcoming_race.push(trainer);
									});
								}
							}
							if (
								moment.utc(trainer.race_start_time).local().format('YYYY-MM-DD') == this.tomorrow.date
							) {
								if (!tomorrow_trainer_added) {
									clone_element.upcoming_race = [];
									clone_element.upcoming_race.push(trainer);
									tomorrow_blackbook_entries.push(clone_element);
									this.upcoming_count = this.upcoming_count + 1;
									tomorrow_trainer_added = true;
								} else {
									tomorrow_blackbook_entries.forEach((tomorrow_element: any) => {
										if (tomorrow_element.name == trainer.trainer)
											tomorrow_element.upcoming_race.push(trainer);
									});
								}
							}
						}
					});
				}
			});
		});
		this.today.blackbook_entries = today_blackbook_entries;
		this.tomorrow.blackbook_entries = tomorrow_blackbook_entries;
		
	}

	

	async addBlackBookNote(type: any, row: any) {
		// this.loading = true;
		let name;
		let note;

		let data = {
			type: type,
			horse_id: type == 'horse' ? row.horse_id : null,
			name: name,
			note: note,
		};

		this.BlackbookService.saveBlackBook(data, this.user.token).subscribe((data: any) => {
			this.loading = false;
			this.getBlackBook(false);
		});
	}

	async saveBlackBookNote(type: any, row: any) {
		//console.log(row);
		// this.loading = true;
		let name;
		let note;

		let data = {
			id: row.id,
			type: type,
			horse_id: type == 'horse' ? row.horse_id : null,
			name: row.name,
			note: row.new_note,
		};

		this.BlackbookService.addBlackBookNote(data, this.user.token).subscribe((data: any) => {
			if (data) {
				this.getBlackBook(false);
			}
			this.loading = false;
		});
	}

	async removeBlackBookConfirm(row: any) {
		let btnCancelText = 'No';
		let btnOkText = 'Yes';
		let message =
			'<div>' + '<p>Are you sure you want to remove ' + row.name + ' from your blackbook?</p>' + '</div>';

		await this.ConfirmationModalService.confirm('Remove from BlackBook', message, btnOkText, btnCancelText)
			.then((confirmed) => {
				if (confirmed) this.removefromBlackBook(row);
			})
			.catch(() =>
				console.log(
					'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
				)
			);
	}

	async removefromBlackBook(row: any) {
		// this.loading = true;
		let data = {
			id: row.id,
		};

		this.BlackbookService.removeBlackBook(data, this.user.token).subscribe((data: any) => {
			this.loading = false;
			if (data) {
				this.getBlackBook(false);
			}
		});
	}

	async editBlackBookNote(row: any, note: any) {
		//console.log(row);
		//console.log(note);

		this.loading = true;

		let data = {
			id: row.id,
			note: note,
		};

		this.BlackbookService.editBlackBookNote(data, this.user.token).subscribe((data: any) => {
			this.loading = false;
			if (data) {
				this.getBlackBook(false);
			}
		});
	}

	onImgError(event: any) {
		event.target.src = 'assets/images/blackbook/horse-blackbook.svg';
	}
}
