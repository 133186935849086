<div class="modal_wrapper choice_popup text-center dark_theme">
	<div class="close_icon" (click)="closemodal()">
		<svg xmlns="http://www.w3.org/2000/svg" width="11.689" height="11.689" viewBox="0 0 11.689 11.689">
			<g class="Group_251" data-name="Group 251" transform="translate(84.329 -65.195) rotate(135)">
				<g class="Group_249" data-name="Group 249" transform="translate(98.464 -2)">
					<path
						class="Path_1"
						data-name="Path 1"
						d="M24.331,20.516V5.986"
						transform="translate(-17.066 -5.986)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
					<path
						class="Path_81"
						data-name="Path 81"
						d="M0,14.53V0"
						transform="translate(14.53 7.265) rotate(90)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
				</g>
			</g>
		</svg>
	</div>
	<!-- end close_icon -->

	<h1 class="page_title mb-2">Log in or Sign up</h1>

	<div class="sign_in_link mb-4">Create a FREE account or else log in via the links below</div>

	<a class="btn white arrow_large" (click)="login()">Log in</a>
	<a class="btn blue arrow_large" (click)="signup()">Sign up</a>
</div>
<!-- end inner -->
