import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class NewsService {
	apiUrl = environment.serverUrl;
	constructor(private httpClient: HttpClient) {}

	/**
	 * get news list.
	 */
	public getNewsList(pageNum: any): Observable<any> {
		return this.httpClient.get(this.apiUrl + 'news?page=' + pageNum);
	}

	/**
	 * get individual news
	 */
	public getNewsDetails(newstId: any): Observable<any> {
		return this.httpClient.get(this.apiUrl + 'news-detail/' + newstId);
	}

	/**
	 * get featured news
	 */
	public getFeaturedNews(): Observable<any> {
		return this.httpClient.get(this.apiUrl + 'featured-news');
	}

	/**
	 * get latest news
	 */
	public getLatestNews(count: any): Observable<any> {
		let data = { count: count };
		return this.httpClient.post(this.apiUrl + 'latest-news', data);
	}

	/**
	 * search news by page.
	 */
	public searchNewsbyPage(searchUrlParms: any, pageNum: any): Observable<any> {
		if (searchUrlParms) return this.httpClient.get(this.apiUrl + 'search?page=' + pageNum + searchUrlParms);
		else return this.httpClient.get(this.apiUrl + 'search?page=' + pageNum);
	}
}
