import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class LiveyardsService {
	apiUrl = environment.serverUrl;
	constructor(private httpClient: HttpClient) {}

	/**
	 * get Liveyard Tracks.
	 */
	public getLiveyardTracks(token:any): Observable<any> {
		return this.httpClient.get(this.apiUrl + 'liveyard-tracks?token='+token);
	}

	/**
	 * get Liveyards.
	 */
	public getLiveyards(data: any,token:any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'liveyards?token='+token, data);
	}

	/**
	 * get Liveyards Horses
	 */
	public getLiveyardsHorses(data: any,token:any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'liveyards-horses?token='+token, data);
	}
}
