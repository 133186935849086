import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
	ForgotPasswordForm: FormGroup = this.formBuilder.group({
		email: ['', [Validators.required]],
	});
	loading = false;
	// myRecaptcha = new FormControl(false);

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.ForgotPasswordForm = this.formBuilder.group({
			email: ['', [Validators.required]],
		});
	}

	get f() {
		return this.ForgotPasswordForm.controls;
	}

	async onSubmit() {
		if (this.ForgotPasswordForm.invalid) {
			return;
		}
		let data = {
			email: this.ForgotPasswordForm.value.email,
		};

		this.loading = true;
		this.userService.sendResetPasswordLink(data).subscribe(
			(response: any) => {
				this.toastr.success(response.message);
				this.loading = false;
			},
			(error) => {
				//console.log(error);
				this.toastr.error(error.error.message);
				this.loading = false;
			}
		);
	}

	onScriptLoad() {
		//console.log('Google reCAPTCHA loaded and is ready for use!');
	}

	onScriptError() {
		//console.log('Something went long when loading the Google reCAPTCHA');
	}
}
