import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
	selector: '[reviews-tab]',
	templateUrl: './reviews-tab.component.html',
	styleUrls: ['./reviews-tab.component.scss'],
})
export class ReviewsTabComponent implements OnInit {
	@Input() runners_to_watch: any;
	@Input() race_review: any;
	@Input() user: any;
	public name = 'geek2';
	public age = 22;

	@Output() openBlackbookIn: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	@Output() public data = new EventEmitter<{ name: string; age: number }>();

	constructor() {}

	ngOnInit(): void {}

	OpenBlackbook(horse: any) {
		this.openBlackbookIn.emit(horse);
	}

	onImgError(event: any, seq_no: any) {
		event.target.src = 'assets/images/jockeys/jockey' + seq_no + '.png';
	}
}
