import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class OffersService {
	apiUrl = environment.serverUrl;
	constructor(private httpClient: HttpClient) {}

	public getGuestOffer(): Observable<any> {
		let data = { type: 'WEBSITE' };
		return this.httpClient.post(this.apiUrl + 'get-guest-offers', data);
	}

	public getOffer(token: any): Observable<any> {
		let data = { type: 'WEBSITE' };
		return this.httpClient.post(this.apiUrl + 'get-offers?token=' + token, data);
	}
}
