import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class FormGuideService {
	apiUrl = environment.serverUrl;
	constructor(private httpClient: HttpClient) {}

	/**
	 * get venues list.
	 */
	public getRaceEvents(): Observable<any> {
		return this.httpClient.get(this.apiUrl + 'race-events');
	}

	/**
	 * get venue
	 */
	public getRaceEvent(slug: any): Observable<any> {
		let data = {
			slug: slug,
			timezoneOffset: new Date().getTimezoneOffset(),
		};
		return this.httpClient.post(this.apiUrl + 'race-event', data);
	}

	/**
	 * get Events Races.
	 */
	public getEventsRaces(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'event-races', data);
	}
	/**
	 * get Race Horses for Form Guide.
	 */
	public getRaceHorsesFormGuide(data: any, token: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'race-horses-form-guide?token=' + token, data);
	}
	/**
	 * get Race Horses for Live Yard.
	 */
	public getFormGuideRaceEvents(): Observable<any> {
		let data = { timezoneOffset: new Date().getTimezoneOffset() };
		return this.httpClient.post(this.apiUrl + 'form-guide-race-events', data);
	}

	/**
	 * get Race Horses for Live Yard.
	 */
	public getRaceResults(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'race-events-results', data);
	}

	/**
	 * get Form Guide
	 */
	public getRaceHorsesLiveYard(data: any, token: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'race-horses-live-yard?token=' + token, data);
	}
	/**
	 * get Race Horses for Speed Maps.
	 */
	public getRaceHorsesSpeedMaps(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'race-horses-speed-maps', data);
	}
	/**
	 * get Race Horses for Speed Maps Perc.
	 */
	public getRaceHorsesSpeedMapsPerc(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'race-horses-speed-maps-perc', data);
	}
	/**
	 * get Race Horse Details.
	 */
	public getRaceHorseDetails(data: any, token: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'race-horse-details?token=' + token, data);
	}
	/**
	 * get Race Preview.
	 */
	public getRacePreview(data: any, token: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'race-previewV1?token=' + token, data);
	}

	/**
	 * get Race Preview.
	 */
	public getRaceReview(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'race-review', data);
	}

	/**
	 * get Races.
	 */
	public getRaces(): Observable<any> {
		let data = { timezoneOffset: new Date().getTimezoneOffset() };
		return this.httpClient.post(this.apiUrl + 'races', data);
	}

	
	/**
	 * get Races.
	 */
	 public getRacesV1(day : any): Observable<any> {
		let data = { timezoneOffset: new Date().getTimezoneOffset(), day: day };
		return this.httpClient.post(this.apiUrl + 'racesv1', data);
	}


	

	/**
	 * get Upcoming Races
	 */
	public getUpcomingRaces(): Observable<any> {
		let data = { timezoneOffset: new Date().getTimezoneOffset() };
		return this.httpClient.post(this.apiUrl + 'upcoming-races', data);
	}
	/**
	 * get todays previews.
	 */
	public getTodaysPreviews(): Observable<any> {
		let data = { timezoneOffset: new Date().getTimezoneOffset() };
		return this.httpClient.post(this.apiUrl + 'todays-previews', data);
	}
	/**
	 * get results.
	 */
	// public getResults(selectedDate:any): Observable<any> {
	// 	let data = {'timezoneOffset' : new Date().getTimezoneOffset(),''}
	// 	return this.httpClient.post(this.apiUrl + 'results',data);
	// }
	public getResults(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'results', data);
	}

	public getResultsforMeeting(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'results-for-meeting', data);
	}

	public getHomePageResults(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'homepage-results', data);
	}

	public getTrackProfile(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'get-track-profile', data);
	}
}
