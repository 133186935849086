import { Component, OnInit } from '@angular/core';
import { PlanService } from '../services/plan.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
	selector: 'app-selectplan',
	templateUrl: './selectplan.component.html',
	styleUrls: ['./selectplan.component.scss'],
})
export class SelectplanComponent implements OnInit {
	features: any;
	plans: any;
	feature_loading: boolean = true;
	plans_loading: boolean = true;
	plans_selected: boolean = false;
	selectedPlan: any;

	constructor(private PlanService: PlanService, private route: Router) {}

	ngOnInit(): void {
		this.getFeatures();
	}

	async getFeatures() {
		this.feature_loading = true;
		await this.PlanService.getFeatures().subscribe((data: any) => {
			if (data) {
				if (data.features) {
					this.features = data.features;
				}
			}
			this.feature_loading = false;
		});
	}

	async getPlans(selected_features: any) {
		this.plans_loading = true;
		await this.PlanService.getPlans(selected_features).subscribe((data: any) => {
			if (data) {
				if (data.plans) {
					this.plans = data.plans;
					this.plans.forEach((element: any) => {
						element.checked = false;
					});
				}
			}
			this.plans_loading = false;
		});
	}

	featureSelected(index: any) {
		// this.features[index].checked = !this.features[index].checked;
		//console.log(index);
		//console.log(this.features);
		let selected_features: any = [];

		this.features.forEach((element: any) => {
			if (element.checked) selected_features.push(element.id);
		});
		if (selected_features.length > 0) this.getPlans(selected_features);
	}

	PlanSelected(index: any) {
		this.selectedPlan = this.plans[index];
	}

	async checkout() {
		localStorage.setItem('selected_plan', JSON.stringify(this.selectedPlan));
		this.route.navigate(['/make-payment']);
	}
}
