import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlackbookService } from '../../services/blackbook.service';
import { ConfirmationModalService } from '../confirmation-modal/confirmation-modal.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-blackbook-popup',
	templateUrl: './blackbook-popup.component.html',
	styleUrls: ['./blackbook-popup.component.scss'],
})
export class BlackbookPopupComponent implements OnInit {
	blackbook_all_entries: any;
	@Input() fromParent: any;
	@Input() meeting_id: any;
	@Input() event_id: any;
	@Input() venue: any;

	loading: any = false;
	horse_added: any = false;
	jockey_added: any = false;
	trainer_added: any = false;
	horse_note: any;
	jockey_note: any;
	trainer_note: any;

	horse_added_now: any = false;
	jockey_added_now: any = false;
	trainer_added_now: any = false;

	constructor(
		private modalService: NgbModal,
		private BlackbookService: BlackbookService,
		private ConfirmationModalService: ConfirmationModalService,
		private router: Router
	) {}

	ngOnInit(): void {
		//console.log(this.fromParent);
		// this.fromParent.horse = this.fromParent.horse.row;
		if (this.fromParent.user && this.fromParent.user.token) this.getBlackBookNotes();
	}

	close() {
		this.modalService.dismissAll();
	}

	viewBlackbook() {
		this.modalService.dismissAll();
		this.router.navigate(['/blackbook']);
	}

	async getBlackBookNotes() {
		this.loading = true;
		this.BlackbookService.getblackbookNotes(this.fromParent.horse, this.fromParent.user.token).subscribe(
			(data: any) => {
				//console.log(data);
				if (data && data.status) {
					if (data.blackbook_entries) {
						this.blackbook_all_entries = data.blackbook_entries;
						this.blackbook_all_entries.forEach((element: any) => {
							if (element.type == 'horse') this.horse_added = true;
							if (element.type == 'jockey') this.jockey_added = true;
							if (element.type == 'trainer') this.trainer_added = true;
						});
						//console.log(this.horse_added, this.jockey_added, this.trainer_added);
					}
				}
				this.loading = false;
			}
		);
	}

	async saveBlackBook(type: any) {
		this.loading = true;
		//console.log(this.fromParent.horse);
		let name;
		if (type === 'horse') name = this.fromParent.horse.horse_name;
		if (type === 'jockey') name = this.fromParent.horse.jockey;
		if (type === 'trainer') name = this.fromParent.horse.trainer;

		let data = {
			type: type,
			horse_id: type == 'horse' ? this.fromParent.horse.horse_id : null,
			name: name,
			silks: this.fromParent.horse.silks,
		};

		this.BlackbookService.saveBlackBook(data, this.fromParent.user.token).subscribe((data: any) => {
			if (data) {
				if (type == 'horse') this.horse_added_now = true;
				if (type == 'jockey') this.jockey_added_now = true;
				if (type == 'trainer') this.trainer_added_now = true;
				this.getBlackBookNotes();
			}
			this.loading = false;
		});
	}

	async addBlackBookNote(type: any) {
		this.loading = true;
		let name;
		let note;
		//console.log(this.horse_note);
		if (type === 'horse') {
			name = this.fromParent.horse.horse_name;
			note = this.horse_note;
		}
		if (type === 'jockey') {
			name = this.fromParent.horse.jockey;
			note = this.jockey_note;
		}
		if (type === 'trainer') {
			name = this.fromParent.horse.trainer;
			note = this.trainer_note;
		}

		let data = {
			type: type,
			horse_id: type == 'horse' ? this.fromParent.horse.horse_id : null,
			name: name,
			note: note,
			meeting_id: this.meeting_id,
			event_id: this.event_id,
			venue: this.venue,
		};

		this.BlackbookService.saveBlackBook(data, this.fromParent.user.token).subscribe((data: any) => {
			if (data) {
				if (type == 'horse') this.horse_added_now = true;
				if (type == 'jockey') this.jockey_added_now = true;
				if (type == 'trainer') this.trainer_added_now = true;
			}
			this.getBlackBookNotes();
			this.loading = false;
		});
	}

	async saveBlackBookNote(type: any, id: any) {
		this.loading = true;
		let name;
		let note;
		//console.log(this.horse_note);
		if (type === 'horse') {
			name = this.fromParent.horse.horse_name;
			note = this.horse_note;
		}
		if (type === 'jockey') {
			name = this.fromParent.horse.jockey;
			note = this.jockey_note;
		}
		if (type === 'trainer') {
			name = this.fromParent.horse.trainer;
			note = this.trainer_note;
		}


		let data = {
			id: id ? id : null,
			type: type,
			horse_id: type == 'horse' ? this.fromParent.horse.horse_id : null,
			name: name,
			note: note,
			meeting_id: this.fromParent.meeting_id,
			event_id: this.fromParent.event_id,
			venue: this.fromParent.venue,
		};

		this.BlackbookService.addBlackBookNote(data, this.fromParent.user.token).subscribe((data: any) => {
			if (data) {
				if (type == 'horse') this.horse_added_now = true;
				if (type == 'jockey') this.jockey_added_now = true;
				if (type == 'trainer') this.trainer_added_now = true;
				this.getBlackBookNotes();
				this.trainer_note = '';
				this.jockey_note = '';
				this.horse_note = '';
				
			}
			this.loading = false;
		});
	}

	async removeBlackBookConfirm(row: any) {
		let btnCancelText = 'No';
		let btnOkText = 'Yes';
		let message =
			'<div>' + '<p>Are you sure you want to remove ' + row.name + ' from your blackbook?</p>' + '</div>';

		await this.ConfirmationModalService.confirm('Remove from BlackBook', message, btnOkText, btnCancelText)
			.then((confirmed) => {
				if (confirmed) this.removefromBlackBook(row.id);
			})
			.catch(() =>
				console.log(
					'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
				)
			);
	}

	async removefromBlackBook(id: any) {
		this.loading = true;
		let data = {
			id: id,
		};

		this.BlackbookService.removeBlackBook(data, this.fromParent.user.token).subscribe((data: any) => {
			if (data) {
				this.getBlackBookNotes();
			}
			this.loading = false;
		});
	}

	onImgError(event: any) {
		event.target.src = 'assets/images/blackbook/horse-blackbook.svg';
	}
}
