<div class="breadcrumbs_wrapper">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title">Jump Outs</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/">Home</a></li>
							<li><a routerLink="/jumpouts">Jump Outs</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end container -->
</div>
<!-- end breadcrumbs_wrapper -->

<div class="jumpouts_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-md-10 offset-md-1 jumpout_inner_wrap">
				<div class="introduction text-center">
					<p>
						In an industry first, Racing Central provides comprehensive jump out detail in one location. You
						can utilise the search function to find any given runners jump out history and link to the
						vision.
					</p>
				</div>
				<!-- end introduction -->

				<div class="search_bar">
					<div class="search-form">
						<div action="">
							<input
								type="text"
								[(ngModel)]="search_txt"
								[ngModelOptions]="{ standalone: true }"
								ngModaautocomplete="off"
								(input)="getJumouts(1)"
								placeholder="Search by horse, trainer or venue"
							/>
							<button (click)="getJumouts(1)" type="submit">
								<img
									class="search-img img-fluid"
									src="assets/images/icons/search-white.png"
									alt="serarch"
								/>
							</button>
						</div>
					</div>
					<!-- search-form -->
				</div>
				<!-- end search_bar -->

				<div class="panel panel_parent">
					<ul class="accr-section" *ngIf="!loading">
						<li
							class="jumpouts-tables-wrap row"
							*ngFor="
								let row of jumpouts_horses
									| paginate: { itemsPerPage: 10, currentPage: page, totalItems: jumpouts_count };
								let i = index
							"
						>
							<div
								class="t-index col-1"
								[ngClass]="{
									green: row.place == 1,
									yellow: row.place == 2 || row.place == 3,
									grey: !row.place
								}"
							>
								<div class="inner">
									<div class="inner">
										<span>
											<strong *ngIf="row.place">{{ row.place }}</strong>
											<strong *ngIf="!row.place">-</strong>
										</span>
										<span>of {{ row.runners }}</span>
									</div>
								</div>
								<div class="time-mobile">{{ row.time }}</div>
							</div>
							<!-- end t-index -->

							<div class="t-conents col-11 border-gradient with-padd">
								<div class="panel grey collapsable-section closed">
									<table class="table">
										<tbody class="horse-panel-header">
											<!-- DESKTOP LAYOUT -->
											<tr class="d-none d-lg-block">
												<td class="td_name" style="width: 24%">
													<h3 class="player_name">{{ row.horse }}</h3>
												</td>
												<td class="trainer" style="width: 17%">
													<span>
														<strong>T:</strong>
														{{ row.trainer }}
													</span>
													<span>
														<strong>Date:</strong>
														{{ row.date }}
													</span>
												</td>
												<td class="venue" style="width: 17%">
													<span>
														<strong>Venue:</strong>
														{{ row.location }}
													</span>
													<span>
														<strong>Heat:</strong>
														{{ row.jump }}
													</span>
												</td>
												<td class="distance" style="width: 17%">
													<span>
														<strong>Distance:</strong>
														{{ row.distance }}
													</span>
													<span>
														<strong>Time:</strong>
														{{ row.time }}
													</span>
												</td>
												<td
													*ngIf="row.bb_count == 0 || row.bb_count == 'undefined'"
													class="add_book text-right"
													style="width: 20%"
												>
													<a class="btn large" (click)="OpenBlackbook(row)">
														Add to Blackbook
													</a>
												</td>
												<td
													*ngIf="row.bb_count >= 1"
													class="add_book text-right"
													style="width: 20%"
												>
													<a class="btn large edit_blackbook" (click)="OpenBlackbook(row)">
														<span class="bb_count">{{ row.bb_count }}</span
														>Edit Blackbook
													</a>
												</td>
												<td class="plus" style="width: 5%">
													<span class="toggleBtn" (click)="toggleAccordion($event)"></span>
												</td>
											</tr>

											<!-- MOBILE LAYOUT -->
											<tr class="d-lg-none mobile_layout">
												<td
													style="width: 100%"
													class="td_name"
													(click)="toggleAccordion($event)"
												>
													<div class="player_name">
														<h3>{{ row.horse }}</h3>
														<div
															*ngIf="row.bb_count >= 1"
															class="add_book text-right"
														>
															<a class="btn large edit_blackbook" (click)="OpenBlackbook(row)">
																Edit <span>Blackbook</span>
															</a>
														</div>
														<div
															*ngIf="row.bb_count == 0 || row.bb_count == 'undefined'"
															class="add_book text-right"
														>
															<a class="btn large add_blackbook" (click)="OpenBlackbook(row)">
																Add <span>to Blackbook</span>
															</a>
														</div>
														<div class="date">{{ row.date | date: 'dd/MM/yyyy' }}</div>
													</div>

													<div class="inner">
														<div class="content">
															<table>
																<tr>
																	<td>
																		<span>
																			<strong>Venue:</strong>
																			{{ row.location }}
																		</span>
																	</td>
																	<td>
																		<span>
																			<strong>Heat:</strong>
																			{{ row.jump }}
																		</span>
																	</td>
																	<td rowspan="2" style="vertical-align: middle">
																		<span class="plus">
																			<span class="toggleBtn"></span>
																		</span>
																	</td>
																</tr>
																<tr>
																	<td>
																		<span>
																			<strong>Distance:</strong>
																			{{ row.distance }}
																		</span>
																	</td>
																	<td class="trainer">
																		<span>
																			<strong>Trainer:</strong>
																			
																			<span class="desktop">{{ row.trainer }}</span>
										
																			<span class="mobile" *ngIf="row.trainer">{{
																				row.trainer.length > 9
																					? (row.trainer | slice: 0:8) + '..'
																					: row.trainer
																			}}</span>

																		</span>
																	</td>
																</tr>
															</table>
														</div>
														<!-- end content -->
													</div>
													<!-- end inner -->
												</td>
											</tr>
										</tbody>
									</table>

									<div class="collapsed-content">
										<div class="info-table">
											<div class="t-row">
												<div class="t-col" style="width: 24%">
													<span class="t-body">Colours:</span>
													<span *ngIf="row.colours" class="t-head">{{ row.colours }}</span>
													<span *ngIf="!row.colours" class="t-head">-</span>
												</div>
												<div class="t-col" style="width: 17%">
													<span class="t-body">Course:</span>
													<span *ngIf="row.course" class="t-head">{{ row.course }}</span>
													<span *ngIf="!row.course" class="t-head">-</span>
												</div>
												<div class="t-col" style="width: 17%">
													<span class="t-body">Jockey:</span>
													<span *ngIf="row.jockey" class="t-head">{{ row.jockey }}</span>
													<span *ngIf="!row.jockey" class="t-head">-</span>
												</div>

												<div class="t-col col-actions">
													<a
														href="{{ row.website }}"
														target="_blank"
														class="btn large blue_outline"
													>
														Visit website
													</a>
													<a
														href="{{ row.race_link }}"
														target="_blank"
														class="btn large blue"
													>
														Visit heat link
													</a>
												</div>
											</div>
											<!-- end t-row -->
										</div>

										<!-- end info-table -->
									</div>
									<!-- collapsed-content -->
								</div>
							</div>
							<!-- end t-conents -->
						</li>
					</ul>
					<!-- end accr-section -->

					<ul class="accr-section" *ngIf="!loading && jumpouts_count == 0">
						<li class="jumpouts-tables-wrap row" >
							<h5>No records found.</h5>
							<!-- end t-conents -->
						</li>
					</ul>

					<ul class="accr-section" *ngIf="loading">
						<li class="jumpouts-tables-wrap row" *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]">
							<div class="t-index col-1">
								<div class="inner">
									<div class="inner">
										<span>
											<strong class="loading-text"></strong>
										</span>
										<span class="loading-text"></span>
									</div>
								</div>
								<div class="time-mobile loading-text"></div>
							</div>
							<!-- end t-index -->

							<div class="t-conents col-11 border-gradient with-padd">
								<div class="panel grey collapsable-section closed">
									<table class="table">
										<tbody class="horse-panel-header">
											<!-- DESKTOP LAYOUT -->
											<tr class="d-none d-lg-table w-100">
												<td class="td_name" style="width: 24%">
													<h3 class="player_name loading-text"></h3>
												</td>
												<td class="trainer" style="width: 17%">
													<span class="loading-text"></span>
													<span class="loading-text"></span>
												</td>
												<td class="venue" style="width: 17%">
													<span class="loading-text"></span>
													<span class="loading-text"></span>
												</td>
												<td class="distance" style="width: 17%">
													<span class="loading-text"></span>
													<span class="loading-text"></span>
												</td>
												<td class="add_book text-right" style="width: 20%">
													<a class="btn large loading-text"></a>
												</td>
												<td class="plus" style="width: 5%"></td>
											</tr>

											<!-- MOBILE LAYOUT -->
											<tr class="d-lg-none mobile_layout">
												<td style="width: 100%" class="td_name">
													<div class="player_name">
														<h3><span class="loading-text"></span></h3>
														<div class="date"><span class="loading-text"></span></div>
													</div>

													<div class="inner">
														<div class="content">
															<table>
																<tr>
																	<td>
																		<span>
																			<strong
																				><span class="loading-text"></span
																			></strong>
																			<span class="loading-text"></span>
																		</span>
																	</td>
																	<td>
																		<span>
																			<strong
																				><span class="loading-text"></span
																			></strong>
																			<span class="loading-text"></span>
																		</span>
																	</td>
																	<td rowspan="2" style="vertical-align: middle">
																		<span class="plus">
																			<span class="toggleBtn loading-text"></span>
																		</span>
																	</td>
																</tr>
																<tr>
																	<td>
																		<span>
																			<strong
																				><span class="loading-text"></span
																			></strong>
																			<span class="loading-text"></span>
																		</span>
																	</td>
																	<td>
																		<span>
																			<strong
																				><span class="loading-text"></span
																			></strong>
																			<span class="loading-text"></span>
																		</span>
																	</td>
																</tr>
															</table>
														</div>
														<!-- end content -->
													</div>
													<!-- end inner -->
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<!-- end t-conents -->
						</li>
					</ul>
					<!-- end accr-section -->

					<div class="row pagination_wrapper" *ngIf="jumpouts_count">
						<pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
					</div>
				</div>
				<!-- end panel -->
			</div>
		</div>
	</div>
</div>
<!-- end jumpouts_wrapper -->
