import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, HostListener } from '@angular/core';
import { UpgradeModalPage } from '../../shared/upgrade-modal/upgrade-modal.page';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: '[horse-panel-header]',
	templateUrl: './horse-panel-header.component.html',
	styleUrls: ['./horse-panel-header.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class HorsePanelHeaderComponent implements OnInit {
	@Input() row: any;
	@Input() i: any;
	@Input() user: any;
	@Input() bb_count: any;
	@Output() getRaceHorseDetailsIn = new EventEmitter<{ e: object; horseId: any; i: any }>();
	screenWidth:any;

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.screenWidth = window.innerWidth;
	}

	constructor(private modalService: NgbModal) {
		this.getScreenSize();
	}

	ngOnInit(): void {}

	getRaceHorseDetailsInner($event: any, $horseId: any, i: any) {
		this.getRaceHorseDetailsIn.emit({ e: $event, horseId: $horseId, i: i });
	}

	onImgError(event: any, seq_no: any) {
		event.target.src = 'assets/images/jockeys/jockey' + seq_no + '.png';
	}

	showUpgradeModal(type: any) {
		const modalRef = this.modalService.open(UpgradeModalPage, {
			size: 'custom',
			windowClass: 'upgrade-modal',
		});

		let data = {
			type: type,
		};

		modalRef.componentInstance.fromParent = data;
		modalRef.result.then(
			(result) => {
				//console.log(result);
			},
			(reason) => {}
		);
	}

	formatName(name: any) {
		let copy_name = name;
		let regex = /^\s*(?:M(?:iss|rs?|s)|Dr|Rev)\b[\s.]*/;
		let pattern = regex.exec(name);
		return this.getInitials(name.replace(pattern, ''));
	}

	getInitials(name: any) {
		let initials = name.split(' ');
		initials = initials.shift().charAt(0) + '. ' + initials.toString().replace(',', ' ');
		return initials;
	}
}
