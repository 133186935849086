<!-- USER HAS ACCESS TO LIVEYARDS and Liveyard / Liveyard Horses Data is present -->
<div class="liveyard_tab_content" *ngIf="user && user?.hasLiveYard && (liveyard || live_yard_horses)">
	<div class="heading_table desktop-layout row" *ngIf="liveyard && liveyard.racenote && screenWidth > 767">
		<div class="col_picks col-lg-3 pr-md-0 col-md-4 mb-md-0 mb-sm-4 mb-4">
			<div class="inner">
				<div class="inner">
					<h5 class="title">Yard picks</h5>

					<div class="cell row">
						<div *ngIf="liveyard?.for_1 && liveyard?.for_1 !== ' '" class="pick col-md-3 col-sm-2 col-3" >
							<span [ngClass]="{
								green: liveyard.for_1 == liveyard.place_1,
								yellow:
									liveyard.for_1 == liveyard.place_2 ||
									liveyard.for_1 == liveyard.place_3 ||
									liveyard.for_1 == liveyard.place_4
							}">{{ liveyard.for_1 }}</span>
						</div>
						<div *ngIf="liveyard?.for_2 && liveyard?.for_2 !== ' '" class="pick col-md-3 col-sm-2 col-3">
							<span [ngClass]="{
								green: liveyard.for_2 == liveyard.place_1,
								yellow:
									liveyard.for_2 == liveyard.place_2 ||
									liveyard.for_2 == liveyard.place_3 ||
									liveyard.for_2 == liveyard.place_4
							}">{{ liveyard.for_2 }}</span>
						</div>
						<div *ngIf="liveyard?.for_3 && liveyard?.for_3 !== ' '" class="pick col-md-3 col-sm-2 col-3">
							<span [ngClass]="{
								green: liveyard.for_3 == liveyard.place_1,
								yellow:
									liveyard.for_3 == liveyard.place_2 ||
									liveyard.for_3 == liveyard.place_3 ||
									liveyard.for_3 == liveyard.place_4
							}">{{ liveyard.for_3 }}</span>
						</div>
						<div *ngIf="liveyard?.for_4 && liveyard?.for_4 !== ' '" class="pick col-md-3 col-sm-2 col-3">
							<span [ngClass]="{
								green: liveyard.for_4 == liveyard.place_1,
								yellow:
									liveyard.for_4 == liveyard.place_2 ||
									liveyard.for_4 == liveyard.place_3 ||
									liveyard.for_4 == liveyard.place_4
							}">{{ liveyard.for_4 }}</span>
						</div>
						<div
							*ngIf="liveyard?.againsts_1 && liveyard?.againsts_1 !== ' '"
							class="pick col-md-3 col-sm-2 col-3 mt-md-3"
						>
							<span class="against">{{ liveyard.againsts_1 }}</span>
						</div>
						<div
							*ngIf="liveyard?.againsts_2 && liveyard?.againsts_2 !== ' '"
							class="pick col-md-3 col-sm-2 col-3 mt-md-3"
						>
							<span class="against">{{ liveyard.againsts_2 }}</span>
						</div>
						<div
							*ngIf="liveyard?.againsts_3 && liveyard?.againsts_3 !== ' '"
							class="pick col-md-3 col-sm-2 col-3 mt-md-3"
						>
							<span class="against">{{ liveyard.againsts_3 }}</span>
						</div>
						<div
							*ngIf="liveyard?.againsts_4 && liveyard?.againsts_4 !== ' '"
							class="pick col-md-3 col-sm-2 col-3 mt-md-3"
						>
							<span class="against">{{ liveyard.againsts_4 }}</span>
						</div>
					</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end inner -->
		</div>
		<!-- end col_picks -->

		<div class="col_text col-lg-8 col-md-8 col-sm-10">
			<div class="inner bubble_chat bubble_white_bg">
				<div class="inner">
					<div class="body">
						<strong>Live Yard Comment:</strong>
						<p *ngIf="liveyard?.racenote">
							{{ liveyard?.racenote.split(',').join(', ') }}
							<strong *ngIf="liveyard?.analyst && liveyard?.analyst !='-'">- {{ liveyard?.analyst }}</strong>
						</p>
					</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end inner -->
		</div>
		<!-- end col_text -->

		<div class="col_img col-lg-1 col-md-12 col-sm-2 pl-lg-0">
			<div class="inner">
				<div class="inner">
					<div class="analyst-img" [ngStyle]="{'background-image': 'url(' + liveyard.analyst_avatar + ')'}"></div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end inner -->
		</div>
		<!-- end col_img -->
	</div>
	<!-- end heading_table -->

	<!-- MOBILE -->
		<div class="heading_table row mobile-layout" *ngIf="liveyard && liveyard.racenote && screenWidth <= 767">
			<div class="col_text col-12">
				<div class="inner bubble_chat bubble_white_bg">
					<div class="inner">
						<div class="body">
							<strong>Live Yard Comment:</strong>
							<p *ngIf="liveyard?.racenote">
								{{ liveyard?.racenote.split(',').join(', ') }}
							</p>
						</div>
					</div>
					<!-- end inner -->
				</div>
				<!-- end inner -->
			</div>
			<!-- end col_text -->

			<div class="col_img col-6 my-auto">
				<div class="inner">
					<div class="inner">
						<div class="analyst-img" [ngStyle]="{'background-image': 'url(' + liveyard.analyst_avatar + ')'}"></div>
						<strong *ngIf="liveyard?.analyst">- {{ liveyard?.analyst }}</strong>
					</div>
					<!-- end inner -->
				</div>
				<!-- end inner -->
			</div>
			<!-- end col_img -->
			<div class="col_picks col-6 my-auto">
				<div class="inner">
					<div class="inner">
						<div class="cell row">
							<div *ngIf="liveyard?.for_1 && liveyard?.for_1 !== ' '" class="pick col-md-3 col-sm-2 col-3" >
								<span [ngClass]="{
									green: liveyard.for_1 == liveyard.place_1,
									yellow:
										liveyard.for_1 == liveyard.place_2 ||
										liveyard.for_1 == liveyard.place_3 ||
										liveyard.for_1 == liveyard.place_4
								}">{{ liveyard.for_1 }}</span>
							</div>
							<div *ngIf="liveyard?.for_2 && liveyard?.for_2 !== ' '" class="pick col-md-3 col-sm-2 col-3">
								<span [ngClass]="{
									green: liveyard.for_2 == liveyard.place_1,
									yellow:
										liveyard.for_2 == liveyard.place_2 ||
										liveyard.for_2 == liveyard.place_3 ||
										liveyard.for_2 == liveyard.place_4
								}">{{ liveyard.for_2 }}</span>
							</div>
							<div *ngIf="liveyard?.for_3 && liveyard?.for_3 !== ' '" class="pick col-md-3 col-sm-2 col-3">
								<span [ngClass]="{
									green: liveyard.for_3 == liveyard.place_1,
									yellow:
										liveyard.for_3 == liveyard.place_2 ||
										liveyard.for_3 == liveyard.place_3 ||
										liveyard.for_3 == liveyard.place_4
								}">{{ liveyard.for_3 }}</span>
							</div>
							<div *ngIf="liveyard?.for_4 && liveyard?.for_4 !== ' '" class="pick col-md-3 col-sm-2 col-3">
								<span [ngClass]="{
									green: liveyard.for_4 == liveyard.place_1,
									yellow:
										liveyard.for_4 == liveyard.place_2 ||
										liveyard.for_4 == liveyard.place_3 ||
										liveyard.for_4 == liveyard.place_4
								}">{{ liveyard.for_4 }}</span>
							</div>
							<div
								*ngIf="liveyard?.againsts_1 && liveyard?.againsts_1 !== ' '"
								class="pick col-md-3 col-sm-2 col-3 mt-md-3"
							>
								<span class="against">{{ liveyard.againsts_1 }}</span>
							</div>
							<div
								*ngIf="liveyard?.againsts_2 && liveyard?.againsts_2 !== ' '"
								class="pick col-md-3 col-sm-2 col-3 mt-md-3"
							>
								<span class="against">{{ liveyard.againsts_2 }}</span>
							</div>
							<div
								*ngIf="liveyard?.againsts_3 && liveyard?.againsts_3 !== ' '"
								class="pick col-md-3 col-sm-2 col-3 mt-md-3"
							>
								<span class="against">{{ liveyard.againsts_3 }}</span>
							</div>
							<div
								*ngIf="liveyard?.againsts_4 && liveyard?.againsts_4 !== ' '"
								class="pick col-md-3 col-sm-2 col-3 mt-md-3"
							>
								<span class="against">{{ liveyard.againsts_4 }}</span>
							</div>
						</div>
					</div>
					<!-- end inner -->
				</div>
				<!-- end inner -->
			</div>
			<!-- end col_picks -->
		</div>
	<!-- MOBILE -->

	<ul *ngIf="live_yard_horses && liveyard.racenote">
		<li *ngFor="let row of live_yard_horses">
			<table class="table" *ngIf="row?.horse_status != 'Scratched' && row?.horse_status != 'LateScratched'">
				<tbody>
					<tr>
						<td class="tabno" [ngClass]="{ twice: row.tabno > 9 }">
							<h1 class="index_no">{{ row?.tabno }}.</h1>
						</td>
						<td class="td_name" [ngClass]="{ twice: row.tabno > 9 }">
							<div class="player_name">
								<h3>
									<span class="index_no d-none">{{ row.tabno }}.</span>{{ row.horsename }}
									<small>({{ row.barrier }})</small>
									<div
										class="yard_pick circle"
										placement="top"
										ngbTooltip="Yard pick"
										*ngIf="row.predicted_place && row.predicted_place != ' '"
									>
										<span>Y</span>
									</div>
									<!-- end yard_pick circle -->
									<div
										class="preview circle"
										placement="top"
										ngbTooltip="Preview pick (win)"
										*ngIf="row.win_bet"
									>
										<span>W</span>
										<!--<span>P</span>-->
										<!-- SHOW P IF THE PICK IS A PLACE -->
									</div>
									<!-- end yard_pick circle -->
									<div
										class="preview_place circle"
										placement="top"
										ngbTooltip="Preview pick (win)"
										*ngIf="row.place_bet"
									>
										<span>P</span>
										<!--<span>P</span>-->
										<!-- SHOW P IF THE PICK IS A PLACE -->
									</div>

									<div
									class="best_bet image_circle"
									placement="top"
									ngbTooltip="Best Bet"
									*ngIf="row.best_bet && user && user?.hasPreviews"
								>
									<span><img
										alt="TRC"
										class="img-fluid"
										src="assets/images/icons/best_bet.png"
									/></span>
									<!--<span>P</span>-->
									<!-- SHOW P IF THE PICK IS A PLACE -->
								</div>
								<div
									class="value_bet image_circle"
									placement="top"
									ngbTooltip="Value Bet"
									*ngIf="row.value_bet && user && user?.hasPreviews"
								>
									<span><img
										alt="TRC"
										class="img-fluid"
										src="assets/images/icons/value_bet.png"
									/></span>
									<!--<span>P</span>-->
									<!-- SHOW P IF THE PICK IS A PLACE -->
								</div>
									<!-- end yard_pick circle -->
									<div
										class="dual_nomination circle"
										placement="top"
										ngbTooltip="Dual acceptance"
										*ngIf="row.dual_nom == 'D'"
									>
										<span>D</span>
									</div>
									<!-- end yard_pick circle -->
								</h3>
							</div>
							<span *ngIf="row?.comment"><strong>Comment: </strong>{{ row?.comment.split(',').join(', ') }}</span>
						</td>

						<td class="trc_rating desktop-layout" *ngIf="user && user.hasPreviews && screenWidth > 767">
							<span class="rating active" *ngIf="row.last_race_trc_rating">{{
								row.last_race_trc_rating
							}}</span>
							<span class="rating active" *ngIf="!row.last_race_trc_rating">NYR</span>
							<span><strong>TRC Rating</strong></span>
						</td>

						<td
							style="width: 12%"
							*ngIf="!user || !user.hasPreviews"
							class="trc_rating locked"
							(click)="showUpgradeModal('previews')"
						>
							<span class="lock-icon">
								<img src="assets/images/icons/lock.svg" alt="locked" />
							</span>
							<span class="trc_rating">TRC Rating</span>
						</td>

						<td class="yard_health_rating desktop-layout" *ngIf="screenWidth > 767">
							<div class="row">
								<div class="col col-circle pl-0">
									<circle-progress
										class="smooth"
										[outerStrokeColor]="percentToColor(row?.final_score, 100, 0)"
										[percent]="row?.final_score"
										[subtitleFormat]="formatSubtitle"
									></circle-progress>
								</div>
								<div class="col col-circle-info align-self-center px-1">
									<p><strong>Previous:</strong> {{ row?.lastcomment_score | number: '1.1-1' }}</p>
									<p><strong>Parade:</strong>{{ row?.final_score | number: '1.1-1' }}</p>
								</div>
							</div>
						</td>

						<!-- MOBILE -->
							<td class="yard_health_rating mobile_layout" *ngIf="screenWidth <= 767">
								<div class="row">
									<div class="col-6 p-0">
										<div class="panel">
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-4 col-circle">
													<circle-progress
														class="fast"
														[responsive]="true"
														[outerStrokeColor]="percentToColor(row.lastcomment_score, 100, 0)"
														[percent]="row.lastcomment_score"
														[subtitleFormat]="formatSubtitle"
														*ngIf="row?.lastcomment_score && row?.lastcomment_score != ' '"
													></circle-progress>
													<span class="loading-circle" *ngIf="!row.lastcomment_score || row.lastcomment_score == ' '">-</span>
												</div>
												<div class="col-xl-8 col-lg-12 col-md-8 col-circle-info align-self-center">
													<div>
														<strong>Analyst</strong>
														<div class="info_circle" (click)="openInstructions()">
															<i class="info_icon">i</i>
														</div>
													</div>
												</div>
											</div>
										</div>
										<!-- end panel -->
									</div>
									<!-- end t-col-1 -->

									<div class="col-6 p-0">
										<div class="panel">
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-4 col-circle">
													<circle-progress
														class="fast"
														[responsive]="true"
														[outerStrokeColor]="percentToColor(row.final_score, 100, 0)"
														[percent]="row.final_score"
														[subtitleFormat]="formatSubtitle"
														*ngIf="row?.final_score && row?.final_score != ' '"
													></circle-progress>
													<span class="loading-circle" *ngIf="!row?.final_score || row?.final_score == ' '">-</span>
												</div>
												<div
													class="col-xl-8 col-lg-12 col-md-8 col-circle-info align-self-center"
												>
													<div>
														<strong>Parade</strong>
														<div class="info_circle" (click)="openInstructions()">
															<i class="info_icon">i</i>
														</div>
													</div>
												</div>
											</div>
										</div>
										<!-- end panel -->
									</div>
									<!-- end t-col-1 -->
								</div>
								<!-- end row -->
							</td>
						<!-- MOBILE -->

						<td class="prices">
							<img
								class="img-fluid bookmaker"
								src="assets/images/bookmakers/unibet-tile2.png"
								alt="TRC"
							/>
							<span class="bookmaker-img small_space"
								><strong>Win:</strong> {{ row?.win3_current | currency: 'USD' }}</span
							>
							<span class="bookmaker-img"
								><strong>Place:</strong>{{ row?.plc3_current | currency: 'USD' }}</span
							>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- end table -->

			<table
				class="table Scratched"
				*ngIf="row?.horse_status == 'Scratched' || row?.horse_status == 'LateScratched'"
			>
				<tbody>
					<tr>
						<td class="tabno">
							<h1 class="index_no">{{ row?.tabno }}.</h1>
						</td>
						<td class="td_name">
							<div class="player_name">
								<h3>
									<span class="index_no d-none">{{ row.tabno }}.</span>{{ row?.horsename }}
									<small>({{ row?.barrier }})</small>
								</h3>
							</div>
							<span><strong>Comment:</strong>{{ row?.comment.split(',').join(', ') }}</span>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- end table -->
		</li>
	</ul>

	<div class="comments with-avatar pending_analysis" *ngIf="liveyard && !liveyard.racenote">
		<div class="row">

			<div class="col-4">
			
				<img src="assets/images/placeholders/liveyard-mini.svg" class="pending_placeholder" />
			
			</div><!-- end col-4 -->

			<div class="col-8 col_message">
			
				<p>Yard analysis status: <b>Pending</b><span>(ETA: 2-6 minutes pre jump)</span></p>
			
			</div><!-- end col-8 -->
		
		</div><!-- end row -->
	</div>

</div>
<!-- end liveyard_tab_content -->
<!-- USER HAS ACCESS TO LIVEYARDS but no Liveyard & Liveyard Horse Data is present -->
<div class="liveyard_tab_content" *ngIf="!liveyard_loading">
	<div class="panel locked_panel" *ngIf="(user && !user?.hasLiveYard) || !user || (!liveyard && !live_yard_horses)">
		<div class="top_text_wrapper">
			<h2 class="col-12 text-center heading" *ngIf="!liveyard && !live_yard_horses">
				Live Yard is currently not scheduled for this race
			</h2>
			<h2 class="col-12 text-center heading" *ngIf="liveyard && live_yard_horses">
				Unlock professional analysis & insights
			</h2>

			<div class="body text-center" *ngIf="liveyard && live_yard_horses">
				<p *ngIf="!liveyard && !live_yard_horses">
					Subscribe to the 'Platinum' or 'Live Yard' package to unlock future Live Yard analysis along with
					other exclusive content.
				</p>
				<p *ngIf="liveyard && live_yard_horses">
					Subscribe to the 'Platinum' or 'Live Yard' package to access this as well as other exclusive
					content.
				</p>
				<a class="btn blue arrow_large" routerLink="/subscribe-plan" *ngIf="user">Unlock</a>
				<a class="btn blue arrow_large" routerLink="/signup" *ngIf="!user">Sign up</a>
			</div>

			<div class="placeholder" *ngIf="liveyard && live_yard_horses">
				<img class="img-fluid" src="assets/images/placeholders/liveyard-large.svg" alt="Upgrade" />
			</div>
		</div>
	</div>
</div>
<!-- end liveyard_tab_content -->
