<div class="onboarding_wrapper login">
	<div class="floating_logo">
		<a routerLink="/">
			<img src="assets/images/logos/logo-vertical-light.png"/>
		</a>
	</div>

	<div class="bg_image"></div>
	<!-- end bg_image -->
	<div class="bg_image mob"></div>
	<!-- end bg_image -->

	<div class="container-fluid outer">
		<div class="row">
			<div class="col_form text-center">
				<div class="inner">
					<h1 class="page_title white">Password reset</h1>

					<div class="subheading">
						<p>
							Forgot your password? Enter your address below to trigger an email containing a password
							reset link.
						</p>
					</div>

					<form class="form_wrapper" [formGroup]="ForgotPasswordForm" (ngSubmit)="onSubmit()">
						<div class="form-row">
							<div class="form-group col-12">
								<input
									type="text"
									class="form-control"
									placeholder="Email Address"
									formControlName="email"
								/>
							</div>

							<div class="form-group col-12">
								<button class="btn blue arrow_large" type="submit">Reset Password</button>
							</div>
							<div class="col-12 col_return">
							
							    <a routerLink="/">Return to Home</a>
							
							</div><!-- end col-12 -->
							
						</div>
					</form>
				</div>
				<!-- end inner -->
			</div>
			<!-- end col_form -->
		</div>
	</div>
	<!-- end container-fluid -->
</div>
<!-- end signup_wrapper -->
