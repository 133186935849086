<ul>
	<li>
		<table class="speed-maps-table" *ngIf="selectedrace?.map_direction == 'RIGHT' || !selectedrace?.map_direction">
			<tbody *ngFor="let row of speed_maps_horses; let i = index" id="{{ row.horse_id }}">
				<tr *ngIf="row.horse_status == 'Active'">
					<td class="index_col">
						<span class="index">{{ row.barrier }}.</span>
					</td>

					<td class="map_position_col">
						<div class="select_wrap">
							<select class="form-control" disabled>
								<option value="" [selected]="row.map_position == 'MID'">Midfield</option>
								<option value="" [selected]="row.map_position == 'OFF-PACE'">Off Pace</option>
								<option value="" [selected]="row.map_position == 'ON-PACE'">On Pace</option>
								<option value="" [selected]="row.map_position == 'LEAD'">First Starter</option>
								<option value="" [selected]="row.map_position == 'BACK'">Backmarker</option>
							</select>
						</div>
					</td>

					<td class="jockey_image_col">
						<img
							class="img-fluid jockey-img"
							[src]="row.silks"
							(error)="onImgError($event, row.seq_no)"
							alt="TRC"
						/>
					</td>

					<td class="progress_bar_col">
						<div class="progress" *ngIf="!speedmap_loading">
							<div
								class="progress-bar"
								role="progressbar"
								[style.width]="row.speed_map_perc + '%'"
								attr.aria-valuenow="{{ row.speed_map_perc }}%"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								<div class="name_wrap inner" [ngClass]="row.speed_map_perc < 50 ? 'd-none' : ''">
									<span class="name_span pr-2">{{ row.tabno }}. {{ row.horse_name }} </span>
									<span class="bookmaker-img" *ngIf="row.win3_current"
										><img
											class="img-fluid bookmaker"
											src="assets/images//bookmakers/unibet-u.png"
											alt="Racing Speed Maps 1"
										/><strong class="win-label"> Win: </strong>
										<span>{{ row.win3_current | currency: 'USD' }}</span></span
									>
								</div>
							</div>
							<div
								class="name_wrap outer align-self-center"
								[ngClass]="row.speed_map_perc >= 50 ? 'd-none' : ''"
							>
								<span class="name_span">{{ row.tabno }}. {{ row.horse_name }}</span>
								<span class="bookmaker-img" *ngIf="row.win3_current"
									><img
										class="img-fluid bookmaker"
										src="assets/images//bookmakers/unibet-u.png"
										alt="Racing Speed Maps 2"
									/><strong class="win-label"> Win: </strong>
									<span>{{ row.win3_current | currency: 'USD' }}</span></span
								>
							</div>
						</div>
						<div class="progress loading-text" *ngIf="speedmap_loading"></div>
					</td>
				</tr>
			</tbody>
		</table>
		<table class="speed-maps-table reverse" *ngIf="selectedrace?.map_direction == 'LEFT'">
			<tbody *ngFor="let row of speed_maps_horses; let i = index" id="{{ row.horse_id }}">
				<tr *ngIf="row.horse_status == 'Active'">
					<td class="progress_bar_col">
						<div class="progress-col">
							<div class="progress d-flex" *ngIf="!speedmap_loading">
								<div
									class="name_wrap outer align-self-center ml-auto"
									[ngClass]="row.speed_map_perc >= 50 ? 'd-none' : ''"
								>
									<span class="name_span mobile">{{ row.tabno }}. {{ row.horse_name }}</span>
									<span class="bookmaker-img" *ngIf="row.win3_current"
										><img
											class="img-fluid bookmaker"
											src="assets/images//bookmakers/unibet-u.png"
											alt="Racing Speed Maps 2"
										/><strong class="win-label"> Win: </strong>
										<span>{{ row.win3_current | currency: 'USD' }}</span></span
									>
									<span class="name_span desktop">{{ row.tabno }}. {{ row.horse_name }}</span>
								</div>
								<div
									class="progress-bar"
									role="progressbar"
									[style.width]="row.speed_map_perc + '%'"
									attr.aria-valuenow="{{ row.speed_map_perc }}%"
									aria-valuemin="0"
									aria-valuemax="100"
								>
									<div class="name_wrap inner" [ngClass]="row.speed_map_perc < 50 ? 'd-none' : ''">
										<span class="bookmaker-img" *ngIf="row.win3_current"
											><img
												class="img-fluid bookmaker"
												src="assets/images//bookmakers/unibet-u.png"
												alt="Racing Speed Maps 1"
											/><strong class="win-label"> Win: </strong>
											<span>{{ row.win3_current | currency: 'USD' }}</span></span
										>
										<span class="pl-2 name_span">{{ row.tabno }}. {{ row.horse_name }} </span>
									</div>
								</div>
							</div>
							<div class="progress loading-text" *ngIf="speedmap_loading"></div>
						</div>
					</td>

					<td class="jockey_image_col">
						<img class="img-fluid jockey-img" src="{{ row.silks }}" alt="TRC" />
					</td>

					<td class="map_position_col">
						<div class="select_wrap">
							<select class="form-control" disabled>
								<option value="" [selected]="row.map_position == 'MID'">Midfield</option>
								<option value="" [selected]="row.map_position == 'OFF-PACE'">Off Pace</option>
								<option value="" [selected]="row.map_position == 'ON-PACE'">On Pace</option>
								<option value="" [selected]="row.map_position == 'LEAD'">First Starter</option>
								<option value="" [selected]="row.map_position == 'BACK'">Backmarker</option>
							</select>
						</div>
					</td>

					<td class="index_col">
						<span class="index">{{ row.barrier }}.</span>
					</td>
				</tr>
			</tbody>
		</table>
	</li>
	<li>
		<table
			class="speed-maps-table-scratchings"
			*ngIf="selectedrace?.map_direction == 'RIGHT' || !selectedrace?.map_direction"
		>
			<tbody>
				<tr>
					<td class="label-td d-none d-lg-table-cell">
						<span
							>Scratchings:
							<p>
								<span *ngIf="selectedrace?.scratced_horses">{{ selectedrace?.scratced_horses }}</span>
							</p></span
						>
					</td>
					<td class="progress-td">
						<div class="progress">
							<div
								class="progress-bar"
								role="progressbar"
								style="width: 100%"
								aria-valuenow="100"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								<span class="{{ selectedrace?.map_direction == 'LEFT' ? 'left' : 'right' }}"
									>{{ selectedrace?.distance }}m</span
								>
							</div>
						</div>
					</td>
					<td class="label-td d-lg-none">
						<span
							>Scratchings:
							<p>
								<span *ngIf="selectedrace?.scratced_horses">{{ selectedrace?.scratced_horses }}</span>
							</p></span
						>
					</td>
				</tr>
			</tbody>
		</table>
		<table class="speed-maps-table-scratchings" *ngIf="selectedrace?.map_direction == 'LEFT'">
			<tbody>
				<tr>
					<td class="progress-td left">
						<div class="progress">
							<div
								class="progress-bar"
								role="progressbar"
								style="width: 100%"
								aria-valuenow="100"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								<span class="{{ selectedrace?.map_direction == 'LEFT' ? 'left' : 'right' }}"
									>{{ selectedrace?.distance }}m</span
								>
							</div>
						</div>
					</td>
					<td class="label-td left">
						<span>
							<strong>Scratchings:</strong>
							<p>
								<span *ngIf="selectedrace?.scratced_horses">{{ selectedrace?.scratced_horses }}</span>
							</p>
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</li>
</ul>
