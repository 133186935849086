<div class="modal_wrapper track_profile_modal">
	<div class="close_icon" (click)="close()">
		<svg xmlns="http://www.w3.org/2000/svg" width="11.689" height="11.689" viewBox="0 0 11.689 11.689">
			<g class="Group_251" data-name="Group 251" transform="translate(84.329 -65.195) rotate(135)">
				<g class="Group_249" data-name="Group 249" transform="translate(98.464 -2)">
					<path
						class="Path_1"
						data-name="Path 1"
						d="M24.331,20.516V5.986"
						transform="translate(-17.066 -5.986)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
					<path
						class="Path_81"
						data-name="Path 81"
						d="M0,14.53V0"
						transform="translate(14.53 7.265) rotate(90)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
				</g>
			</g>
		</svg>
	</div>
	<!-- end close_icon -->

	<div class="image_wrapper">
		<img *ngIf="event?.map" src="{{ event?.map }}" alt="{{ event?.name }} map" />
		<img *ngIf="!event?.map" src="assets/images/the-race-club-cranbourne-lg-med.png" alt="{{ event?.name }} map" />
	</div>
	<!-- end image_wrapper -->

	<div class="content">
		<h4>{{ event?.name }}</h4>
		<div class="cell label" *ngIf="race?.rail"> <span>Rail:</span>{{ race?.rail }}</div>
		<div class="cell label" *ngIf="event?.info"> <span>Venue Info:</span>{{ event?.info }}</div>
	</div>
	<!-- end col_content -->
</div>
<!-- end inner -->
