import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NewsService } from '../services/news.service';
import { OffersService } from '../services/offers.service';
import { ResourcesService } from '../services/resources.service';
import { FormGuideService } from '../services/form-guide.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { EventService } from '../services/event.service';
import { MetatagService } from '../services/metatag.service';

const news_articles_count = 3;

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
	news: any;
	offer: any;
	resources: any;
	racesData: any;
	quote: any;
	selectedRace: any;
	loadingOffers: boolean = true;
	loadingNews: boolean = true;
	loadingResources: boolean = true;
	loadingRaces: boolean = true;
	loadingRacesData: boolean = false;
	loadingResults: boolean = true;
	loadingTodaysPreviews: boolean = true;
	loadingAllData: boolean = true;
	selectedDate: any = moment().local().format('YYYY-MM-DD');

	previews: any;
	results: any;
	time_now = moment.utc();
	user: any;
	// @ViewChild('slickModal') slickModal: SlickCarouselComponent;

	slideRaceConfig = {
		slidesToShow: 5,
		slidesToScroll: 1,
		swipeToSlide: false,
		infinite: false,
		swipe: false,
		arrows: false,
		responsive: [
			{
				breakpoint: 1580,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					swipeToSlide: true,
					swipe: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					swipeToSlide: true,
					swipe: true,
				},
			},
		],
	};

	slideDaysConfig = {
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		infinite: false,
		responsive: [
			{
				breakpoint: 495,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	};

	constructor(
		private NewsService: NewsService,
		private OffersService: OffersService,
		private ResourcesService: ResourcesService,
		private FormGuideService: FormGuideService,
		private router: Router,
		private EventService: EventService,
		private MetatagService: MetatagService
	) {
		this.MetatagService.updateMetatags('Racing Central', 'web site', "Australia's most trusted horse racing destination with our trusted form guides, tips, news, liveyard & betting tools.","https://mobile.theraceclub.com.au/assets/images/the-race-club-facebook-og.png");

		this.getUser();

		this.EventService.userLogin.subscribe((state) => {
			if (state) {
				this.getUser();
				//console.log('login');
			} 
		});
	}

	ngOnInit() {
		this.getLatestNews();
		this.getLatestResources();
		this.getRaces();
		this.getTodaysPreviews();
		this.getResults();
		if (this.user) {
			this.getOffer();
		} else {
			this.getGuestOffer();
		}
		this.loadingAllData = false;
	}

	getUser() {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
	}

	// Show races for today, tomorrow and upcoming. Triggers when button is clicked.
	async changeRace(row: any) {
		this.loadingRacesData = true;
		setTimeout(() => {
			// this.verfiyToken();
			this.loadingRacesData = false;
		}, 1000);

		this.racesData.forEach((element: any) => {
			if (element.day == row.day) {
				element.isSelect = true;
				this.selectedRace = row;
			} else {
				element.isSelect = false;
			}
		});

		this.selectedRace.races.forEach((element: any) => {
			element.local_time = moment.utc(element.race_start_time).local();
			element.seconds = element.local_time.diff(this.time_now, 'seconds');
		});
	}

	getLatestNews() {
		this.NewsService.getLatestNews(news_articles_count).subscribe((data: any) => {
			if (data && data.news) {
				this.news = data.news ? data.news : [];
				//console.log(this.news);
			}
			if (!this.news) {
				this.quote = 'no news are available';
				//console.log(this.quote);
			}
			this.loadingNews = false;
		});
	}

	getOffer() {
		this.OffersService.getOffer(this.user.token).subscribe((data: any) => {
			if (data && data.offer) {
				this.offer = data.offer ? data.offer : [];
				this.offer.buttons = JSON.parse(this.offer.buttonsmeta);
			}
			this.loadingOffers = false;
		});
	}

	getGuestOffer() {
		this.OffersService.getGuestOffer().subscribe((data: any) => {
			if (data && data.offer) {
				this.offer = data.offer ? data.offer : [];
				this.offer.buttons = JSON.parse(this.offer.buttonsmeta);
			}
			this.loadingOffers = false;
		});
	}

	getLatestResources() {
		this.ResourcesService.getLatestResources().subscribe((data: any) => {
			if (data && data.resources) {
				this.resources = data.resources ? data.resources : [];
				//console.log(this.resources);
			}
			if (!this.resources) {
				this.quote = 'no resources are available';
				//console.log(this.quote);
			}
			this.loadingResources = false;
		});
	}

	getRaces() {
		this.FormGuideService.getRaces().subscribe((data: any) => {
			if (data && data.racesData) {
				this.racesData = data.racesData ? data.racesData : [];
				this.selectedRace = null;
				this.racesData.forEach((element: any, index: any) => {
					if (element.races.length > 0 && !this.selectedRace) {
						element.isSelect = true;
						this.selectedRace = element;
					} else {
						element.isSelect = false;
					}
				});
				this.selectedRace.races.forEach((element: any) => {
					element.local_time = moment.utc(element.race_start_time).local();
					element.seconds = element.local_time.diff(this.time_now, 'seconds');
				});
				//console.log(this.selectedRace);
			}
			this.loadingRaces = false;
		});
	}

	getResults() {
		var data = {
			selected_date: this.selectedDate,
			timezoneOffset: new Date().getTimezoneOffset(),
		};

		this.FormGuideService.getHomePageResults(data).subscribe((data: any) => {
			if (data.results && data.results) {
				this.results = data.results ? data.results : [];
			}
			this.loadingResults = false;
		});
	}

	async OfferClicked(row: any) {
		if (row.link_type == 'url') {
			window.open(row.value);
		} else {
			this.router.navigate([row.value]);
		}
	}

	getTodaysPreviews() {
		this.FormGuideService.getTodaysPreviews().subscribe((data: any) => {
			if (data.status && data.previews) {
				this.previews = data.previews ? data.previews : [];
				this.previews.sort(this.compare);
			}
			this.loadingTodaysPreviews = false;
		});
	}

	// Sort Upcoming Races
	compare(a: any, b: any) {
		if (a.race_start_time < b.race_start_time) {
			return -1;
		}
		if (a.race_start_time > b.race_start_time) {
			return 1;
		}
		return 0;
	}

	gotoSingleNews(news: any) {
		this.router.navigate(['/news/' + news.id + '/' + news.slug]);
	}

	gotoSingleResource(resource: any) {
		this.router.navigate(['/resource/' + resource.id + '/' + resource.slug]);
	}

	TimerBelowFiveMinutes(race: any) {
		//console.log(race.event_id);
		$('#' + race.event_id).removeClass();
		$('#' + race.event_id).addClass('time-pills red to-blue');
	}

	timeexpired(race: any) {
		//console.log('time expired' + race);
		// this.processEvents();
		$('#' + race.event_id).removeClass();
		$('#' + race.event_id).addClass('time-pills grey');
	}

	async gotoFormGuide(race: any) {
		//console.log(race);
		this.router.navigate(['/form-guide/' + race.slug]);
	}

	// next() {
	// 	this.slickModal.slickNext();
	// }

	// prev() {
	// 	this.slickModal.slickPrev();
	// }
}
