import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ResourcesService {
	apiUrl = environment.serverUrl;
	constructor(private httpClient: HttpClient) {}

	/**
	 * get resources list.
	 */
	public getResourcesList(): Observable<any> {
		return this.httpClient.get(this.apiUrl + 'resources');
	}

	/**
	 * get resource details
	 */
	public getResourceDetail(newstId: any): Observable<any> {
		return this.httpClient.get(this.apiUrl + 'resource-detail/' + newstId);
	}

	/**
	 * get latest resources
	 */
	public getLatestResources(): Observable<any> {
		return this.httpClient.get(this.apiUrl + 'latest-resources');
	}
}
