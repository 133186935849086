<div class="breadcrumbs_wrapper">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title">Blackbook</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/">Home</a></li>
							<li><a routerLink="/blackbook">Blackbook</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end container -->
</div>
<!-- end breadcrumbs_wrapper -->

<div class="section_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-lg-8 offset-lg-2" *ngIf="!user">
				<div class="panel locked_panel">
					<div class="top_text_wrapper">
						<h2 class="col-12 text-center heading">Start your Blackbook.</h2>

						<div class="body text-center">
							<p>Create your FREE account to start adding horses, trainers and jockeys to your blackbook</p>
							<a class="btn blue arrow_large" routerLink="/signup">Sign up</a>
						</div>

						<div class="placeholder">
							<img class="img-fluid" src="assets/images/placeholders/blackbook-large.svg" alt="Upgrade" />
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-10 offset-lg-1" *ngIf="user">
				<div class="blackbook_wrapper">
					<ul class="trc-tabs nav nav-tabs" id="formGuidePageTab" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" id="blackbook-tab" data-toggle="tab" href="#blackbook" role="tab" aria-controls="blackbook" aria-selected="true">Blackbook<span class="count">{{ blackbook_all_entries?.length }}</span></a>
						</li>
						<li class="nav-item">
							<a class="nav-link" id="upcoming-tab" data-toggle="tab" href="#upcoming" role="tab" aria-controls="upcoming" aria-selected="true">Upcoming<span class="count">{{ upcoming_count }}</span></a>
						</li>
					</ul>

					<div class="trc-tabs tab-content" id="blackBookContent">

						<!-- BLACKBOOK TAB -->
						<div class="tab-pane fade show active" id="blackbook" role="tabpanel" aria-labelledby="blackbook">
							<div class="row heading_tab_wrap">
								<div class="col-xl-4 offset-xl-4 col-lg-12 col_filters align-self-center">

									<div class="radio_wrap switch_4 grey">
										<input type="radio" checked name="all" id="all" class="input-hidden" />
										<label for="all" (click)="filterBlackbook('all')">
											<span class="btn">All</span>
										</label>

										<input type="radio" name="all" id="horses" class="input-hidden" />
										<label for="horses" class="m-0" (click)="filterBlackbook('horse')">
											<span class="btn">Horses</span>
										</label>

										<input type="radio" name="all" id="jockeys" class="input-hidden" />
										<label for="jockeys" class="m-0" (click)="filterBlackbook('jockey')">
											<span class="btn">Jockeys</span>
										</label>

										<input type="radio" name="all" id="trainers" class="input-hidden" />
										<label for="trainers" class="m-0" (click)="filterBlackbook('trainer')">
											<span class="btn">Trainers</span>
										</label>

										<div class="slider"></div>
									</div>
									<!-- end radio_wrap -->

									<!-- GHOST ELEMENT -->
									<!--<ul *ngIf="loading" class="panel grey loading-text"></ul>-->
								</div>

								<div class="col-xl-4 col_search_form align-self-center">
									<div class="search-form">
										<form action="">
											<input type="text" [(ngModel)]="search_txt" [ngModelOptions]="{ standalone: true }" ngModaautocomplete="off" placeholder="Search by horse, trainer or venue" />
											<button (click)="getBlackBook(true)" type="submit">
												<div class="svg_wrapper">
													<svg xmlns="http://www.w3.org/2000/svg" width="15.897" height="15.848" viewBox="0 0 15.897 15.848">
														<g id="Group_129" data-name="Group 129"
															transform="translate(0.011 -0.123)">
															<g id="Ellipse_2" data-name="Ellipse 2"
																transform="translate(-0.011 0.123)" fill="none"
																stroke="#fff" stroke-width="2">
																<circle cx="7" cy="7" r="7" stroke="none" />
																<circle cx="7" cy="7" r="6" fill="none" />
															</g>
															<path id="Path_17" data-name="Path 17"
																d="M2391.322,80.422l-4.342-4.3"
																transform="translate(-2376.14 -65.16)" fill="none"
																stroke="#fff" stroke-width="2" />
														</g>
													</svg>
												</div>
												<!-- end svg_wrapper -->
											</button>
										</form>
									</div>
									<!-- search-form -->
								</div>
							</div>
							<!-- end heading_tab_wrap -->

							<ul *ngIf="!loading" class="accr-section">
								<li class="collapsable-section closed" *ngFor="let row of blackbook_all_entries | BlackBookFilter: filter ; let i = index">
									<table class="table blackbook">
										<tbody>
											<tr>
												<td>
													<div class="small_img_wrap">
														<img class="img-fluid jockey-img" *ngIf="row?.type == 'horse'" src="{{ row?.silks }}" (error)="onImgError($event)" alt="Horse" />
														<img class="img-fluid jockey-img" *ngIf="row?.type == 'jockey'" src="assets/images/blackbook/jockey-blackbook-2.svg" alt="Jockey">
														<img class="img-fluid jockey-img" *ngIf="row?.type == 'trainer'" src="assets/images/blackbook/trainer-blackbook-2.svg" alt="Trainer">
													</div>
													<div class="name_wrap">
														<h3 class="player_name">{{ row.name }}</h3>
													</div>
													<span class="toggleBtn d-md-none" (click)="toggleAccordion($event)"></span>
												</td>
												<td class="td_left text-md-right">
													<a class="btn black large" (click)="removeBlackBookConfirm(row)">Remove from blackbook</a>
													<span class="btn large bubble_chat" [ngClass]="row.notes.length > 0 ? 'count_bg_grey' : 'count_bg_white bubble_white_bg'" *ngIf="row.notes">{{ row.notes.length }}</span>
													<span class="toggleBtn d-none d-md-inline-block" (click)="toggleAccordion($event)"></span>
												</td>
											</tr>
										</tbody>
									</table>
									<!-- end table -->

									<div class="collapsed-content" *ngIf="row?.upcoming_race">
										<div class="inner" *ngFor="let race of row?.upcoming_race">
											<div class="info-table">
												<div class="t-row">
													<div class="t-col-1">
														<div class="inner_row">
															<div class="inner_col">
																<span class="t-head">{{ race.track_name }} {{ race.raceno }}</span>
															</div>
															<div class="inner_col">
																<span class="t-body">{{ race.distance }}m</span>
															</div>
															<div class="inner_col">
																<span class="t-body">{{ race.race_start_time | amFromUtc | amLocal | amDateFormat: 'hh:mma' }}</span>
															</div>
															<div class="inner_col">
																<span class="t-body">T: {{ race.trainer }}</span>
															</div>
															<div class="inner_col">
																<span class="t-body">J: {{ race.jockey }}</span>
															</div>
															<div class="inner_col">
																<span class="t-body">Win: ${{ race.win3_current }}</span>
															</div>
															<div class="inner_col">
																<span class="t-body">Place: ${{ race.plc3_current }}</span>
															</div>
														</div>
													</div>
													<div class="t-col-2 text-right">
														<span class="t-head">
															<a class="link small blue" routerLink="/form-guide/{{ race.slug | lowercase }}/{{ race.raceno }}">View form guide</a>
														</span>
													</div>
												</div>
												<!-- end t-row -->
											</div>
											<!-- end info-table -->
										</div>
										<!-- end inner -->
									</div>
									<!-- collapsed-content  -->

									<div class="collapsed-content">
										<div class="inner">
											<div class="add_comments bubble_chat white_bg_grey">
												<textarea placeholder="Add Comment..." [(ngModel)]="row.new_note"></textarea>
												<a class="btn large" (click)="saveBlackBookNote(row.type, row)">Add</a>
											</div>
											<!-- end add_comments -->

											<div class="edit-table row" *ngFor="let note of row?.notes">
												<div class="t-col-1 col-3 align-self-center pr-0">
													<div class="panel black text-center">
														{{ note.past_date | amFromUtc | amLocal | amDateFormat: 'DD MMM YY' }}
													</div>
												</div>
												<!-- end t-col-1 -->

												<div class="t-col-2 col-9 align-self-center edit_comments bubble_chat white_bg_grey" *ngIf="note?.editNote">
													<textarea [(ngModel)]="note.note"><b *ngIf="note.venue">{{note.venue}}: </b></textarea>
													<a class="btn large" (click)="editBlackBookNote(row, note)">Save</a>
												</div>
												<!-- end add_comments -->

												<div class="t-col-2 col-9 align-self-center" *ngIf="!note?.editNote">
													<div class="panel bubble_chat white_bg_grey">
														<span><b *ngIf="note.venue">{{note.venue}}: </b>{{ note.note }}</span>
														<a class="btn large" (click)="editNote(row, note)">Edit</a>
													</div>
													<!-- end panel -->
												</div>
												<!-- end t-col-2 -->
											</div>
											<!-- end edit-table -->
										</div>
										<!-- end inner -->
									</div>
									<!-- collapsed-content  -->
								</li>
								<!-- end collapsable-section -->
							</ul>
							<!-- end accr-section -->

							<!-- GHOST ELEMENT -->
							<ul *ngIf="loading" class="accr-section">
								<li class="collapsable-section closed loading-text" *ngFor="let number of [0, 1, 2, 3]"></li>
								<!-- end collapsable-section -->
							</ul>
							<!-- end accr-section -->
						</div>
						<!-- end tab-pane -->

						<!-- UPCOMING TAB -->
						<div class="tab-pane fade show" id="upcoming" role="tabpanel" aria-labelledby="upcoming">
							<div class="row heading_tab_wrap">
								<div class="col-12">
									<div class="radio_wrap switch_2 grey">
										<input type="radio" checked name="day" id="today" class="input-hidden" />
										<label for="today" (click)="filterBlackbookbyDay('today')">
											<span class="btn">Today</span>
										</label>

										<input type="radio" name="day" id="tomorrow" class="input-hidden" />
										<label for="tomorrow" class="m-0" (click)="filterBlackbookbyDay('tomorrow')">
											<span class="btn">Tomorrow</span>
										</label>

										<div class="slider"></div>
									</div>
									<!-- end radio_wrap -->

									<!-- GHOST ELEMENT -->
									<ul *ngIf="loading" class="panel grey loading-text"></ul>
								</div>
							</div>

							<ul class="accr-section" *ngIf="!loading && dayfilter == 'today'">
								<li class="collapsable-section closed" *ngFor="let row of today?.blackbook_entries; let i = index">
									<table class="table blackbook">
										<tbody>
											<tr>
												<td>
													<div class="small_img_wrap image_big">
														<img class="img-fluid jockey-img" *ngIf="row?.type == 'horse'" src="{{ row?.silks }}" (error)="onImgError($event)" alt="Horse" />
														<img class="img-fluid jockey-img" *ngIf="row?.type == 'jockey'" src="assets/images/blackbook/jockey-blackbook-2.svg" alt="Jockey">
														<img class="img-fluid jockey-img" *ngIf="row?.type == 'trainer'" src="assets/images/blackbook/trainer-blackbook-2.svg" alt="Trainer">
													</div>

													<div class="name_wrap">
														<h3 class="player_name">{{ row.name }}</h3>
													</div>
													<span class="toggleBtn d-md-none" (click)="toggleAccordion($event)"></span>
												</td>
												<td class="td_left text-md-right">
													<a class="btn black large" (click)="removeBlackBookConfirm(row)">Remove from blackbook</a>
													<span class="btn bubble_chat large count_bg_grey" *ngIf="row.notes && row.notes.length > 0">{{ row.notes.length }}</span>
													<span class="toggleBtn d-none d-md-inline-block" (click)="toggleAccordion($event)"></span>
												</td>
											</tr>
										</tbody>
									</table>
									<!-- end table -->
									<div class="race_details_content">
										<div class="" *ngIf="row?.upcoming_race">
											<div class="inner" *ngFor="let race of row?.upcoming_race">
												<div class="info-table">
													<div class="t-row">
														<div class="t-col-1">
															<div class="inner_row">
																<div class="inner_col">
																	<span class="t-head">{{ race.track_name }} {{ race.raceno }}</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">{{ race.distance }}m</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">{{ race.race_start_time | amFromUtc | amLocal | amDateFormat: 'hh:mma' }}</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">T: {{ race.trainer }}</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">J: {{ race.jockey }}</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">Win: ${{ race.win3_current }}</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">Place: ${{ race.plc3_current }}</span>
																</div>
															</div>
														</div>
														<div class="t-col-2 text-right">
															<span class="t-head">
																<a class="link small blue" routerLink="/form-guide/{{ race.slug | lowercase }}//{{ race.raceno }}">View form guide</a>
															</span>
														</div>
													</div>
													<!-- end t-row -->
												</div>
												<!-- end info-table -->
											</div>
											<!-- end inner -->
										</div>
									</div>

									<div class="collapsed-content">
										<div class="inner">
											<div class="add_comments bubble_chat white_bg_grey">
												<textarea placeholder="Add Comment..." [(ngModel)]="row.new_note"></textarea>
												<a class="btn large" (click)="saveBlackBookNote(row.type, row)">Add</a>
											</div>
											<!-- end add_comments -->

											<div class="edit-table row" *ngFor="let note of row?.notes">
												<div class="t-col-1 col-3 align-self-center pr-0">
													<div class="panel grey text-center">
														{{ note.past_date | amFromUtc | amLocal | amDateFormat: 'DD MMM YY' }}
													</div>
												</div>
												<!-- end t-col-1 -->

												<div class="t-col-2 col-9 align-self-center edit_comments bubble_chat white_bg_grey" *ngIf="note?.editNote">
													<textarea [(ngModel)]="note.note"><b *ngIf="note.venue">{{note.venue}}: </b></textarea>
													<a class="btn large" (click)="editBlackBookNote(row, note)">Save</a>
												</div>
												<!-- end add_comments -->

												<div class="t-col-2 col-9 align-self-center" *ngIf="!note?.editNote">
													<div class="panel bubble_chat white_bg_grey">
														<span><b *ngIf="note.venue">{{note.venue}}: </b>{{ note.note }}</span>
														<a class="btn large" (click)="todayeditNote(row, note)">Edit</a>
													</div>
													<!-- end panel -->
												</div>
												<!-- end t-col-2 -->
											</div>
											<!-- end edit-table -->
										</div>
										<!-- end inner -->
									</div>
									<!-- collapsed-content  -->
								</li>
								<!-- end collapsable-section -->
							</ul>
							<!-- end accr-section -->

							<ul class="accr-section" *ngIf="!loading && dayfilter == 'tomorrow'">
								<li class="collapsable-section closed" *ngFor="let row of tomorrow?.blackbook_entries; let i = index">
									<table class="table blackbook">
										<tbody>
											<tr>
												<td>
													<div class="small_img_wrap">
														<img class="img-fluid jockey-img" *ngIf="row?.type == 'horse'" src="{{ row?.silks }}" (error)="onImgError($event)" alt="Horse" />
														<img class="img-fluid jockey-img" *ngIf="row?.type == 'jockey'" src="assets/images/blackbook/jockey-blackbook-2.svg" alt="Jockey">
														<img class="img-fluid jockey-img" *ngIf="row?.type == 'trainer'" src="assets/images/blackbook/trainer-blackbook-2.svg" alt="Trainer">
													</div>
													<div class="name_wrap">
														<h3 class="player_name">{{ row.name }}</h3>
													</div>
													<span class="toggleBtn d-md-none" (click)="toggleAccordion($event)"></span>
												</td>
												<td class="td_left text-md-right">
													<a class="btn black large" (click)="removeBlackBookConfirm(row)">Remove from blackbook</a>
													<span class="btn large bubble_chat count_bg_grey" *ngIf="row.notes && row.notes.length > 0">{{ row.notes.length }}</span>
													<span class="toggleBtn d-none d-md-inline-block" (click)="toggleAccordion($event)"></span>
												</td>
											</tr>
										</tbody>
									</table>
									<!-- end table -->

									<div class="race_details_content">
										<div class="" *ngIf="row?.upcoming_race">
											<div class="inner" *ngFor="let race of row?.upcoming_race">
												<div class="info-table">
													<div class="t-row">
														<div class="t-col-1">
															<div class="inner_row">
																<div class="inner_col">
																	<span class="t-head">{{ race.track_name }} {{ race.raceno }}</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">{{ race.distance }}m</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">{{ race.race_start_time | amFromUtc | amLocal | amDateFormat: 'hh:mma' }}</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">T: {{ race.trainer }}</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">J: {{ race.jockey }}</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">Win: ${{ race.win3_current }}</span>
																</div>
																<div class="inner_col">
																	<span class="t-body">Place: ${{ race.plc3_current }}</span>
																</div>
															</div>
														</div>
														<div class="t-col-2 text-right">
															<span class="t-head">
																<a class="link small blue" routerLink="/form-guide/{{ race.slug | lowercase }}//{{ race.raceno }}">View form guide</a>
															</span>
														</div>
													</div>
													<!-- end t-row -->
												</div>
												<!-- end info-table -->
											</div>
											<!-- end inner -->
										</div>
									</div>
									<!-- collapsed-content  -->

									<div class="collapsed-content">
										<div class="inner">
											<div class="add_comments bubble_chat white_bg_grey">
												<textarea placeholder="Add Comment..." [(ngModel)]="row.new_note"></textarea>
												<a class="btn large" (click)="saveBlackBookNote(row.type, row)">Add</a>
											</div>
											<!-- end add_comments -->

											<div class="edit-table row" *ngFor="let note of row?.notes">
												<div class="t-col-1 col-3 align-self-center pr-0">
													<div class="panel black text-center">
														{{ note.past_date | amFromUtc | amLocal | amDateFormat: 'DD MMM YY' }}
													</div>
												</div>
												<!-- end t-col-1 -->

												<div class="t-col-2 col-9 align-self-center edit_comments bubble_chat white_bg_grey" *ngIf="note?.editNote">
													<textarea [(ngModel)]="note.note"><b *ngIf="note.venue">{{note.venue}}: </b></textarea>
													<a class="btn large" (click)="editBlackBookNote(row, note)">Save</a>
												</div>
												<!-- end add_comments -->

												<div class="t-col-2 col-9 align-self-center" *ngIf="!note?.editNote">
													<div class="panel bubble_chat white_bg_grey">
														<span><b *ngIf="note.venue">{{note.venue}}: </b>{{ note.note }}</span>
														<a class="btn large" (click)="tomorroweditNote(row, note)">Edit</a>
													</div>
													<!-- end panel -->
												</div>
												<!-- end t-col-2 -->
											</div>
											<!-- end edit-table -->
										</div>
										<!-- end inner -->
									</div>
									<!-- collapsed-content  -->
								</li>
								<!-- end collapsable-section -->
							</ul>
							<!-- end accr-section -->

							<!-- GHOST ELEMENT -->
							<ul *ngIf="loading" class="accr-section">
								<li class="collapsable-section closed loading-text" *ngFor="let number of [0, 1, 2, 3]"></li>
								<!-- end collapsable-section -->
							</ul>
							<!-- end accr-section -->
						</div>
						<!-- end tab-pane -->

					</div>
					<!-- end tab-content -->
				</div>
				<!-- end blackbook_wrapper -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->
		</div>
		<!-- row -->
	</div>
	<!-- container -->
</div>
<!-- section_wrapper -->