<div class="inner" *ngIf="row.horse_details && !row.loading_horse">
	<div class="d-lg-block d-none" *ngIf="screenWidth > 991">
	<div class="top-table row">
		<div class="t-col-1 col-lg-3">
			<div class="panel d-flex align-items-center h-100">
				<div class="inner">
					<span class="price">{{ row?.win3_current | currency: 'USD' }}</span>
					<span><strong>Fluctuations</strong></span>
					<span class="small"
						>{{ row?.win3_opn | currency: 'USD' }}, {{ row?.win3_9am | currency: 'USD' }},
						{{ row?.win3_current | currency: 'USD' }}</span
					>
					<div
						class="t-link"
						[ngClass]="{ up: row.win3_movement === 'UP', down: row.win3_movement == 'DOWN' }"
					></div>
				</div>
			</div>
		</div>
		<!-- end t-col-1 -->
		<div class="t-col-2 col-lg-9">
			<div class="panel h-100">
				<div class="row d-flex align-items-center h-100">
					<div class="col-3 col_rating">
						<div *ngIf="user && user.hasPreviews" class="trc_rating">
							<span class="rating active" *ngIf="row.last_race_trc_rating">{{
								row.last_race_trc_rating
							}}</span>
							<span class="rating active" *ngIf="!row.last_race_trc_rating">NYR</span>
							<span><strong>TRC Rating</strong></span>
						</div>

						<div
							*ngIf="!user || !user.hasPreviews"
							class="trc_rating locked"
							(click)="showUpgradeModal('previews')"
						>
							<span class="lock-icon"><img src="assets/images/icons/lock.svg" alt="locked" /></span>
							<span class="trc_rating">TRC Rating</span>
						</div>
					</div>
					<!-- end col-3 -->

					<div class="col-3 col_1">
						<span><strong>Sire: </strong>{{ row?.horse_details.sire }}</span>
						<span><strong>Dam: </strong>{{ row?.horse_details.dam }}</span>

						<!--<span *ngIf="row?.owners"><strong>Owners: </strong>{{row?.owners}}</span>-->
					</div>
					<div class="col-3 col_2">
						<span><strong>Earnings: </strong>{{ row.PrizeTotal | currency: 'USD':'symbol':'1.0-0' }}</span>
						<span *ngIf="row.horse_details.distancewonat"><strong>Distances: </strong>{{ row.horse_details.distancewonat }}</span>
						<span *ngIf="!row.horse_details.distancewonat"><strong>Distances: </strong>-</span>

						<!--<span><strong>BM Rating: </strong>-</span>-->
					</div>
					<div class="col-3 text-right">
						<a
							*ngIf="bb_count == 0 || bb_count == 'undefined' || !bb_count"
							(click)="OpenBlackbook(row)"
							class="btn large"
							>Add to Blackbook</a
						>
						<a *ngIf="bb_count >= 1" (click)="OpenBlackbook(row)" class="btn large">Edit Blackbook</a>
					</div>
				</div>
				<!-- end row -->
			</div>
			<!-- end panel -->
		</div>
		<!-- end t-col-2 -->
	</div>
	<!-- end top-table -->

	<!-- HIDE ENTIRE LIVE YARD TABLE IF NO data in liveyard_details *ngIf="row?.liveyard_details" -->
	<div class="live-yard-table row" *ngIf="row?.liveyard_details && user && user?.hasLiveYard && row?.liveyard_details.comment">
		<div class="t-col-1 col-lg-3">
			<div class="panel">
				<div class="inner">
					<div class="row">
						<div class="col-4 col-circle">
							<circle-progress
								class="smooth"
								[outerStrokeColor]="percentToColor(row.liveyard_details.lastcomment_score, 100, 0)"
								[percent]="row.liveyard_details.lastcomment_score"
								[subtitleFormat]="formatSubtitle"
							></circle-progress>
						</div>
						<div class="col-8 col-circle-info align-self-center">
							<p>
								<strong
									>Previous Score
									<div
										class="info_circle"
										(click)="openInstructions()"
									>
										<i class="info_icon">i</i>
									</div>
									<!-- end info_circle --></strong
								>
							</p>
							<!--<p class="score" *ngIf="!row?.liveyard_details || !row.liveyard_details.healthrating">7.5</p>-->
						</div>
					</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end panel -->
		</div>
		<!-- end t-col-1 -->

		<div class="t-col-1 col-lg-3">
			<div class="panel">
				<div class="inner">
					<div class="row">
						<div class="col-4 col-circle">
							<circle-progress
								class="smooth"
								[outerStrokeColor]="percentToColor(row.liveyard_details.final_score, 100, 0)"
								[percent]="row.liveyard_details.final_score"
								[subtitleFormat]="formatSubtitle"
							></circle-progress>
						</div>
						<div class="col-8 col-circle-info align-self-center">
							<p>
								<strong
									>Parade Rating
									<div
										class="info_circle"
										(click)="openInstructions()"
									>
										<i class="info_icon">i</i>
									</div>
									<!-- end info_circle --></strong
								>
							</p>
							<!--<p class="score" *ngIf="!row?.liveyard_details || !row.liveyard_details.yardrating">3.0</p>-->
						</div>
					</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end panel -->
		</div>
		<!-- end t-col-1 -->

		<div class="t-col-2 col-lg-5" *ngIf="row?.liveyard_details && row.liveyard_details.comment && row.liveyard_details.comment != ' '">
			<div class="panel grey bubble_chat">
				<div class="inner">
					<div class="body">
						<strong>Yard Comment:</strong>
						<p *ngIf="row?.liveyard_details && row.liveyard_details.comment && row.liveyard_details.comment != ' ' ">
							{{   row.liveyard_details.comment.split(',').join(', ')}}
							<strong *ngIf="row?.liveyard_details.comment && row.liveyard_details.analyst && row.liveyard_details.analyst !='-'">- {{ row.liveyard_details.analyst }}</strong>
						</p>
					</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end panel -->
		</div>
		<!-- end t-col-2 -->

		<!--<div class="t-col-3 col-lg-1" *ngIf="row?.liveyard_details && row?.liveyard_details.analyst">-->
		<div class="t-col-3 col-lg-1" *ngIf="row?.liveyard_details && row?.liveyard_details.analyst && row.liveyard_details.comment && row.liveyard_details.comment != ' '">
			<div class="inner profile">
				<div class="inner">
					<div class="analyst-img" [ngStyle]="{'background-image': 'url(' + row?.liveyard_details.analyst_avatar + ')'}"></div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end inner -->
		</div>
		<!-- end t-col-2 -->

		<!-- HIDE ENTIRE LIVE YARD TABLE IF NO data in liveyard_details *ngIf="row?.liveyard_details" -->
		<div class="t-col-4 col-lg-6 comments with-avatar pending_analysis" *ngIf="row?.liveyard_details && row?.liveyard_details.comment && row?.liveyard_details.comment == ' '">
			<div class="row h-100">
				<div class="col-4 align-self-center">

					<img src="assets/images/placeholders/liveyard-mini.svg" class="pending_placeholder" />

				</div><!-- end col-4 align-self-center -->

				<div class="col-8 align-self-center col_message">

					<p>Yard analysis status: <b>Pending</b><span>(ETA: 2-6 minutes pre jump)</span></p>

				</div><!-- end col-8 align-self-center -->
			</div>
		</div>
	</div>
	<!-- end live-yard-table -->

	<!-- HIDE ENTIRE LIVE YARD TABLE IF NO data in liveyard_details *ngIf="row?.liveyard_details" -->
	<div class="top-table row" *ngIf="(!user || !user.hasLiveYard) && row?.liveyard_details && row?.liveyard_details.comment">
		<div class="t-col-1 col-lg-3">
			<div class="panel h-100">
				<div class="row d-flex align-items-center h-100">
					<div class="col-12 locked_liveyard">
						<div class="circle-fake"></div>
						<div class="inner">
							<strong>Live Yard </strong><img class="lock_icon" src="assets/images/icons/lock-black.png" />
							<div class="rectangle">
								<span class="empty-placeholder"></span>
								<span class="empty-placeholder"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end t-col-1 -->
		<div class="t-col-2 col-lg-9">
			<div class="row d-flex align-items-center h-100 no-gutters">
				<div class="col-8 panel grey bubble_chat locked_liveyard_comment">
					<div class="inner">
						<div class="body">
							<div><strong>Live Yard Comment:</strong><img class="lock_icon" src="assets/images/icons/lock-black.png" /><span class="empty-placeholder"></span></div>
							<span class="empty-placeholder"></span>
						</div>
					</div>
					<!-- end inner -->
				</div>
				<div class="col-4 text-right locked_liveyard_button">
					<a class="btn large blue" (click)="showUpgradeModal('liveyard')">Unlock Premium Content</a>
				</div>
			</div>
			<!-- end row -->
		</div>
		<!-- end t-col-2 -->
	</div>
	<!-- end top-table -->

	<div class="info-table">
		<div class="t-row">
			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_all"
					>{{ row.horse_details.st_all }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_all">-</span>
				<span class="t-body">Career</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.formLast10"
					>{{ row.horse_details.formLast10 }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.formLast10">-</span>
				<span class="t-body">Last 10</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.WINSTRK"
					>{{ row.horse_details.WINSTRK | percent }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.WINSTRK">-</span>
				<span class="t-body">Win %</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.TOP3STRK"
					>{{ row.horse_details.TOP3STRK | percent }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.TOP3STRK">-</span>
				<span class="t-body">Top 3 %</span>
			</div>
			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_good"
					>{{ row.horse_details.st_good }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_good">-</span>
				<span class="t-body good">Good</span>
			</div>
			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_soft"
					>{{ row.horse_details.st_soft }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_soft">-</span>
				<span class="t-body soft">Soft</span>
			</div>
			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_heavy"
					>{{ row.horse_details.st_heavy }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_heavy">-</span>
				<span class="t-body heavy">Heavy</span>
			</div>
			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_wet"
					>{{ row.horse_details.st_wet }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_wet">-</span>
				<span class="t-body">Wet</span>
			</div>
		</div>
		<!-- end t-row -->

		<div class="t-row">
			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_trk"
					>{{ row.horse_details.st_trk }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_trk">-</span>
				<span class="t-body">Track</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_dist"
					>{{ row.horse_details.st_dist }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_dist">-</span>
				<span class="t-body">Distance</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_distance_track"
					>{{ row.horse_details.st_distance_track }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_distance_track">-</span>
				<span class="t-body">Track/Dist</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.DSLS"
					>{{ row.horse_details.DSLS }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.DSLS">-</span>
				<span class="t-body">Last Start</span>
			</div>
			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_1stup"
					>{{ row.horse_details.st_1stup }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_1stup">-</span>
				<span class="t-body">1st Up</span>
			</div>
			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_2ndup"
					>{{ row.horse_details.st_2ndup }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_2ndup">-</span>
				<span class="t-body">2nd Up</span>
			</div>

			
			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_3rdup"
					>{{ row.horse_details.st_3rdup }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_3rdup">-</span>
				<span class="t-body">3rd Up</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_4thup"
					>{{ row.horse_details.st_4thup }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_4thup">-</span>
				<span class="t-body">4th Up</span>
			</div>
		</div>

		<div class="t-row">

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.TWL100"
					>{{ row.horse_details.TWL100 | percent }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.TWL100">-</span>
				<span class="t-body">T W L100</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.JWL25"
					>{{ row.horse_details.JWL25 | percent }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.JWL25">-</span>
				<span class="t-body">J W L25</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.TJL15"
					>{{ row.horse_details.TJL15 | percent }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.TJL15">-</span>
				<span class="t-body">T/J L15</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.st_jockey"
					>{{ row.horse_details.st_jockey }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.st_jockey">-</span>
				<span class="t-body">J/H</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.last_race_price"
					>{{ row.horse_details.last_race_price | currency: 'USD' }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.last_race_price">-</span>
				<span class="t-body">Last SP</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.map_position"
					>{{ row.horse_details.map_position }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.map_position">-</span>
				<span class="t-body">Expected PIR</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.gearchange"
					>{{ row.horse_details.gearchange }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.gearchange">-</span>
				<span class="t-body">Gear Changes</span>
			</div>

			<div class="t-col">
				<span class="t-head" *ngIf="row.horse_details.past_class"
					>{{ row.horse_details.past_class }}</span
				>
				<span class="t-head" *ngIf="!row.horse_details.past_class">-</span>
				<span class="t-body">Last Class</span>
			</div>

		</div>


	</div>
	<!-- end info-table -->

	<div class="result-tables row" *ngFor="let race_hist of row.race_history; let j = index">
		<h5 class="days">{{ race_hist?.diff_in_days }} days</h5>

		<div
			class="t-index col-1"
			[ngClass]="{
				green: race_hist.past_placing == 1,
				yellow: race_hist.past_placing == 2 || race_hist.past_placing == 3,
				grey: race_hist.past_placing > 3,
				Scratched: race_hist.horse_status == 'Scratched' || race_hist.horse_status == 'LateScratched'
			}"
		>
			<div class="inner">
				<div
					class="inner"
					*ngIf="race_hist.horse_status != 'Scratched' && race_hist.horse_status != 'LateScratched'"
				>
					<span
						><strong>{{ race_hist.past_placing }}</strong></span
					>
					<span>of {{ race_hist.past_starters }}</span>
				</div>
				<!-- end inner -->
				<div
					class="inner"
					*ngIf="race_hist.horse_status == 'Scratched' || race_hist.horse_status == 'LateScratched'"
				>
					<span><strong>S</strong></span>
				</div>
				<!-- end inner -->
			</div>
			<!-- end inner -->
		</div>
		<!-- end t-index -->

		<div
			class="t-conents"
			[ngClass]="{
				't-conents-1 col-8': race_hist.trc_review,
				't-conents-3 col-11': !user || user.hasPreviews || race_hist.trc_review
			}"
		>
			<div class="panel">
				<span class="pill light_blue" *ngIf="race_hist.past_class == 'Jump Out'">Jump Out</span>
				<div class="inner">
					<div class="body row">
						<div
							class="col_body accr-section"
							[ngClass]="{
								'col-lg-9': race_hist.commentfull || race_hist.yardrating || race_hist.health_rating,
								'col-lg-12':
									!race_hist.commentfull && !race_hist.yardrating && race_hist.health_rating
							}"
						>
							<div class="body">

								<div class="topline" [ngClass]="{'jumpout': race_hist.past_class == 'Jump Out'}">
								
								  <span><b>{{ race_hist.past_date }}</b> {{ race_hist.past_venue }} R{{ race_hist.past_raceno }} <span *ngIf="race_hist.past_trackcond" class="button {{ removeNumbers(race_hist.past_trackcond) | lowercase }} trackcond">{{ removeNumbers(race_hist.past_trackcond) | titlecase}} {{race_hist.past_trackrating}}</span></span>
									<span>{{race_hist.past_class}} @ {{race_hist.past_distance}}m. </span>
									<span *ngIf="race_hist.past_rail"><b>Rail: </b> {{race_hist.past_rail}}. </span>
									<span *ngIf="race_hist.COURSE"><b>Course: </b>{{race_hist.COURSE}} </span>
									<span *ngIf="race_hist.TRACK_VARIANCE"><b>Track Variance: </b>{{race_hist.TRACK_VARIANCE}} </span>
									<span *ngIf="race_hist.RACE_STRENGTH"><b>Race Strength: </b>{{race_hist.RACE_STRENGTH}} </span>
									<span *ngIf="race_hist.RACE_PACE"><b>Race Pace: </b>{{race_hist.RACE_PACE}} </span>
									<span *ngIf="race_hist.past_prize"><b>Prize: </b> ${{race_hist.past_prize}}. </span>
									<span *ngIf="race_hist.past_sp"><b>SP: </b> ${{race_hist.past_sp}}. </span>
									<span *ngIf="race_hist.past_jockey"><b>Jockey: </b>{{race_hist.past_jockey}}<span *ngIf="race_hist.past_weight"> ({{race_hist.past_weight}}KG)</span>. </span>
									<span *ngIf="race_hist.past_barrier"><b>Barrier: </b>{{race_hist.past_barrier}}. </span>
									<span *ngIf="race_hist.BREEDING"><b>Breeding: </b>{{race_hist.BREEDING}} </span>
									<span *ngIf="race_hist.COLOURS"><b>Colours: </b>{{race_hist.COLOURS}} </span>
									<span *ngIf="race_hist.past_margin"><b>Margin: </b>{{race_hist.past_margin}}L. </span>
									<span *ngIf="race_hist.past_comment"><b>Comment: </b>{{race_hist.past_comment}} </span>
									<!--<span *ngIf="race_hist.COMMENTJO"><b>Comment: </b>{{race_hist.COMMENTJO}}</span>-->

								</div><!-- end topline -->
								

									<div class="other_runners 1" *ngIf="race_hist.past_1stplcname">
									
										<div class="first" *ngIf="race_hist.past_1stplcname">
										
											<span><p>1<small>st</small></p> <b>{{race_hist.past_1stplcname}} </b></span>
											<span *ngIf="race_hist.past_1stplcjockey">{{race_hist.past_1stplcjockey}} <span *ngIf="race_hist.past_1stplcweight">({{race_hist.past_1stplcweight}}KG)</span>. </span>
											<span *ngIf="race_hist.past_1stplcbarrier">Barrier {{race_hist.past_1stplcbarrier}}. </span>
										
										</div><!-- end first -->

										<div class="second" *ngIf="race_hist.past_2ndplcname">
										
											<span><p>2<small>nd</small></p> <b>{{race_hist.past_2ndplcname}} </b></span>
											<span *ngIf="race_hist.past_2ndplcjockey">{{race_hist.past_2ndplcjockey}} <span *ngIf="race_hist.past_2ndplcweight">({{race_hist.past_2ndplcweight}}KG)</span>. </span>
											<span *ngIf="race_hist.past_2ndplcbarrier">Barrier {{race_hist.past_2ndplcbarrier}}. </span>
										
										</div><!-- end second -->

										<div class="third" *ngIf="race_hist.past_3rdplcname">
										
											<span><p>3<small>rd</small></p> <b>{{race_hist.past_3rdplcname}} </b></span>
											<span *ngIf="race_hist.past_3rdplcjockey">{{race_hist.past_3rdplcjockey}} <span *ngIf="race_hist.past_3rdplcweight">({{race_hist.past_3rdplcweight}}KG)</span>. </span>
											<span *ngIf="race_hist.past_3rdplcbarrier">Barrier {{race_hist.past_3rdplcbarrier}}. </span>
										
										</div><!-- end third -->
										
									</div><!-- end other_runners -->

									<div class="jumpout_links pl-3 pr-2 mt-3" *ngIf="race_hist.past_class == 'Jump Out' && (race_hist.RACELINK || race_hist.WEBLINK)">
									
										<div class="row">
											<div class="col-6 pr-2" *ngIf="race_hist.RACELINK">
												<div class="row">
													<a class="arrow_link small" (click)="openLink(race_hist.RACELINK)">
														Visit heat link
														<svg xmlns="http://www.w3.org/2000/svg" width="41.896" height="30" viewBox="0 0 41.896 30"><g data-name="Group 9062" transform="translate(-1588.104 -227)" class="Group_9062"><circle data-name="Ellipse 86" cx="15" cy="15" r="15" transform="translate(1600 227)" fill="#6cfcfd" class="Ellipse_86"></circle><g data-name="Group 540" transform="translate(1588.109 236.846)" class="Group_540"><line data-name="Line 1" x1="29.317" y1="0.145" transform="translate(0 5.478)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2" class="Line_1"></line><path data-name="Path 1" d="M24.331,17.232l5.355-5.623L24.331,5.986" transform="translate(-0.369 -5.986)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2" class="Path_1"></path></g></g></svg>
													</a>
												</div>
											</div>
											<div class="col-6 pl-2" *ngIf="race_hist.WEBLINK">
												<div class="row">
													<a class="arrow_link small" (click)="openLink(race_hist.WEBLINK)">
														Visit heat link
														<svg xmlns="http://www.w3.org/2000/svg" width="41.896" height="30" viewBox="0 0 41.896 30"><g data-name="Group 9062" transform="translate(-1588.104 -227)" class="Group_9062"><circle data-name="Ellipse 86" cx="15" cy="15" r="15" transform="translate(1600 227)" fill="#6cfcfd" class="Ellipse_86"></circle><g data-name="Group 540" transform="translate(1588.109 236.846)" class="Group_540"><line data-name="Line 1" x1="29.317" y1="0.145" transform="translate(0 5.478)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2" class="Line_1"></line><path data-name="Path 1" d="M24.331,17.232l5.355-5.623L24.331,5.986" transform="translate(-0.369 -5.986)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2" class="Path_1"></path></g></g></svg>
													</a>
												</div>
											</div>
										</div>
									
									</div><!-- end jumpout_details -->

							</div>
						</div>
						<div
							class="col-lg-3 live-yard-col"
							*ngIf="
								user &&
								user?.hasPremiumSubscription &&
								(race_hist.commentfull || race_hist.yardrating || race_hist.health_rating)
							"
						>
							<div class="inner">
								<div class="inner">
									<div class="live_yard_title">
										<span><strong>Live Yard</strong></span>
										<div
											class="info_circle"
											(click)="openInstructions()"
										>
											<span>i</span>
										</div>
										<!-- end info_circle -->
									</div>
									<span>
										<!-- <strong *ngIf="race_hist.yardrating">Analyst: </strong
										>{{ race_hist.yardrating }} | -->
										<strong *ngIf="race_hist.health_rating">Parade: </strong
										>{{ race_hist.health_rating }}</span
									>
									<div class="inner live_yard_summary">
										<span><strong>Yard Summary: </strong> </span>
										<span>{{race_hist.racenote}}.</span>
										<span class="py-2"><b>{{race_hist.horse_name}}</b>:
											{{race_hist.commentfull.split(',').join(', ')}}.</span>
									</div>
								</div>
							</div>
						</div>
						<!-- end live-yard-col -->

						<div
							class="col-lg-3 live-yard-col locked"
							*ngIf="
								(!user || !user?.hasPremiumSubscription) &&
								(race_hist.commentfull || race_hist.yardrating || race_hist.health_rating)
							"
						>
							<div class="inner">
								<div class="inner">
									<span (click)="showUpgradeModal('premium')"
										><strong>Live Yard</strong
										><img class="lock_icon" src="assets/images/icons/lock-black.png"
									/></span>
									<div class="rectangle">
										<span class="empty-placeholder"></span>
										<span class="empty-placeholder"></span>
									</div>
								</div>
							</div>
						</div>
						<!-- end live-yard-col -->
					</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end panel -->
		</div>
		<!-- end t-conents -->

		<div class="t-conents t-conents-2 col-3" *ngIf="user && user.hasPremiumSubscription && race_hist.trc_review">
			<div class="panel">
				<div class="inner">
					<div class="body"><strong>TRC Review: </strong>{{ race_hist.trc_review }}</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end panel -->
		</div>
		<!-- end t-conents -->
		<div class="t-conents t-conents-2 col-3" *ngIf="(!user || (user && !user.hasPremiumSubscription)) && race_hist.trc_review">
			<div class="panel">
				<div class="inner">
					<div class="body">
						<strong>TRC Review: </strong><img class="lock_icon" src="assets/images/icons/lock-black.png" />
						<span class="empty-placeholder placeholder-1"></span>
						<span class="empty-placeholder"></span>
					</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end panel -->
		</div>
		<!-- end t-conents -->

	</div>
	<!-- end result-tables -->
	</div>

	<!-- MOBILE LAYOUT -->
	<div class="mobile-layout d-lg-none d-block" *ngIf="screenWidth <= 991">
		<div>
			<div class="runner_details_wrapper">
				<div class="basic_details">
					<div class="row mb-3">
						<div class="col-6 pr-2">
							<div class="row">
								<div class="col-5 title pr-2">Trainer</div>
								<div class="col-7 data pl-0">{{ row?.trainer }}</div>
							</div>
						</div>
						<div class="col-6 pl-2">
							<div class="row">
								<div class="col-5 title pr-2">Jockey</div>
								<div class="col-7 data pl-0">
									{{ row?.jockey }} ({{row?.weight | number:'1.0-0' }}kg)
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-6 pr-2">
							<div class="row">
								<div class="col-5 title pr-2">Sire</div>
								<div class="col-7 data pl-0">{{ row?.sire }}</div>
							</div>
						</div>
						<div class="col-6 pl-2">
							<div class="row">
								<div class="col-5 title pr-2">Dam</div>
								<div class="col-7 data pl-0">{{ row?.dam }}</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-6 pr-2">
							<div class="row">
								<div class="col-5 title pr-2">Colour</div>
								<div class="col-7 data pl-0">{{ row?.colour }}</div>
							</div>
						</div>
						<div class="col-6 pl-2">
							<div class="row">
								<div class="col-5 title pr-2">Age/Sex</div>
								<div class="col-7 data pl-0">{{ row?.age }}yo {{ row?.sex }}</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end basic_details -->

				<div class="bookmaker_fluctuations_wrap row no-gutters" *ngIf="row?.win3_current">
					<div class="col-7 align-self-center fluctuations_col">
						<div class="t-link down"
							[ngClass]="{'up': row.win3_movement === 'UP','down': row.win3_movement == 'DOWN'}"></div>
						<div class="fluctuations">
							<div class="price">{{row?.win3_current | currency : 'USD'}}</div>
							<div class="the_fluctuations">
								<span class="prices_label">Flucs</span>
								<div class="prices">
									<span *ngIf="row?.win3_opn">{{row?.win3_opn | currency : 'USD'}}</span>
									<span *ngIf="row?.win3_9am">{{row?.win3_9am | currency : 'USD'}}</span>
									<span *ngIf="row?.win3_current">{{row?.win3_current | currency : 'USD'}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-5 align-self-center bookmaker_col">
						<img class="bookmaker-img img-fluid" src="assets/images/bookmakers/unibet-tile.png" alt="TRC" />
						<div class="bookmaker">
							<div><strong>Win:</strong> {{ row?.win3_current | currency : 'USD' }}</div>
							<div><strong>Place:</strong> {{ row?.plc3_current | currency : 'USD' }}</div>
						</div>
					</div>
				</div>

				<div class="comments with-avatar"
					*ngIf="user && user.hasLiveYard && row?.liveyard_details && row.liveyard_details.comment && row.liveyard_details.comment != ' '">
					<div class="inner">
						<span><strong>Yard Summary: </strong></span>
						<span
							*ngIf="row.liveyard_details.comment && row.liveyard_details.comment != ' '">{{row.liveyard_details.comment.split(',').join(',
							')}}. <strong class="analyst_name" *ngIf="row?.liveyard_details.comment && row.liveyard_details.analyst && row.liveyard_details.analyst !='-'">- {{ row.liveyard_details.analyst }}</strong></span>
					</div>
					<div class="avatar" *ngIf="row.liveyard_details.analyst_avatar"
						[ngStyle]="{'background-image': 'url(' + row.liveyard_details.analyst_avatar +')'}"></div>
					<div class="avatar" *ngIf="!row.liveyard_details.analyst_avatar"
						[ngStyle]="{'background-image': 'url(assets/images/icons/avatar.png)'}"></div>
				</div>
				<!-- end comments -->

				<div class="ratings row"
					*ngIf="user && user.hasLiveYard && row?.liveyard_details && row.liveyard_details.lastcomment_score && row.liveyard_details.lastcomment_score != ' '">
					<div class="col-6 align-self-center">
						<circle-progress class="smooth"
							[outerStrokeColor]="percentToColor(row.liveyard_details.lastcomment_score, 100, 0)"
							[percent]="row.liveyard_details.lastcomment_score" [subtitleFormat]="formatSubtitle">
						</circle-progress>
						<span class="text">Previous Score
							<!--<span class="info" placement="top" (click)="gettoolTip('Analyst Score')">i</span>-->
							<div
								class="info_circle"
								(click)="openInstructions()"
							>
								<span>i</span>
							</div>
						</span>
					</div>
					<div class="col-6 align-self-center pl-0">
						<circle-progress class="smooth"
							[outerStrokeColor]="percentToColor(row.liveyard_details.final_score, 100, 0)"
							[percent]="row.liveyard_details.final_score" [subtitleFormat]="formatSubtitle">
						</circle-progress>
						<span class="text">Parade Rating
							<!--<span class="info" placement="top" (click)="gettoolTip('Parade Rating')">i</span>-->
							<div
								class="info_circle"
								(click)="openInstructions()"
							>
								<span>i</span>
							</div>
						</span>
					</div>
				</div>

				<div class="comments with-avatar pending_analysis"
					*ngIf="user && user.hasLiveYard && row?.liveyard_details && row.liveyard_details.comment && row.liveyard_details.comment == ' '">
					<div class="row">

						<div class="col-4">

							<img src="assets/images/placeholders/liveyard-mini.svg" class="pending_placeholder" />

						</div><!-- end col-4 -->

						<div class="col-8 col_message">

							<p>Yard analysis status: <b>Pending</b><span>(ETA: 2-6 minutes pre jump)</span></p>

						</div><!-- end col-8 -->

					</div><!-- end row -->

				</div>
				<!-- end ratings -->

				<div class="upgrades">
					<div class="upgrade_panel liveyard TEST2"
						*ngIf="(!user || (user && !user.hasLiveYard)) && row.liveyard_details">
						<div class="row align-items-center">
							<div class="col-4 col_placeholder">
								<img src="assets/images/placeholders/liveyard-mini.svg" />
							</div>
							<!-- end col-6 col_placeholder -->
							<div class="col-4 col_title">
								<h4>Live Yard Analysis</h4>
							</div>
							<!-- end col-4 col_title -->
							<div class="col-4 col_cta">
								<span (click)="showUpgradeModal('liveyard')" class="btn black_fill unlock">Unlock</span>
							</div>
							<!-- end col-4 col_cta -->
						</div>
						<!-- end row -->
					</div>
					<!-- end upgrade_panel liveyard -->

					<div class="upgrade_panel previews" *ngIf="(!user || (user && !user.hasPreviews)) && row.trc_preview">
						<div class="row align-items-center">
							<div class="col-4 col_placeholder">
								<img src="assets/images/placeholders/previews-mini.svg" />
							</div>
							<!-- end col-6 col_placeholder -->
							<div class="col-4 col_title">
								<h4>TRC Preview</h4>
							</div>
							<!-- end col-4 col_title -->
							<div class="col-4 col_cta">
								<span (click)="showUpgradeModal('previews')" class="btn black_fill unlock">Unlock</span>
							</div>
							<!-- end col-4 col_cta -->
						</div>
						<!-- end row -->
					</div>
					<!-- end upgrade_panel previews -->
				</div>
				<!-- end upgrades -->

				<!-- <div (click)="close('preview')" class="previews_link" *ngIf="user && user.hasPreviews && row.trc_preview"> -->
				<div class="previews_link" *ngIf="user && user.hasPreviews && row.trc_preview">
					<span>Race Preview</span>
				</div>
				<!-- end previews_link -->

				<div class="info-table">
					<div class="t-row">
						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_all"
								>{{ row.horse_details.st_all }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_all">-</span>
							<span class="t-body">Career</span>
						</div>

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.formLast10"
								>{{ row.horse_details.formLast10 }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.formLast10">-</span>
							<span class="t-body">Last 10</span>
						</div>

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.WINSTRK"
								>{{ row.horse_details.WINSTRK | percent }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.WINSTRK">-</span>
							<span class="t-body">Win %</span>
						</div>

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.TOP3STRK"
								>{{ row.horse_details.TOP3STRK | percent }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.TOP3STRK">-</span>
							<span class="t-body">Top 3 %</span>
						</div>

					</div>
					<!-- end t-row -->

					<div class="t-row">
						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_good"
								>{{ row.horse_details.st_good }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_good">-</span>
							<span class="t-body good">Good</span>
						</div>
						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_soft"
								>{{ row.horse_details.st_soft }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_soft">-</span>
							<span class="t-body soft">Soft</span>
						</div>
						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_heavy"
								>{{ row.horse_details.st_heavy }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_heavy">-</span>
							<span class="t-body heavy">Heavy</span>
						</div>
						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_wet"
								>{{ row.horse_details.st_wet }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_wet">-</span>
							<span class="t-body">Wet</span>
						</div>
					</div>
					<!-- end t-row -->

					<div class="t-row">


						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_trk"
								>{{ row.horse_details.st_trk }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_trk">-</span>
							<span class="t-body">Track</span>
						</div>

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_dist"
								>{{ row.horse_details.st_dist }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_dist">-</span>
							<span class="t-body">Distance</span>
						</div>

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_distance_track"
								>{{ row.horse_details.st_distance_track }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_distance_track">-</span>
							<span class="t-body">Track/Dist</span>
						</div>

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.DSLS"
								>{{ row.horse_details.DSLS }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.DSLS">-</span>
							<span class="t-body">Last Start</span>
						</div>

						
					</div>
					<!-- end t-row -->

					<div class="t-row">

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_1stup"
								>{{ row.horse_details.st_1stup }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_1stup">-</span>
							<span class="t-body">1st Up</span>
						</div>
						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_2ndup"
								>{{ row.horse_details.st_2ndup }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_2ndup">-</span>
							<span class="t-body">2nd Up</span>
						</div>

						
						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_3rdup"
								>{{ row.horse_details.st_3rdup }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_3rdup">-</span>
							<span class="t-body">3rd Up</span>
						</div>

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_4thup"
								>{{ row.horse_details.st_4thup }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_4thup">-</span>
							<span class="t-body">4th Up</span>
						</div>
						
						
					</div>
					<!-- end t-row -->

					<div class="t-row">

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.TWL100"
								>{{ row.horse_details.TWL100 | percent }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.TWL100">-</span>
							<span class="t-body">T W L100</span>
						</div>

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.JWL25"
								>{{ row.horse_details.JWL25 | percent }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.JWL25">-</span>
							<span class="t-body">J W L25</span>
						</div>

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.TJL15"
								>{{ row.horse_details.TJL15 | percent }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.TJL15">-</span>
							<span class="t-body">T/J L15</span>
						</div>

						<div class="t-col">
							<span class="t-head" *ngIf="row.horse_details.st_jockey"
								>{{ row.horse_details.st_jockey }}</span
							>
							<span class="t-head" *ngIf="!row.horse_details.st_jockey">-</span>
							<span class="t-body">J/H</span>
						</div>

					</div>
				</div>
				<!-- end info-table -->
			</div>
			<!-- end runner_details_wrapper -->
		</div>

		<div class="add_to_bb_wrapper">
		
		    <a
				*ngIf="bb_count == 0 || bb_count == 'undefined' || !bb_count"
				(click)="OpenBlackbook(row)"
				class="btn large"
				>Add to Blackbook</a
			>
			<a *ngIf="bb_count >= 1" (click)="OpenBlackbook(row)" class="btn large">Edit Blackbook</a>
		
		</div><!-- end add_to_bb_wrapper -->

		<div class="accr-section past_result_wrapper" *ngIf="row?.race_history.length > 0">
			<div class="past_result collapsable-section closed panel" *ngFor="let race_hist of row.race_history">
				<div class="diff_days text-center">{{race_hist?.diff_in_days}} days</div>
				<div class="past_result_info">
					<div class="head row no-gutters" (click)="toggleAccordionInner($event)">
						<span class="col align-self-center col-index">
							<div class="t-index"
								[ngClass]="{'green': race_hist?.past_placing == 1,'yellow': race_hist?.past_placing == 2 || race_hist.past_placing == 3,'grey': race_hist?.past_placing > 3,'Scratched': race_hist.horse_status == 'Scratched' || race_hist.horse_status == 'LateScratched'}">
								{{ race_hist.past_placing }} of {{ race_hist.past_starters }}
							</div>
						</span>
						<span class="col align-self-center col-venue"><strong
								*ngIf="race_hist.past_venue.length > 6">{{race_hist.past_venue.slice(0,5)}}. R{{
								race_hist.past_raceno }}</strong>
							<strong *ngIf="race_hist.past_venue.length <= 6">{{race_hist.past_venue}} R{{
								race_hist.past_raceno }}</strong> {{
							race_hist.past_distance }}m
							<!-- <span *ngIf="race_hist.past_class == 'Jump Out'" (click)="getJumpoutsModal(race_hist)" class="pill small light_blue"
									>Jump out</span
								> -->

							<span *ngIf="race_hist.past_class == 'Jump Out'" class="pill small light_blue">Jump out</span>

							<span *ngIf="race_hist.past_trackcond"
								class="{{ removeNumbers(race_hist.past_trackcond) | lowercase }} trackcond">{{
								removeNumbers(race_hist.past_trackcond) | titlecase}}
								{{race_hist.past_trackrating}}</span>

							<span *ngIf="race_hist.past_class && race_hist.past_class != 'Jump Out'"
								class="align-self-center col-past-class"> {{race_hist.past_class}}</span>
						</span>

						<!-- <span class="col align-self-center col-comment"
								><div class="comments count">
									<div class="inner" *ngIf="race_hist.yard_rating || race_hist.health_rating">2</div>
									<div class="inner" *ngIf="!race_hist.yard_rating && !race_hist.health_rating">1</div>
								</div></span
							> -->
						<span class="col align-self-center col-toggle"></span>
					</div>
					<div class="collapsed-content">
						<div class="body">
							<div class="topline" [ngClass]="{'jumpout': race_hist.past_class == 'Jump Out'}">
							
								<span><b>{{ race_hist.past_date }}</b> {{ race_hist.past_venue }} R{{ race_hist.past_raceno }} <span *ngIf="race_hist.past_trackcond" class="button {{ removeNumbers(race_hist.past_trackcond) | lowercase }} trackcond">{{ removeNumbers(race_hist.past_trackcond) | titlecase}} {{race_hist.past_trackrating}}</span></span>
								<span>{{race_hist.past_class}} @ {{race_hist.past_distance}}m. </span>
								
								<span *ngIf="race_hist.past_rail"><b>Rail: </b> {{race_hist.past_rail}}. </span>
								<span *ngIf="race_hist.COURSE"><b>Course: </b>{{race_hist.COURSE}}. </span>
								<span *ngIf="race_hist.TRACK_VARIANCE"><b>Track Variance: </b>{{race_hist.TRACK_VARIANCE}}. </span>
								<span *ngIf="race_hist.RACE_STRENGTH"><b>Race Strength: </b>{{race_hist.RACE_STRENGTH}}. </span>
								<span *ngIf="race_hist.RACE_PACE"><b>Race Pace: </b>{{race_hist.RACE_PACE}}. </span>
								<span *ngIf="race_hist.past_prize"><b>Prize: </b> ${{race_hist.past_prize}}. </span>
								<span *ngIf="race_hist.past_sp"><b>SP: </b> ${{race_hist.past_sp}}. </span>
								<span *ngIf="race_hist.PAST_FLUCS"><b>Flucs: </b>{{race_hist.PAST_FLUCS}}. </span>
								<span *ngIf="race_hist.past_jockey"><b>Jockey: </b>{{race_hist.past_jockey}}<span *ngIf="race_hist.past_weight"> ({{race_hist.past_weight}}KG)</span>. </span>
								<span *ngIf="race_hist.past_barrier"><b>Barrier: </b>{{race_hist.past_barrier}}. </span>
								
								<span *ngIf="race_hist.BREEDING"><b>Breeding: </b>{{race_hist.BREEDING}}. </span>
								<span *ngIf="race_hist.COLOURS"><b>Colours: </b>{{race_hist.COLOURS}}. </span>
								<span *ngIf="race_hist.past_winnertime"><b>Winner Time: </b>{{race_hist.past_winnertime}}. </span>
								<span *ngIf="race_hist.past_margin"><b>Margin: </b>{{race_hist.past_margin}}L. </span>
								<span *ngIf="race_hist.STEWARDSCOM"><b>Steward's Comment: </b>{{race_hist.STEWARDSCOM}}</span>
								<!--<span *ngIf="race_hist.COMMENTJO"><b>Comment: </b>{{race_hist.COMMENTJO}}</span>-->
							
							</div><!-- end topline -->
							

							<div class="other_runners 2" *ngIf="race_hist.past_1stplcname">

								<div class="first" *ngIf="race_hist.past_1stplcname">

									<span>
										<p>1<small>st</small></p> <b>{{race_hist.past_1stplcname}} </b>
									</span>
									<span *ngIf="race_hist.past_1stplcjockey">{{race_hist.past_1stplcjockey}} <span
											*ngIf="race_hist.past_1stplcweight">({{race_hist.past_1stplcweight}}KG)</span>.
									</span>
									<span *ngIf="race_hist.past_1stplcbarrier">Barrier {{race_hist.past_1stplcbarrier}}. </span>

								</div><!-- end first -->

								<div class="second" *ngIf="race_hist.past_2ndplcname">

									<span>
										<p>2<small>nd</small></p> <b>{{race_hist.past_2ndplcname}} </b>
									</span>
									<span *ngIf="race_hist.past_2ndplcjockey">{{race_hist.past_2ndplcjockey}} <span
											*ngIf="race_hist.past_2ndplcweight">({{race_hist.past_2ndplcweight}}KG)</span>.
									</span>
									<span *ngIf="race_hist.past_2ndplcbarrier">Barrier {{race_hist.past_2ndplcbarrier}}. </span>

								</div><!-- end second -->

								<div class="third" *ngIf="race_hist.past_3rdplcname">

									<span>
										<p>3<small>rd</small></p> <b>{{race_hist.past_3rdplcname}} </b>
									</span>
									<span *ngIf="race_hist.past_3rdplcjockey">{{race_hist.past_3rdplcjockey}} <span
											*ngIf="race_hist.past_3rdplcweight">({{race_hist.past_3rdplcweight}}KG)</span>.
									</span>
									<span *ngIf="race_hist.past_3rdplcbarrier">Barrier {{race_hist.past_3rdplcbarrier}}. </span>

								</div><!-- end third -->

							</div><!-- end other_runners -->

							<div class="jumpout_links pl-3 pr-2 mt-3" *ngIf="race_hist.past_class == 'Jump Out' && (race_hist.RACELINK || race_hist.WEBLINK)">
							
								<div class="row">
									<div class="col-6 pr-2" *ngIf="race_hist.RACELINK">
										
											<a class="arrow_link small" (click)="openLink(race_hist.RACELINK)">
												Visit heat link
												<svg xmlns="http://www.w3.org/2000/svg" width="41.896" height="30" viewBox="0 0 41.896 30"><g data-name="Group 9062" transform="translate(-1588.104 -227)" class="Group_9062"><circle data-name="Ellipse 86" cx="15" cy="15" r="15" transform="translate(1600 227)" fill="#6cfcfd" class="Ellipse_86"></circle><g data-name="Group 540" transform="translate(1588.109 236.846)" class="Group_540"><line data-name="Line 1" x1="29.317" y1="0.145" transform="translate(0 5.478)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2" class="Line_1"></line><path data-name="Path 1" d="M24.331,17.232l5.355-5.623L24.331,5.986" transform="translate(-0.369 -5.986)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2" class="Path_1"></path></g></g></svg>
											</a>
										
									</div>
									<div class="col-6 pl-2" *ngIf="race_hist.WEBLINK">
										
											<a class="arrow_link small" (click)="openLink(race_hist.WEBLINK)">
												Visit heat link
												<svg xmlns="http://www.w3.org/2000/svg" width="41.896" height="30" viewBox="0 0 41.896 30"><g data-name="Group 9062" transform="translate(-1588.104 -227)" class="Group_9062"><circle data-name="Ellipse 86" cx="15" cy="15" r="15" transform="translate(1600 227)" fill="#6cfcfd" class="Ellipse_86"></circle><g data-name="Group 540" transform="translate(1588.109 236.846)" class="Group_540"><line data-name="Line 1" x1="29.317" y1="0.145" transform="translate(0 5.478)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2" class="Line_1"></line><path data-name="Path 1" d="M24.331,17.232l5.355-5.623L24.331,5.986" transform="translate(-0.369 -5.986)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2" class="Path_1"></path></g></g></svg>
											</a>
										
									</div>
								</div>
							
							</div><!-- end jumpout_details -->

						</div>

						<div class="comments with-avatar-outside"
							*ngIf="user && user.hasPremiumSubscription && (race_hist.commentfull || race_hist.racenote)">
							<div class="inner">
								<span><strong>Yard Summary: </strong></span>
								<span>{{race_hist.racenote}}.</span>
								<span class="py-2"><b>{{race_hist.horse_name}}</b>:
									{{race_hist.commentfull.split(',').join(', ')}}.</span>
							</div>
							<!-- TODO AVATAR -->
							<div class="avatar" *ngIf="race_hist.analyst_avatar"
								[ngStyle]="{'background-image': 'url(' + race_hist.analyst_avatar +')'}"></div>
							<div class="avatar" *ngIf="!race_hist.analyst_avatar"
								[ngStyle]="{'background-image': 'url(assets/images/trc-app-icon.png)'}"></div>

						</div>
						<!-- end comments -->

						<div class="ratings row"
							*ngIf="user && user.hasPremiumSubscription && (race_hist.health_rating)">
							<div class="col-6 align-self-center">
								<circle-progress
									class="smooth"
									[outerStrokeColor]="percentToColor(race_hist.yardrating, 100, 0)"
									[percent]="race_hist.yardrating"
									[subtitleFormat]="formatSubtitle"
								></circle-progress>
								<span class="text">Previous Score
									<div
										class="info_circle"
										(click)="openInstructions()"
									>
										<span>i</span>
									</div>
								</span>
							</div>
							<div class="col-6 align-self-center pl-0">
								<circle-progress
									class="smooth"
									[outerStrokeColor]="percentToColor(race_hist.health_rating, 100, 0)"
									[percent]="race_hist.health_rating"
									[subtitleFormat]="formatSubtitle"
								></circle-progress>
								<span class="text">Parade Rating
									<div
										class="info_circle"
										(click)="openInstructions()"
									>
										<span>i</span>
									</div>
								</span>
							</div>
						</div>
						<!-- end ratings -->

						<div class="comments with-avatar-outside"
							*ngIf="user && user.hasPremiumSubscription && race_hist.trc_review">
							<div class="inner">
								<span><strong>Review:</strong></span>
								<span>{{race_hist.trc_review}}.</span>
							</div>
							<!-- TODO AVATAR -->
							<div class="avatar" *ngIf="race_hist.avatar_url"
								[ngStyle]="{'background-image': 'url(' + race_hist.avatar_url +')'}"></div>
							<div class="avatar" *ngIf="!race_hist.avatar_url"
								[ngStyle]="{'background-image': 'url(assets/images/trc-app-icon.png)'}"></div>

						</div>
						<!-- end comments -->

						<div class="upgrades">
							<div class="upgrade_panel liveyard TEST3"
								*ngIf="(!user || (user && !user.hasPremiumSubscription)) && (race_hist.yardrating || race_hist.health_rating)">
								<div class="row align-items-center">
									<div class="col-4 col_placeholder">
										<img src="assets/images/placeholders/liveyard-mini.svg" />
									</div>
									<!-- end col-6 col_placeholder -->
									<div class="col-4 col_title">
										<h4>Live Yard Analysis</h4>
									</div>
									<!-- end col-4 col_title -->
									<div class="col-4 col_cta">
										<span (click)="showUpgradeModal('premium')"
											class="btn black_fill unlock">Unlock</span>
									</div>
									<!-- end col-4 col_cta -->
								</div>
								<!-- end row -->
							</div>
							<!-- end upgrade_panel liveyard -->

							<div class="upgrade_panel reviews"
								*ngIf="(!user || (user && !user.hasPremiumSubscription)) && race_hist.trc_review">
								<div class="row align-items-center">
									<div class="col-4 col_placeholder">
										<img src="assets/images/placeholders/reviews-mini.svg" />
									</div>
									<!-- end col-6 col_placeholder -->
									<div class="col-4 col_title">
										<h4>TRC Review</h4>
									</div>
									<!-- end col-4 col_title -->
									<div class="col-4 col_cta">
										<span (click)="showUpgradeModal('premium')"
											class="btn black_fill unlock">Unlock</span>
									</div>
									<!-- end col-4 col_cta -->
								</div>
								<!-- end row -->
							</div>
							<!-- end upgrade_panel previews -->
						</div>
						<!-- end upgrades -->
					</div>
				</div>

			</div>
			<!-- end past_result -->
		</div>
	</div>

</div>
<!-- end inner -->

<ghost-element type="horse-details" *ngIf="row.loading_horse && screenWidth > 991" class="ghost_element"></ghost-element><!-- GHOST ELEMENT -->

<div class="inner" *ngIf="row.loading_horse && screenWidth <= 991">
	<div class="mobile-layout d-lg-none d-block">
		<div id="runnerCard" #runnerCard>
			<!-- GHOST ELEMENT -->
			<div class="runner_details_wrapper ghost_element_mobile">
				<div class="basic_details">
					<div class="row mb-3">
						<div class="col-6 pr-2">
							<div class="row">
								<div class="col-5 title pr-2">Trainer</div>
								<div class="col-7 data pl-0"><span class="loading-text"></span></div>
							</div>
						</div>
						<div class="col-6 pl-2">
							<div class="row">
								<div class="col-5 title pr-2">Jockey</div>
								<div class="col-7 data pl-0"><span class="loading-text"></span></div>
							</div>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-6 pr-2">
							<div class="row">
								<div class="col-5 title pr-2">Sire</div>
								<div class="col-7 data pl-0"><span class="loading-text"></span></div>
							</div>
						</div>
						<div class="col-6 pl-2">
							<div class="row">
								<div class="col-5 title pr-2">Dam</div>
								<div class="col-7 data pl-0"><span class="loading-text"></span></div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-6 pr-2">
							<div class="row">
								<div class="col-5 title pr-2">Colour</div>
								<div class="col-7 data pl-0"><span class="loading-text"></span></div>
							</div>
						</div>
						<div class="col-6 pl-2">
							<div class="row">
								<div class="col-5 title pr-2">Age/Sex</div>
								<div class="col-7 data pl-0"><span class="loading-text"></span></div>
							</div>
						</div>
					</div>
				</div>
				<!-- end basic_details -->

				<div class="bookmaker_fluctuations_wrap row no-gutters">
					<div class="col-6 align-self-center fluctuations_col" *ngFor="let number of [0,1]">
						<div class="t-link loading-text"></div>
						<div class="fluctuations">
							<div class="price loading-text"></div>
							<div class="prices loading-text small"></div>
						</div>
					</div>
				</div>

				<div class="info-table">
					<div class="t-row" *ngFor="let number of [0,1,2,3]">
						<div class="t-col" *ngFor="let number of [0,1,2,3]">
							<span class="t-head loading-text"></span>
							<span class="t-body loading-text small"></span>
						</div>
					</div>
					<!-- end t-row -->
				</div>
				<!-- end info-table -->
			</div>
			<!-- end runner_details_wrapper -->
		</div>
	</div>
</div>
