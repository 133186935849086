import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import * as moment from 'moment';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Component({
	selector: '[counter]',
	templateUrl: './count-down.component.html',
	styleUrls: ['./count-down.component.scss'],
})
export class CountDownComponent implements OnInit, OnDestroy {
	@Input() counter: any;
	@Input() race_status: any;
	@Input() race: any;

	@Output() TimerExpired: EventEmitter<any> = new EventEmitter<any>();
	@Output() TimerBelowFiveMinutes: EventEmitter<any> = new EventEmitter<any>();

	time_now = moment.utc();

	public subscription: Subscription;
	public timeDisplay: any;
	isBrowser: boolean = false;
	timebelowfiveMinutesEmitted: boolean = false;

	constructor(@Inject(PLATFORM_ID) platformId: string) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	ngOnInit() {
		this.init();
	}

	ngOnDestroy() {
		if (this.isBrowser && this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	init(){
		let local_time = moment.utc(this.counter).local();
		let seconds = local_time.diff(this.time_now, 'seconds');
		let duration = moment.duration(seconds, 'seconds');
		// console.log(this.counter);
		// console.log(this.timeDisplay);
		// console.log(this.race);


		if (seconds > 3600) {
			let h = Number(moment.duration(duration).asHours().toFixed(0));
			if (h > 1) {
				this.timeDisplay = `${h}hrs`;
			} else {
				this.timeDisplay = `${h}hr`;
			}
		} else if (seconds < -120 && this.race_status != 'FINAL') {
			this.timeDisplay = 'Pending';
			if (this.subscription) this.subscription.unsubscribe();
			this.TimerExpired.emit();
		} else if (this.race_status == 'FINAL') {
			this.timeDisplay = 'Completed';
		} else if (seconds < 3600) {
			if (this.isBrowser) {
				this.subscription = interval(1000).subscribe((x) => {
					duration = moment.duration(duration.asMilliseconds() - 1000, 'milliseconds');
					let h = moment.duration(duration).hours();
					let m = moment.duration(duration).minutes();
					let s = moment.duration(duration).seconds();
					if (h == 0 && m == 0) {
						this.timeDisplay = `${s}s`;
					} else if (m != 0 && h == 0) {
						this.timeDisplay = m == 1 || m == -1 ? `${m}min` : `${m}mins`;
					} else {
						this.timeDisplay = h == 1 || h == -1 ? `${h}hr` : `${h}hrs`;
					}
					if (m <= -2 && this.race_status != 'FINAL') {
						this.subscription.unsubscribe();
						this.timeDisplay = 'Pending';
						this.TimerExpired.emit();
					}

					if (m <= 5 && !this.timebelowfiveMinutesEmitted) {
						this.TimerBelowFiveMinutes.emit(this.race);
						this.timebelowfiveMinutesEmitted = true;
					}
				});
			}
		}
	}

	ngOnChanges(){
		// console.log('ngOnChanges');
		if(this.timeDisplay === 'Pending') this.init();

	}	
	

}
