<div class="section_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
				<div class="selected_plan_wrapper mb-4">
					<div class="panel grey">
						<div class="row">
							<div class="col_plan_info col-12">
								<div class="heading_wrap row">
									<div class="col-img col-4 align-self-center">
										<div class="inner">
											<img
												class="img-fluid jockey-img"
												src="assets/images/jockeys/jockey1.png"
												alt="TRC"
											/>
										</div>
									</div>
									<div class="col-heading col-8 align-self-center">
										<h4>{{ user?.subscription.plan_name }}</h4>
										<div class="date">
											<strong>{{ user?.subscription.pricing_plan.price }}</strong
											>/{{ user?.subscription.pricing_plan.duration }}
										</div>
										<div class="card_display">
											<small>Paid with {{ user?.card_display_number }}</small>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="col_btn col-12 align-self-center text-right">
                            <a class="btn large p-2 m-2" href="/change-plan">Change plan</a>
                            <a class="btn large red p-2 m-2">Cancel</a>
                        </div> -->
					</div>
				</div>
				<!-- end selected_plan_wrapper -->

				<div
					class="row features_wrapper slides"
					*ngIf="!feature_loading"
					[ngClass]="{ active: activeSlide == 'slide-1' }"
				>
					<h2 class="heading col-12 text-center mb-2">Change Subscription Plan</h2>
					<div class="col-12 body mb-5 text-center">
						<p>Choose the features so that we get right plans for you</p>
					</div>

					<div
						class="features_links_box py-2"
						[ngClass]="{
							'col-lg-4 col-md-12': features_count >= 3,
							'col-lg-6 col-md-6': features_count == 2
						}"
						*ngFor="let feature of features; let i = index"
					>
						<div class="panel grey p-2" (click)="selectFeature(i)" [ngClass]="{ active: feature?.checked }">
							<div
								class="icon tour"
								[ngStyle]="{ 'background-image': 'url(' + feature.image + ')' }"
							></div>
							<h3 class="title">{{ feature.title }}</h3>
							<p class="description p-2" [innerHTML]="feature.short_description | nl2br"></p>
							<div
								class="tick_wrap"
								[ngClass]="{ active: feature.checked, inactive: !feature.checked == true }"
							>
								<div class="tick">
									<span class="checkmark"></span>
								</div>
							</div>
						</div>
						<!-- end panel -->
					</div>
					<!-- end features_links_box -->
				</div>
				<!-- end features_wrapper -->

				<div
					class="row features_wrapper slides"
					*ngIf="feature_loading"
					[ngClass]="{ active: activeSlide == 'slide-1' }"
				>
					<h2 class="heading col-12 text-center mb-2">Get Started today</h2>
					<div class="col-12 body mb-5 text-center">
						<p>Choose the features so that we get right plans for you</p>
					</div>
					<div class="features_links_box py-2 col-lg-6 col-md-6">
						<div class="panel grey p-2 loading">
							<div class="icon tour loading-text"></div>
							<h3 class="title d-block m-auto loading-text"></h3>
							<p class="description loading-text mt-5"></p>
							<p class="description loading-text mt-2"></p>
						</div>
						<!-- end inner -->
					</div>
					<!-- end features_links_box -->
					<div class="features_links_box py-2 col-lg-6 col-md-6">
						<div class="panel grey p-2 loading">
							<div class="icon tour loading-text"></div>
							<h3 class="title d-block m-auto loading-text"></h3>
							<p class="description loading-text mt-5"></p>
							<p class="description loading-text mt-2"></p>
						</div>
						<!-- end inner -->
					</div>
					<!-- end features_links_box -->
				</div>
				<!-- end features_wrapper -->

				<div
					class="row plans_wrapper slides"
					*ngIf="!plans_loading"
					[ngClass]="{ active: activeSlide == 'slide-2' }"
				>
					<h2 class="heading col-12 text-center mb-2">Change Subscription Plan</h2>
					<div class="col-12 body mb-5 text-center">
						<p>Choose the right plan</p>
					</div>

					<div
						class="plans_links_box py-2"
						[ngClass]="{
							'col-lg-4': plans.length >= 3,
							'col-lg-6': plans.length == 2,
							'col-lg-12': plans.length == 1
						}"
						*ngFor="let plan of plans; let j = index"
					>
						<div class="panel grey">
							<div class="heading_wrap row mb-4">
								<div class="col-img col-12 mb-2">
									<div class="inner">
										<img class="img-fluid jockey-img" src="{{ plan.image }}" alt="TRC" />
									</div>
								</div>
								<div class="col-heading col-12">
									<h4>{{ plan.title }}</h4>
								</div>
							</div>

							<h5 class="mb-4 feature-heading">Features</h5>

							<ul class="features_list mb-3">
								<li *ngFor="let feature of features">
									<div class="tick_wrap">
										<div class="tick">
											<span class="checkmark"></span>
										</div>
									</div>
									<strong [innerHTML]="feature.title | nl2br"></strong>
								</li>
							</ul>
							<!-- end features_list -->

							<div class="btn_wrap text-center">
								<a
									class="btn large mb-2 mx-2"
									[ngClass]="{
										active:
											selected_plan && selected_plan.price && selected_plan?.price.id == price?.id
									}"
									(click)="selectPlan(plan, price)"
									*ngFor="let price of plan.prices; let k = index"
									>${{ price.price }} per {{ price.duration }}</a
								>
							</div>
						</div>
						<!-- end inner -->
					</div>
					<!-- end plans_links_box -->
				</div>
				<!-- end plans_wrapper -->

				<div
					class="row features_wrapper slides"
					*ngIf="plans_loading"
					[ngClass]="{ active: activeSlide == 'slide-2' }"
				>
					<h2 class="heading col-12 text-center mb-2">Change Subscription Plan</h2>
					<div class="col-12 body mb-5 text-center">
						<p>Choose the right plan</p>
					</div>
					<div class="features_links_box py-2 col-lg-6 col-md-6">
						<div class="panel grey p-2 loading">
							<div class="icon tour loading-text"></div>
							<h3 class="title d-block m-auto loading-text"></h3>
							<p class="description loading-text mt-5"></p>
							<p class="description loading-text mt-2"></p>
						</div>
						<!-- end inner -->
					</div>
					<!-- end features_links_box -->
					<div class="features_links_box py-2 col-lg-6 col-md-6">
						<div class="panel grey p-2 loading">
							<div class="icon tour loading-text"></div>
							<h3 class="title d-block m-auto loading-text"></h3>
							<p class="description loading-text mt-5"></p>
							<p class="description loading-text mt-2"></p>
						</div>
						<!-- end inner -->
					</div>
					<!-- end features_links_box -->
				</div>
				<!-- end plans_wrapper -->

				<div class="row checkout_wrapper slides slide-3" [ngClass]="{ active: activeSlide == 'slide-3' }">
					<h2 class="heading col-12 text-center mb-2">Change Subscription Plan</h2>

					<div class="col-12 body mb-5 text-center">
						<p>Subscribe to the plan</p>
					</div>

					<div class="checkout_box col-12">
						<div class="panel grey">
							<div class="row">
								<div class="col_plan_info col-12 align-self-center mb-2">
									<div class="heading_wrap row">
										<div class="col-img col-lg-2 col-md-2">
											<div class="inner">
												<img
													class="img-fluid rounded"
													src="{{ selected_plan?.image }}"
													alt="TRC"
												/>
											</div>
											<!-- end inner -->
										</div>

										<div class="col-heading col-lg-10">
											<h4>{{ selected_plan?.title }}</h4>
											<div class="date">
												<strong>${{ selected_plan?.price?.price }}</strong
												>/
												{{ selected_plan?.price?.duration }}
											</div>
										</div>
									</div>
									<!-- end heading_wrap -->
								</div>
								<!-- end col_plan_info -->

								<div class="col_btn col-12 align-self-center text-right">
									<a (click)="changeSlide('slide-2')" class="btn large">Change plan</a>
								</div>
								<!-- end col_btn -->
							</div>
							<!-- end row -->
						</div>
						<!-- end panel -->

						<div class="col_form panel grey mt-4">
							<form class="form_wrapper" [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
								<div class="form-row">
									<div class="form-group col-12">
										<input
											id="name"
											formControlName="name"
											maxlength="20"
											type="text"
											class="form-control"
											placeholder="Name"
										/>
										<div *ngIf="submitted && f.name.errors" class="text-danger">
											<div *ngIf="f.name.errors.required">Name is required</div>
											<div *ngIf="f.name.errors.pattern">Name is not valid</div>
										</div>
										<div
											*ngIf="submitted && pinpayment_response && pinpayment_response.error"
											class="text-danger"
										>
											<div *ngIf="pinpayment_errors.name">{{ pinpayment_errors.name }}</div>
										</div>
									</div>

									<div class="form-group col-12">
										<input
											id="number"
											type="text"
											formControlName="number"
											pattern="[0-9]*"
											class="form-control"
											placeholder="Card Number"
											inputmode="numeric"
										/>
										<div *ngIf="submitted && f.number.errors" class="text-danger">
											<div *ngIf="f.number.errors.required">Card Number is required</div>
											<div *ngIf="f.number.errors.pattern">Number is not valid</div>
										</div>
										<div
											*ngIf="submitted && pinpayment_response && pinpayment_response.error"
											class="text-danger"
										>
											<div *ngIf="pinpayment_errors.number">{{ pinpayment_errors.number }}</div>
										</div>
									</div>

									<div class="form-group col-md-6">
										<input
											id="expiry_month"
											type="text"
											formControlName="expiry_month"
											pattern="[0-9]*"
											class="form-control"
											placeholder="Expirty Month"
											inputmode="numeric"
										/>
										<div *ngIf="submitted && f.expiry_month.errors" class="text-danger">
											<div *ngIf="f.expiry_month.errors.required">Expirty Month is required</div>
											<div *ngIf="f.expiry_month.errors.pattern">Expirty Month is not valid</div>
										</div>
										<div
											*ngIf="submitted && pinpayment_response && pinpayment_response.error"
											class="form-control"
											class="text-danger"
										>
											<div *ngIf="pinpayment_errors.expiry_month">
												{{ pinpayment_errors.expiry_month }}
											</div>
										</div>
									</div>

									<div class="form-group col-md-6">
										<input
											id="expiry_year"
											type="text"
											formControlName="expiry_year"
											pattern="[0-9]*"
											class="form-control"
											placeholder="Expirty Year"
											inputmode="numeric"
										/>
										<div *ngIf="submitted && f.expiry_year.errors" class="text-danger">
											<div *ngIf="f.expiry_year.errors.required">Expirty Year is required</div>
											<div *ngIf="f.expiry_year.errors.pattern">Expirty Year is not valid</div>
										</div>
										<div
											*ngIf="submitted && pinpayment_response && pinpayment_response.error"
											class="form-control"
											class="text-danger"
										>
											<div *ngIf="pinpayment_errors.expiry_year">
												{{ pinpayment_errors.expiry_year }}
											</div>
										</div>
									</div>

									<div class="form-group col-md-6">
										<input
											id="cvc"
											formControlName="cvc"
											placeholder="CVC"
											type="text"
											pattern="[0-9]*"
											class="form-control"
											inputmode="numeric"
										/>
										<div *ngIf="submitted && f.cvc.errors" class="text-danger">
											<div *ngIf="f.cvc.errors.required">CVC is required</div>
											<div *ngIf="f.cvc.errors.pattern">CVC is not valid</div>
										</div>
										<div
											*ngIf="submitted && pinpayment_response && pinpayment_response.error"
											class="text-danger"
										>
											<div *ngIf="pinpayment_errors.cvc">{{ pinpayment_errors.cvc }}</div>
										</div>
									</div>
								</div>

								<div
									*ngIf="submitted && pinpayment_response && pinpayment_response.error"
									class="text-danger"
								>
									<div>{{ pinpayment_response.error_description }}</div>
								</div>

								<div class="form-group text-right mb-0">
									<button class="btn large" type="submit" (click)="onSubmit()">Subscribe</button>
								</div>
							</form>
							<!-- end form_wrapper -->
						</div>
						<!-- end col_form -->
					</div>
					<!-- end checkout_box -->
				</div>
				<!-- end checkout_wrapper -->

				<div class="row processing_window slides slide-4" [ngClass]="{ active: activeSlide == 'slide-4' }">
					<div class="col-12">
						<div class="panel">
							<div class="checkmark_wrap mb-2">
								<span class="checkmark"></span>
							</div>
							<!-- end checkmark_wrap -->

							<h2 class="heading text-center mb-2">Please wait</h2>

							<div class="body mb-5 text-center mb-2">
								<p>We are validating and processing your payment information.....</p>
							</div>

							<div class="progress skill-bar">
								<div
									class="progress-bar progress-bar-success"
									id="theprogressbar"
									role="progressbar"
									aria-valuenow="100"
									aria-valuemin="0"
									aria-valuemax="100"
								></div>
							</div>
						</div>
						<!-- end panel -->
					</div>
				</div>
				<!-- end processing_window -->

				<div class="row waiting_window slides slide-5" [ngClass]="{ active: activeSlide == 'slide-5' }">
					<div class="col-12">
						<div class="panel">
							<div class="checkmark_wrap mb-2 text-center">
								<span class="checkmark"></span>
							</div>
							<!-- end checkmark_wrap -->

							<h2 class="heading text-center mb-2">Subscription Successful.</h2>

							<div class="body text-center">
								<p class="mb-4">Your subscription to {{ selected_plan?.title }} is successful.</p>
								<a class="btn large" routerLink="/">Explore the premium content on the platform.</a>
							</div>
						</div>
						<!-- end panel -->
					</div>
					<!-- end col-12 -->
				</div>
				<!-- end waiting_window -->

				<div class="btn_wrap mt-4">
					<a
						class="btn large float-left"
						*ngIf="activeSlide == 'slide-2' || activeSlide == 'slide-3' || activeSlide == 'slide-4'"
						(click)="prevSlide()"
						>Prev</a
					>
					<a
						class="btn large float-right"
						*ngIf="activeSlide == 'slide-1' || activeSlide == 'slide-2' || activeSlide == 'slide-4'"
						(click)="nextSlide()"
						>Next</a
					>
				</div>
			</div>
			<!-- end col-10 offset-md-1 -->
		</div>
		<!-- row -->
	</div>
	<!-- container -->
</div>
<!-- section_wrapper -->
