import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../services/event.service';
import { ToastrService } from 'ngx-toastr';
import { SignupPopupComponent } from '../signup-popup/signup-popup.component';
@Component({
	selector: 'app-login-popup',
	templateUrl: './login-popup.component.html',
	styleUrls: ['./login-popup.component.scss'],
})
export class LoginPopupComponent implements OnInit {
	loginForm: FormGroup = this.formBuilder.group({
		email: [
			'',
			Validators.compose([
				Validators.required,
				Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
				Validators.minLength(1),
			]),
		],
		password: ['', [Validators.required, Validators.minLength(6)]],
	});
	loading = false;
	error: string = '';
	// myRecaptcha = new FormControl(false);
	submitted: any = false;
	response: any;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private EventService: EventService,
		private modalService: NgbModal,
		private toastr: ToastrService,
		private authenticationService: AuthenticationService
	) {}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			email: [
				'',
				Validators.compose([
					Validators.required,
					Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
					Validators.minLength(1),
				]),
			],
			password: ['', [Validators.required, Validators.minLength(6)]],
		});
	}

	get f() {
		return this.loginForm.controls;
	}

	onSubmit() {
		this.submitted = true;
		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}
		let data = {
			email: this.loginForm.value.email,
			password: this.loginForm.value.password,
		};
		this.loading = true;
		this.userService.login(data).subscribe(
			(response: any) => {
				if (response.status) {
					//console.log(response);
					localStorage.setItem('trc_user', JSON.stringify(response.user));
					this.router.navigate(['/']);
					// this.router.navigate(['/'], { queryParams: { logIn: true } });
					this.EventService.userLoggedin();
					this.modalService.dismissAll();
					this.loading = false;
				} else {
					this.EventService.userLoggedout();
					this.loading = false;
					//console.log(response);
					this.toastr.error(response.message);
				}
				this.loading = false;
			},
			(error) => {
				//console.log(error);
				this.loading = false;
			}
		);
	}

	async signup() {
		this.modalService.dismissAll();
		this.modalService.open(SignupPopupComponent, { size: 'custom', windowClass: 'signup-modal' });
	}

	async navigateToForgotPassword(){
		this.modalService.dismissAll();
		this.router.navigate(['/forgot-password']);
	}

	closemodal() {
		this.modalService.dismissAll();
	}

	onScriptLoad() {
		//console.log('Google reCAPTCHA loaded and is ready for use!');
	}

	onScriptError() {
		//console.log('Something went long when loading the Google reCAPTCHA');
	}
}
