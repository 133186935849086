import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormguideComponent } from './formguide/formguide.component';
import { FormguideArchiveComponent } from './formguide-archive/formguide-archive.component';
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { NewsComponent } from './news/news.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { AuthGuard } from './shared/guards/auth/auth.guard'; // Import the AuthGuard



import { SingleNewsComponent } from './single-news/single-news.component';
import { TournamentsComponent } from './tournaments/tournaments.component';
import { SingleTournamentComponent } from './single-tournament/single-tournament.component';
import { RoundRobinTournamentsComponent } from './round-robin-tournaments/round-robin-tournaments.component';
import { ResultsComponent } from './results/results.component';
import { BlackbookComponent } from './blackbook/blackbook.component';
import { LiveyardComponent } from './liveyard/liveyard.component';
import { ResourcesComponent } from './resources/resources.component';
import { ResourceComponent } from './resource/resource.component';
import { MakepaymentComponent } from './makepayment/makepayment.component';
import { SelectplanComponent } from './selectplan/selectplan.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

import { ContactUsComponent } from './contact-us/contact-us.component';
import { JumpoutsComponent } from './jumpouts/jumpouts.component';

import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutRCComponent } from './checkout-rc/checkout-rc.component';

import { ThankYouComponent } from './thank-you/thank-you.component';

import { SubscribePlanComponent } from './subscribe-plan/subscribe-plan.component';
import { SubscribeOffersComponent } from './subscribe-offers/subscribe-offers.component';
import { ChangePlanComponent } from './change-plan/change-plan.component';
import { TermsAppComponent } from './terms-app/terms-app.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy.component';
import { LandingComponent } from './landing-page/landing.component';

import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
	{ path: '', redirectTo: 'coming-soon', pathMatch: 'full' },
	{ path: 'signup', component: SignupComponent },
	{ path: 'news/:id/:slug', component: SingleNewsComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'make-payment', component: MakepaymentComponent },
	{ path: 'select-plan', component: SelectplanComponent },
	{ path: 'account', component: MyProfileComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'contact', component: ContactUsComponent },
	{ path: 'change-password', component: ChangePasswordComponent },
	{ path: 'terms-app', component: TermsComponent },
	{ path: 'terms-and-conditions', component: TermsComponent },
	{ path: 'terms', component: TermsComponent },
	{ path: 'privacy-policy', component: PrivacyPolicyComponent },
	{ path: 'checkout', component: CheckoutComponent },
	{ path: 'checkout-rc', component: CheckoutRCComponent },

	{ path: 'thank-you', component: ThankYouComponent },
	{ path: 'change-plan', component: ChangePlanComponent },
	{ path: 'authenticate-user/:token', component: SubscribePlanComponent },
	{ path: 'subscribe-plan', component: SubscribeOffersComponent, canActivate: [AuthGuard] },
	{ path: 'subscribe-plans', component: SubscribePlanComponent, canActivate: [AuthGuard] },

	{ path: 'coming-soon', component: LandingComponent },
	{ path: '**', redirectTo: 'coming-soon' }, // Wildcard route to catch all undefined routes



	// { path: 'form-guide/:slug', component: FormguideComponent },
	// { path: 'form-guide/:slug/:race_no', component: FormguideComponent },
	// { path: 'form-guide', component: FormguideArchiveComponent },
	// { path: 'previews', component: FormguideComponent },
	// { path: '', component: HomeComponent },
	// { path: 'home', redirectTo: '' },
	// { path: 'news', component: NewsComponent },
	// { path: 'news/:id/:slug', component: SingleNewsComponent },
	// { path: 'resources', component: ResourcesComponent },
	// { path: 'resource/:id/:slug', component: ResourceComponent },
	// { path: 'tournaments', component: TournamentsComponent },
	// { path: 'tournament/:id/:slug', component: SingleTournamentComponent },
	// { path: 'tournament/round-robin/:id/:slug', component: RoundRobinTournamentsComponent },
	// { path: 'results', component: ResultsComponent },
	// { path: 'results/:meeting_id', component: ResultsComponent },
	// { path: 'blackbook', component: BlackbookComponent },
	// { path: 'liveyard', component: LiveyardComponent },
	// { path: 'jumpouts', component: JumpoutsComponent },
	// { path: 'tournament/view/:id/:slug', component: SingleTournamentComponent },
	// { path: 'tournament/round-robin/view/:id/:slug', component: RoundRobinTournamentsComponent },
	// { path: 'sponsors', component: SponsorsComponent },

	{ path: '404', component: NotFoundComponent },
	{ path: '500', component: NotFoundComponent },
	{ path: '**', redirectTo: '/404' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabled',
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
