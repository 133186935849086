<div class="modal_wrapper text-center upgrade_modal_wrapper">
	<div class="close_icon" (click)="closemodal()">
		<svg xmlns="http://www.w3.org/2000/svg" width="11.689" height="11.689" viewBox="0 0 11.689 11.689">
			<g class="Group_251" data-name="Group 251" transform="translate(84.329 -65.195) rotate(135)">
				<g class="Group_249" data-name="Group 249" transform="translate(98.464 -2)">
					<path
						class="Path_1"
						data-name="Path 1"
						d="M24.331,20.516V5.986"
						transform="translate(-17.066 -5.986)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
					<path
						class="Path_81"
						data-name="Path 81"
						d="M0,14.53V0"
						transform="translate(14.53 7.265) rotate(90)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
				</g>
			</g>
		</svg>
	</div>
	<!-- end close_icon -->
	<h1 class="page_title mb-2" *ngIf="!user">Create your account</h1>
	<h1 class="page_title mb-2" *ngIf="user">Unlock content</h1>

	<div class="col_content">
		<p *ngIf="fromParent?.type == 'previews'">
			Subscribe to the 'Platinum' or 'Previews & Analysis' package to access along with other exclusive content.
		</p>
		<!-- <p *ngIf="fromParent?.type == 'jumpouts'">Subscribe to the 'Platinum' or 'Jump Outs' package to access this and other exclusive content.</p> -->
		<p *ngIf="fromParent?.type == 'liveyard'">
			Subscribe to the 'Platinum' or 'Live Yard' package to access along with other exclusive content.
		</p>
		<p *ngIf="fromParent?.type == 'premium'">
			Subscribe to the 'Platinum' package to access this along with other exclusive content.
		</p>
		<p *ngIf="fromParent?.type == 'free'">
			Create a free account to access features such as Form Guide, Jump Outs Search Engine, Blackbook, Tournaments
			and more!
		</p>

		<img
			class="img-fluid"
			src="assets/images/placeholders/liveyard-large.svg"
			alt="Upgrade"
			*ngIf="fromParent?.type == 'liveyard'"
		/>

		<img
			class="img-fluid"
			src="assets/images/placeholders/liveyard-large.svg"
			alt="Upgrade"
			*ngIf="fromParent?.type == 'premium'"
		/>

		<img
			class="img-fluid"
			src="assets/images/placeholders/previews-large.svg"
			alt="Upgrade"
			*ngIf="fromParent?.type == 'previews'"
		/>

		<img
			class="img-fluid"
			src="assets/images/placeholders/blackbook-large.svg"
			alt="Upgrade"
			*ngIf="fromParent?.type == 'free'"
		/>
	</div>
	<!-- end col_content -->

	<div class="form-group col-12">
		<div class="first_button_group">
			<button *ngIf="user" class="btn blue arrow_large" type="submit" (click)="gotoSubscribe()">Continue</button>
			<button *ngIf="!user" class="btn blue arrow_large" type="submit" (click)="gotoSignUp()">Sign up</button>
		</div>
		<button class="btn black" (click)="closemodal()" type="submit">No thanks</button>
	</div>
</div>
<!-- end upgrade_wrapper -->
