<div class="page_error_wrapper">
	<div class="container-fluid outer" *ngIf="error === '500'">
		<div class="row">
			<div class="col_form text-center">
				<div class="inner">
					<h1 class="page_title white">500 Server Error</h1>

					<div class="subheading">
						<p>
							If this issue persists, please contact
							<a href="mailto:support@theraceclub.com.au">support@racingcentral.com.au</a>
						</p>
					</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end col_form -->
		</div>
	</div>

	<div class="container-fluid outer" *ngIf="error === '404'">
		<div class="row">
			<div class="col_form text-center">
				<div class="inner">
					<h1 class="page_title white">Page not found</h1>

					<div class="subheading">
						<p>
							404 error: the page you are looking for could not be found. Return to
							<a routerLink="/">Home</a>
						</p>
					</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end col_form -->
		</div>
	</div>

	<div class="container-fluid outer" *ngIf="error != '404' && error != '500'">
		<div class="row">
			<div class="col_form text-center">
				<div class="inner">
					<h1 class="page_title white">An unknown error occurred</h1>

					<div class="subheading">
						<p>
							If this issue persists, please contact
							<a href="mailto:support@theraceclub.com.au">support@racingcentral.com.au</a>
						</p>
					</div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end col_form -->
		</div>
	</div>
	<!-- end container-fluid -->
</div>
<!-- end signup_wrapper -->
