import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../services/event.service';
import { LoginComponent } from '../login/login.component';
import { GeoIpService } from '../services/geoip.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { SegmentAnalyticsService } from '../services/segment-analysis.service';
declare let ga: Function;

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SignupComponent implements OnInit {
	registerForm: FormGroup = this.formBuilder.group({
		firstName: ['', Validators.required],
		lastName: ['', Validators.required],
		phone: [''],
		state: [''],
		dd: ['', Validators.required],
		mm: ['', Validators.required],
		yyyy: ['', Validators.required],
		email: [
			'',
			Validators.compose([
				Validators.required,
				Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
				Validators.minLength(1),
			]),
		],
		password: ['', [Validators.required, Validators.minLength(6)]],
		acceptTerms: [false, Validators.requiredTrue],
	});
	loading = false;
	error: string = '';
	submitted: any = false;
	response: any;

	constructor(
		private formBuilder: FormBuilder,
		private GeoIpService: GeoIpService,
		private router: Router,
		private userService: UserService,
		private authenticationService: AuthenticationService,
		private EventService: EventService,
		private toastr: ToastrService,
		private modalService: NgbModal,
		private segmentAnalyticsService: SegmentAnalyticsService
	) {
		// redirect to home if already logged in
	}

	ngOnInit() {
		this.registerForm = this.formBuilder.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			phone: [''],
			state: [''],
			dd: ['', Validators.required],
			mm: ['', Validators.required],
			yyyy: ['', Validators.required],
			email: [
				'',
				Validators.compose([
					Validators.required,
					Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
					Validators.minLength(1),
				]),
			],
			password: ['', [Validators.required, Validators.minLength(6)]],
			acceptTerms: ['', Validators.requiredTrue],
		});
		// //console.log(localStorage.getItem('user_id'));
		this.setUserLocation();
	}

	ngAfterViewInit() {
		// $(document).ready(function() {
		// 	$('.date-field').autotab('number');
		// });
	}

	autoTab($event: any, next: boolean) {
		if ($event.currentTarget.maxLength == $event.target.value.length) {
			if (next) {
				$event.srcElement.parentElement.nextElementSibling.nextElementSibling.firstElementChild.focus();
			} else {
				const passwordInput = document.getElementById('password-field')!;
				passwordInput.focus();
			}
		}
	}

	setUserLocation() {
		this.GeoIpService.getUserLocation().subscribe((data: any) => {
			if (data) {
				if (data.body.region == 'New South Wales') {
					this.registerForm.controls.state.setValue('NSW');
				} else if (data.body.region == 'Australian Capital Territory') {
					this.registerForm.controls.state.setValue('ACT');
				} else if (data.body.region == 'Queensland') {
					this.registerForm.controls.state.setValue('QLD');
				} else if (data.body.region == 'South Australia') {
					this.registerForm.controls.state.setValue('SA');
				} else if (data.body.region == 'Tasmania') {
					this.registerForm.controls.state.setValue('TAS');
				} else if (data.body.region == 'Victoria') {
					this.registerForm.controls.state.setValue('VIC');
				} else if (data.body.region == 'Western Australia') {
					this.registerForm.controls.state.setValue('WA');
				}
			}
		});
	}

	get f() {
		return this.registerForm.controls;
	}

	onSubmit() {
		//DATE VALIDATION
		var birth_date =
			this.registerForm.value.yyyy + '-' + this.registerForm.value.mm + '-' + this.registerForm.value.dd;
		var eighteenYearsAgo = moment().subtract(18, 'years');
		var dateFormat = 'YYYY-MM-DD';
		//console.log(birth_date);
		var correctFormat = moment(moment(birth_date).format(dateFormat), dateFormat, true).isValid();
		//console.log(correctFormat);

		if (correctFormat == false) {
			//console.log('invalid date');
			this.registerForm.controls['dd'].setErrors({ notcorrect: true });
			this.registerForm.controls['mm'].setErrors({ notcorrect: true });
			this.registerForm.controls['yyyy'].setErrors({ notcorrect: true });
		} else {
			//console.log('valid date');
			this.registerForm.controls['dd'].setErrors(null);
			this.registerForm.controls['mm'].setErrors(null);
			this.registerForm.controls['yyyy'].setErrors(null);
		}
		// 18 years

		if (eighteenYearsAgo.isAfter(birth_date)) {
			//console.log('over 18');
			this.registerForm.controls['dd'].setErrors(null);
			this.registerForm.controls['mm'].setErrors(null);
			this.registerForm.controls['yyyy'].setErrors(null);
		} else {
			//console.log('smaller than 18');
			this.registerForm.controls['dd'].setErrors({ illegal: true });
			this.registerForm.controls['mm'].setErrors({ illegal: true });
			this.registerForm.controls['yyyy'].setErrors({ illegal: true });
		}

		this.submitted = true;
		//console.log(this.registerForm.value);
		// stop here if form is invalid
		if (this.registerForm.invalid) {
			//console.log(this.registerForm);
			return;
		}
		let data = {
			first_name: this.registerForm.value.firstName,
			last_name: this.registerForm.value.lastName,
			phone: this.registerForm.value.phone,
			state: this.registerForm.value.state,
			birth_date: birth_date,
			email: this.registerForm.value.email,
			password: this.registerForm.value.password,
		};
		this.loading = true;
		this.userService.signup(data).subscribe(
			(response: any) => {
				this.response = response;
				if (response.status) {
					//console.log(response);
					ga('send', {
						hitType: 'event',
						eventCategory: 'User',
						eventAction: 'Sign up',
						eventLabel: this.registerForm.value.state,
					});
					localStorage.setItem('trc_user', JSON.stringify(response.user));
					this.EventService.userLoggedin();
					this.modalService.dismissAll();
					this.loading = false;
					this.segmentAnalyticsService.identify({
						'average_stake': this.response.user.stake,
						'userId': this.response.user.id
					})
					this.segmentAnalyticsService.track('Registration Confirmed', {
						'email': this.response.user.email,
						'birthday': `${this.response.user.dd}/${this.response.user.mm}/${this.response.user.yyyy}`,
						'first_name': this.response.user.name,
						'last_name': this.response.user.last_name,
						'avatar': this.response.user.avatar,
						'handle': this.response.user.display_name,
						'racing_iq': this.response.user.racingIq,
						'average_stake': this.response.user.stake,
					})
					window.analytics.identify(this.response.user.id, {
						email: this.response.user.email,
						name: this.response.user.name,
						subscription_tier: 'Free',
						first_name: this.response.user.name,
						last_name:  this.response.user.last_name,
						handle: this.response.user.display_name,

					});
					this.router.navigate(['/subscribe-plans']);

				} else {
					this.loading = false;
					//console.log(response);
					this.toastr.error(response.message);
				}
				this.loading = false;
			},
			(error) => {
				//console.log(error);
				this.loading = false;
			}
		);
	}

	async login() {
		this.modalService.dismissAll();
		this.modalService.open(LoginComponent);
	}

	closemodal() {
		this.modalService.dismissAll();
	}

	async navigateToTerms(){
		window.open('/terms-and-conditions');
	}
}
