<div class="formguide_archive_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-lg-10 offset-lg-1" *ngIf="!loading">
				<div class="form_guide_wrapper desktop_form_guide_archive_wrapper" *ngIf="screenWidth > 1200">
					<ul class="trc-tabs nav nav-tabs" id="formGuidePageTab" role="tablist">
						<li class="nav-item" *ngIf="yesterday?.races.length > 0" >
							<a (click)="setTab(0)"
								class="nav-link" [ngClass]="{ active:default_tab == 0 }"
								id="yesterday-tab"
								data-toggle="tab"
								href="#yesterday"
								role="tab"
								aria-controls="home"
								aria-selected="true"
								>Yesterday</a
							>
						</li>
						<li class="nav-item" *ngIf="today?.races.length > 0" >
							<a (click)="setTab(1)"
								class="nav-link" [ngClass]="{ active:default_tab == 1 }"
								id="today-tab"
								data-toggle="tab"
								href="#today"
								role="tab"
								aria-controls="profile"
								aria-selected="false"
								>Today</a
							>
						</li>
						<li class="nav-item" *ngIf="tomorrow?.races.length > 0">
							<a
								class="nav-link" [ngClass]="{ active:default_tab == 2 }"
								id="tomorrow-tab" (click)="setTab(2)"
								data-toggle="tab"
								href="#tomorrow"
								role="tab"
								aria-controls="contact"
								aria-selected="false"
								>Tomorrow</a
							>
						</li>
						<li class="nav-item" *ngIf="dayAfterTomorrow.races.length > 0">
							<a
								class="nav-link" [ngClass]="{ active:default_tab == 3 }"
								id="{{ dayAfterTomorrow.day }}-tab" (click)="setTab(3)"
								data-toggle="tab"
								href="#{{ dayAfterTomorrow.day }}"
								role="tab"
								aria-controls="contact"
								aria-selected="false"
								>{{ dayAfterTomorrow.day }}</a
							>
						</li>
						<li class="nav-item" *ngIf="twodaysAfterTomorrow.races.length > 0">
							<a
								class="nav-link" [ngClass]="{ active:default_tab == 4 }"
								id="{{ twodaysAfterTomorrow.day }}-tab" (click)="setTab(4)"
								data-toggle="tab"
								href="#{{ twodaysAfterTomorrow.day }}"
								role="tab"
								aria-controls="contact"
								aria-selected="false"
								>{{ twodaysAfterTomorrow.day }}</a
							>
						</li>
						<li class="nav-item" *ngIf="threedaysAfterTomorrow.races.length > 0">
							<a
								class="nav-link" [ngClass]="{ active:default_tab == 5 }"
								id="{{ threedaysAfterTomorrow.day }}-tab" (click)="setTab(5)"
								data-toggle="tab"
								href="#{{ threedaysAfterTomorrow.day }}"
								role="tab"
								aria-controls="contact"
								aria-selected="false"
								>{{ threedaysAfterTomorrow.day }}</a
							>
						</li>
					</ul>

					<div class="trc-tabs tab-content">
						<div class="tab-pane fade show" [ngClass]="{ active:default_tab == 0 }"  id="yesterday" role="tabpanel" aria-labelledby="yesterday">
							<table class="table race_table">
								<tbody>
									<tr>
										<th style="width: 10%">Track</th>
										<th ng-style="{'width' : 90/yesterday.max_number_of_races + '%' }" *ngFor="let race_headers of getArray(yesterday?.max_number_of_races); let i = index">R{{i+1}}</th>
									</tr>
									<tr *ngFor="let row of yesterday?.races; let yesterday_index = index">
										<td routerLink="/form-guide/{{ row?.slug }}">{{ row.venue }}</td>
										<td
											*ngFor="let race of row.races; let yesterday_race_index = index"
											routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
										>
											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
												[ngClass]="{ active: race.trc_preview }"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let today_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span *ngIf="today_race_horse_index < race.horses.length - 1"
														>,</span
													></span
												>
											</div>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && !race.startTimeCrossed"
											>
												<span>{{
													race?.race_start_time | amFromUtc | amLocal | amDateFormat: 'HH:mm'
												}}</span>
											</span>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && race.startTimeCrossed"
											>
												<span>Pending</span>
											</span>
										</td>
										<ng-container *ngIf="row.races.length < yesterday.max_number_of_races">
											<td ng-style="{'width' : 90/yesterday.max_number_of_races + '%' }" *ngFor="let in of counter(row.races.length, yesterday.max_number_of_races)">-</td>
										</ng-container>
									</tr>
								</tbody>
							</table>
							<!-- end table -->
						</div>
						<!-- end tab-pane -->

						<div class="tab-pane fade show" [ngClass]="{ active:default_tab == 1 }" id="today" role="tabpanel" aria-labelledby="today">
							<table class="table race_table">
								<tbody>
									<tr>
										<th style="width: 10%">Track</th>
										<th ng-style="{'width' : 90/today.max_number_of_races + '%' }" *ngFor="let race_headers of getArray(today?.max_number_of_races); let i = index">R{{i+1}}</th>

									</tr>
									<tr *ngFor="let row of today?.races; let today_index = index">
										<td routerLink="/form-guide/{{ row?.slug }}">{{ row.venue }}</td>
										<td
											class="race"
											*ngFor="let race of row.races; let today_race_index = index"
											routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
										>
											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results (R{{ race?.raceno }})</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let today_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span *ngIf="today_race_horse_index < race.horses.length - 1"
														>,</span
													></span
												>
											</div>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && !race.startTimeCrossed"
											>
												<span>{{
													race?.race_start_time | amFromUtc | amLocal | amDateFormat: 'HH:mm'
												}}</span>
											</span>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && race.startTimeCrossed"
											>
												<span>Pending</span>
											</span>
										</td>
										<ng-container *ngIf="row.races.length < today.max_number_of_races">
											<td ng-style="{'width' : 90/today.max_number_of_races + '%' }" *ngFor="let in of counter(row.races.length, today.max_number_of_races)">-</td>
										</ng-container>
									</tr>
								</tbody>
							</table>
							<!-- end table -->
						</div>
						<!-- end tab-pane -->

						<div class="tab-pane fade show" [ngClass]="{ active:default_tab == 2 }" id="tomorrow" role="tabpanel" aria-labelledby="tomorrow">
							<table class="table race_table">
								<tbody>
									<tr>
										<th style="width: 10%">Track</th>
										<th ng-style="{'width' : 90/tomorrow.max_number_of_races + '%' }" *ngFor="let race_headers of getArray(tomorrow?.max_number_of_races); let i = index">R{{i+1}}</th>
										
									</tr>
									<tr *ngFor="let row of tomorrow?.races; let tomorrow_index = index">
										<td routerLink="/form-guide/{{ row?.slug }}">{{ row.venue }}</td>
										<td
											*ngFor="let race of row.races; let tomorrow_race_index = index"
											routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
										>
											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let today_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span *ngIf="today_race_horse_index < race.horses.length - 1"
														>,</span
													></span
												>
											</div>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && !race.startTimeCrossed"
											>
												<span>{{
													race?.race_start_time | amFromUtc | amLocal | amDateFormat: 'HH:mm'
												}}</span>
											</span>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && race.startTimeCrossed"
											>
												<span>Pending</span>
											</span>
										</td>
										<ng-container *ngIf="row.races.length < tomorrow.max_number_of_races">
											<td ng-style="{'width' : 90/tomorrow.max_number_of_races + '%' }" *ngFor="let in of counter(row.races.length, tomorrow.max_number_of_races)">-</td>
										</ng-container>
									</tr>
								</tbody>
							</table>
							<!-- end table -->
						</div>
						<!-- end tab-pane -->

						<div
							class="tab-pane fade show" [ngClass]="{ active:default_tab == 3}"
							id="{{ dayAfterTomorrow?.day }}"
							role="tabpanel"
							aria-labelledby="saturday"
						>
							<table class="table race_table">
								<tbody>
									<tr>
										<th style="width: 10%">Track</th>
										<th ng-style="{'width' : 90/dayAfterTomorrow.max_number_of_races + '%' }" *ngFor="let race_headers of getArray(dayAfterTomorrow?.max_number_of_races); let i = index">R{{i+1}}</th>

									</tr>
									<tr *ngFor="let row of dayAfterTomorrow?.races; let dayAfterTomorrow_index = index">
										<td routerLink="/form-guide/{{ row?.slug }}">{{ row.venue }}</td>
										<td
											*ngFor="let race of row.races; let dayAfterTomorrow_race_index = index"
											class="active"
											routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
										>
											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let today_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span *ngIf="today_race_horse_index < race.horses.length - 1"
														>,</span
													></span
												>
											</div>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && !race.startTimeCrossed"
											>
												<span>{{
													race?.race_start_time | amFromUtc | amLocal | amDateFormat: 'HH:mm'
												}}</span>
											</span>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && race.startTimeCrossed"
											>
												<span>Pending</span>
											</span>
										</td>
										<ng-container *ngIf="row.races.length < dayAfterTomorrow.max_number_of_races">
											<td ng-style="{'width' : 90/dayAfterTomorrow.max_number_of_races + '%' }" *ngFor="let in of counter(row.races.length, dayAfterTomorrow.max_number_of_races)">-</td>
										</ng-container>
									</tr>
								</tbody>
							</table>
							<!-- end table -->
						</div>
						<!-- end tab-pane -->

						<div
							class="tab-pane fade show" [ngClass]="{ active:default_tab == 4 }"
							id="{{ twodaysAfterTomorrow?.day }}"
							role="tabpanel"
							aria-labelledby="saturday"
						>
							<table class="table race_table">
								<tbody>
									<tr>
										<th style="width: 10%">Track</th>
										<th ng-style="{'width' : 90/twodaysAfterTomorrow.max_number_of_races + '%' }" *ngFor="let race_headers of getArray(twodaysAfterTomorrow?.max_number_of_races); let i = index">R{{i+1}}</th>

									</tr>
									<tr
										*ngFor="
											let row of twodaysAfterTomorrow?.races;
											let twodaysAfterTomorrow_index = index
										"
									>
										<td routerLink="/form-guide/{{ row?.slug }}">{{ row.venue }}</td>
										<td
											*ngFor="let race of row.races; let twodaysAfterTomorrow_race_index = index"
											class="active"
											routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
										>
											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let today_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span *ngIf="today_race_horse_index < race.horses.length - 1"
														>,</span
													></span
												>
											</div>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && !race.startTimeCrossed"
											>
												<span>{{
													race?.race_start_time | amFromUtc | amLocal | amDateFormat: 'HH:mm'
												}}</span>
											</span>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && race.startTimeCrossed"
											>
												<span>Pending</span>
											</span>
										</td>
										<ng-container *ngIf="row.races.length < twodaysAfterTomorrow.max_number_of_races">
											<td ng-style="{'width' : 90/twodaysAfterTomorrow.max_number_of_races + '%' }" *ngFor="let in of counter(row.races.length, twodaysAfterTomorrow.max_number_of_races)">-</td>
										</ng-container>
									</tr>
								</tbody>
							</table>
							<!-- end table -->
						</div>
						<!-- end tab-pane -->

						<div
							class="tab-pane fade show" [ngClass]="{ active:default_tab == 5 }"
							id="{{ threedaysAfterTomorrow?.day }}"
							role="tabpanel"
							aria-labelledby="saturday"
						>
							<table class="table race_table">
								<tbody>
									<tr>
										<th style="width: 10%">Track</th>
										<th ng-style="{'width' : 90/threedaysAfterTomorrow.max_number_of_races + '%' }" *ngFor="let race_headers of  getArray(threedaysAfterTomorrow?.max_number_of_races); let i = index">R{{i+1}}</th>
									</tr>
									<tr
										*ngFor="
											let row of threedaysAfterTomorrow?.races;
											let threedaysAfterTomorrow_index = index
										"
									>
										<td routerLink="/form-guide/{{ row?.slug }}">{{ row.venue }}</td>
										<td
											*ngFor="
												let race of row.races;
												let threedaysAfterTomorrow_race_index = index
											"
											class="active"
											routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
										>
											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let today_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span *ngIf="today_race_horse_index < race.horses.length - 1"
														>,</span
													></span
												>
											</div>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && !race.startTimeCrossed"
											>
												<span>{{
													race?.race_start_time | amFromUtc | amLocal | amDateFormat: 'HH:mm'
												}}</span>
											</span>
											<span
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL' && race.startTimeCrossed"
											>
												<span>Pending</span>
											</span>
										</td>
										<ng-container *ngIf="row.races.length < threedaysAfterTomorrow.max_number_of_races">
											<td ng-style="{'width' : 90/threedaysAfterTomorrow.max_number_of_races + '%' }" *ngFor="let in of counter(row.races.length, threedaysAfterTomorrow.max_number_of_races)">-</td>
										</ng-container>
									</tr>
								</tbody>
							</table>
							<!-- end table -->
						</div>
						<!-- end tab-pane -->
					</div>
					<!-- end tab-content -->
				</div>
				<!-- end form_guide_wrapper -->

				<div class="form_guide_wrapper mobile_form_guide_archive_wrapper" *ngIf="screenWidth <= 1200">
					<div class="select_wrap">
						<select
							class="form-control mobile_form_guide_select"
							type="text"
							style="width: 100%"
							[(ngModel)]="mobileOptionDay" (ngModelChange)="applyFilter()"
						>
							<option value="none">Select Day</option>
							<option value="0">Yesterday</option>
							<option value="1">Today</option>
							<option value="2">Tomorrow</option>
							<option value="3">{{ dayAfterTomorrow.day }}</option>
							<option value="4" *ngIf="twodaysAfterTomorrow.races.length > 0">
								{{ twodaysAfterTomorrow.day }}
							</option>
							<option value="5" *ngIf="threedaysAfterTomorrow.races.length > 0">
								{{ threedaysAfterTomorrow.day }}
							</option>
						</select>
					</div>

					<ng-container *ngIf="mobileOptionDay == 0">
						<div
							class="fade show active container mobile_race_info_container"
							id="yesterday"
							aria-labelledby="yesterday"
						>
							
							<div
								class="mobile_race_info_wrapper collapsable-section closed"
								*ngFor="let row of yesterday?.races; let yesterday_index = index"
							>
								<div class="row mobile_venue_row">
									<div class="col-6 align-self-center">
										<h3 (click)="gotoFormGuide(row)">{{ row.venue }}</h3>
									</div>
									<div class="col-6 col_expand align-self-center" (click)="toggleAccordion($event)">
										<span *ngIf="row?.track_going && row?.track_going !=''" class="button rating {{ row?.track_going | lowercase }}"
											>{{ row?.track_going | titlecase }}
											{{ row?.track_rating | lowercase }}
										</span>

										<span class="toggleBtn showMore"></span>
									</div>
								</div>

								<div
									class="collapsed-content"
									*ngFor="let race of row.races; let yesterday_race_index = index"
									routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
								>
									<div class="row mobile_race_info_row">
										<div class="col-7 mobile_race_details">
											
											<div class="row mobile_race_num_name no-gutters">
												<div class="col">
													<p class="race_num"
													[ngClass]="{'previewed' : race?.trc_preview, 'grey': race?.race_status == 'FINAL'}"
													>R{{ yesterday_race_index + 1 }}</p>
												</div>
												<div class="col my-auto">
													<p class="race_name">
														{{
															race?.name.length > 15
																? (race?.name | slice: 0:15) + '..'
																: race?.name
														}}
													</p>
												</div>
											</div>

										</div>
										<div class="col-2">
											<p class="race_distance">{{race?.distance}}m</p>
										</div>
										<div class="col-3 right-col">
											<p
												class="race_time"
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL'"
											>
												<span>{{
													race?.race_start_time | amFromUtc | amLocal | amDateFormat: 'HH:mm'
												}}</span>
											</p>
											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let yesterday_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span *ngIf="yesterday_race_horse_index < race.horses.length - 1"
														>,</span
													></span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="mobileOptionDay == 1">
						<div
							class="fade show active container mobile_race_info_container"
							id="today"
							aria-labelledby="today"
						>
							<div
								class="mobile_race_info_wrapper collapsable-section closed"
								*ngFor="let row of today?.races; let today_index = index"
							>
								<div class="row mobile_venue_row">
									<div class="col-6 align-self-center">
										<h3 (click)="gotoFormGuide(row)">{{ row.venue }}</h3>
									</div>
									<div class="col-6 col_expand align-self-center" (click)="toggleAccordion($event)">
										<span *ngIf="row?.track_going && row?.track_going !=''" class="button rating {{ row?.track_going | lowercase }}"
											>{{ row?.track_going | titlecase }}
											{{ row?.track_rating | lowercase }}
										</span>

										<span class="toggleBtn showMore"></span>
									</div>
								</div>

								<div
									class="collapsed-content"
									*ngFor="let race of row.races; let today_race_index = index"
									routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
								>
									<div class="row mobile_race_info_row">
										<div class="col-7 mobile_race_details my-auto">
											
											<div class="row mobile_race_num_name no-gutters">
												<div class="col">
													<p class="race_num"
													[ngClass]="{'previewed' : race?.trc_preview, 'grey': race?.race_status == 'FINAL'}"
													>R{{ today_race_index + 1 }}</p>
												</div>
												<div class="col my-auto">
													<p class="race_name">
														{{
															race?.name.length > 15
																? (race?.name | slice: 0:15) + '..'
																: race?.name
														}}
													</p>
												</div>
											</div>


										</div>
										<div class="col-2">
											<p class="race_distance">{{race?.distance}}m</p>
										</div>
										<div class="col-3 right-col">
											<p
												class="race_time"
												[ngClass]="{ active: race.trc_preview }"
												*ngIf="race.race_status != 'FINAL'"
											>
												<span>{{
													race?.race_start_time | amFromUtc | amLocal | amDateFormat: 'HH:mm'
												}}</span>
											</p>
											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let today_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span *ngIf="today_race_horse_index < race.horses.length - 1"
														>,</span
													></span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="mobileOptionDay == 2">
						<div
							class="fade show active container mobile_race_info_container"
							id="tomorrow"
							aria-labelledby="tomorrow"
						>
							<div
								class="mobile_race_info_wrapper collapsable-section closed"
								*ngFor="let row of tomorrow?.races; let tomorrow_index = index"
							>
								<div class="row mobile_venue_row">
									<div class="col-6 align-self-center">
										<h3 (click)="gotoFormGuide(row)">{{ row.venue }}</h3>
									</div>
									<div class="col-6 col_expand align-self-center" (click)="toggleAccordion($event)">
										<span *ngIf="row?.track_going && row?.track_going !=''" class="button rating {{ row?.track_going | lowercase }}"
											>{{ row?.track_going | titlecase }}
											{{ row?.track_rating | lowercase }}
										</span>

										<span class="toggleBtn showMore"></span>
									</div>
								</div>

								<div
									class="collapsed-content"
									*ngFor="let race of row.races; let tomorrow_race_index = index"
									routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
								>
									<div class="row mobile_race_info_row">
										<div class="col-7 mobile_race_details">

											<div class="row mobile_race_num_name no-gutters">
												<div class="col">
													<p class="race_num"
													[ngClass]="{'previewed' : race?.trc_preview, 'grey': race?.race_status == 'FINAL'}"
													>R{{ tomorrow_race_index + 1 }}</p>
												</div>
												<div class="col my-auto">
													<p class="race_name">
														{{
															race?.name.length > 15
																? (race?.name | slice: 0:15) + '..'
																: race?.name
														}}
													</p>
												</div>
											</div>

										</div>
										<div class="col-2">
											<p class="race_distance">{{race?.distance}}m</p>
										</div>
										<div class="col-3 my-auto right-col">
										
											<div
												class="time-pills"
												[attr.data-counter-id]="race?.event_id"
												(click)="$event.stopPropagation();toggleTime($event);"
												[ngClass]="{'green': race.race_status == 'FINAL', 'grey': (race.race_status != 'FINAL' || race.race_status == '') && race.seconds < -120, 'red to-blue': race.seconds <= 300 && race.seconds >= -120,'blue': race.seconds > 300 && race.show_counter, 'grey to-blue': race.seconds > 300}"
											>
												<ng-template [ngIf]="race.show_counter">
													<span
														[counter]="race?.race_start_time"
														[race_status]="race?.race_status"
														[race]="race"
														(TimerExpired)="timeexpired(race)"
														(TimerBelowFiveMinutes)="TimerBelowFiveMinutes(race)"
													></span>
													<span *ngIf="!race.show_pending"
														>{{ race?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
														}}</span
													>
													<span *ngIf="race.show_pending">Pending</span>
												</ng-template>
												<ng-template [ngIf]="!race.show_counter">
													<span *ngIf="race.show_pending">Pending</span>
													<span *ngIf="!race.show_pending"
														>{{ race?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
														}}</span
													>
													<span
														[counter]="race?.race_start_time"
														[race]="race"
														[race_status]="race?.race_status"
														(TimerExpired)="timeexpired(race)"
													></span>
												</ng-template>
											</div>

											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let tomorrow_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span *ngIf="tomorrow_race_horse_index < race.horses.length - 1"
														>,</span
													></span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="mobileOptionDay == 3">
						<div
							class="fade show active container mobile_race_info_container"
							id="dayAfterTomorrow"
							aria-labelledby="dayAfterTomorrow"
						>
							<div
								class="mobile_race_info_wrapper collapsable-section closed"
								*ngFor="let row of dayAfterTomorrow?.races; let dayAfterTomorrow_index = index"
							>
								<div class="row mobile_venue_row">
									<div class="col-6 align-self-center">
										<h3 (click)="gotoFormGuide(row)">{{ row.venue }}</h3>
									</div>
									<div class="col-6 col_expand align-self-center" (click)="toggleAccordion($event)">
										<span *ngIf="row?.track_going && row?.track_going !=''" class="button rating {{ row?.track_going | lowercase }}"
											>{{ row?.track_going | titlecase }}
											{{ row?.track_rating | lowercase }}
										</span>

										<span class="toggleBtn showMore"></span>
									</div>
								</div>

								<div
									class="collapsed-content"
									*ngFor="let race of row.races; let dayAfterTomorrow_race_index = index"
									routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
								>
									<div class="row mobile_race_info_row">
										<div class="col-7 mobile_race_details">

											<div class="row mobile_race_num_name no-gutters">
												<div class="col">
													<p class="race_num"
													[ngClass]="{'previewed' : race?.trc_preview, 'grey': race?.race_status == 'FINAL'}"
													>R{{ dayAfterTomorrow_race_index + 1 }}</p>
												</div>
												<div class="col my-auto">
													<p class="race_name">
														{{
															race?.name.length > 15
																? (race?.name | slice: 0:15) + '..'
																: race?.name
														}}
													</p>
												</div>
											</div>

										</div>
										<div class="col-2">
											<p class="race_distance">{{race?.distance}}m</p>
										</div>
										<div class="col-3 my-auto right-col">

											<div
												class="time-pills"
												[attr.data-counter-id]="race?.event_id"
												(click)="$event.stopPropagation();toggleTime($event);"
												[ngClass]="{'green': race.race_status == 'FINAL', 'grey': (race.race_status != 'FINAL' || race.race_status == '') && race.seconds < -120, 'red to-blue': race.seconds <= 300 && race.seconds >= -120,'blue': race.seconds > 300 && race.show_counter, 'grey to-blue': race.seconds > 300}"
											>
												<ng-template [ngIf]="race.show_counter">
													<span
														[counter]="race?.race_start_time"
														[race_status]="race?.race_status"
														[race]="race"
														(TimerExpired)="timeexpired(race)"
														(TimerBelowFiveMinutes)="TimerBelowFiveMinutes(race)"
													></span>
													<span *ngIf="!race.show_pending"
														>{{ race?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
														}}</span
													>
													<span *ngIf="race.show_pending">Pending</span>
												</ng-template>
												<ng-template [ngIf]="!race.show_counter">
													<span *ngIf="race.show_pending">Pending</span>
													<span *ngIf="!race.show_pending"
														>{{ race?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
														}}</span
													>
													<span
														[counter]="race?.race_start_time"
														[race]="race"
														[race_status]="race?.race_status"
														(TimerExpired)="timeexpired(race)"
													></span>
												</ng-template>
											</div>

											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let dayAfterTomorrow_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span
														*ngIf="
															dayAfterTomorrow_race_horse_index < race.horses.length - 1
														"
														>,</span
													></span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="mobileOptionDay == 4">
						<div
							class="fade show active container mobile_race_info_container"
							id="twodaysAfterTomorrow"
							aria-labelledby="twodaysAfterTomorrow"
						>
							<div
								class="mobile_race_info_wrapper collapsable-section closed"
								*ngFor="let row of twodaysAfterTomorrow?.races; let twodaysAfterTomorrow_index = index"
							>
								<div class="row mobile_venue_row">
									<div class="col-6 align-self-center">
										<h3 (click)="gotoFormGuide(row)">{{ row.venue }}</h3>
									</div>
									<div class="col-6 col_expand align-self-center" (click)="toggleAccordion($event)">
										<span *ngIf="row?.track_going && row?.track_going !=''" class="button rating {{ row?.track_going | lowercase }}"
											>{{ row?.track_going | titlecase }}
											{{ row?.track_rating | lowercase }}
										</span>

										<span class="toggleBtn showMore"></span>
									</div>
								</div>

								<div
									class="collapsed-content"
									*ngFor="let race of row.races; let twodaysAfterTomorrow_race_index = index"
									routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
								>
									<div class="row mobile_race_info_row">
										<div class="col-7 mobile_race_details">

											<div class="row mobile_race_num_name no-gutters">
												<div class="col">
													<p class="race_num"
													[ngClass]="{'previewed' : race?.trc_preview, 'grey': race?.race_status == 'FINAL'}"
													>R{{ twodaysAfterTomorrow_race_index + 1 }}</p>
												</div>
												<div class="col my-auto">
													<p class="race_name">
														{{
															race?.name.length > 15
																? (race?.name | slice: 0:15) + '..'
																: race?.name
														}}
													</p>
												</div>
											</div>

										</div>
										<div class="col-2">
											<p class="race_distance">{{race?.distance}}m</p>
										</div>
										<div class="col-3 my-auto right-col">

											<div
												class="time-pills"
												[attr.data-counter-id]="race?.event_id"
												(click)="$event.stopPropagation();toggleTime($event);"
												[ngClass]="{'green': race.race_status == 'FINAL', 'grey': (race.race_status != 'FINAL' || race.race_status == '') && race.seconds < -120, 'red to-blue': race.seconds <= 300 && race.seconds >= -120,'blue': race.seconds > 300 && race.show_counter, 'grey to-blue': race.seconds > 300}"
											>
												<ng-template [ngIf]="race.show_counter">
													<span
														[counter]="race?.race_start_time"
														[race_status]="race?.race_status"
														[race]="race"
														(TimerExpired)="timeexpired(race)"
														(TimerBelowFiveMinutes)="TimerBelowFiveMinutes(race)"
													></span>
													<span *ngIf="!race.show_pending"
														>{{ race?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
														}}</span
													>
													<span *ngIf="race.show_pending">Pending</span>
												</ng-template>
												<ng-template [ngIf]="!race.show_counter">
													<span *ngIf="race.show_pending">Pending</span>
													<span *ngIf="!race.show_pending"
														>{{ race?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
														}}</span
													>
													<span
														[counter]="race?.race_start_time"
														[race]="race"
														[race_status]="race?.race_status"
														(TimerExpired)="timeexpired(race)"
													></span>
												</ng-template>
											</div>

											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let twodaysAfterTomorrow_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span
														*ngIf="
															twodaysAfterTomorrow_race_horse_index <
															race.horses.length - 1
														"
														>,</span
													></span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="mobileOptionDay == 5">
						<div
							class="fade show active container mobile_race_info_container"
							id="threedaysAfterTomorrow"
							aria-labelledby="threedaysAfterTomorrow"
						>
							<div
								class="mobile_race_info_wrapper collapsable-section closed"
								*ngFor="
									let row of threedaysAfterTomorrow?.races;
									let threedaysAfterTomorrow_index = index
								"
							>
								<div class="row mobile_venue_row">
									<div class="col-6 align-self-center">
										<h3 (click)="gotoFormGuide(row)">{{ row.venue }}</h3>
									</div>
									<div class="col-6 col_expand align-self-center" (click)="toggleAccordion($event)">
										<span *ngIf="row?.track_going && row?.track_going !=''" class="button rating {{ row?.track_going | lowercase }}"
											>{{ row?.track_going | titlecase }}
											{{ row?.track_rating | lowercase }}
										</span>

										<span class="toggleBtn showMore"></span>
									</div>
								</div>

								<div
									class="collapsed-content"
									*ngFor="let race of row.races; let threedaysAfterTomorrow_race_index = index"
									routerLink="/form-guide/{{ row?.slug }}/{{ race?.raceno }}"
								>
									<div class="row mobile_race_info_row">
										<div class="col-7 mobile_race_details">

											<div class="row mobile_race_num_name no-gutters">
												<div class="col">
													<p class="race_num"
													[ngClass]="{'previewed' : race?.trc_preview, 'grey': race?.race_status == 'FINAL'}"
													>R{{ threedaysAfterTomorrow_race_index + 1 }}</p>
												</div>
												<div class="col my-auto">
													<p class="race_name">
														{{
															race?.name.length > 15
																? (race?.name | slice: 0:15) + '..'
																: race?.name
														}}
													</p>
												</div>
											</div>

										</div>
										<div class="col-2">
											<p class="race_distance">{{race?.distance}}m</p>
										</div>
										<div class="col-3 my-auto right-col">

											<div
												class="time-pills"
												[attr.data-counter-id]="race?.event_id"
												(click)="$event.stopPropagation();toggleTime($event);"
												[ngClass]="{'green': race.race_status == 'FINAL', 'grey': (race.race_status != 'FINAL' || race.race_status == '') && race.seconds < -120, 'red to-blue': race.seconds <= 300 && race.seconds >= -120,'blue': race.seconds > 300 && race.show_counter, 'grey to-blue': race.seconds > 300}"
											>
												<ng-template [ngIf]="race.show_counter">
													<span
														[counter]="race?.race_start_time"
														[race_status]="race?.race_status"
														[race]="race"
														(TimerExpired)="timeexpired(race)"
														(TimerBelowFiveMinutes)="TimerBelowFiveMinutes(race)"
													></span>
													<span *ngIf="!race.show_pending"
														>{{ race?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
														}}</span
													>
													<span *ngIf="race.show_pending">Pending</span>
												</ng-template>
												<ng-template [ngIf]="!race.show_counter">
													<span *ngIf="race.show_pending">Pending</span>
													<span *ngIf="!race.show_pending"
														>{{ race?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
														}}</span
													>
													<span
														[counter]="race?.race_start_time"
														[race]="race"
														[race_status]="race?.race_status"
														(TimerExpired)="timeexpired(race)"
													></span>
												</ng-template>
											</div>

											<div
												class="race_status"
												*ngIf="race.race_status == 'FINAL'"
												placement="bottom"
												[ngbPopover]="popContent"
												[popoverTitle]="popTitle"
												[openDelay]="300"
												[closeDelay]="-100"
												triggers="mouseenter:mouseleave"
											>
												<ng-template #popTitle>{{ row.venue }}</ng-template>
												<ng-template #popContent>
													<div class="result_head">Results</div>
													<div *ngFor="let horse of race.horses" class="result_data">
														<b>{{ horse.horse_fp }}</b> - {{ horse.tabno }}.
														{{ horse.horse_name }}
													</div>
													<!-- <b *ngFor="let horse of race.horse">{{horse.horse_fp}} {{horse.horse_name}} {{horse.jockey}}</b> -->
												</ng-template>
												<span
													*ngFor="
														let horse of race.horses;
														let threedaysAfterTomorrow_race_horse_index = index
													"
													>{{ horse.tabno
													}}<span
														*ngIf="
															threedaysAfterTomorrow_race_horse_index <
															race.horses.length - 1
														"
														>,</span
													></span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
				<!-- end form_guide_wrapper -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->

			<!-- DESKTOP GHOST ELEMENT -->
			<div class="col-lg-10 offset-lg-1 desktop_ghost_element" *ngIf="loading && screenWidth > 1200">
				<div class="form_guide_wrapper">
					<ul class="trc-tabs nav nav-tabs" id="formGuidePageTab" role="tablist">
						<li class="nav-item" *ngFor="let number of [0, 1, 2, 3]">
							<a class="nav-link" data-toggle="tab" role="tab" aria-selected="true">&nbsp;</a>
						</li>
					</ul>

					<div class="trc-tabs tab-content">
						<div class="tab-pane fade show active" role="tabpanel">
							<table class="table race_table">
								<tbody>
									<tr *ngFor="let number of [0, 1, 2, 3, 4, 5]">
										<th style="width: 10%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
										<th style="width: 9%">&nbsp;</th>
									</tr>
								</tbody>
							</table>
							<!-- end table -->
						</div>
						<!-- end tab-pane -->
					</div>
					<!-- end tab-content -->
				</div>
				<!-- end form_guide_wrapper -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->

			<!-- MOBILE GHOST ELEMENT -->
			<div class="col-lg-10 offset-lg-1 mobile_form_guide_archive_wrapper mobile_ghost_element" *ngIf="loading && screenWidth <= 1200">
				<div class="select2 loading-text"></div>
				<div class="mobile_race_info_wrapper" *ngFor="let number of [0,1,2,3,4,5]">
					<div class="mobile_venue_row row">
						<div class="col-6 ">
							<h3 class="loading-text"></h3>
						</div>
						<div class="col-6 col_expand">
							<span class=" button rating loading-text"></span>
							<span class="toggleBtn loading-text"></span>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-10 offset-lg-1">
				<div class="row">
					<!-- <div class="col-xl-7 col_left" [ngClass]="{ 'col-xs-7': loading, 'col-xl-7' : previews?.length > 0 && !loading, 'col-xl-12' : previews?.length == 0 && !loading }"> -->
					<div class="col-xl-7 col_left">
						<div class="row resources_widget" *ngIf="resources?.length > 0 && !loadingResources">
							<div class="col-12">
								<div class="panel with_inner_content">
									<div class="row">
										<h2 class="heading col-lg-6 align-self-center">Resources</h2>
										<div class="view_links col-lg-6 align-self-center text-right d-none d-md-block">
											<a class="arrow_link small" routerLink="/resources" routerLink="/resources"
												>View all resources

												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="41.896"
													height="30"
													viewBox="0 0 41.896 30"
												>
													<g
														class="Group_9062"
														data-name="Group 9062"
														transform="translate(-1588.104 -227)"
													>
														<circle
															class="Ellipse_86"
															data-name="Ellipse 86"
															cx="15"
															cy="15"
															r="15"
															transform="translate(1600 227)"
															fill="#6cfcfd"
														/>
														<g
															class="Group_540"
															data-name="Group 540"
															transform="translate(1588.109 236.846)"
														>
															<line
																class="Line_1"
																data-name="Line 1"
																x1="29.317"
																y1="0.145"
																transform="translate(0 5.478)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
															<path
																class="Path_1"
																data-name="Path 1"
																d="M24.331,17.232l5.355-5.623L24.331,5.986"
																transform="translate(-0.369 -5.986)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
														</g>
													</g>
												</svg>
											</a>
										</div>
									</div>

									<div class="row">
										<div
											class="col-md-4 resources_single d-flex flex-column"
											*ngFor="let row of resources"
										>
											<div class="panel grey flex-grow-1 d-flex flex-column">
												<h4 class="title">
													<a (click)="gotoSingleResource(row)">{{ row?.title }}</a>
												</h4>

												<div class="body" *ngIf="row?.short_description">
													{{
														row?.short_description.length > 80
															? (row?.short_description | slice: 0:80) + '..'
															: row?.short_description
													}}
												</div>

												<div class="read-link mt-auto">
													<a class="link small" (click)="gotoSingleResource(row)"
														>Read more</a
													>
												</div>
											</div>
											<!-- end panel -->
											<a (click)="gotoSingleResource(row)">
												<div
													class="img bottom-border gradient"
													[style.backgroundImage]="'url(' + row?.image_url + ')'"
												></div>
											</a>
										</div>
										<!-- end resources_single -->

										<div class="view_links col-lg-6 align-self-center text-right d-md-none">
											<a class="arrow_link small" routerLink="/resources"
												>View all resources

												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="41.896"
													height="30"
													viewBox="0 0 41.896 30"
												>
													<g
														class="Group_9062"
														data-name="Group 9062"
														transform="translate(-1588.104 -227)"
													>
														<circle
															class="Ellipse_86"
															data-name="Ellipse 86"
															cx="15"
															cy="15"
															r="15"
															transform="translate(1600 227)"
															fill="#6cfcfd"
														/>
														<g
															class="Group_540"
															data-name="Group 540"
															transform="translate(1588.109 236.846)"
														>
															<line
																class="Line_1"
																data-name="Line 1"
																x1="29.317"
																y1="0.145"
																transform="translate(0 5.478)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
															<path
																class="Path_1"
																data-name="Path 1"
																d="M24.331,17.232l5.355-5.623L24.331,5.986"
																transform="translate(-0.369 -5.986)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
														</g>
													</g>
												</svg>
											</a>
										</div>
									</div>
									<!-- end row -->
								</div>
							</div>
						</div>
						<!-- end resources_widget -->

						<!-- GHOST ELEMENT -->
						<div class="row resources_widget" *ngIf="loadingResources">
							<div class="col-12">
								<div class="panel with_inner_content">
									<div class="row">
										<h2 class="heading col-lg-6 align-self-center">Resources</h2>
									</div>

									<div class="row">
										<div class="col-md-4 resources_single" *ngFor="let number of [0, 1, 2]">
											<div class="panel grey">
												<h4 class="title"><div class="loading-text"></div></h4>

												<div class="body">
													<div class="loading-text"></div>
													<div class="loading-text"></div>
												</div>
											</div>
											<!-- end panel -->

											<div class="img bottom-border gradient loading-text"></div>
										</div>
										<!-- end resources_single -->
									</div>
									<!-- end row -->
								</div>
							</div>
						</div>
						<!-- end resources_widget -->
					</div>
					<!-- end col_left -->

					<div class="col-xl-5 col_right">
						<div class="row previews_widget" *ngIf="previews?.length > 0 && !loading">
							<div class="col-12">
								<div class="row">
									<h2 class="heading col-lg-6 align-self-center">Upcoming previews</h2>
								</div>

								<div class="row">
									<div class="col-12 previews_single" *ngFor="let row of previews">
										<div class="panel">
											<div
												class="p-row row"
												routerLink="form-guide/{{ row.slug }}/{{ row.raceno }}"
											>
												<div
													class="col_title_link col align-self-center"
													routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}"
												>
													<a
														routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}"
														class="title_link small"
														>{{ row.venue }}</a
													>
												</div>

												<div class="col_next_race text-right col align-self-center" routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}">
													<a>
														<div class="next_race">
															<span class="next_text">Next Race</span>
															<span class="race_no align-middle"
																><p>{{ row.raceno }}</p></span
															>
														</div>
													</a>
												</div>
											</div>
										</div>
									</div>
									<!-- end previews_single -->
								</div>
							</div>
						</div>
						<!-- end previews_widget -->

						<!-- GHOST ELEMENT -->
						<div class="row previews_widget" *ngIf="loading">
							<div class="col-12">
								<div class="row">
									<h2 class="heading col-lg-6 align-self-center"></h2>
								</div>
								<div class="row">
									<div class="col-12 previews_single" *ngFor="let number of [0, 1, 2, 3]">
										<div class="panel">
											<div class="p-row row">
												<div class="col_title_link col align-self-center">
													<div class="loading-text"></div>
												</div>
												<div class="col_next_race text-right col align-self-center">
													<div class="loading-text"></div>
												</div>
											</div>
										</div>
									</div>
									<!-- end previews_single -->
								</div>
							</div>
						</div>
						<!-- end previews_wrapper -->
					</div>
					<!-- end col_right -->
				</div>
			</div>
			<!-- end col-lg-10 offset-lg-1 -->
		</div>
		<!-- row -->
	</div>
	<!-- container -->
</div>
<!-- formguide_archive_wrapper -->
