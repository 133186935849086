import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PlanService } from '../services/plan.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalService } from '../shared/confirmation-modal/confirmation-modal.service';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '../services/event.service';

import * as moment from 'moment';
declare var pinApi: any;

@Component({
	selector: 'app-subscribe-offers',
	templateUrl: './subscribe-offers.component.html',
	styleUrls: ['./subscribe-offers.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SubscribeOffersComponent implements OnInit {
	features: any;
	plans: any;
	feature_loading: boolean = true;
	plans_loading: boolean = true;
	plans_selected: boolean = false;
	selectedPlan: any;
	activeSlide: any = 'slide-1';
	name: any;
	cardnumber: any;
	expirationdate: any;
	cvc: any;
	output: any;
	ccicon: any;
	ccsingle: any;
	generatecard: any;
	selection: any;
	submitted: any = false;
	payment_gateway_response: any;
	pinpayment_response: any;
	user: any;
	selected_plan: any;
	token: any;
	plan_id: any;
	loading_features: any = false;
	loading_plans: any = false;
	payment_progress: any = 0;
	features_count: any = 0;
	plans_count: any = 0;
	selected_features: any = [];
	plan_duration: any = 'weekly';
	premium_plan: any;
	discount: any;
	total_price: any;
	premium_subscribed: any = false;
	subscription_in_cancellation: any = false;

	paymentForm: FormGroup = this.formBuilder.group({
		number: ['', [Validators.required]],
		name: ['', [Validators.required]],
		expiry_month: ['', [Validators.required]],
		cvc: ['', [Validators.required]],
		expiry_year: ['', [Validators.required]],
	});

	pinpayment_errors: any = {
		number: null,
		name: null,
		expiry_month: null,
		cvc: null,
		expiry_year: null,
	};

	constructor(
		private PlanService: PlanService,
		private route: Router,
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toastr: ToastrService,
		private ConfirmationModalService: ConfirmationModalService,
		private AlertModalService: AlertModalService,
		private activatedroute: ActivatedRoute,
		private eventService: EventService
	) {
		if (activatedroute.params) {
			this.activatedroute.params.subscribe((params) => {
				this.token = params.token;
				if (this.token) this.user = this.getUser();
			});
		}

		if (!this.user) {
			this.user = localStorage.getItem('trc_user');
			this.user = JSON.parse(this.user);
			this.getUserSubscription();
		}
	}

	ngOnInit(): void {
		this.getPromotionalPlans();
	}
	get f() {
		return this.paymentForm.controls;
	}

	getUserSubscription() {
		if (this.user != null && this.user != 'undefined' && this.user.token) {
			this.token = this.user.token;
			this.selected_plan = localStorage.getItem('selected_plan');
			if (this.selected_plan) {
				this.selected_plan = JSON.parse(this.selected_plan);
				this.plan_duration = this.selected_plan.duration.duration ? this.selected_plan.duration.duration : 'weekly';
			}
			if (this.user && this.user.subscription && this.user.subscription.plan && this.user.subscription.status) {
				if (this.user.subscription.plan.premium_plan && this.user.subscription.plan.rc_plan) {
					this.premium_subscribed = true;
					console.log("subscribe", this.premium_subscribed);
				}
				if (this.user.subscription.cancelled) {
					this.subscription_in_cancellation = true;
				}
				if (this.user.subscription && this.user.subscription.pricing_plan && this.user.subscription.pricing_plan.duration) {
					this.plan_duration = this.user.subscription.pricing_plan.duration;
				}
			}
		}
	}

	async getUser() {
		await this.userService.getAuthUser(this.token).subscribe((data: any) => {
			if (data) {
				if (data.result) {
					localStorage.setItem('trc_user', JSON.stringify(data.result));
					this.user = data.result;
					this.getUserSubscription();
					this.eventService.userLoggedin();
				}
			}
		});
	}

	async getPromotionalPlans() {
		this.feature_loading = true;
		await this.PlanService.getPromotionalPlans().subscribe((data: any) => {
			if (data) {
				if (data.plans) {
					// data.plans.forEach(element => {
					// 	console.log('element',element)
					// 	if(element.duration === 'weekly') {
					// 		this.premium_plan = element;
					// 	}
					// });
					this.premium_plan = data.plans[0];
					this.setPlanDuration('weekly');
				}
			}
			this.feature_loading = false;
		});
	}


	async getPlans(selected_features: any) {
		this.plans_loading = true;
		let premium_plan_selected;
		if (this.selected_features.length == this.features.length) {
			premium_plan_selected = true;
		} else {
			premium_plan_selected = false;
		}

		if (!premium_plan_selected) {
			await this.PlanService.getPlans(selected_features).subscribe((data: any) => {
				if (data) {
					if (data.plans) {
						this.plans = data.plans;
						// if (this.plans.length == 1) this.plans[0].premium_plan = 0;
						this.plans.forEach((element: any) => {
							element.prices.forEach((price: any) => {
								if (price.duration === this.plan_duration && !element.premium_plan) {
									this.selected_plan = element;
									this.selected_plan.duration = price;
									this.selected_plan.selected_features = [];
									this.features.forEach((feature: any) => {
										if (feature.checked) this.selected_plan.selected_features.push(feature);
									});
									this.validatePlanandCheckout(this.selected_plan);
								}
							});
						});

						if (this.plans.length == 1 && this.plans[0].premium_plan) {
							this.premium_plan.prices.forEach((price: any) => {
								if (price.duration === this.plan_duration) {
									this.selected_plan = this.premium_plan;
									this.selected_plan.duration = price;
									this.validatePlanandCheckout(this.selected_plan);
								}
							});
						}
					}
				}
				this.plans_loading = false;
			});
		} else {
			this.premium_plan.prices.forEach((price: any) => {
				if (price.duration === this.plan_duration) {
					this.selected_plan = this.premium_plan;
					this.selected_plan.duration = price;
					this.validatePlanandCheckout(this.selected_plan);
				}
			});
		}
	}

	async validatePlanandCheckout(plan: any) {
		if (this.premium_subscribed && !plan.premium_plan) {
			let message =
				'<div>' +
				'<p>Do you want to downgrade your package from ' +
				this.user.subscription.plan_name +
				' subscription?</p>' +
				'</div>';

			await this.ConfirmationModalService.confirm('Downgrade Package', message)
				.then((confirmed) => {
					if (confirmed) {
						localStorage.setItem('selected_plan', JSON.stringify(plan));
						this.route.navigate(['/checkout']);
					} else {
						// this.route.navigate(['/'])
					}
				})
				.catch(() => this.route.navigate(['/']));
		} else {
			if (
				plan.duration &&
				this.user.subscription && this.user.subscription.pricing_plan &&
				plan.duration.id === this.user.subscription.pricing_plan.id &&
				!this.user.subscription.cancelled
			) {
				if (plan.premium_plan) {
					let message =
						'<div>' +
						'<p>You are already subscribed to the Premium package, click the button below to return to the dashboard</p>' +
						'</div>';

					await this.AlertModalService.confirm('Already Subscribed', message)
						.then((confirmed) => {
							if (confirmed) {
								this.route.navigate(['/']);
							} else {
								// this.route.navigate(['/'])
							}
						})
						.catch(() => this.route.navigate(['/']));
				} else {
					this.toastr.warning('You have already subscribed to this plan');
				}
			} else {
				localStorage.setItem('selected_plan', JSON.stringify(plan));
				this.route.navigate(['/checkout']);
			}
		}
	}

	async setPlanDuration(duration: any) {
		this.plan_duration = duration;
		this.total_price = +this.premium_plan.price;

		if (this.premium_plan) {
			this.premium_plan.prices.forEach((price: any) => {
				if (price.duration === duration) {
					this.premium_plan.selected_plan = price;
				}
			});
			this.discount = ((this.total_price - this.premium_plan.prices[0].price) / this.total_price) * 100;
		}
	}

	async checkOut() {
		let selected_features: any = [];
		this.features.forEach((element: any) => {
			if (element.checked) selected_features.push(element.id);
		});
		if (selected_features.length > 0) {
			this.getPlans(selected_features);
		} else {
			this.toastr.warning('Please select one or more features so that we get best plans for you..');
		}
	}

	async premiumCheckOut() {
		this.premium_plan.prices.forEach((price: any) => {
			if (price.duration === this.plan_duration) {
				this.selected_plan = this.premium_plan;
				this.selected_plan.duration = price;
				localStorage.setItem('selected_plan', JSON.stringify(this.selected_plan));
				this.route.navigate(['/checkout']);
			}
		});
	}

	async selectFeature(index: any) {
		if (this.features[index].checked) {
			this.features[index].checked = false;
		} else {
			this.features[index].checked = true;
		}
	}

	async unSubscribe() {
		let message =
			'<div>' +
			'<p>Do you want to unsubscribe from ' +
			this.user.subscription.plan_name +
			' package?</p>' +
			'</div>';

		await this.ConfirmationModalService.confirm('Unsubscribe', message)
			.then((confirmed) => {
				if (confirmed) {
					//cancel-subscription

					let data = {
						subscr_id: this.user.subscription.id,
					};

					this.userService.cancelSubscription(data, this.token).subscribe(
						(response: any) => {
							if (response.status) {
								this.toastr.success(response.message);
								this.getUser();
								this.premium_subscribed = true;
								this.subscription_in_cancellation = true;
							} else {
								this.toastr.error(response.message);
							}
						},
						(error) => {
							console.log(error);
						}
					);
				} else {
					// this.route.navigate(['/'])
				}
			})
			.catch(() => this.route.navigate(['/']));
	}

	async reactivatePremium() {
		let message =
			'<div>' + '<p>Do you want to Reactivate  ' + this.user.subscription.plan_name + ' package?</p>' + '</div>';

		let data = {
			subscr_id: this.user.subscription.id,
		};

		this.userService.reactivateSubscription(data, this.token).subscribe(
			(response: any) => {
				if (response.status) {
					this.toastr.success(response.message);
					this.getUser();
					this.premium_subscribed = true;
					this.subscription_in_cancellation = false;
				} else {
					this.toastr.error(response.message);
				}
			},
			(error) => {
				console.log(error);
			}
		);
		//TODO:: should this be done for all subscriptions including premium
		this.premium_subscribed = true;
	}
}
