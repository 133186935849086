import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { EventEmitter } from '@angular/core';
import { Output, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCardComponent } from '../shared/add-card/add-card.component';
import { LoginPopupComponent } from '../shared/login-popup/login-popup.component';
import { ConfirmationModalService } from '../shared/confirmation-modal/confirmation-modal.service';
// import { throws } from 'node:assert';


@Component({
	selector: 'app-my-profile',
	templateUrl: './my-profile.component.html',
	styleUrls: ['./my-profile.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MyProfileComponent implements OnInit {
	userForm: FormGroup = this.formBuilder.group({
		name: ['', [Validators.required]],
		last_name: ['', [Validators.required]],
		email: ['', [Validators.required]],
		display_name: ['', [Validators.required]],
	});

	image: any;
	changePasswordForm: FormGroup = this.formBuilder.group({
		password: ['', [Validators.required]],
		new_password: ['', [Validators.required]],
	});
	loading = false;
	update_loading = false;
	changePasswordFormsubmitted: any = false;
	userFormsubmitted: any = false;
	// myRecaptcha = new FormControl(false);
	user: any;
	token: any;
	cards: any;
	@Output() profileHero: EventEmitter<File> = new EventEmitter<File>();

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		public cdref: ChangeDetectorRef,
		private toastr: ToastrService,
		private modalService: NgbModal,
		private ConfirmationModalService: ConfirmationModalService
	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		this.token = this.user.token;
		this.image = this.user.avatar;
	}

	ngOnInit(): void {
		$('.addAvatar').on('click', function () {
			$('#fileupload').click();
			return false;
		});
		this.getUser();
		this.getCards();

		this.changePasswordForm = this.formBuilder.group({
			password: ['', [Validators.required]],
			new_password: ['', [Validators.required]],
		});

		this.userForm = this.formBuilder.group({
			name: ['', [Validators.required]],
			last_name: ['', [Validators.required]],
			email: ['', [Validators.required]],
			display_name: ['', [Validators.required]],
		});
	}

	get cf() {
		return this.changePasswordForm.controls;
	}
	get uf() {
		return this.userForm.controls;
	}

	async getUser() {
		this.userService.getAuthUser(this.token).subscribe((data: any) => {
			//console.log(data);
			if (data && data.result) {
				this.user = data.result ? data.result : null;
				this.userForm.controls['name'].setValue(this.user.name);
				this.userForm.controls['last_name'].setValue(this.user.last_name);
				this.userForm.controls['email'].setValue(this.user.email);
				this.userForm.controls['display_name'].setValue(this.user.display_name);
			}
		});
	}

	async getCards() {
		this.userService.getCards(this.token).subscribe((data: any) => {
			//console.log(data);
			if (data && data.result) {
				this.cards = data.result ? data.result : null;
			}
		});
	}

	onFileChange(event: any) {
		//console.log(event);
		let reader = new FileReader();

		if (event.target.files && event.target.files.length) {
			const [file] = event.target.files;
			reader.readAsDataURL(file);
			reader.onload = () => {
				this.image = reader.result;
				this.cdref.markForCheck();

				let formData = new FormData();
				formData.append('file', file);
				//console.log(this.image);
				this.userService.updateAvatar(formData, this.token).subscribe(
					(response: any) => {
						this.user.avatar = response.user.avatar;
						this.user.avatar_url = response.user.avatar_url;

						localStorage.setItem('trc_user', JSON.stringify(this.user));
						//console.log('response', response);
					},
					(error) => {
						//console.log(error);
					}
				);
			};
		}
	}

	updateProfile() {
		this.userFormsubmitted = true;
		if (this.userForm.invalid) {
			//console.log('error');
			return;
		}

		let userData = {
			name: this.userForm.value.name,
			last_name: this.userForm.value.last_name,
			display_name: this.userForm.value.display_name,
			email: this.userForm.value.email,
		};
		this.update_loading = true;

		this.userService.updateProfile(userData, this.token).subscribe(
			(response: any) => {
				//console.log('response', response);
				if (response.status) {
					this.toastr.success(response.message);
					this.update_loading = false;
				} else {
					this.toastr.error(response.message);
					this.update_loading = false;
				}
			},
			(error) => {
				this.update_loading = false;
				//console.log(error);
			}
		);
		
	}

	updatePassword() {
		this.changePasswordFormsubmitted = true;
		if (this.changePasswordForm.invalid) {
			//console.log('error');
			return;
		}

		let userData = {
			password: this.changePasswordForm.value.password,
			new_password: this.changePasswordForm.value.new_password,
		};

		this.userService.updatePassword(userData, this.token).subscribe(
			(response: any) => {
				if (response.status) {
					this.changePasswordForm.reset();
					this.toastr.success(response.message);
					this.changePasswordFormsubmitted = false;
				} else {
					this.toastr.error(response.message);
				}
			},
			(error) => {
				//console.log(error);
			}
		);
	}

	manageSubscription(){
		if(this.user && this.user.payment_method != 'ios') {
			this.router.navigate(['/subscribe-plans']);
		} else if(this.user && !this.user.payment_method) {
			this.router.navigate(['/subscribe-plans']);
		} else {
			this.manageItunesSubscription();
		}
	}

	async manageItunesSubscription(){
		let btnCancelText = 'Cancle';
		let btnOkText = 'Go to AppStore';
		let message =
			'<div>' +
			'<p>You can manage iTunes Subscription through App Store.<strong>'+
			
			'</strong>?</p>' +
			'</div>';

		await this.ConfirmationModalService.confirm('Manage iTunes Subscription', message, btnOkText, btnCancelText)
			.then((confirmed) => {
				if (confirmed) {
						window.open('https://finance-app.itunes.apple.com/account/subscriptions');
				}
			})
			.catch(() =>
				console.log(
					'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
				)
			);
	}

	async confirmCancelSubscription() {

		if(this.user && this.user.payment_method == 'ios') {
			this.manageItunesSubscription();
			return;
		}	

		let btnCancelText = 'No';
		let btnOkText = 'Yes';
		let message =
			'<div>' +
			'<p>Do you want to cancel your subscription to <strong>' +
			this.user.subscription.plan_name +
			'</strong>?</p>' +
			'</div>';

		await this.ConfirmationModalService.confirm('Cancel Subscription', message, btnOkText, btnCancelText)
			.then((confirmed) => {
				if (confirmed) {
						this.cancelSubscription();
				}
			})
			.catch(() =>
				console.log(
					'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
				)
			);
	}

	async cancelSubscription() {
		let data = {
			subscr_id: this.user.subscription.id,
		};

		this.userService.cancelSubscription(data, this.token).subscribe(
			(response: any) => {
				if (response.status) {
					this.toastr.success(response.message);
					this.getUser();
					location.reload();
				} else {
					this.toastr.error(response.message);
				}
			},
			(error) => {
				//console.log(error);
			}
		);
	}

	async addCard() {
		let dialogRef = this.modalService.open(AddCardComponent, { size: 'custom', windowClass: 'login-modal' });

		dialogRef.result.then(
			(data: any) => {
				if (data) this.getCards();
			},
			(reason: any) => {
				if (reason) this.getCards();
			}
		);
	}



	async removeUserCard(card_token: any) {
		let data = {
			card_token: card_token,
		};
		this.userService.removeCard(data, this.token).subscribe(
			(response: any) => {
				if (response.status) {
					this.toastr.success(response.message);
					this.getCards();
				} else {
					this.toastr.error(response.message);
				}
			},
			(error) => {
				//console.log(error);
			}
		);
	}

	async changeUserPrimaryCard(card_token: any) {
		let data = {
			card_token: card_token,
		};
		this.userService.changeUserPrimaryCard(data, this.token).subscribe(
			(response: any) => {
				if (response.status) {
					this.toastr.success(response.message);
					this.getCards();
				} else {
					this.toastr.error(response.message);
				}
			},
			(error) => {
				//console.log(error);
			}
		);
	}

	async login() {
		this.modalService.open(LoginPopupComponent, { size: 'custom', windowClass: 'login-modal' });
	}

	async removeCard(card: any) {
		let btnCancelText = 'No';
		let btnOkText = 'Yes';
		let message = '<div>' + '<p>Are you sure you wish to remove this card?</p>' + '</div>';

		await this.ConfirmationModalService.confirm('Remove Card', message, btnOkText, btnCancelText)
			.then((confirmed) => {
				//console.log('User confirmed:', confirmed);
				if (confirmed) this.removeUserCard(card.token);
			})
			.catch(() =>
				console.log(
					'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
				)
			);
	}

	async changePrimaryCard(card: any) {
		let btnCancelText = 'No';
		let btnOkText = 'Yes';
		let message =
			'<div>' +
			'<p>Are you sure you wish to set ' +
			card.display_number +
			' as your Primary Card?</p>' +
			'</div>';

		await this.ConfirmationModalService.confirm('Make Card Primary', message, btnOkText, btnCancelText)
			.then((confirmed) => {
				if (confirmed) this.changeUserPrimaryCard(card.token);
			})
			.catch(() =>
				console.log(
					'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
				)
			);
	}

	async changePassword() {
		let data = {
			email: this.user.email,
		};
		this.userService.sendResetPasswordLink(data).subscribe(
			(response: any) => {
				this.toastr.success(response.message);
			},
			(error) => {
				this.toastr.error(error.message);
			}
		);
	}

	async confirmChangepassword() {
		let btnCancelText = 'No';
		let btnOkText = 'Yes';
		let message =
			'<div>' +
			'<p>A password reset link will be sent to your email. Would you like to continue?</p>' 
			'</div>';

		await this.ConfirmationModalService.confirm('Change Password', message, btnOkText, btnCancelText)
			.then((confirmed) => {
				if (confirmed) this.changePassword();
			})
			.catch(() =>
				console.log(
					'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
				)
			);
	}

	navigateAppleStore(){
		window.open('https://apps.apple.com/au/app/racing-central/id1434221878', '_blank');

	}

	navigateGoogleStore(){
		window.open('https://play.google.com/store/apps/details?id=io.ionic.raceclub&pli=1', '_blank');

	}
}
