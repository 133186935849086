// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	serverUrl: 'https://membersv2.theraceclub.com.au/api/',
	//serverUrl: 'http://127.0.0.1:8000/api/',

	
	// serverUrl: 'https://membersv2.theraceclub.com.au/api/',
	storage_serverUrl: 'https://membersv2.theraceclub.com.au/storage/',
	//serverUrl: 'https://trc2backend.pinkpanda.com.au/api/',
	// storage_serverUrl: 'https://trc2backend.pinkpanda.com.au/storage/',
	publicUrl: 'https://theraceclub.com.au/',
	defaultLanguage: 'en-US',
	supportedLanguages: ['en-US', 'fr-FR'],
	googleCaptchPublicKey:'6Lec5hwbAAAAAC_g8R4aAwa6DsRuzkd8DRlRDE_X',
	type: 'dev'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
