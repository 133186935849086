<div class="modal_wrapper modal-body blackbook_modal blackbook">
	<h2 class="heading text-center">Add to blackbook</h2>

	<div class="close_icon" (click)="close()">
		<svg xmlns="http://www.w3.org/2000/svg" width="11.689" height="11.689" viewBox="0 0 11.689 11.689">
			<g class="Group_251" data-name="Group 251" transform="translate(84.329 -65.195) rotate(135)">
				<g class="Group_249" data-name="Group 249" transform="translate(98.464 -2)">
					<path
						class="Path_1"
						data-name="Path 1"
						d="M24.331,20.516V5.986"
						transform="translate(-17.066 -5.986)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
					<path
						class="Path_81"
						data-name="Path 81"
						d="M0,14.53V0"
						transform="translate(14.53 7.265) rotate(90)"
						fill="none"
						stroke="#fff"
						stroke-miterlimit="10"
						stroke-width="2"
					/>
				</g>
			</g>
		</svg>
	</div>
	<!-- end close_icon -->

	<ul>
		<li class="panel grey" *ngFor="let row of blackbook_all_entries">
			<div class="row heading_wrap">
				<div class="col-md-8 col-sm-7 col_left align-self-center">
					<div class="small_img_wrap">
						<img
							class="img-fluid jockey-img"
							*ngIf="row?.type == 'horse'"
							src="{{ row?.silks }}"
							(error)="onImgError($event)"
							alt="TRC"
						/>

						<svg
							*ngIf="row?.type == 'jockey'"
							xmlns="http://www.w3.org/2000/svg"
							width="33"
							height="33"
							viewBox="0 0 33 33"
						>
							<g id="Group_9058" data-name="Group 9058" transform="translate(-620 -1044)">
								<rect
									id="Rectangle_1280"
									data-name="Rectangle 1280"
									width="33"
									height="33"
									rx="16.5"
									transform="translate(620 1044)"
									fill="#fff"
								/>
								<g id="Group_9045" data-name="Group 9045" transform="translate(445.402 2674.113)">
									<path
										id="Path_8299"
										data-name="Path 8299"
										d="M204.619-1412.462s-.864,1.206-5.049,1.34a11.753,11.753,0,0,0-7.323,2.5,6.779,6.779,0,0,1-3.73.715l.031.2a.452.452,0,0,0,.45.383,12.34,12.34,0,0,0,2.142-.173c.412.761,2.341,3.967,5.627,3.967.094,0,.19,0,.287-.009a10.073,10.073,0,0,0,1.55,2.588,4.933,4.933,0,0,1-.789.352c-.2.068-.165.373.046.45a2.069,2.069,0,0,0,1.9-.075c.479-.271.587-1.067.592-1.692,0-.236-.291-.369-.421-.191a3.88,3.88,0,0,1-.663.7c-2.723-3.208-2.472-7.952-2.4-8.8l.062-.017a24.94,24.94,0,0,1,6.322-.4c4.594.089,5.164-.759,5.164-.759A27.592,27.592,0,0,1,204.619-1412.462Zm-7.863,8.124c-2.6-.019-4.289-2.493-4.806-3.368a4.729,4.729,0,0,0,1.389-.78,8.557,8.557,0,0,1,2.705-1.459A15.531,15.531,0,0,0,196.757-1404.338Z"
										transform="translate(-6.492 -204.124)"
									/>
									<path
										id="Path_8300"
										data-name="Path 8300"
										d="M185.689-1613.012l.044-.03a8.451,8.451,0,0,1-.385-1.7,14.156,14.156,0,0,1,1.266-7.232,2.512,2.512,0,0,0,.482.006c-1.819,4.222-1.472,7.108-.975,8.654a11.09,11.09,0,0,1,6.76-2.2c.134,0,.263-.01.39-.016a10.832,10.832,0,0,0-.749-2.359,7.92,7.92,0,0,0-4.447-4.29,2.178,2.178,0,0,0,.515-.265,8.529,8.529,0,0,1,4.346,4.385,11.321,11.321,0,0,1,.788,2.5c3.383-.246,4.115-1.206,4.115-1.206s-1.439-6.6-9-6.047a.192.192,0,0,0,0-.022c-.06-.281-.723-.378-1.481-.217s-1.324.52-1.264.8c0,0,0,.009,0,.014-5.666,2.226-4.067,9.939-4.067,9.939A6.554,6.554,0,0,0,185.689-1613.012Z"
										transform="translate(0 0)"
									/>
								</g>
							</g>
						</svg>

						<svg
							*ngIf="row?.type == 'trainer'"
							xmlns="http://www.w3.org/2000/svg"
							width="33"
							height="33"
							viewBox="0 0 33 33"
						>
							<g id="Group_9054" data-name="Group 9054" transform="translate(-620 -912)">
								<rect
									id="Rectangle_1279"
									data-name="Rectangle 1279"
									width="33"
									height="33"
									rx="16.5"
									transform="translate(620 912)"
									fill="#fff"
								/>
								<g id="Group_9046" data-name="Group 9046" transform="translate(-1607.134 2249.674)">
									<path
										id="Path_8301"
										data-name="Path 8301"
										d="M2289.481-1326.879a1.181,1.181,0,0,1-.646-1.008,1.27,1.27,0,0,1,.04-.389c.131-.51.738-2.969.273-3.135-.414-.148-1.677,1.4-2.172,2.037a2.214,2.214,0,0,0-.257.43c-.636,1.363-5.888,2.75-8.775.575a6.014,6.014,0,0,0-4.359-1.451.867.867,0,0,0-.713,1.145,14.05,14.05,0,0,0,1.548,2.192,2.286,2.286,0,0,0,1.716,1.043c.065,1.173.418,7.772.346,11.806a2.4,2.4,0,0,0-1.165,1.3,1.229,1.229,0,0,0-.114.518v1.638h3.09v-1.638a1.239,1.239,0,0,0-.114-.518,2.545,2.545,0,0,0-1.08-1.268c.073-4.012-.272-10.535-.344-11.827.9-.052,1.956-.342,3.191-.416,2.486-.148,2.894.742,3.747,1.534s4.489-.124,5.417-.6S2290.3-1326.44,2289.481-1326.879Zm-11.756,14.555a1.226,1.226,0,0,1,.161.611v1.048h-2.278v-1.048a1.224,1.224,0,0,1,.162-.611,1.755,1.755,0,0,1,.7-.726.751.751,0,0,1,.28-.09.807.807,0,0,1,.337.121A1.83,1.83,0,0,1,2277.725-1312.324Z"
										transform="translate(-37.058)"
									/>
									<rect
										id="Rectangle_1275"
										data-name="Rectangle 1275"
										width="2.357"
										height="0.209"
										transform="translate(2238.511 -1310.135)"
									/>
									<path
										id="Path_8302"
										data-name="Path 8302"
										d="M2238.842-1235.8a2.529,2.529,0,0,1-1.682-1.115c-.318-.443-.59-.791-.809-1.071-.183-.233-.336-.429-.46-.609-.814,1.774-2.6,6.507-.681,10.632,0,0,.61,1.774,3.976,2.886C2239.2-1228.908,2238.922-1234.326,2238.842-1235.8Z"
										transform="translate(0 -89.424)"
									/>
									<path
										id="Path_8303"
										data-name="Path 8303"
										d="M2393.421-1171.885a3.088,3.088,0,0,0-2.688-1.119c-.16,0-.33.005-.507.016a16.812,16.812,0,0,0-1.806.232c-.4.066-.777.13-1.14.164.089,1.635.371,7.191.339,11.017a18.557,18.557,0,0,0,2.073.371,3.214,3.214,0,0,0,3-3.35c.119-2.621-.072-5.7.98-7.074C2393.587-1171.714,2393.5-1171.8,2393.421-1171.885Z"
										transform="translate(-147.322 -152.612)"
									/>
								</g>
							</g>
						</svg>
					</div>
					<div class="name_wrap">
						<h3 class="player_name">{{ row?.name }}</h3>
					</div>
				</div>

				<div class="col-md-4 col-sm-5 text-right col_right align-self-center">
					<span class="btn bubble_chat " *ngIf="row?.notes.length > 0">{{ row?.notes.length }}</span>
					<a
						class="btn black"
						(click)="removeBlackBookConfirm(row)"
						*ngIf="row?.type == 'horse' && !horse_added_now"
						>Remove</a
					>
					<a
						class="btn black"
						(click)="removeBlackBookConfirm(row)"
						*ngIf="row?.type == 'jockey' && !jockey_added_now"
						>Remove</a
					>
					<a
						class="btn black"
						(click)="removeBlackBookConfirm(row)"
						*ngIf="row?.type == 'trainer' && !trainer_added_now"
						>Remove</a
					>

					<a class="btn blue" *ngIf="row?.type == 'horse' && horse_added_now">Added</a>
					<a class="btn blue" *ngIf="row?.type == 'jockey' && jockey_added_now">Added</a>
					<a class="btn blue" *ngIf="row?.type == 'trainer' && trainer_added_now">Added</a>
				</div>
			</div>
			<!-- end heading_wrap -->

			<div class="add_comments mt-3 bubble_chat white_bg_grey">
				<input *ngIf="row?.type == 'horse'" type="text" placeholder="Add Comment..." [(ngModel)]="horse_note" class="" />
				<input
					*ngIf="row?.type == 'jockey'"
					type="text"
					placeholder="Add Comment..."
					[(ngModel)]="jockey_note"
				/>
				<input
					*ngIf="row?.type == 'trainer'"
					type="text"
					placeholder="Add Comment..."
					[(ngModel)]="trainer_note"
				/>

				<a class="btn" (click)="saveBlackBookNote(row?.type, row.id)">Add</a>
			</div>
			<!-- end add_comments -->
		</li>
		<!-- end panel grey -->

		<li class="panel grey" *ngIf="!horse_added && !horse_added_now">
			<div class="row heading_wrap">
				<div class="col-md-8 col-sm-7 col_left align-self-center">
					<div class="small_img_wrap">
						<img
							class="img-fluid jockey-img"
							src="{{ fromParent?.horse.silks }}"
							(error)="onImgError($event)"
							alt="TRC"
						/>
					</div>
					<div class="name_wrap">
						<h3 class="player_name">{{ fromParent?.horse.horse_name }}</h3>
					</div>
				</div>

				<div class="col-md-4 col-sm-5 text-right col_right align-self-center" (click)="saveBlackBook('horse')">
					<a class="btn large">Add</a>
				</div>
			</div>
			<!-- end heading_wrap -->
		</li>
		<!-- end panel grey -->

		<li class="panel grey" *ngIf="!jockey_added && !jockey_added_now && fromParent.horse.jockey">
			<div class="row heading_wrap">
				<div class="col-md-8 col-sm-7 col_left align-self-center">
					<div class="small_img_wrap">
						<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
							<g id="Group_9058" data-name="Group 9058" transform="translate(-620 -1044)">
								<rect
									id="Rectangle_1280"
									data-name="Rectangle 1280"
									width="33"
									height="33"
									rx="16.5"
									transform="translate(620 1044)"
									fill="#fff"
								/>
								<g id="Group_9045" data-name="Group 9045" transform="translate(445.402 2674.113)">
									<path
										id="Path_8299"
										data-name="Path 8299"
										d="M204.619-1412.462s-.864,1.206-5.049,1.34a11.753,11.753,0,0,0-7.323,2.5,6.779,6.779,0,0,1-3.73.715l.031.2a.452.452,0,0,0,.45.383,12.34,12.34,0,0,0,2.142-.173c.412.761,2.341,3.967,5.627,3.967.094,0,.19,0,.287-.009a10.073,10.073,0,0,0,1.55,2.588,4.933,4.933,0,0,1-.789.352c-.2.068-.165.373.046.45a2.069,2.069,0,0,0,1.9-.075c.479-.271.587-1.067.592-1.692,0-.236-.291-.369-.421-.191a3.88,3.88,0,0,1-.663.7c-2.723-3.208-2.472-7.952-2.4-8.8l.062-.017a24.94,24.94,0,0,1,6.322-.4c4.594.089,5.164-.759,5.164-.759A27.592,27.592,0,0,1,204.619-1412.462Zm-7.863,8.124c-2.6-.019-4.289-2.493-4.806-3.368a4.729,4.729,0,0,0,1.389-.78,8.557,8.557,0,0,1,2.705-1.459A15.531,15.531,0,0,0,196.757-1404.338Z"
										transform="translate(-6.492 -204.124)"
									/>
									<path
										id="Path_8300"
										data-name="Path 8300"
										d="M185.689-1613.012l.044-.03a8.451,8.451,0,0,1-.385-1.7,14.156,14.156,0,0,1,1.266-7.232,2.512,2.512,0,0,0,.482.006c-1.819,4.222-1.472,7.108-.975,8.654a11.09,11.09,0,0,1,6.76-2.2c.134,0,.263-.01.39-.016a10.832,10.832,0,0,0-.749-2.359,7.92,7.92,0,0,0-4.447-4.29,2.178,2.178,0,0,0,.515-.265,8.529,8.529,0,0,1,4.346,4.385,11.321,11.321,0,0,1,.788,2.5c3.383-.246,4.115-1.206,4.115-1.206s-1.439-6.6-9-6.047a.192.192,0,0,0,0-.022c-.06-.281-.723-.378-1.481-.217s-1.324.52-1.264.8c0,0,0,.009,0,.014-5.666,2.226-4.067,9.939-4.067,9.939A6.554,6.554,0,0,0,185.689-1613.012Z"
										transform="translate(0 0)"
									/>
								</g>
							</g>
						</svg>
					</div>
					<div class="name_wrap">
						<h3 class="player_name">{{ fromParent.horse.jockey }}</h3>
					</div>
				</div>

				<div class="col-md-4 col-sm-5 text-right col_right align-self-center" (click)="saveBlackBook('jockey')">
					<a class="btn large">Add</a>
				</div>
			</div>
			<!-- end heading_wrap -->
		</li>
		<!-- end panel grey -->

		<li class="panel grey" *ngIf="!trainer_added && !trainer_added_now && fromParent.horse.trainer">
			<div class="row heading_wrap">
				<div class="col-md-8 col-sm-7 col_left align-self-center">
					<div class="small_img_wrap">
						<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
							<g id="Group_9054" data-name="Group 9054" transform="translate(-620 -912)">
								<rect
									id="Rectangle_1279"
									data-name="Rectangle 1279"
									width="33"
									height="33"
									rx="16.5"
									transform="translate(620 912)"
									fill="#fff"
								/>
								<g id="Group_9046" data-name="Group 9046" transform="translate(-1607.134 2249.674)">
									<path
										id="Path_8301"
										data-name="Path 8301"
										d="M2289.481-1326.879a1.181,1.181,0,0,1-.646-1.008,1.27,1.27,0,0,1,.04-.389c.131-.51.738-2.969.273-3.135-.414-.148-1.677,1.4-2.172,2.037a2.214,2.214,0,0,0-.257.43c-.636,1.363-5.888,2.75-8.775.575a6.014,6.014,0,0,0-4.359-1.451.867.867,0,0,0-.713,1.145,14.05,14.05,0,0,0,1.548,2.192,2.286,2.286,0,0,0,1.716,1.043c.065,1.173.418,7.772.346,11.806a2.4,2.4,0,0,0-1.165,1.3,1.229,1.229,0,0,0-.114.518v1.638h3.09v-1.638a1.239,1.239,0,0,0-.114-.518,2.545,2.545,0,0,0-1.08-1.268c.073-4.012-.272-10.535-.344-11.827.9-.052,1.956-.342,3.191-.416,2.486-.148,2.894.742,3.747,1.534s4.489-.124,5.417-.6S2290.3-1326.44,2289.481-1326.879Zm-11.756,14.555a1.226,1.226,0,0,1,.161.611v1.048h-2.278v-1.048a1.224,1.224,0,0,1,.162-.611,1.755,1.755,0,0,1,.7-.726.751.751,0,0,1,.28-.09.807.807,0,0,1,.337.121A1.83,1.83,0,0,1,2277.725-1312.324Z"
										transform="translate(-37.058)"
									/>
									<rect
										id="Rectangle_1275"
										data-name="Rectangle 1275"
										width="2.357"
										height="0.209"
										transform="translate(2238.511 -1310.135)"
									/>
									<path
										id="Path_8302"
										data-name="Path 8302"
										d="M2238.842-1235.8a2.529,2.529,0,0,1-1.682-1.115c-.318-.443-.59-.791-.809-1.071-.183-.233-.336-.429-.46-.609-.814,1.774-2.6,6.507-.681,10.632,0,0,.61,1.774,3.976,2.886C2239.2-1228.908,2238.922-1234.326,2238.842-1235.8Z"
										transform="translate(0 -89.424)"
									/>
									<path
										id="Path_8303"
										data-name="Path 8303"
										d="M2393.421-1171.885a3.088,3.088,0,0,0-2.688-1.119c-.16,0-.33.005-.507.016a16.812,16.812,0,0,0-1.806.232c-.4.066-.777.13-1.14.164.089,1.635.371,7.191.339,11.017a18.557,18.557,0,0,0,2.073.371,3.214,3.214,0,0,0,3-3.35c.119-2.621-.072-5.7.98-7.074C2393.587-1171.714,2393.5-1171.8,2393.421-1171.885Z"
										transform="translate(-147.322 -152.612)"
									/>
								</g>
							</g>
						</svg>
					</div>
					<div class="name_wrap">
						<h3 class="player_name">{{ fromParent.horse.trainer }}</h3>
					</div>
				</div>

				<div class="col-md-4 col-sm-5 text-right col_right align-self-center" (click)="saveBlackBook('trainer')">
					<a class="btn large">Add</a>
				</div>
			</div>
			<!-- end heading_wrap -->
		</li>
		<!-- end panel grey -->

		<li class="panel grey" *ngIf="!horse_added && horse_added_now">
			<div class="row heading_wrap">
				<div class="col-md-8 col-sm-7 col_left align-self-center">
					<div class="small_img_wrap">
						<img
							class="img-fluid jockey-img"
							src="{{ fromParent?.horse.silks }}"
							(error)="onImgError($event)"
							alt="TRC"
						/>
					</div>
					<div class="name_wrap">
						<h3 class="player_name">{{ fromParent.horse.horse_name }}</h3>
					</div>
				</div>

				<div class="col-md-4 col-sm-5 text-right col_right align-self-center">
					<a class="btn blue">Added</a>
				</div>
			</div>
			<!-- end heading_wrap -->

			<div class="add_comments mt-3">
				<input type="text" placeholder="Add Comment..." [(ngModel)]="horse_note" />
				<a class="btn" (click)="addBlackBookNote('horse')">Add</a>
			</div>
			<!-- end add_comments -->
		</li>
		<!-- end panel grey -->

		<li class="panel grey" *ngIf="!jockey_added && jockey_added_now && fromParent.horse.jockey">
			<div class="row heading_wrap">
				<div class="col-md-8 col-sm-7 col_left align-self-center">
					<div class="small_img_wrap">
						<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
							<g id="Group_9058" data-name="Group 9058" transform="translate(-620 -1044)">
								<rect
									id="Rectangle_1280"
									data-name="Rectangle 1280"
									width="33"
									height="33"
									rx="16.5"
									transform="translate(620 1044)"
									fill="#fff"
								/>
								<g id="Group_9045" data-name="Group 9045" transform="translate(445.402 2674.113)">
									<path
										id="Path_8299"
										data-name="Path 8299"
										d="M204.619-1412.462s-.864,1.206-5.049,1.34a11.753,11.753,0,0,0-7.323,2.5,6.779,6.779,0,0,1-3.73.715l.031.2a.452.452,0,0,0,.45.383,12.34,12.34,0,0,0,2.142-.173c.412.761,2.341,3.967,5.627,3.967.094,0,.19,0,.287-.009a10.073,10.073,0,0,0,1.55,2.588,4.933,4.933,0,0,1-.789.352c-.2.068-.165.373.046.45a2.069,2.069,0,0,0,1.9-.075c.479-.271.587-1.067.592-1.692,0-.236-.291-.369-.421-.191a3.88,3.88,0,0,1-.663.7c-2.723-3.208-2.472-7.952-2.4-8.8l.062-.017a24.94,24.94,0,0,1,6.322-.4c4.594.089,5.164-.759,5.164-.759A27.592,27.592,0,0,1,204.619-1412.462Zm-7.863,8.124c-2.6-.019-4.289-2.493-4.806-3.368a4.729,4.729,0,0,0,1.389-.78,8.557,8.557,0,0,1,2.705-1.459A15.531,15.531,0,0,0,196.757-1404.338Z"
										transform="translate(-6.492 -204.124)"
									/>
									<path
										id="Path_8300"
										data-name="Path 8300"
										d="M185.689-1613.012l.044-.03a8.451,8.451,0,0,1-.385-1.7,14.156,14.156,0,0,1,1.266-7.232,2.512,2.512,0,0,0,.482.006c-1.819,4.222-1.472,7.108-.975,8.654a11.09,11.09,0,0,1,6.76-2.2c.134,0,.263-.01.39-.016a10.832,10.832,0,0,0-.749-2.359,7.92,7.92,0,0,0-4.447-4.29,2.178,2.178,0,0,0,.515-.265,8.529,8.529,0,0,1,4.346,4.385,11.321,11.321,0,0,1,.788,2.5c3.383-.246,4.115-1.206,4.115-1.206s-1.439-6.6-9-6.047a.192.192,0,0,0,0-.022c-.06-.281-.723-.378-1.481-.217s-1.324.52-1.264.8c0,0,0,.009,0,.014-5.666,2.226-4.067,9.939-4.067,9.939A6.554,6.554,0,0,0,185.689-1613.012Z"
										transform="translate(0 0)"
									/>
								</g>
							</g>
						</svg>
					</div>
					<div class="name_wrap">
						<h3 class="player_name">{{ fromParent.horse.jockey }}</h3>
					</div>
				</div>

				<div class="col-md-4 col-sm-5 text-right col_right align-self-center">
					<a class="btn blue">Added</a>
				</div>
			</div>
			<!-- end heading_wrap -->

			<div class="add_comments mt-3">
				<input type="text" placeholder="Add Comment..." [(ngModel)]="jockey_note"/>
				<a class="btn" (click)="addBlackBookNote('jockey')">Add</a>
			</div>
			<!-- end add_comments -->
		</li>
		<!-- end panel grey -->

		<li class="panel grey" *ngIf="!trainer_added && trainer_added_now && fromParent.horse.trainer">
			<div class="row heading_wrap">
				<div class="col-md-8 col-sm-7 col_left align-self-center">
					<div class="small_img_wrap">
						<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
							<g id="Group_9054" data-name="Group 9054" transform="translate(-620 -912)">
								<rect
									id="Rectangle_1279"
									data-name="Rectangle 1279"
									width="33"
									height="33"
									rx="16.5"
									transform="translate(620 912)"
									fill="#fff"
								/>
								<g id="Group_9046" data-name="Group 9046" transform="translate(-1607.134 2249.674)">
									<path
										id="Path_8301"
										data-name="Path 8301"
										d="M2289.481-1326.879a1.181,1.181,0,0,1-.646-1.008,1.27,1.27,0,0,1,.04-.389c.131-.51.738-2.969.273-3.135-.414-.148-1.677,1.4-2.172,2.037a2.214,2.214,0,0,0-.257.43c-.636,1.363-5.888,2.75-8.775.575a6.014,6.014,0,0,0-4.359-1.451.867.867,0,0,0-.713,1.145,14.05,14.05,0,0,0,1.548,2.192,2.286,2.286,0,0,0,1.716,1.043c.065,1.173.418,7.772.346,11.806a2.4,2.4,0,0,0-1.165,1.3,1.229,1.229,0,0,0-.114.518v1.638h3.09v-1.638a1.239,1.239,0,0,0-.114-.518,2.545,2.545,0,0,0-1.08-1.268c.073-4.012-.272-10.535-.344-11.827.9-.052,1.956-.342,3.191-.416,2.486-.148,2.894.742,3.747,1.534s4.489-.124,5.417-.6S2290.3-1326.44,2289.481-1326.879Zm-11.756,14.555a1.226,1.226,0,0,1,.161.611v1.048h-2.278v-1.048a1.224,1.224,0,0,1,.162-.611,1.755,1.755,0,0,1,.7-.726.751.751,0,0,1,.28-.09.807.807,0,0,1,.337.121A1.83,1.83,0,0,1,2277.725-1312.324Z"
										transform="translate(-37.058)"
									/>
									<rect
										id="Rectangle_1275"
										data-name="Rectangle 1275"
										width="2.357"
										height="0.209"
										transform="translate(2238.511 -1310.135)"
									/>
									<path
										id="Path_8302"
										data-name="Path 8302"
										d="M2238.842-1235.8a2.529,2.529,0,0,1-1.682-1.115c-.318-.443-.59-.791-.809-1.071-.183-.233-.336-.429-.46-.609-.814,1.774-2.6,6.507-.681,10.632,0,0,.61,1.774,3.976,2.886C2239.2-1228.908,2238.922-1234.326,2238.842-1235.8Z"
										transform="translate(0 -89.424)"
									/>
									<path
										id="Path_8303"
										data-name="Path 8303"
										d="M2393.421-1171.885a3.088,3.088,0,0,0-2.688-1.119c-.16,0-.33.005-.507.016a16.812,16.812,0,0,0-1.806.232c-.4.066-.777.13-1.14.164.089,1.635.371,7.191.339,11.017a18.557,18.557,0,0,0,2.073.371,3.214,3.214,0,0,0,3-3.35c.119-2.621-.072-5.7.98-7.074C2393.587-1171.714,2393.5-1171.8,2393.421-1171.885Z"
										transform="translate(-147.322 -152.612)"
									/>
								</g>
							</g>
						</svg>
					</div>
					<div class="name_wrap">
						<h3 class="player_name">{{ fromParent.horse.trainer }}</h3>
					</div>
				</div>

				<div class="col-md-4 col-sm-5 text-right col_right align-self-center">
					<a class="btn blue">Added</a>
				</div>
			</div>
			<!-- end heading_wrap -->

			<div class="add_comments mt-3">
				<input type="text" placeholder="Add Comment..." [(ngModel)]="trainer_note" />
				<a class="btn" (click)="addBlackBookNote('trainer')">Add</a>
			</div>
			<!-- end add_comments -->
		</li>
		<!-- end panel grey -->
	</ul>

	<div class="bottom">
		<!-- <button class="btn blue view_blackbook mr-3">Save</button> -->
		<button class="btn black view_blackbook" (click)="viewBlackbook()">View blackbook</button>
	</div>
	<!-- end bottom -->
</div>
<!-- end inner -->
