import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { FormGuideService } from '../services/form-guide.service';
import { ResourcesService } from '../services/resources.service';
import { Router } from '@angular/router';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';
import { TweenLite, Cubic } from 'gsap';
import * as moment from 'moment';

@Component({
	selector: 'app-formguide-archive',
	templateUrl: './formguide-archive.component.html',
	styleUrls: ['./formguide-archive.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [NgbPopoverConfig], // add NgbPopoverConfig to the component providers
})
export class FormguideArchiveComponent implements OnInit {
	screenWidth:any;
	LogedIn = true;
	race_events: any;
	event_races: any;
	selectedRaceEvent: any;
	selectedrace: any;
	form_guide_horses: any;
	activeTab: any;
	loading: boolean = true;
	today: any;
	tomorrow: any;
	dayAfterTomorrow: any;
	twodaysAfterTomorrow: any;
	threedaysAfterTomorrow: any;
	track:any;
	yesterday: any;
	resources: any;
	previews: any;
	mobileOptionDay: any = 1;
	time_now = moment.utc();
	loadingResources: boolean = true;
	default_tab:any=1;
	max_number_of_races:any=10;
	th_array = Array(this.max_number_of_races);
	th_width = 90/this.max_number_of_races;


	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.screenWidth = window.innerWidth;
	}

	constructor(
		private FormGuideService: FormGuideService,
		private ResourcesService: ResourcesService,
		private router: Router,
		config: NgbPopoverConfig
	) {
		config.placement = 'right';
		config.triggers = 'hover';
		this.getScreenSize();

		$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
			var target = $(e.target).attr("href") // activated tab
			alert(target);
		});
	}

	setTab(tabNo : any) {
		this.default_tab = tabNo;
		localStorage.setItem('tab',this.default_tab);
	}

	applyFilter(){
		this.default_tab = this.mobileOptionDay;
		localStorage.setItem('tab',this.mobileOptionDay);
	}

	ngOnInit() {
		this.loading = true;
		this.default_tab = localStorage.getItem('tab');
		this.mobileOptionDay = this.default_tab;

		console.log(this.default_tab)
		if(this.default_tab == 'undefined' || this.default_tab == null) {
			this.default_tab = 1;
			this.mobileOptionDay = this.default_tab;
		}
		this.getRaceEvents();
		this.getLatestResources();
		this.getTodaysPreviews();
	}

	async toggleAccordion(event: any) {
		var $this = $(event.target),
			$content = $(event.target).closest('.collapsable-section').find('.collapsed-content');

		if ($this.closest('.collapsable-section').hasClass('closed')) {
			TweenLite.set($content, {
				height: 'auto',
				opacity: 1,
			});
			TweenLite.from($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
			$this.closest('.collapsable-section').removeClass('closed');
			$this.closest('.collapsable-section').find('.view_text').text('hide races');
			if($this.hasClass("toggleBtn")) {
				$this.addClass('expand');
			} else {
				$this.find('.toggleBtn').addClass('expand');
			}
		} else {
			$this.closest('.collapsable-section').addClass('closed');
			$this.closest('.collapsable-section').find('.view_text').text('view races');
			if($this.hasClass("toggleBtn")) {
				$this.removeClass('expand');
			} else {
				$this.find('.toggleBtn').removeClass('expand');
			}
			TweenLite.to($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
		}
	}

	async getRaceEvents() {
		this.FormGuideService.getFormGuideRaceEvents().subscribe((data: any) => {
			if (data && data.race_events) {
				this.race_events = data.race_events ? data.race_events : [];
				this.max_number_of_races = data.max_number_of_races ? data.max_number_of_races : 10;
				this.th_array = Array(this.max_number_of_races);
				this.th_width = 90/this.max_number_of_races;


				this.processEvents();
			}
			this.loading = false;
			//console.log(this.race_events);
		});
	}

	counter(i: number, max_length : number) {
		return new Array(max_length - i);
	}

	getArray(i: number) {
		console.log('getArray' + i)
		return new Array(i);
	}


	async processEvents() {
		let todayDate = moment().local();
		let yesterdayDate = moment().local().subtract(1, 'day');
		let tomorrowDate = moment().local().add(1, 'day');
		let dayAfterTomorrowDate = moment().local().add(2, 'days');
		let twodaysAfterTomorrowDate = moment().local().add(3, 'days');
		let threedaysAfterTomorrowDate = moment().local().add(4, 'days');

		this.today = {
			date: todayDate.format('YYYY-MM-DD'),
			day: todayDate.format('dddd'),
			races: [],
			max_number_of_races: 10,
		};

		this.yesterday = {
			date: yesterdayDate.format('YYYY-MM-DD'),
			day: yesterdayDate.format('dddd'),
			races: [],
			max_number_of_races: 10,

		};

		this.dayAfterTomorrow = {
			date: dayAfterTomorrowDate.format('YYYY-MM-DD'),
			day: dayAfterTomorrowDate.format('dddd'),
			races: [],
			max_number_of_races: 10,

		};

		this.twodaysAfterTomorrow = {
			date: twodaysAfterTomorrowDate.format('YYYY-MM-DD'),
			day: twodaysAfterTomorrowDate.format('dddd'),
			races: [],
			max_number_of_races: 10,

		};

		this.threedaysAfterTomorrow = {
			date: threedaysAfterTomorrowDate.format('YYYY-MM-DD'),
			day: threedaysAfterTomorrowDate.format('dddd'),
			races: [],
			max_number_of_races: 10,

		};

		this.tomorrow = {
			date: tomorrowDate.format('YYYY-MM-DD'),
			day: tomorrowDate.format('dddd'),
			races: [],
			max_number_of_races: 10,

		};

		this.race_events.forEach((element: any) => {
			let counter = 0;
			element.races.forEach((race: any, index:any) => {
				let st = moment.utc(race.race_start_time).local();
				let secs = st.diff(this.time_now, 'seconds');
				race.seconds = secs;
				if (secs <= 0) {
					race.startTimeCrossed = true;
				} else {
					race.startTimeCrossed = false;
				}
				counter = index;
			});
			counter = counter +1;

			let race_start_time = moment.utc(element.races[0].race_start_time).local();
			let seconds = race_start_time.diff(this.time_now, 'seconds');
			let yyyymmdd = moment.utc(element.races[0].race_start_time).local().format('YYYY-MM-DD');
			
			if (yyyymmdd == this.today.date) {
				if(this.today.max_number_of_races < counter) {
					this.today.max_number_of_races = counter;
					// this.today.th_array = Array(counter); 

				}	
				this.today.races.push(element);
			}

			if (yyyymmdd == this.tomorrow.date) {
				if(this.tomorrow.max_number_of_races < counter)  {
					this.tomorrow.max_number_of_races = counter; 
					// this.tomorrow.th_array = Array(counter); 

				}
				this.tomorrow.races.push(element);
			}

			if (yyyymmdd == this.yesterday.date) {
				if(this.yesterday.max_number_of_races < counter) {
					this.yesterday.max_number_of_races = counter;
					// this.yesterday.th_array = Array(counter); 
				}	

				this.yesterday.races.push(element);
			}

			if (yyyymmdd == this.dayAfterTomorrow.date) {
				if(this.dayAfterTomorrow.max_number_of_races < counter) {
					this.dayAfterTomorrow.max_number_of_races = counter;
					// this.dayAfterTomorrow.th_array = Array(counter); 

				}

				this.dayAfterTomorrow.races.push(element);
			}

			if (yyyymmdd == this.twodaysAfterTomorrow.date) {
				if(this.twodaysAfterTomorrow.max_number_of_races < counter) {
					this.twodaysAfterTomorrow.max_number_of_races = counter;
					// this.twodaysAfterTomorrow.th_array = Array(counter); 
				}


				this.twodaysAfterTomorrow.races.push(element);
			}

			if (yyyymmdd == this.threedaysAfterTomorrow.date) {
				if(this.threedaysAfterTomorrow.max_number_of_races < counter) {
					this.threedaysAfterTomorrow.max_number_of_races = counter;
					// this.threedaysAfterTomorrow.th_array = Array(counter); 
				}

				this.threedaysAfterTomorrow.races.push(element);
			}
			// //console.log(this.today.races[0]);
		});
	}

	async getLatestResources() {
		this.ResourcesService.getLatestResources().subscribe((data: any) => {
			if (data && data.resources) {
				this.resources = data.resources ? data.resources : [];
				//console.log(this.resources);
			}
			if (!this.resources) {
			}
			this.loadingResources = false;
		});
	}

	getTodaysPreviews() {
		this.FormGuideService.getTodaysPreviews().subscribe((data: any) => {
			if (data.status && data.previews) {
				this.previews = data.previews ? data.previews : [];
				//console.log('this.previews', this.previews);
			}
		});
	}

	async gotoFormGuide(race: any) {
		//console.log(race);
		this.router.navigate(['/form-guide/' + race.slug]);
	}

	gotoSingleResource(resource: any) {
		this.router.navigate(['/resource/' + resource.id + '/' + resource.slug]);
	}

	TimerBelowFiveMinutes(race: any) {
		$("[data-counter-id='" + race.event_id + "']").removeClass('red to-red grey to-grey blue to-blue white to-white'); 
		$("[data-counter-id='" + race.event_id + "']").addClass('red to-blue'); 
	}

	timeexpired(race: any) {
		//console.log('time expired' + race);
		// this.processEvents();
	}

	toggleTime($event: any) {
		$('app-formguide-archive .time-pills').removeClass('active');
		$($event.target).parents('.time-pills').addClass('active');
		setTimeout(() => {
			$($event.target).parents('.time-pills').removeClass('active');
		}, 2000);
	}
}
