import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, HostListener } from '@angular/core';
import { UpgradeModalPage } from '../../shared/upgrade-modal/upgrade-modal.page';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalService } from '../../shared/alert-modal/alert-modal.service';

@Component({
	selector: '[horse-details]',
	templateUrl: './horse-details.component.html',
	styleUrls: ['./horse-details.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HorseDetailsComponent implements OnInit {
	@Input() row: any;
	@Input() user: any;
	@Input() bb_count: any;
	@Output() openBlackbookIn: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	@Output() toggleAccordionIn: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	screenWidth:any;

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.screenWidth = window.innerWidth;
	}

	constructor(private modalService: NgbModal,private AlertModalService: AlertModalService) {
		this.getScreenSize();
	}

	ngOnInit(): void {
	}

	OpenBlackbook(horse: any) {
		this.openBlackbookIn.emit(horse);
	}

	toggleAccordionInner($event: any) {
		this.toggleAccordionIn.emit($event);
	}

	formatSubtitle = (percent: number) => {
		return percent;
	};

	percentToColor(percent: number, min: number, max: number): string {
		if (percent < 54) {
			return '#FF0000';
		} else if (percent >= 55 && percent <= 74) {
			return '#F5C61C';
		} else if (percent > 75) {
			return '#67BE53';
		}
		return '#67BE53';
	}

	removeNumbers(string: any) {
		let return_string = string.replace(/[0-9]/g, '');
		if(return_string == 'Synthetic' || return_string == 'synthetic') return_string = 'Synth';
		return return_string;
	}

	showUpgradeModal(type: any) {
		const modalRef = this.modalService.open(UpgradeModalPage, {
			size: 'custom',
			windowClass: 'upgrade-modal',
		});

		let data = {
			type: type,
		};

		modalRef.componentInstance.fromParent = data;
		modalRef.result.then(
			(result) => {
				//console.log(result);
			},
			(reason) => {}
		);
	}
	
	async openLink(link: any) {
		window.open(link);
	}
	async openInstructions() {
		let message =
			'<h5><strong>0-54</strong></h5>'+

			'<p class="info"> A rating in this range indicates the horses’ presentation or behaviour will likely hinder performance.</p>'+
	
			'<h5><strong>55-74</strong></h5>'+
	
			'<p class="info"> A rating in this range is neutral. Refer to in depth runner comments and weight to form as the main performance indicator.</p>'+
	
			'<h5><strong>75-100</strong></h5>'+
	
			'<p class="info"> A rating in this range indicates optimal performance. The horse is expected to run to or possibly exceed form indicators.</p>';

		await this.AlertModalService.confirm('Parade Rating', message)
		.then((confirmed) => {
			if (confirmed) {
				// donothing
			}else {
				// donothing
			}
		})
	}
}
