import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGuideService } from '../services/form-guide.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

import * as $ from 'jquery';
import { TweenLite, Cubic, Power4, TweenMax, TimelineMax } from 'gsap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlackbookPopupComponent } from './../shared/blackbook-popup/blackbook-popup.component';
import { LoginPopupComponent } from './../shared/login-popup/login-popup.component';

import { TrackProfileComponent } from './../shared/track-profile/track-profile.component';
import { BlackbookService } from '../services/blackbook.service';
import { EventService } from '../services/event.service';
import { environment } from '../../../src/environments/environment';

// import tippy from 'tippy.js';
// import 'tippy.js/dist/tippy.css'; // optional for styling
import * as moment from 'moment';
import { Options } from 'select2';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import * as e from 'express';
import { MetatagService } from '../services/metatag.service';

@Component({
	selector: 'app-formguide',
	templateUrl: './formguide.component.html',
	styleUrls: ['./formguide.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FormguideComponent implements OnInit {
	public select2options: Options = {
		minimumResultsForSearch: -1,
	};
	total = 150;
	finished = 80;
	race_events: any;
	event_races: any;
	selectedRaceEvent: any;
	event: any;
	selectedrace: any;
	selectedraceno: any;
	form_guide_horses: any;
	live_yard_horses: any;
	liveyard: any;
	speed_maps_horses: any;
	speed_maps_horses_perc: any;
	horse_details: any;
	today: any;
	tomorrow: any;
	scratched_horses: any;
	race_preview: any;
	top_selections:any=[];
	suggested_bets: any = [];

	race_review: any;
	runners_to_watch: any;

	activeTab: any;
	loading: boolean = true;
	results_loading: boolean = true;
	speedmap_loading: boolean = true;
	liveyard_loading: boolean = true;
	preview_loading: boolean = true;
	loading_horses: boolean = true;

	user: any;
	race_event_slug: any;
	time_now = moment.utc();
	showRaceLabel: boolean = true;

	mobileTabClass: any = '';
	bb_count: any;

	@ViewChild('slickModal') slickModal: SlickCarouselComponent;

	slideRaceNosConfig = {
		slidesToShow: 15,
		slidesToScroll: 1,
		arrows: false,
		infinite: false,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 12,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 10,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 8,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 490,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 1,
				},
			},
		],
	};

	constructor(
		private FormGuideService: FormGuideService,
		private route: ActivatedRoute,
		private titleService: Title,
		private modalService: NgbModal,
		private metaService: Meta,
		private blackbookService: BlackbookService,
		private EventService: EventService,
		private MetatagService: MetatagService
	) {
		this.route.params.subscribe((params) => {
			this.race_event_slug = params.slug;
			// this.titleService.setTitle(this.race_event_slug);
			this.selectedraceno = params.race_no ? params.race_no : 1;
		});

		this.EventService.userLogin.subscribe((state: any) => {
			if (!state) {
				this.getUser();
				// console.log('login');
			} 
		});
	}

	
	getUser() {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);

		if (this.user && this.user.subscription) {
			if (this.user.subscription.plan_name === 'Platinum' || this.user.subscription.platinum_cancelling_or_override) this.user.hasPremiumSubscription = true;
			this.user.subscription.active_features.forEach((element: any) => {
				if (element.slug == 'previews-and-reviews') this.user.hasPreviews = true;

				if (element.slug == 'liveyard') this.user.hasLiveYard = true;
			});
			if (this.user.subscription.legacy_plan) this.user.hasPreviews = true;
			if (this.user.day_pass) this.user.hasPremiumSubscription = true;
		}
	}

	ngOnInit() {
		this.today = moment().local();
		this.tomorrow = moment().local().add(1, 'day');
		this.getUser();
		this.getRaceEvents();
	}

	async toggleAccordion(event: any) {
		var $this = $(event.target),
			$content = $(event.target).closest('.collapsable-section').find('.collapsed-content:first');

		if ($this.closest('.collapsable-section').hasClass('closed')) {
			TweenLite.set($content, {
				height: 'auto',
				opacity: 1,
			});
			TweenLite.from($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
			$this.closest('.collapsable-section').removeClass('closed');
			if($this.hasClass("toggleBtn") || $this.hasClass("col-toggle")) {
				$this.addClass('expand');
			} else {
				$this.find('.toggleBtn').addClass('expand');
				$this.find('.col-toggle').addClass('expand');
				if($this.hasClass('col-venue')) {
					$this.siblings('.col-toggle').addClass('expand');
				}	
			}
		} else {
			$this.closest('.collapsable-section').addClass('closed');
			if($this.hasClass("toggleBtn") || $this.hasClass("col-toggle")) {
				$this.removeClass('expand');
			} else {
				$this.find('.toggleBtn').removeClass('expand');
				$this.find('.col-toggle').removeClass('expand');
				if($this.hasClass('col-venue')) {
					$this.siblings('.col-toggle').removeClass('expand');
				}	
			}
			TweenLite.to($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
		}
	}

	formatSubtitle = (percent: number) => {
		return percent;
	};

	percentToColor(percent: number, min: number, max: number): string {
		if (percent < 54) {
			return '#FF0000';
		} else if (percent >= 55 && percent <= 74) {
			return '#F5C61C';
		} else if (percent > 75) {
			return '#67BE53';
		}
		return '#67BE53';
	}

	changeRaceEvent(event: any) {
		console.log(event);
		this.selectedRaceEvent = event;
		this.getEventsRaces();
	}

	async getRaceEvents() {
		await this.FormGuideService.getRaceEvent(this.race_event_slug).subscribe((data: any) => {
			if (data && data.race_events) {
				this.race_events = data.race_events ? data.race_events : [];
				this.event = data.race_event ? data.race_event : null;

				// this.race_events.forEach((element:any) => {
				// 	if(element.slug == this.race_event_slug) {
				this.selectedRaceEvent = this.event.meeting_id;
				// console.log(this.selectedRaceEvent)
				this.MetatagService.updateMetatags(this.event.venue + ' Form Guide', 'web site', this.event.info ? this.event.info : 'Form Guide',this.event.image ? this.event.image : 'https://theraceclub.com.au/assets/images/form-single-generic.jpg');
				window.history.pushState(null, this.event.venue, '/form-guide/'+this.event.slug+'/'+this.selectedraceno);

				// 	}
				// });
				if (!this.selectedRaceEvent) {
					this.selectedRaceEvent = this.race_events[0].meeting_id;
				}

				if (this.selectedRaceEvent) {
					this.getEventsRaces();
				}
			}
			// console.log(this.race_events);
		});
	}

	getEventsRaces() {
		// console.log(this.selectedRaceEvent);
		let data = {
			meeting_id: this.selectedRaceEvent,
		};

		this.FormGuideService.getEventsRaces(data).subscribe((data: any) => {
			if (data.status) {
				this.event_races = data.event_races;
				this.event = data.event;
				this.race_event_slug = this.event.slug;

				this.event_races.forEach((element: any, index: any) => {
					if (element.raceno == this.selectedraceno) {
						element.isSelect = true;
						this.selectedrace = element;
						this.setMobileTab(this.selectedrace);
						this.selectedrace.local_time = moment.utc(this.selectedrace.race_start_time).local();
						this.selectedrace.seconds = this.selectedrace.local_time.diff(this.time_now, 'seconds');
						window.history.pushState(null, this.event.venue, '/form-guide/'+this.event.slug+'/'+this.selectedraceno);
					} else {
						element.isSelect = false;
					}

					let race_start_time = moment.utc(element.race_start_time).local();
					let seconds = race_start_time.diff(this.time_now, 'seconds'); //  // expiry Date - current time
					if (seconds > 3600) {
						element.timediff = Math.round(seconds / 3600) + ' hrs';
					} else if (seconds < 3600 && seconds > 60) {
						element.timediff = Math.round(seconds / 60) + ' mins';
					} else if (seconds > 0) {
						element.timediff = Math.round(seconds) + ' s';
					} else {
						element.timediff = moment.utc(element.race_start_time).local().format('h:mm a');
						element.timediff = moment.utc(element.race_start_time).local().format('h:mm a');
					}

					if (moment.utc(element.race_start_time).local().format('YYYY-MM-DD') == this.today.format('YYYY-MM-DD')) {
						element.formattedDate = 'Today';
					} else if (moment.utc(element.race_start_time).local().format('YYYY-MM-DD') == this.tomorrow.format('YYYY-MM-DD')) {
						element.formattedDate = 'Tomorrow';
					} else element.formattedDate = moment.utc(element.race_start_time).local().format('DD MMM YYYY');
				});

				if (this.selectedrace.race_status == 'FINAL') {
					var tab = 'result-tab';
				} else {
					var tab = 'form-guide-tab';
				}
				this.changeFormGuidePageTab('', tab);
				this.getRacePreview();
				if(this.selectedrace.liveyard_available){
					this.liveyard = true;
					this.live_yard_horses = true;
					this.liveyard_loading = false;
				} else {
					this.liveyard = false;
					this.live_yard_horses = false;
					this.liveyard_loading = false;
				}
				// this.getRaceHorsesLiveYard();

				setTimeout(() => {
					this.loading = false;
				}, 1000);
			}
			// console.log(data);
		});
	}

	//Get Horses for form guide tab
	getRaceHorsesFormGuide() {
		this.loading_horses = true;
		let data = {
			meeting_id: this.selectedrace.meeting_id,
			event_id: this.selectedrace.event_id,
		};

		let token = this.user && this.user.token ? this.user.token : null;

		this.FormGuideService.getRaceHorsesFormGuide(data, token).subscribe((data: any) => {
			if (data.status) {
				this.form_guide_horses = data.form_guide_horses;
				this.selectedrace.scratced_horses = '';
				let scratched_horses = 0;

				let all_top_selections:any[] =   data.top_selections && data.top_selections.selections? data.top_selections.selections:[]; 
				this.top_selections = [];
				
				this.form_guide_horses.forEach((element: any) => {
					element.loading_horse = true;
	
					// console.log(element.horse_id);
					if(all_top_selections.includes(element.horse_id)){
						
						this.top_selections[all_top_selections.indexOf(element.horse_id)] = 
						{
							tabno:element.tabno,
							horse_name:element.horse_name,
							barrier:element.barrier,
							jockey:element.jockey,
							silks:element.silks,
							win3_current:element.win3_current,
							scratched : element.horse_status == 'Scratched' || element.horse_status == 'LateScratched' ? true : false

						};
					}

					if (element.horse_status == 'Scratched' || element.horse_status == 'LateScratched') {
						this.selectedrace.scratced_horses = this.selectedrace.scratced_horses + element.horse_name;
						scratched_horses = scratched_horses + 1;
						if (scratched_horses > 1)
							this.selectedrace.scratced_horses = this.selectedrace.scratced_horses + ',';
					}
				});
				this.scratched_horses = scratched_horses;
				this.loading_horses = false;
			}
			// console.log(this.selectedrace);
			// console.log(this.form_guide_horses);
		});
	}

	//Get Horses for live yard tab
	async getRaceHorsesLiveYard() {
		let data = {
			meeting_id: this.selectedrace.meeting_id,
			event_id: this.selectedrace.event_id,
		};

		this.live_yard_horses = null;
		this.liveyard = null;
		this.liveyard_loading = true;
		let token = this.user && this.user.token ? this.user.token : null;

		await this.FormGuideService.getRaceHorsesLiveYard(data,token).subscribe((data: any) => {
			if (data.status) {
				this.live_yard_horses = data.live_yard_horses;
				this.liveyard = data.liveyard;
				let horses = data.live_yard_horses ? data.live_yard_horses : [];
				let scracted_horses : any= [];
				let running_horses: any = [];
				horses.forEach((element:any) => {
					if(element.horse_status == 'Scratched' || element.horse_status == 'LateScratched') {
						scracted_horses.push(element);
					} else 
					{
						running_horses.push(element);
					}
				});
				this.live_yard_horses = running_horses.concat(scracted_horses);
			}
			this.liveyard_loading = false;
			// console.log(this.live_yard_horses);
		});
	}

	//Get Horses for Speed Maps tab
	getRaceHorsesSpeedMaps() {
		this.speedmap_loading = true;
		let data = {
			meeting_id: this.selectedrace.meeting_id,
			event_id: this.selectedrace.event_id,
		};

		this.FormGuideService.getRaceHorsesSpeedMaps(data).subscribe((data: any) => {
			if (data.status) {
				this.speed_maps_horses = data.speed_maps_horses;

				this.selectedrace.scratced_horses = '';
				let scratched_horses = 0;

				this.speed_maps_horses.forEach((element: any) => {
					element.loading_horse = true;
					if (element.horse_status == 'Scratched' || element.horse_status == 'LateScratched') {
						this.selectedrace.scratced_horses = this.selectedrace.scratced_horses + element.horse_name;
						scratched_horses = scratched_horses + 1;
						if (scratched_horses > 1)
							this.selectedrace.scratced_horses = this.selectedrace.scratced_horses + ',';
					}
				});
			}
			setTimeout(() => {
				this.speedmap_loading = false;
			}, 500);
			setTimeout(() => {
				if (this.selectedrace.map_direction == 'RIGHT' || this.selectedrace.map_direction == undefined) {
					this.animateSpeedMaps();
				} else {
					this.animateSpeedMapsReverse();
				}
			}, 600);
		});
	}

	animateSpeedMaps() {
		var progress = jQuery('.speed-maps-table:not(.reverse) tbody .progress');
		var name = jQuery('.speed-maps-table:not(.reverse) tbody .name_wrap');
		var tl = new TimelineMax();
		tl.addLabel('triggerMap')
			.staggerFromTo(
				progress,
				2.2,
				{ ease: Cubic.easeInOut, width: '0%' },
				{ ease: Cubic.easeInOut, width: '100%', transformOrigin: '100% 50%' },
				0.05,
				'triggerMap'
			)
			.staggerFromTo(
				name,
				2.2,
				{ ease: Cubic.easeInOut, opacity: '0' },
				{ delay: 0.7, ease: Cubic.easeInOut, opacity: '1' },
				0.05,
				'triggerMap'
			);
		return tl;
	}

	animateSpeedMapsReverse() {
		var progress = jQuery('.speed-maps-table.reverse tbody .progress');
		var name = jQuery('.speed-maps-table.reverse tbody .name_wrap');
		var tl = new TimelineMax();
		tl.addLabel('triggerMap')
			.staggerFromTo(
				progress,
				2.2,
				{ ease: Cubic.easeInOut, width: '0%' },
				{ ease: Cubic.easeInOut, width: '100%' },
				0.05,
				'triggerMap'
			)
			.staggerFromTo(
				name,
				2.2,
				{ ease: Cubic.easeInOut, opacity: '0' },
				{ delay: 0.7, ease: Cubic.easeInOut, opacity: '1' },
				0.05,
				'triggerMap'
			);
		return tl;
	}

	//Get Horses for Speed Maps tab
	getRaceHorsesSpeedMapsPerc() {
		let data = {
			meeting_id: this.selectedrace.meeting_id,
			event_id: this.selectedrace.event_id,
		};

		this.FormGuideService.getRaceHorsesSpeedMapsPerc(data).subscribe((data: any) => {
			if (data.status) {
				this.speed_maps_horses_perc = data.speed_maps_horses_perc;
			}
			// console.log('speed_maps_horses_perc', this.speed_maps_horses_perc);
		});
	}

	setMobileTab($selectedRace: any) {
		if ($selectedRace.trc_preview == null && $selectedRace.trc_review == null) {
			this.mobileTabClass = 'switch_3';
		} else if ($selectedRace.trc_preview != null && $selectedRace.trc_review == null) {
			this.mobileTabClass = 'switch_4';
		} else if ($selectedRace.trc_preview == null && $selectedRace.trc_review != null) {
			this.mobileTabClass = 'switch_4';
		} else if ($selectedRace.trc_preview != null && $selectedRace.trc_review != null) {
			this.mobileTabClass = 'switch_5';
		}
	}

	changeEventRace(row: any, index: any = '') {
		this.loading_horses = true;

		if (index) {
			this.slickModal.slickGoTo(index);
		}

		window.history.pushState(null, this.event.venue, '/form-guide/'+this.race_event_slug+'/'+row.raceno);
		// DOUBT
		this.selectedrace = row;
		this.event_races.forEach((element: any) => {
			if (element.event_id == row.event_id) {
				element.isSelect = true;
				// DOUBT
				this.selectedrace = row;
				this.setMobileTab(this.selectedrace);
				this.selectedrace.local_time = moment.utc(this.selectedrace.race_start_time).local();
				this.selectedrace.seconds = this.selectedrace.local_time.diff(this.time_now, 'seconds');
			} else {
				element.isSelect = false;
			}
		});

		setTimeout(() => {
			this.loading_horses = false;
		}, 1000);

		this.setMobileTab(this.selectedrace);
		if (this.selectedrace.race_status == 'FINAL') {
			var tab = 'result-tab';
		} else {
			var tab = 'form-guide-tab';
		}
		this.changeFormGuidePageTab('', tab);

		this.getRacePreview();

		if(this.selectedrace.liveyard_available){
			this.liveyard = true;
			this.live_yard_horses = true;
			this.liveyard_loading = false;
		} else {
			this.liveyard = false;
			this.live_yard_horses = false;
			this.liveyard_loading = false;
		}

		// this.getRaceHorsesLiveYard();
	}

	changeFormGuidePageTab(event: any = '', activeTab: any) {
		this.activeTab = activeTab;
		if (this.activeTab == 'form-guide-tab' || this.activeTab == 'result-tab') {
			this.getRaceHorsesFormGuide();
		} else if (this.activeTab == 'review-tab') {
			this.getRaceReview();
		} else if (this.activeTab == 'live-yard-tab') {
			if(this.user && (this.user.hasLiveYard || this.user.hasPremiumSubscription))  {
				this.getRaceHorsesLiveYard();
			} else {
				this.liveyard_loading = true;
				setTimeout(() => {
					this.liveyard_loading = false;
				}, 300);
			}
		} else if (this.activeTab == 'speed-maps-tab') {
			this.getRaceHorsesSpeedMaps();
			// this.getRaceHorsesSpeedMapsPerc();
		}
	}

	//Get Horse Detailsss
	getRaceHorseDetails(info: any) {
		// console.log('getRaceHorseDetails' + info);
		this.toggleAccordion(info.e);
		// console.log('horseId', info.horseId);
		let data = {
			horse_id: info.horseId,
			meeting_id: this.selectedrace.meeting_id,
			event_id: this.selectedrace.event_id,
		};

		let token = this.user && this.user.token ? this.user.token : null;

		this.FormGuideService.getRaceHorseDetails(data,token).subscribe((data: any) => {
			// console.log(data);
			if (data.status) {
				this.form_guide_horses[info.i].horse_details = data.horse_details;
				this.form_guide_horses[info.i].race_history = data.race_history;
				this.form_guide_horses[info.i].liveyard_details = data.liveyard_details;

				if (this.form_guide_horses[info.i].loading_horse) {
					setTimeout(() => {
						this.form_guide_horses[info.i].loading_horse = false;
					}, 200);
					setTimeout(() => {
						TweenLite.to($(info.e.target).closest('.collapsable-section').find('.collapsed-content:first'), 0.5, {
							height: 'auto',
							ease: Cubic.easeInOut,
						});
					}, 400);
				}
			} else {
				this.form_guide_horses[info.i].horse_details = [];
			}
		});
		if (this.user && this.user.token) {
			this.getBlackbookCount(this.form_guide_horses[info.i]);
		}
	}

	//Get Horse details for speed map
	getSpeedMapsRaceHorseDetails(info: any) {
		this.toggleAccordion(info.e);
		// console.log('horseId', info.horseId);
		let data = {
			horse_id: info.horseId,
			meeting_id: this.selectedrace.meeting_id,
			event_id: this.selectedrace.event_id,
		};
		let token = this.user && this.user.token ? this.user.token : null;

		this.FormGuideService.getRaceHorseDetails(data,token).subscribe((data: any) => {
			// console.log(data);
			if (data.status) {
				this.speed_maps_horses[info.i].horse_details = data.horse_details;
				this.speed_maps_horses[info.i].liveyard_details = data.liveyard_details;
				this.speed_maps_horses[info.i].race_history = data.race_history;

				if (this.speed_maps_horses[info.i].loading_horse) {
					this.speed_maps_horses[info.i].loading_horse = false;
					setTimeout(() => {
						TweenLite.set($(info.e.target).closest('.collapsable-section').find('.collapsed-content'), {
							height: 'auto',
						});
					}, 400);
				}
			} else {
				this.speed_maps_horses[info.i].horse_details = [];
			}
			if (this.user.token) {
				this.getBlackbookCount(this.speed_maps_horses[info.i]);
			}
		});

		// console.log(this.speed_maps_horses);
	}

	//get Race Preview
	async getRacePreview() {
		let data = {
			meeting_id: this.selectedrace.meeting_id,
			event_id: this.selectedrace.event_id,
		};
		let token = this.user && this.user.token ? this.user.token : null;

		this.FormGuideService.getRacePreview(data,token).subscribe((data: any) => {
			if (data.status) {
				this.race_preview = data.race_preview;
				let all_suggested_bets = data.suggested_bets;
				this.suggested_bets = [];
				all_suggested_bets.forEach((element: any) => {
					if (element.win_bet && element.place_bet) {
						let win_push_record = {
							barrier: element.barrier,
							tabno: element.tabno,
							horse_fp: element.horse_fp,
							horse_name: element.horse_name,
							jockey: element.jockey,
							place_bet: 0,
							place_cut_off_price: element.place_cut_off_price,
							place_unit_size: element.place_unit_size,
							plc3_current: element.plc3_current,
							win3_current: element.win3_current,
							win_bet: 1,
							win_cut_off_price: element.win_cut_off_price,
							win_unit_size: element.win_unit_size,
							name: element.name,
							last_name: element.last_name,
							trc_review: element.trc_review,
							avatar: element.avatar,
						};

						let place_push_record = {
							barrier: element.barrier,
							tabno: element.tabno,
							horse_fp: element.horse_fp,
							horse_name: element.horse_name,
							jockey: element.jockey,
							place_bet: 1,
							place_cut_off_price: element.place_cut_off_price,
							place_unit_size: element.place_unit_size,
							plc3_current: element.plc3_current,
							win3_current: element.win3_current,
							win_bet: 0,
							win_cut_off_price: element.win_cut_off_price,
							win_unit_size: element.win_unit_size,
							name: element.name,
							last_name: element.last_name,
							trc_review: element.trc_review,
							avatar: element.avatar,
						};
						this.suggested_bets.push(win_push_record);
						this.suggested_bets.push(place_push_record);
					} else {
						this.suggested_bets.push(element);
					}
				});
				// this.suggested_bets = all_suggested_bets;
				// console.log(this.suggested_bets);
			} else {
				this.race_preview = null;
				this.suggested_bets = null;
			}
			this.preview_loading = false;
		});
	}

	async getRaceReview() {
		let data = {
			meeting_id: this.selectedrace.meeting_id,
			event_id: this.selectedrace.event_id,
		};

		this.FormGuideService.getRaceReview(data).subscribe((data: any) => {
			if (data.status) {
				this.race_review = data.race_review;
				this.race_review.total_runners = data.total_runners;
				this.runners_to_watch = data.runners_to_watch;
			} else {
				this.race_review = null;
				this.runners_to_watch = null;
			}
		});
	}

	async OpenBlackbook(horse: any) {
		if (!this.user) {
			this.modalService.open(LoginPopupComponent, { size: 'custom', windowClass: 'login-modal' });
		} else {
			const modalRef = this.modalService.open(BlackbookPopupComponent, {
				size: 'custom',
				windowClass: 'blackbook-modal',
				scrollable: true
			});
			// console.log(horse);
			let data = {
				horse: horse,
				user: this.user,
				venue: this.event.venue + ' R' + this.selectedrace.raceno,
				event_id: this.selectedrace.event_id,
				meeting_id: this.event.meeting_id
			};

			modalRef.componentInstance.fromParent = data;
			modalRef.result.then(
				(result) => {
					// console.log(result);
				},
				(reason) => {}
			);
		}
	}

	async login() {
		this.modalService.open(LoginPopupComponent, { size: 'custom', windowClass: 'login-modal' });
	}

	async OpenTrackProfile() {
		// console.log(this.event);
		const modalRef = this.modalService.open(TrackProfileComponent, {
			size: 'custom',
			windowClass: 'track-profile-modal',
		});

		let data = {
			"event" : this.event,
			"selectedrace"  : this.selectedrace
		};

		modalRef.componentInstance.fromParent = data;
		modalRef.result.then(
			(result) => {
				// console.log(result);
			},
			(reason) => {}
		);
	}

	async getBlackbookCount(selectedHorse: any) {
		this.blackbookService.getblackbookNotes(selectedHorse, this.user.token).subscribe((data: any) => {
			if (data) {
				// console.log(data);
				if (data.blackbook_entries.length != 0 && data.blackbook_entries != null) {
					this.bb_count = 0;
					data.blackbook_entries.forEach((element: any) => {
						if (element.type == 'horse') {
							this.bb_count++;
						}
						if (element.type == 'jockey') this.bb_count++;
						if (element.type == 'trainer') this.bb_count++;
					});
				} else {
					this.bb_count = 0;
				}
				// console.log(this.bb_count);
			}
		});
	}
}
