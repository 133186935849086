import { Component, OnInit } from '@angular/core';
import { LiveyardsService } from '../services/liveyards.service';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';

import * as $ from 'jquery';
import { TweenLite, Cubic } from 'gsap';
// import { Select2OptionData } from 'ngSelect2';
import * as moment from 'moment';
import { EventService } from '../services/event.service';

@Component({
	selector: 'app-liveyard',
	templateUrl: './liveyard.component.html',
	styleUrls: ['./liveyard.component.scss'],
})
export class LiveyardComponent implements OnInit {
	loading: boolean = true;
	loadingRacedata: boolean = true;
	loadingHorses: any = [];
	isSelect: any = 1;
	venues: any;
	tracks: any;
	liveyards: any;
	pending_liveyards: any = [];
	past_liveyards: any = [];
	user: any;
	LiveyardsAvailable: any = true;
	// liveyard_horses: any = [];
	time_now = moment.utc();
	slug: any;

	constructor(private LiveyardsService: LiveyardsService, private EventService: EventService, private AlertModalService: AlertModalService) {
		this.EventService.userLogin.subscribe((state: any) => {
			if (state) {
				this.getUser();
				console.log('userLogin' + state)
				//console.log('login');
			} 
		});
	}

	ngOnInit() {
		console.log('ngOnInit' + 'getUser')
		this.getUser();
	}

	getUser() {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);

		if (this.user && this.user.subscription) {
			if (this.user.subscription.plan_name === 'Platinum' || this.user.subscription.platinum_cancelling_or_override) this.user.hasPremiumSubscription = true;
			this.user.subscription.active_features.forEach((element: any) => {
				if (element.slug == 'previews-and-reviews') this.user.hasPreviews = true;
				if (element.slug == 'liveyard') this.user.hasLiveYard = true;
			});
			if (this.user.subscription.legacy_plan) this.user.hasPreviews = true;
			if (this.user.day_pass) this.user.hasPremiumSubscription = true;
		}

		if(this.user) {
			this.getLiveyardTracks();
		}
	}

	async toggleAccordion(event: any) {
		var $this = $(event.target),
			$content = $(event.target).closest('.collapsable-section').find('.collapsed-content');

		if ($this.closest('.collapsable-section').hasClass('closed')) {
			TweenLite.set($content, {
				height: 'auto',
				opacity: 1,
			});
			TweenLite.from($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
			$this.closest('.collapsable-section').removeClass('closed');
			$this.addClass('expand');
		} else {
			$this.closest('.collapsable-section').addClass('closed');
			$this.removeClass('expand');
			TweenLite.to($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
		}
	}

	async getLiveyardTracks() {
		let token = this.user && this.user.token ? this.user.token : null;
		console.log('getLiveyardTracks' + 'getLiveyardTracks')

		this.LiveyardsService.getLiveyardTracks(token).subscribe((data: any) => {
			if (data.status) {
				this.tracks = data.tracks ? data.tracks : [];
				this.getLiveyards(null, 1);
			} else {
				this.tracks = [];
				this.LiveyardsAvailable = false;
				this.loadingRacedata = false;
			}
			this.loading = false;
			//console.log('this.tracks', this.tracks);
		});
	}

	async selectTrack($event: any) {
		this.getLiveyards($event.target.value, $event.target.selectedIndex + 1);
	}

	async getLiveyards(track_code: any, isSelect: any) {
		this.loadingRacedata = true;
		this.isSelect = isSelect;
		var data = {
			track_code: null,
		};

		if (track_code) {
			data = {
				track_code: track_code,
			};
		}

		let token = this.user && this.user.token ? this.user.token : null;

		this.LiveyardsService.getLiveyards(data, token).subscribe((data: any) => {
			if (data.status) {
				this.liveyards = data.liveyards ? data.liveyards : [];
				this.pending_liveyards = data.pending_liveyards ? data.pending_liveyards : [];
				this.past_liveyards = data.past_liveyards ? data.past_liveyards : [];

				this.liveyards.forEach((liveyard: any) => {
					let race_start_time = moment.utc(liveyard.race_start_time).local();
					let seconds = race_start_time.diff(this.time_now, 'seconds'); //  // expiry Date - current time
					liveyard.seconds = seconds;
					if (seconds > 3600) {
						liveyard.timediff = Math.round(seconds / 3600) + ' hrs';
					} else if (seconds < 3600 && seconds > 60) {
						liveyard.timediff = Math.round(seconds / 60) + ' mins';
					} else if (seconds > 0) {
						liveyard.timediff = Math.round(seconds) + ' s';
					} else {
						liveyard.timediff = moment.utc(liveyard.race_start_time).local().format('h:mm a');
					}
					this.loadingHorses.push(true);
				});

				this.pending_liveyards.forEach((liveyard: any) => {
					let race_start_time = moment.utc(liveyard.race_start_time).local();
					let seconds = race_start_time.diff(this.time_now, 'seconds'); //  // expiry Date - current time
					liveyard.seconds = seconds;
					if (seconds > 3600) {
						liveyard.timediff = Math.round(seconds / 3600) + ' hrs';
					} else if (seconds < 3600 && seconds > 60) {
						liveyard.timediff = Math.round(seconds / 60) + ' mins';
					} else if (seconds > 0) {
						liveyard.timediff = Math.round(seconds) + ' s';
					} else {
						liveyard.timediff = moment.utc(liveyard.race_start_time).local().format('h:mm a');
					}
				});

				this.past_liveyards.forEach((element: any) => {
					if (element.for_1 == element.place_1) element.for_1_rank = 1;
					if (element.for_1 == element.place_2) element.for_1_rank = 2;
					if (element.for_1 == element.place_3) element.for_1_rank = 3;
					if (element.for_1 == element.place_4) element.for_1_rank = 4;
					if (element.for_2 == element.place_1) element.for_2_rank = 1;
					if (element.for_2 == element.place_2) element.for_2_rank = 2;
					if (element.for_2 == element.place_3) element.for_2_rank = 3;
					if (element.for_2 == element.place_4) element.for_2_rank = 4;
					if (element.for_3 == element.place_1) element.for_3_rank = 1;
					if (element.for_3 == element.place_2) element.for_3_rank = 2;
					if (element.for_3 == element.place_3) element.for_3_rank = 3;
					if (element.for_3 == element.place_4) element.for_3_rank = 4;
					if (element.for_4 == element.place_1) element.for_4_rank = 1;
					if (element.for_4 == element.place_2) element.for_4_rank = 2;
					if (element.for_4 == element.place_3) element.for_4_rank = 3;
					if (element.for_4 == element.place_4) element.for_4_rank = 4;
					this.loadingHorses.push(true);
				});

				this.loadingRacedata = false;
			} else {
				this.liveyards = [];
				this.loadingRacedata = false;
			}
		});
	}

	onImgError(event: any, seq_no: any) {
		event.target.src = 'assets/images/jockeys/jockey' + seq_no + '.png';
	}

	//Get Horse Details
	getLiveyardsHorses(event: any, liveyardId: any, index: any) {
		var $this = $(event.target);
		if ($this.closest('.collapsable-section').hasClass('closed')) {
			// Already Loaded 
			// Uncomment 222, 223, 224, 264 to only load liveyard data once on first expand
			// if(this.loadingHorses[index] == false) {
				// this.toggleAccordion(event);
			// } else {
				this.loadingHorses[index] = 'loading';
				setTimeout(() => {
					this.toggleAccordion(event);
				}, 400);
				let data = {
					liveyard_id: liveyardId,
				};
				let token = this.user && this.user.token ? this.user.token : null;

				this.LiveyardsService.getLiveyardsHorses(data, token).subscribe((data: any) => {
					if (data.status) {
						// this.liveyards[index].liveyard_horses = data.liveyard_horses;
						let horses = data.liveyard_horses ? data.liveyard_horses : [];
						var scracted_horses:string[] = [];
						var running_horses:string[] = [];

						horses.forEach((element:any) => {
							if(element.horse_status == 'Scratched' || element.horse_status == 'LateScratched') {
								scracted_horses.push(element);
							} else 
							{
								running_horses.push(element);
							}
						});
						
						this.liveyards[index].liveyard_horses = running_horses.concat(scracted_horses);

						setTimeout(() => {
							this.loadingHorses[index] = false;
						}, 1000);
						setTimeout(() => {
							TweenLite.set($(event.target).closest('.collapsable-section').find('.collapsed-content'), {
								height: 'auto',
								ease: Cubic.easeInOut,
							});
						}, 1400);
					} else {
						this.liveyards[index].liveyard_horses = [];
					}
				});
			// }
		} else {
			this.toggleAccordion(event);
		}
	}

	// Get Horse Details
	getpastLiveyardsHorses(event: any, liveyardId: any, index: any) {
		var $this = $(event.target);
		if ($this.closest('.collapsable-section').hasClass('closed')) {
			// Already Loaded
			if(this.loadingHorses[index] == false) {
				this.toggleAccordion(event);
			} else {
				this.loadingHorses[index] = 'loading';
				setTimeout(() => {
					this.toggleAccordion(event);
				}, 400);
				let data = {
					liveyard_id: liveyardId,
				};
				let token = this.user && this.user.token ? this.user.token : null;

				this.LiveyardsService.getLiveyardsHorses(data, token).subscribe((data: any) => {
					if (data.status) {
						this.past_liveyards[index].liveyard_horses = data.liveyard_horses;
						setTimeout(() => {
							this.loadingHorses[index] = false;
						}, 1000);
						setTimeout(() => {
							TweenLite.set($(event.target).closest('.collapsable-section').find('.collapsed-content'), {
								height: 'auto',
								ease: Cubic.easeInOut,
							});
						}, 1400);
					} else {
						this.past_liveyards[index].liveyard_horses = [];
					}
				});
			}

		} else {
			this.toggleAccordion(event);
		}
	}

	formatSubtitle = (percent: number) => {
		return percent;
	};

	percentToColor(percent: number, min: number, max: number): string {
		if (percent < 54) {
			return '#FF0000';
		} else if (percent >= 55 && percent <= 74) {
			return '#F5C61C';
		} else if (percent > 75) {
			return '#67BE53';
		}
		return '#67BE53';
	}

	TimerBelowFiveMinutes(race: any) {
		$("[data-counter-id='" + race.event_id + "']").removeClass('red to-red grey to-grey blue to-blue white to-white'); 
		$("[data-counter-id='" + race.event_id + "']").addClass('red to-blue'); 
	}

	timeexpired(race: any) {
		//console.log('time expired' + race);
		// this.processEvents();
	}

	toggleTime($event: any) {
		$('app-formguide-archive .time-pills').removeClass('active');
		$($event.target).parents('.time-pills').addClass('active');
		setTimeout(() => {
			$($event.target).parents('.time-pills').removeClass('active');
		}, 2000);
	}

	async openInstructions() {
		let message =
			'<h5><strong>0-54</strong></h5>'+

			'<p class="info"> A rating in this range indicates the horses’ presentation or behaviour will likely hinder performance.</p>'+
	
			'<h5><strong>55-74</strong></h5>'+
	
			'<p class="info"> A rating in this range is neutral. Refer to in depth runner comments and weight to form as the main performance indicator.</p>'+
	
			'<h5><strong>75-100</strong></h5>'+
	
			'<p class="info"> A rating in this range indicates optimal performance. The horse is expected to run to or possibly exceed form indicators.</p>';

		await this.AlertModalService.confirm('Parade Rating', message)
		.then((confirmed) => {
			if (confirmed) {
				// donothing
			}else {
				// donothing
			}
		})
	}
}
