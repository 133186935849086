import { Component } from '@angular/core';
import { AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import * as $ from 'jquery';
import { TweenMax, Power4 } from 'gsap';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './services/user.service';
import { NavigationEnd } from '@angular/router'; // import Router and NavigationEnd
import { isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { EventService } from './services/event.service';
import * as moment from 'moment';

declare var gtag: any;
declare let ga: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'theraceclubapp';
	user: any;
	token: any;
	campaign: any;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private userService: UserService,
		@Inject(PLATFORM_ID) private platformId: Object,
		private gtmService: GoogleTagManagerService,
		private EventService: EventService,

	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		if (this.user && this.user.token) this.token = this.user.token;

		this.route.queryParams.subscribe(params => {
		this.campaign = params['campaign'];
			if(this.campaign) {
				localStorage.setItem('campaign', this.campaign);
			}
		})
		 
		// subscribe to router events and send page views to Google Analytics
	}

	ngOnInit() {
		$(document).ready(function () {
			// console.log('document ready');
			TweenMax.to($('h1'), 0, { ease: Power4.easeInOut, y: '20%', opacity: 0 });
			TweenMax.to($('.logo'), 0, { ease: Power4.easeInOut, y: '40%', opacity: 0 });
			TweenMax.to($('h1'), 0.9, { ease: Power4.easeInOut, y: '0%', opacity: 1 });
			TweenMax.to($('.logo'), 0.9, { ease: Power4.easeInOut, y: '0%', opacity: 1 });
		});
		if (this.token) this.getUser();
		localStorage.setItem('tab','1');

		let time_now = moment.utc();
		localStorage.setItem('time',time_now.toString());

		this.router.events.subscribe((evt) => {
			if (evt instanceof NavigationEnd) {
				let oldTime = localStorage.getItem('time');
				time_now = moment.utc();
				let diff = time_now.diff(oldTime, 'seconds');
				if(diff > 3600)
				{
					localStorage.setItem('time',time_now.toString());
					this.getUser()
				}
				const gtmTag = {
					event: 'page',
					pageName: evt.url,
				};
				this.gtmService.pushTag(gtmTag);

				ga('set', 'page', evt.urlAfterRedirects);
				ga('send', 'pageview');

				var _hsq = (<any>window)._hsq = (<any>window)._hsq || [];

				_hsq.push(['setPath', evt.url]);

				_hsq.push(['trackPageView']);

				let pageProperties;
				this.user = localStorage.getItem('trc_user');
				this.user = JSON.parse(this.user);
				if(this.user) {
					window.analytics.identify(this.user.id, {
						email: this.user.email,
						name: this.user.name,
					  });
				} else {
					window.analytics.identify('anonymous', {
						name: 'anonymous',
					});
				}
				const originalString = evt.url;
				const stringWithoutSlash = originalString.substring(1);
				const route = stringWithoutSlash === '' ? 'Home' : stringWithoutSlash;
				pageProperties = {
					title: route,
					url: evt.url, // Use the original URL from evt
					path: '/'+route,
					referrer: document.referrer, // Capture the actual referring page's URL
				};
				window.analytics.page(route, pageProperties);
			}
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
		});
	}

	async getUser() {

		this.user = await localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		if (this.user && this.user.token) this.token = this.user.token;

		await this.userService.getAuthUser(this.token).subscribe((data: any) => {
			if (data && data.result) {
				localStorage.setItem('trc_user', JSON.stringify(data.result));
			} else {
				localStorage.removeItem('trc_user');
				this.EventService.userLoggedout();
				this.router.navigate(['/']);
			}
		},err => {
			localStorage.removeItem('trc_user');
			this.EventService.userLoggedout();
			this.router.navigate(['/']);
		});
	}

	hasRoute(route: string) {
		return this.router.url.includes(route);
	}
}
