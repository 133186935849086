import { Component, OnInit } from '@angular/core';
import { TournamentService } from '../services/tournaments.service';
import { Router, ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';
import { TweenLite, Cubic } from 'gsap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ConfirmationModalService } from '../shared/confirmation-modal/confirmation-modal.service';
import { VerifyTorunamentEntrycodeComponent } from '../shared/verify-torunament-entrycode/verify-torunament-entrycode.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginPopupComponent } from './../shared/login-popup/login-popup.component';

@Component({
	selector: 'app-single-tournament',
	templateUrl: './single-tournament.component.html',
	styleUrls: ['./single-tournament.component.scss'],
})
export class SingleTournamentComponent implements OnInit {
	tournament: any;
	tournamentId: any;
	tournamentRaces: any;
	userTournamentHorsesIds: any;
	tournamentUser: any;
	selectedHorseArray: any = [];
	disabledButton: boolean = false;
	user: any;
	token: any;
	loading: boolean = true;
	storageUrl = environment.storage_serverUrl;
	getEntryCode:any=false;
	lock_private_tournament:any=false;
	private_tournament_code_verified:any=false;
	tracks:any=[];

	constructor(
		private TournamentService: TournamentService,
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private ConfirmationModalService: ConfirmationModalService,
		private modalService: NgbModal,
	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);

		if (this.user != null && this.user != 'undefined') this.token = this.user.token;
	}

	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.tournamentId = params.id;
			//console.log(this.tournamentId);
			this.getTournament();
		});
	}

	toggleAccordion(event: any) {
		var $this = $(event.currentTarget),
			$content = $(event.currentTarget).closest('.collapsable-section').find('.collapsed-content');

		if ($this.closest('.collapsable-section').hasClass('closed')) {
			TweenLite.set($content, {
				height: 'auto',
				opacity: 1,
			});
			TweenLite.from($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
			$this.closest('.collapsable-section').removeClass('closed');
			$this.addClass('expand');
		} else {
			$this.closest('.collapsable-section').addClass('closed');
			$this.removeClass('expand');
			TweenLite.to($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
		}


		var $parent = $(event.currentTarget).closest('.collapsed-content-parent');
		TweenLite.set($parent, {
			height: 'auto',
			opacity: 1
		});
	}

	
	toggleAccordionTrack(event: any) {
		var $this = $(event.currentTarget),
			$content = $(event.currentTarget).parents('.collapsable-section').find('.collapsed-content:first');
		
		if ($this.parents('.collapsable-section').hasClass('closed')) {
			TweenLite.set($content, {
				height: 'auto',
				opacity: 1,
			});
			TweenLite.from($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
			$this.closest('.collapsable-section').removeClass('closed');
			$this.addClass('expand');
		} else {
			$this.closest('.collapsable-section').addClass('closed');
			$this.removeClass('expand');
			TweenLite.to($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
		}
	}

	getTournament() {
		let data = {
			tournamentId: this.tournamentId,
			token: this.token,
		};

		this.TournamentService.getTournament(data).subscribe((data: any) => {
			if (data && data.tournament) {
				//console.log(data);
				this.tournament = data.tournament ? data.tournament : [];

				// if (!this.tournament.cut_off_date_over) {
				// 	this.tournament.status = 'Open';
				// } else if (this.tournament.show_results) {
				// 	this.tournament.status = 'Complete';
				// } else {
				// 	this.tournament.status = 'In Progress';
				// }

				if (this.tournament.show_results && this.tournament.cut_off_date_over) {
					this.tournament.status = 'Complete';
				} else if(!this.tournament.show_results && this.tournament.cut_off_date_over) {
					this.tournament.status = 'In Progress';
				} else {
					this.tournament.status = 'Open';
				}

				this.tournamentRaces = data.tournamentRaces ? data.tournamentRaces : [];


				this.tournamentRaces.forEach((element:any) => {
					element.horses.sort(this.compare);
					this.tracks.push(
						{
							'track_code' : element.track_code,
							'track_going'  : element.track_going,
							'track_rating'  : element.track_rating,
							'race_start_time' : element.race_start_time,
							'meeting_id'  : element.meeting_id,

						}
					)
				});
				const unique_tracks = [... this.tracks.reduce((a: any,c: any)=>(a.set(c.meeting_id,c)),new Map).values()]
				this.tracks = unique_tracks;
				this.userTournamentHorsesIds = data.userTournamentHorsesIds ? data.userTournamentHorsesIds : [];
				this.parseTournamentsData();
				// this.tournament.users.forEach((user:any) => {
				// 	let userInitial1 = user?.name
				// 	?.split(' ')
				// 	.map((n: any) => n[0])
				// 	.join('');
				// let userInitial2 = user?.last_name
				// 	?.split(' ')
				// 	.map((n : any) => n[0])
				// 	.join('');
				// user.userInitial = userInitial1 + userInitial2;
				// });
				// //select old horse
				// if(this.userTournamentHorsesIds.length > 0 && this.tournamentRaces.length > 0) {
				// 	this.userTournamentHorsesIds.forEach(element => {
				// 		this.tournamentRaces.forEach(row => {
				// 			if(element.tournament_race_id == row.tournament_race_id && element.tournament_race_horse_id == row.tournament_race_horse_id) {
				// 				row.isChecked = true;
				// 			} else {
				// 				row.isChecked = false;
				// 			}
				// 		});
				// 	});
				// }

					//select old horse
					if(this.userTournamentHorsesIds.length > 0 && this.tournamentRaces.length > 0) {
						this.userTournamentHorsesIds.forEach((element : any) => {
							this.tournamentRaces.forEach((row : any)=> {
								if(element.tournament_race_id == row.id) {
									row.isChecked = true;
									row.selected_horse_id = element.tournament_race_horse_id;
									row.selected_horse_points = element.points;
	
								} 
							});
						});
					}
	
					this.tracks.forEach((track:any) => {
						track.races = [];
						this.tournamentRaces.forEach((race:any) => {
							if(track.meeting_id === race.meeting_id)
							track.races.push(race);
						});
					});

				if (this.userTournamentHorsesIds) {
					this.userTournamentHorsesIds.forEach((element: any) => {
						let data = {
							tournament_id: element.tournament_id,
							token: this.token,
							tournament_race_id: element.tournament_race_id,
							tournament_race_horse_id: element.tournament_race_horse_id,
						};
						this.selectedHorseArray.push(data);
					});
					//console.log(this.selectedHorseArray);
				}

				this.tournamentUser = data.tournamentUser ? data.tournamentUser : null;
				if (this.tournament.cut_off_date_over || this.tournament.show_results || !this.user || (this.tournamentUser && this.tournamentUser.status != 'entered')) {
					this.disabledButton = true;
				}
				if (this.tournament.is_private && this.tournamentUser && this.tournamentUser.status == 'entered') {
					this.lock_private_tournament = false;
					this.private_tournament_code_verified = true;
				} else 
				if (this.tournament.is_private && this.tournamentUser && this.tournamentUser.status == 'code_verified') {
					this.lock_private_tournament = true;
					this.private_tournament_code_verified = true;
				} else {
					this.lock_private_tournament = true;
					this.private_tournament_code_verified = false;
				}

				//console.log('tournament', this.tournament);
				//console.log('tournamentRaces', this.tournamentRaces);
				//console.log('userTournamentHorsesIds', this.userTournamentHorsesIds);
				//console.log('tournamentUser', this.tournamentUser);
			}
			this.loading = false;
			// if(this.tournamentUser && this.tournamentUser.status != 'entered') {
			// 	this.openTermsModal(this.tournament, this.tournamentUser, 'tournament')
			// }
		
		});
	}

	getName(user: any) {
		let name = user && user.display_name ? user.display_name : user.name;
		return name
			.split(' ')
			.map((n: any) => n[0])
			.join('');
	}

	selectHorse(tournamentRaceId: any, horseId: any) {
		//console.log(tournamentRaceId);
		//console.log(horseId);
		let data = {
			tournament_id: this.tournamentId,
			tournament_race_id: tournamentRaceId,
			tournament_race_horse_id: horseId,
		};

		let index = this.selectedHorseArray.findIndex((e: any) => e.tournament_race_id === data.tournament_race_id);

		if (index === -1) {
			this.selectedHorseArray.push(data);
		} else {
			this.selectedHorseArray[index] = data;
		}

		//console.log(this.selectedHorseArray);
	}

	submitTournament() {
		let select_horses_data = {
			tournament_id: this.tournamentId,
			token: this.token,
			selectedhorses: this.selectedHorseArray,
		};
		//console.log(this.selectedHorseArray);
		// if (this.selectedHorseArray.length == this.tournamentRaces.length) {
			this.TournamentService.saveSelectedHorse(select_horses_data).subscribe((data: any) => {
				//console.log(data);
				if (data.status) {
					this.toastr.success(data.message);
				} else {
					this.toastr.error(data.message);
				}
			});
		// } 
		// else {
		// 	this.toastr.error('Please select all horses');
		// }
	}

	async ShowPaymentForm(event: any) {
		this.tournamentUser = event;

	//	//console.log(event);
	}

	async showTournament(event: any) {
		this.tournamentUser = event;
	}

	compare( a : any, b : any ) {
		if ( a.tabno < b.tabno ){
		  return -1;
		}
		if ( a.tabno > b.tabno ){
		  return 1;
		}
		return 0;
	  }

	  parseTournamentsData() {
		var now = moment.utc().format('YYYY MM DD HH:mm:ss');
			if (!this.tournament.cut_off_date_over) {
				this.tournament.alert = 'grey';
				this.tournament.status = 'Open';

				// var diff = moment.duration(moment(this.tournament.cut_off_date_time).diff(moment(now)));

				this.tournament.cut_off_date_time_local = moment.utc(this.tournament.cut_off_date_time).local();
				var diff = moment.duration(moment(this.tournament.cut_off_date_time_local).diff(moment.utc()));


				var duration = moment.duration(diff);
				var years = duration.years(),
					days = duration.days(),
					months = duration.months(),
					hrs = duration.hours(),
					mins = duration.minutes(),
					secs = duration.seconds();
				if (years > 0) {
					this.tournament.time_remaining = this.calculateTime(years, 'year');
				} else if (months > 0) {
					this.tournament.time_remaining = this.calculateTime(months, 'month');
				} else if (days > 0) {
					this.tournament.time_remaining = this.calculateTime(days, 'day');
				} else if (hrs > 0) {
					this.tournament.time_remaining = this.calculateTime(hrs, 'hour') + this.calculateTime(mins, 'min');
				} else if (mins > 0) {
					this.tournament.time_remaining = this.calculateTime(mins, 'min') + this.calculateTime(secs, 'sec');
					if (mins < 5) {
						this.tournament.alert = 'red';
					}
				} else {
					this.tournament.time_remaining = this.calculateTime(secs, 'sec');
					this.tournament.alert = 'red';
				}
			} else {
				this.tournament.alert = '';
				this.tournament.time_remaining = null;
				if (this.tournament.show_results) {
					this.tournament.status = 'Complete';
				} else {
					this.tournament.status = 'In Progress';
				}
			}
			this.tournament.cut_off_date_time = moment(this.tournament.cut_off_date_time).local().format('YYYY MM DD HH:mm:ss');

            if(!this.tournament.image) {
                this.tournament.image_url = '/assets/images/single-tour-banner.jpg';
            }
		
	}

	calculateTime(counter: number, timeUnit: string) {
		if (counter === 1) {
			return counter + ' ' + timeUnit; // singular (1 hours)
		} else {
			let toReturn = counter + ' ' + timeUnit + 's ';
			return toReturn; // plural (2 hours)
		}
	}

	formatName(name : any){
		let copy_name = name;
		let regex = /^\s*(?:M(?:iss|rs?|s)|Dr|Rev)\b[\s.]*/;
		let pattern = regex.exec(name)
		return this.getInitials(name.replace(pattern,''))
	}

	getInitials(name:any) {
		let initials = name.split(' ');
		initials = initials.shift().charAt(0) + '. ' + initials.toString().replace(',',' ');
		return initials;
	}

	async openTermsModal(tournament: any, tournamentUser:any, type: any) {
		if(!this.user) {
			this.modalService.open(LoginPopupComponent, { size: 'custom', windowClass: 'login-modal' });
			return;
		}

		if (
			tournament.is_private &&
			type == 'tournament' &&
			tournamentUser &&
			tournamentUser.status != 'entered' &&
			tournamentUser.status != 'terms_agreed' &&
			tournamentUser.status != 'code_verified'
		) {
			let dialogSize: 'sm' | 'lg' = 'lg';
			const modalRef = this.modalService.open(VerifyTorunamentEntrycodeComponent, {
				size: dialogSize,
				backdrop: 'static',
				keyboard: false,
				centered: true,
			});
			modalRef.componentInstance.backdrop = 'static';
			modalRef.componentInstance.keyboard = false;
			modalRef.componentInstance.btnOkText = 'Enter';
			modalRef.componentInstance.btnCancelText = 'Cancel';
			let data = {
				tournament: tournament,
				user: this.user,
			};
			modalRef.componentInstance.fromParent = data;
			modalRef.result
				.then((result) => {
					if(result) {
						this.getTournament();
						this.showTermsPopup(tournament, type);
					}
				})
				.catch((result) => {
					console.log('cancelling');
				});
			return;
		}

		if (
			tournament.tournamentUser &&
			(tournament.tournamentUser.status == 'entered' || tournament.tournamentUser.status == 'terms_agreed')
		) {
			if (type == 'tournament') {
				this.getTournament();
				return;
			}
			if (type == 'round-robin-tournament') {
				this.gotoRoundRobinTournaments(tournament);
				return;
			}
		}

		this.showTermsPopup(tournament,type)
	}

	async showTermsPopup(tournament : any, type : any) {
		let message =
			'<div>' +
			'<p>By entering this tournament, you agree to the terms and conditions on our website</p>' +
			'</div>' +
			'<a target="_blank" class="terms_link" href="http://theraceclub.com.au/terms-and-conditions">View Terms & Conditions</a>';

		await this.ConfirmationModalService.confirm('Terms & Conditions', message)
			.then((confirmed) => {
				//	console.log('User confirmed:', confirmed);
				if (confirmed) this.agreeTerms(tournament, type);
			})
			.catch(() =>
				console.log(
					'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
				)
			);
	}

	gotoSingleTournament(tournament: any) {
		this.router.navigate(['/tournament/' + tournament.id + '/' + this.slugify(tournament.name)]);
	}

	gotoSingleTournamentView(tournamentView: any) {
		console.log(tournamentView)
		this.router.navigate(['/tournament/view/' + tournamentView.id + '/' + this.slugify(tournamentView.name)]);
	}

	async agreeTerms(tournament: any, type: any) {
		let data = {
			id: tournament.id,
			type: type,
		};
		try {
			await this.TournamentService.agreeTerms(this.token, data).subscribe((data: any) => {
				if (data && data.status) {
					if (type == 'tournament') {
						this.getTournament();
					} else {
						this.gotoRoundRobinTournaments(tournament);
					}
				}
			});
		} catch (error) {
		//	console.log(error);
		}
	}

	
	gotoRoundRobinTournaments(roundRobinTournament: any) {
		this.router.navigate(['/tournament/round-robin/' + roundRobinTournament.id + '/' + this.slugify(roundRobinTournament.title)]);
	}

	gotoRoundRobinTournamentsView(roundRobinTournamentsView: any) {
		this.router.navigate(['/tournament/round-robin/view/' + roundRobinTournamentsView.id + '/' + this.slugify(roundRobinTournamentsView.title)]);
	}

	slugify(title: any) {
		// console.log(title, title.replace(/\s+/g, '-').toLowerCase());
		return title.replace(/\s+/g, '-').toLowerCase();
	}

}
