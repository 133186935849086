<div class="modal_wrapper blackbook_modal">

		<h2 class="popup_title text-center">
			{{ fromParent?.tournament.name }}
		</h2>

	
		<div class="close_icon" (click)="decline()">
			<svg xmlns="http://www.w3.org/2000/svg" width="11.689" height="11.689" viewBox="0 0 11.689 11.689">
				<g class="Group_251" data-name="Group 251" transform="translate(84.329 -65.195) rotate(135)">
					<g class="Group_249" data-name="Group 249" transform="translate(98.464 -2)">
						<path
							class="Path_1"
							data-name="Path 1"
							d="M24.331,20.516V5.986"
							transform="translate(-17.066 -5.986)"
							fill="none"
							stroke="#fff"
							stroke-miterlimit="10"
							stroke-width="2"
						/>
						<path
							class="Path_81"
							data-name="Path 81"
							d="M0,14.53V0"
							transform="translate(14.53 7.265) rotate(90)"
							fill="none"
							stroke="#fff"
							stroke-miterlimit="10"
							stroke-width="2"
						/>
					</g>
				</g>
			</svg>
		</div>


	<div class="col-12 py-2 text-center">
		<h4>Please enter your invite code in order to participate in this tournament.</h4>
		<br>

		<div class="form-row">
			<div class="form-group col-12">
				<input type="text" class="form-control" placeholder="Entry Code" [(ngModel)]="entry_code" />
			</div>

			<div class="form-group col-12">
				<button class="btn blue" type="submit" (click)="onSubmit()">Verify Code</button>
			</div>
		</div>
	</div>
</div>
<!-- end inner -->
