<div
	class="onboarding_wrapper signup_wrapper signup"
	style="background-image: url('assets/images/Winx-RGB-Split-Background.png')"
>
	<div class="floating_logo">
		<a routerLink="/">
			<img src="assets/images/logos/logo-vertical-light.png"/>
		</a>
	</div>

	<div class="bg_image"></div>
	<!-- end bg_image -->
	<div class="bg_image mob"></div>
	<!-- end bg_image -->

	<div class="container-fluid outer">
		<div class="row">
			<div class="col_form text-center">
				<div class="inner">
					<h1 class="page_title white">Create your account</h1>

					<div class="subheading">
						<p>Already have an account? <a routerLink="/login">Log in</a></p>
					</div>

					<!-- <form class="form_wrapper" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
						<div class="form-row">

							<div class="form-group col-sm-6">
								<input type="text" placeholder="First Name" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" pattern="[a-z,A-Z]*"/>
								<div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
									<div *ngIf="f.firstName.errors.required">First Name is required</div>
									<div *ngIf="f.firstName.errors.pattern">First Name is not valid</div>
								</div>
							</div>
							<div class="form-group col-sm-6">
								<input type="text" placeholder="Last Name" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" pattern="[a-z,A-Z]*"/>
								<div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
									<div *ngIf="f.lastName.errors.required">Last Name is required</div>
									<div *ngIf="f.lastName.errors.pattern">Last Name is not valid</div>
								</div>
							</div>
							<div class="form-group col-12">
								<input type="text" placeholder="Email Address" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
								<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
									<div *ngIf="f.email.errors.required">Email is required</div>
									<div *ngIf="f.email.errors.pattern">Email is invalid</div>
									<div *ngIf="f.email.errors.response">Email has already been taken</div>
								</div>
							</div>
							<div class="form-group col-12">
								<input type="password" placeholder="Password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
								<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
									<div *ngIf="f.password.errors.required">Password is required</div>
									<div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
								</div>
							</div>

							<div class="form-group form-check col-12">
								<input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }"/>
								<label for="acceptTerms" class="form-check-label">I have read and agree to the <a href="#">Terms & Conditions</a></label>
								<div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
									You must accept terms and conditions.
								</div>
							</div>

							<div class="form-group col-12">
								<button class="btn blue arrow_large" [disabled]="loading" type="submit">
								<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
								Sign up</button>
							</div>

							
						
						</div>
					</form> -->
					<form class="form_wrapper form_modal" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
						<div class="form-row">
							<div class="form-group col-sm-6">
								<input
									appAutofocus
									type="text"
									placeholder="First name"
									formControlName="firstName"
									class="form-control"
									[ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
								/>
								<div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
									<div *ngIf="f.firstName.errors.required">First name is required</div>
									<div *ngIf="f.firstName.errors.pattern">First name is not valid</div>
								</div>
							</div>
							<div class="form-group col-sm-6">
								<input
									type="text"
									placeholder="Last name"
									formControlName="lastName"
									class="form-control"
									[ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
								/>
								<div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
									<div *ngIf="f.lastName.errors.required">Last name is required</div>
									<div *ngIf="f.lastName.errors.pattern">Last name is not valid</div>
								</div>
							</div>
							<div class="form-group col-sm-6">
								<input
									type="text"
									placeholder="Email address"
									formControlName="email"
									class="form-control"
									[ngClass]="{ 'is-invalid': submitted && f.email.errors }"
								/>
								<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
									<div *ngIf="f.email.errors.required">Email is required</div>
									<div *ngIf="f.email.errors.pattern">Email is invalid</div>
									<div *ngIf="f.email.errors.response">Email has already been taken</div>
								</div>
							</div>

							<div class="form-group col-sm-6">
								<input
									type="phone"
									placeholder="Phone number (optional)"
									formControlName="phone"
									class="form-control"
								/>
							</div>

							<div class="form-group col-12 state">
								<select formControlName="state" name="state" class="form-control">
									<option value="" [selected]="true" disabled>- Select state (optional) -</option>
									<option value="ACT">ACT</option>
									<option value="NSW">NSW</option>
									<option value="QLD">QLD</option>
									<option value="SA">SA</option>
									<option value="TAS">TAS</option>
									<option value="VIC">VIC</option>
									<option value="WA">WA</option>
								</select>
								<div *ngIf="submitted && f.state.errors" class="invalid-feedback">
									<div *ngIf="f.state.errors.required">State is required</div>
								</div>
							</div>

							<div class="form-group col-12 col_dob">
								<div class="label_wrap">
									<label>Date of Birth</label>
									<span class="why" ngbTooltip="This site is for 18+ only.">
										<span>?</span>
									</span>
								</div>
								<!-- end label_wrap -->

								<div class="inner">
									<div class="field-inline-block">
										<input
											formControlName="dd"
											placeholder="DD"
											type="text"
											maxlength="2"
											size="2"
											autocomplete="off"
											(input)="autoTab($event, true)"
											class="date-field form-control"
											[ngClass]="{ 'is-invalid': submitted && f.dd.errors }"
											pattern="[0-9]*"
										/>
									</div>
									<span>/</span>
									<div class="field-inline-block">
										<input
											formControlName="mm"
											placeholder="MM"
											type="text"
											pattern="[0-9]*"
											maxlength="2"
											size="2"
											autocomplete="off"
											(input)="autoTab($event, true)"
											class="date-field form-control"
											[ngClass]="{ 'is-invalid': submitted && f.mm.errors }"
										/>
									</div>
									<span>/</span>
									<div class="field-inline-block">
										<input
											formControlName="yyyy"
											placeholder="YYYY"
											type="text"
											pattern="[0-9]*"
											maxlength="4"
											size="4"
											autocomplete="off"
											(input)="autoTab($event, false)"
											class="date-field date-field--year form-control"
											[ngClass]="{ 'is-invalid': submitted && f.yyyy.errors }"
										/>
									</div>
								</div>
								<!-- end inner -->

								<div class="row">
									<div class="col-4">
										<div
											*ngIf="submitted && f.dd.errors"
											class="invalid-feedback"
											[ngClass]="{ 'd-block': submitted && f.mm.errors }"
										>
											<div *ngIf="f.dd.errors.required">Date is required</div>
											<div *ngIf="f.dd.errors.pattern">Date is Invalid</div>
										</div>
									</div>
									<div class="col-4">
										<div
											*ngIf="submitted && f.mm.errors"
											class="invalid-feedback"
											[ngClass]="{ 'd-block': submitted && f.dd.errors }"
										>
											<div *ngIf="f.mm.errors.required">Month is required</div>
											<div *ngIf="f.mm.errors.pattern">Month is invalid</div>
										</div>
									</div>
									<div class="col-4">
										<div
											*ngIf="submitted && f.yyyy.errors"
											class="invalid-feedback"
											[ngClass]="{ 'd-block': submitted && f.yyyy.errors }"
										>
											<div *ngIf="f.yyyy.errors.required">Year is required</div>
											<div *ngIf="f.yyyy.errors.pattern">Year is invalid</div>
										</div>
									</div>
								</div>
								<!-- end row -->
								<div class="row">
									<div class="col-12">
										<div
											*ngIf="submitted && f.yyyy.errors && f.mm.errors && f.dd.errors"
											class="invalid-feedback"
											[ngClass]="{
												'd-block': submitted && (f.yyyy.errors || f.mm.errors || f.dd.errors)
											}"
										>
											<div
												*ngIf="
													f.yyyy.errors.notcorrect ||
													f.mm.errors.notcorrect ||
													f.dd.errors.notcorrect
												"
											>
												Please enter correct date
											</div>
										</div>
									</div>
									<!-- <div class="col-4">
										<div *ngIf="submitted && f.dd.errors " class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.dd.errors }">
											<div *ngIf="f.dd.errors.notcorrect">please enter correct date</div>
										</div>
									</div> -->
								</div>
								<!--  end row -->

								<!-- 18 years validation -->
								<div class="row">
									<div class="col-12">
										<div
											*ngIf="submitted && f.yyyy.errors && f.mm.errors && f.dd.errors"
											class="invalid-feedback"
											[ngClass]="{ 'd-block': submitted && f.yyyy.errors }"
										>
											<div
												*ngIf="
													f.yyyy.errors.illegal && f.mm.errors.illegal && f.dd.errors.illegal
												"
											>
												You must be above 18 to sign up
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- end form-group col-12 -->

							<div class="form-group col-12">
								<input
									type="password"
									placeholder="Password"
									formControlName="password"
									class="form-control"
									id="password-field"
									[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
								/>
								<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
									<div *ngIf="f.password.errors.required">Password is required</div>
									<div *ngIf="f.password.errors.minlength">
										Password must be at least 6 characters
									</div>
								</div>
							</div>

							<div class="form-group form-check col-12">
								<input
									type="checkbox"
									formControlName="acceptTerms"
									id="acceptTerms"
									class="form-check-input"
									[ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }"
								/>
								<label for="acceptTerms" class="form-check-label"
									>I have read and agree to the <a (click)="navigateToTerms()">Terms & Conditions</a></label
								>
								<div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
									You must accept terms and conditions.
								</div>
							</div>

							<div class="form-group col-12">
								<button class="btn blue arrow_large" [disabled]="loading" type="submit">
									<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
									Sign up
								</button>
							</div>
						</div>
					</form>
				</div>
				<!-- end inner -->
			</div>
			<!-- end col_form -->
		</div>
	</div>
	<!-- end container-fluid -->
</div>
<!-- end signup_wrapper -->
