import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NewsService } from '../services/news.service';
import { OffersService } from '../services/offers.service';
import { FormGuideService } from '../services/form-guide.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MetatagService } from '../services/metatag.service';

@Component({
	selector: 'app-news',
	templateUrl: './news.component.html',
	styleUrls: ['./news.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NewsComponent implements OnInit {
	news: any;
	offer: any;
	totalnewsCount: any;
	page: any = 1;
	searchUrlParms: any = '';
	newsId: any;
	quote: string = '';
	newsSlug: any;
	featuredNews: any;
	loading: boolean = true;
	loadingOffers: boolean = true;
	loadingNewsList: boolean = true;
	loadingFeaturedNews: boolean = true;
	loadingTodaysPreviews: boolean = true;
	previews: any;
	time_now = moment.utc().format('YYYY-MM-DD HH:mm:ss');
	user: any;

	constructor(
		private NewsService: NewsService,
		private OffersService: OffersService,
		private FormGuideService: FormGuideService,
		private route: Router,
		private MetatagService: MetatagService
	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		this.MetatagService.updateMetatags('Horse Racing News from Australia', 'web site', 'Get the latest horse racing news and breaking headlines from Australia and right around the world at TheRaceClub. Be sure to stay up to date on trainers, horses, jockeys, tips on top bets and much more.','https://mobile.theraceclub.com.au/assets/images/the-race-club-facebook-og.png');

	}

	ngOnInit() {
		this.getNewsList(this.page);
		this.getFeaturedNews();
		if (this.user) {
			this.getOffer();
		} else {
			this.getGuestOffer();
		}
		this.getTodaysPreviews();
	}

	ngAfterViewInit() {
		this.loading = false;
	}

	getNewsList(page: any) {
		this.NewsService.getNewsList(page).subscribe((data: any) => {
			if (data && data.news) {
				this.news = data.news ? data.news : [];
				this.totalnewsCount = data.total;
				//console.log(data);
			}
			if (!this.news) {
				this.quote = 'no news are available';
				this.totalnewsCount = 0;
			}
			this.loadingNewsList = false;
		});
	}

	getOffer() {
		this.OffersService.getOffer(this.user.token).subscribe((data: any) => {
			if (data && data.offer) {
				this.offer = data.offer ? data.offer : [];
				this.offer.buttons = JSON.parse(this.offer.buttonsmeta);
			}
			this.loadingOffers = false;
		});
	}

	getGuestOffer() {
		this.OffersService.getGuestOffer().subscribe((data: any) => {
			if (data && data.offer) {
				this.offer = data.offer ? data.offer : [];
				this.offer.buttons = JSON.parse(this.offer.buttonsmeta);
			}
			this.loadingOffers = false;
		});
	}

	pageChanged(event: any) {
		this.page = event;
		this.loadingNewsList = true;
		this.getNewsList(event);
	}

	async OfferClicked(row: any) {
		if (row.link_type == 'url') {
			window.open(row.value);
		} else {
			this.route.navigate([row.value]);
		}
	}

	getFeaturedNews() {
		this.NewsService.getFeaturedNews().subscribe((data: any) => {
			if (data && data.news) {
				this.featuredNews = data.news ? data.news : [];
			}
			if (!this.news) {
				this.quote = 'no featured news are available';
			}
			this.loadingFeaturedNews = false;
		});
	}

	gotoSingleNews(news: any) {
		this.route.navigate(['/news/' + news.id + '/' + news.slug]);
	}

	getTodaysPreviews() {
		this.FormGuideService.getTodaysPreviews().subscribe((data: any) => {
			if (data.status && data.previews) {
				this.previews = data.previews ? data.previews : [];
			}
			this.loadingTodaysPreviews = false;
		});
	}
}
