import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'terms-and-conditions',
	templateUrl: './terms.component.html',
	styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
