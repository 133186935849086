import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PlanService } from '../services/plan.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';
declare var pinApi: any;

@Component({
	selector: 'app-thank-you',
	templateUrl: './thank-you.component.html',
	styleUrls: ['./thank-you.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ThankYouComponent implements OnInit {
	features: any;
	plans: any;
	feature_loading: boolean = true;
	plans_loading: boolean = true;
	plans_selected: boolean = false;
	selectedPlan: any;
	activeSlide: any = 'slide-1';
	name: any;
	cardnumber: any;
	expirationdate: any;
	cvc: any;
	output: any;
	ccicon: any;
	ccsingle: any;
	generatecard: any;
	selection: any;
	submitted: any = false;
	payment_gateway_response: any;
	pinpayment_response: any;
	user: any;
	selected_plan: any;
	token: any;
	plan_id: any;
	loading_features: any = false;
	loading_plans: any = false;
	payment_progress: any = 0;
	features_count: any = 0;
	plans_count: any = 0;
	selected_features: any = [];

	paymentForm: FormGroup = this.formBuilder.group({
		number: ['', [Validators.required]],
		name: ['', [Validators.required]],
		expiry_month: ['', [Validators.required]],
		cvc: ['', [Validators.required]],
		expiry_year: ['', [Validators.required]],
	});

	pinpayment_errors: any = {
		number: null,
		name: null,
		expiry_month: null,
		cvc: null,
		expiry_year: null,
	};

	constructor(
		private PlanService: PlanService,
		private route: Router,
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toastr: ToastrService
	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		this.token = this.user.token;
		localStorage.removeItem('selected_plan');
	}

	ngOnInit(): void {
		this.selected_plan = localStorage.getItem('selected_plan');
		if(this.selected_plan) {
			this.selected_plan = JSON.parse(this.selected_plan);
		}

		if(this.selected_plan) {
			this.getFeatures();
		}
	}
	get f() {
		return this.paymentForm.controls;
	}

	async getUser() {
		await this.userService.getAuthUser(this.token).subscribe((data: any) => {
			if (data) {
				if (data.result) {
					localStorage.setItem('trc_user', JSON.stringify(data.result));
				}
			}
		});
	}

	async getFeatures() {
		this.feature_loading = true;
		await this.PlanService.getFeatures().subscribe((data: any) => {
			if (data) {
				if (data.features) {
					this.features = data.features;
					this.features_count = this.features.length;
				}
			}
			this.feature_loading = false;
		});
	}

	async getPlans(selected_features: any) {
		this.plans_loading = true;
		await this.PlanService.getPlans(selected_features).subscribe((data: any) => {
			if (data) {
				if (data.plans) {
					this.plans = data.plans;
					this.plans.forEach((element: any) => {
						element.checked = false;
					});
				}
			}
			this.plans_loading = false;
		});
	}

	featureSelected(index: any) {
		// this.features[index].checked = !this.features[index].checked;

		this.features.forEach((element: any) => {
			if (element.checked) this.selected_features.push(element.id);
		});
		if (this.selected_features.length > 0) this.getPlans(this.selected_features);
	}


	public findInvalidControls() {
		const invalid = [];
		const controls = this.paymentForm.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		return invalid;
	}




}
