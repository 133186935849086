<div class="modal_wrapper blackbook_modal">
	<h2 class="popup_title mb-4 text-center">
		{{ title }}
	</h2>

	<div class="col-12 py-2" [innerHtml]="message"></div>

	<div class="modal-footer pt-2">
		<button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
	</div>
</div>
