import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	apiUrl = environment.serverUrl;
	constructor(private httpClient: HttpClient) {}

	signup(user: any) {
		return this.httpClient.post(this.apiUrl + 'signup', user);
	}

	login(user: any) {
		return this.httpClient.post(this.apiUrl + 'login', user);
	}

	createCustomerWithCard(data: any, token: any) {
		return this.httpClient.post(this.apiUrl + 'create-customer-with-card?token=' + token, data);
	}

	addCard(data: any, token: any) {
		return this.httpClient.post(this.apiUrl + 'add-user-card?token=' + token, data);
	}

	removeCard(data: any, token: any) {
		return this.httpClient.post(this.apiUrl + 'remove-user-card?token=' + token, data);
	}

	createSubscription(data: any, token: any) {
		return this.httpClient.post(this.apiUrl + 'create-subscription?token=' + token, data);
	}

	cancelSubscription(data: any, token: any) {
		return this.httpClient.post(this.apiUrl + 'cancel-subscription?token=' + token, data);
	}

	reactivateSubscription(data: any, token: any) {
		return this.httpClient.post(this.apiUrl + 'reactivate-subscription?token=' + token, data);
	}

	changeUserPrimaryCard(data: any, token: any) {
		return this.httpClient.post(this.apiUrl + 'change-primary-user-card?token=' + token, data);
	}

	contactSupport(data: any) {
		return this.httpClient.post(this.apiUrl + 'contact-support', data);
	}

	getCards(token: any) {
		let data: any;
		return this.httpClient.post(this.apiUrl + 'get-user-cards?token=' + token, data);
	}

	getAuthUser(token: any) {
		let data: any;
		return this.httpClient.post(this.apiUrl + 'get-auth-user?token=' + token, data);
	}

	updateProfile(data: any, token: any) {
		return this.httpClient.post(this.apiUrl + 'update-profile?token=' + token, data);
	}

	updateAvatar(data: any, token: any) {
		return this.httpClient.post(this.apiUrl + 'update-avatar?token=' + token, data);
	}

	updatePassword(data: any, token: any) {
		return this.httpClient.post(this.apiUrl + 'update-password?token=' + token, data);
	}

	sendResetPasswordLink(data: any) {
		return this.httpClient.post(this.apiUrl + 'reset-password-request', data);
	}

	resetPassword(data: any) {
		return this.httpClient.post(this.apiUrl + 'change-password', data);
	}

	getResetemail(data: any) {
		return this.httpClient.get(this.apiUrl + 'get-reset-email/' + data);
	}

	verifyCoupon(coupon_code: any,planId:any) {
		let data = {
			coupon_code:coupon_code,
			planId:planId
		}
		return this.httpClient.post(this.apiUrl + "verify-coupon", data);
	}
}

