import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
	currentYear: number = new Date().getFullYear();
	constructor(
	) {}

	ngOnInit() {
		
	}
	ngOnDestroy() {}

	navigateAppleStore(){
		window.open('https://apps.apple.com/au/app/racing-central/id1434221878', '_blank');
	}

	navigateGoogleStore(){
		window.open('https://play.google.com/store/apps/details?id=io.ionic.raceclub&pli=1', '_blank');
	}
	
}
