<div class="plans_wrapper">
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-8 col_form d-flex align-items-center text-center">
				<div class="card">
					<div class="row">
						<div class="col-md-8 cart">
							<div class="features" *ngIf="!feature_loading">
								<div class="title">
									<div class="row">
										<div class="col">
											<h4><b>Select Features</b></h4>
										</div>
									</div>
								</div>
								<div class="row border-top border-bottom" *ngFor="let feature of features; index as i">
									<div class="row main align-items-center">
										<div class="col-2"><img class="img-fluid" src="{{ feature.image }}" /></div>
										<div class="col">
											<div class="row text-muted">{{ feature.title }}</div>
											<div class="row">{{ feature.short_description }}</div>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												[(ngModel)]="feature.checked"
												(change)="featureSelected(i)"
											/>
										</div>
									</div>
								</div>
							</div>
							<div class="plans" *ngIf="!plans_loading">
								<div class="title">
									<div class="row">
										<div class="col">
											<h4><b>Select Plans</b></h4>
										</div>
									</div>
								</div>
								<div class="row border-top border-bottom" *ngFor="let plan of plans; index as j">
									<div class="row main align-items-center">
										<div class="col-2"><img class="img-fluid" src="{{ plan.image }}" /></div>
										<div class="col">
											<div class="row text-muted">{{ plan.title }}</div>
											<div class="row">{{ plan.short_description }}</div>
										</div>
										<div class="col">${{ plan.price }}</div>
										<div class="col">{{ plan.duration }}</div>

										<div class="form-check">
											<input
												class="form-check-input"
												type="radio"
												id="{{ j }}"
												value="{{ plan.id }}"
												[(ngModel)]="plan.checked"
												(change)="PlanSelected(j)"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 summary" *ngIf="selectedPlan">
							<div>
								<h5><b>Summary</b></h5>
							</div>
							<hr />
							<div class="row">
								<div class="col" style="padding-left: 0">{{ selectedPlan.title }}</div>
								<div class="col text-right">${{ selectedPlan.price }}/{{ selectedPlan.duration }}</div>
							</div>

							<div class="row" style="border-top: 1px solid rgba(0, 0, 0, 0.1); padding: 2vh 0">
								<div class="col">TOTAL PRICE</div>
								<div class="col text-right">${{ selectedPlan.price }}</div>
							</div>
							<button class="btn" (click)="checkout()">CHECKOUT</button>
						</div>
					</div>
				</div>
			</div>
			<div
				class="col-lg-4 col_image"
				style="background-image: url('assets/images/Winx-RGB-Split-Background.png')"
			></div>
		</div>
	</div>
</div>
