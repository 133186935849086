import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';

import { NewsComponent } from './news/news.component';

import { SingleNewsComponent } from './single-news/single-news.component';
import { ResultsComponent } from './results/results.component';
import { BlackbookComponent } from './blackbook/blackbook.component';
import { FormguideComponent } from './formguide/formguide.component';
import { FormguideArchiveComponent } from './formguide-archive/formguide-archive.component';
import { TournamentsComponent } from './tournaments/tournaments.component';
import { SingleTournamentComponent } from './single-tournament/single-tournament.component';
import { RoundRobinTournamentsComponent } from './round-robin-tournaments/round-robin-tournaments.component';
import { LiveyardComponent } from './liveyard/liveyard.component';
import { HeaderComponent } from './shared/header/header.component';
import { JumpoutsHorsesListComponent } from './shared/jumpouts-horses-list/jumpouts-horses-list.component';

import { FooterComponent } from './shared/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { MomentModule } from 'ngx-moment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResourcesComponent } from './resources/resources.component';
import { ResourceComponent } from './resource/resource.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectplanComponent } from './selectplan/selectplan.component';
import { MakepaymentComponent } from './makepayment/makepayment.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

import { ContactUsComponent } from './contact-us/contact-us.component';
import { JumpoutsComponent } from './jumpouts/jumpouts.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SubscribePlanComponent } from './subscribe-plan/subscribe-plan.component';
import { SubscribeOffersComponent } from './subscribe-offers/subscribe-offers.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutRCComponent } from './checkout-rc/checkout-rc.component';

import { ThankYouComponent } from './thank-you/thank-you.component';

import { Nl2BrPipeModule } from 'nl2br-pipe';
import { ChangePlanComponent } from './change-plan/change-plan.component';
import { VerifyTorunamentEntrycodeComponent } from './shared/verify-torunament-entrycode/verify-torunament-entrycode.component';
import { TournamentPayComponent } from './shared/tournament-pay/tournament-pay.component';

import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalService } from './shared/confirmation-modal/confirmation-modal.service';
import { AlertModalComponent } from './shared/alert-modal/alert-modal.component';
import { AlertModalService } from './shared/alert-modal/alert-modal.service';

import { SignupPopupComponent } from './shared/signup-popup/signup-popup.component';
import { LoginPopupComponent } from './shared/login-popup/login-popup.component';
import { ChoicePopupComponent } from './shared/choice-popup/choice-popup.component';
import { AddCardComponent } from './shared/add-card/add-card.component';
import { CountDownComponent } from './shared/count-down/count-down.component';
import { UpgradeModalPage } from './shared/upgrade-modal/upgrade-modal.page';

import { BlackbookPopupComponent } from './shared/blackbook-popup/blackbook-popup.component';
import { TrackProfileComponent } from './shared/track-profile/track-profile.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelect2Module } from 'ng-select2';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
import {
	RECAPTCHA_SETTINGS,
	RecaptchaSettings,
	RecaptchaLoaderService,
	RecaptchaModule,
	RecaptchaFormsModule,
} from 'ng-recaptcha';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { BlackBookFilter } from './shared/pipes/blackbook-filter.pipe';
import { TermsAppComponent } from './terms-app/terms-app.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy.component';

import { AutofocusDirective } from './autofocus.directive';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormGuideTabComponent } from './formguide/formguide-tab/formguide-tab.component';
import { SpeedMapsTabComponent } from './formguide/speedmaps-tab/speedmaps-tab.component';
import { LiveYardTabComponent } from './formguide/liveyard-tab/liveyard-tab.component';
import { ReviewsTabComponent } from './formguide/reviews-tab/reviews-tab.component';
import { ResultsTabComponent } from './formguide/results-tab/results-tab.component';
import { GhostElementComponent } from './formguide/ghost-element/ghost-element.component';
import { PreviewsTabComponent } from './formguide/previews-tab/previews-tab.component';
import { SpeedmapsHorsesListComponent } from './formguide/speedmaps-horses-list/speedmaps-horses-list.component';
import { HorseDetailsComponent } from './formguide/horse-details/horse-details.component';
import { HorsePanelHeaderComponent } from './formguide/horse-panel-header/horse-panel-header.component';

import { environment } from '../environments/environment';
import { NotFoundComponent } from './not-found/not-found.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { LandingComponent } from './landing-page/landing.component';

// import { LiveChatComponent } from './live-chat/live-chat.component';

const globalSettings: RecaptchaSettings = { siteKey: environment.googleCaptchPublicKey };
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

declare global {
	interface Window { analytics: any; }
}

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		SignupComponent,
		UpgradeModalPage,
		LoginComponent,
		NewsComponent,
		SingleNewsComponent,
		SingleTournamentComponent,
		RoundRobinTournamentsComponent,
		ResultsComponent,
		LiveyardComponent,
		BlackbookComponent,
		FormguideComponent,
		FormguideArchiveComponent,
		TournamentsComponent,
		HeaderComponent,
		JumpoutsHorsesListComponent,
		FooterComponent,
		ResourcesComponent,
		ResourceComponent,
		SelectplanComponent,
		MakepaymentComponent,
		MyProfileComponent,
		ForgotPasswordComponent,
		ChangePasswordComponent,
		ContactUsComponent,
		CheckoutComponent,
		CheckoutRCComponent,
		SubscribePlanComponent,
		SubscribeOffersComponent,
		ChangePlanComponent,
		JumpoutsComponent,
		AddCardComponent,
		CountDownComponent,
		VerifyTorunamentEntrycodeComponent,
		TournamentPayComponent,
		ConfirmationModalComponent,
		AlertModalComponent,
		BlackbookPopupComponent,
		TrackProfileComponent,
		LoginPopupComponent,
		ChoicePopupComponent,
		SignupPopupComponent,
		BlackBookFilter,
		TermsAppComponent,
		TermsComponent,
		PrivacyPolicyComponent,
		AutofocusDirective,
		FormGuideTabComponent,
		SpeedMapsTabComponent,
		LiveYardTabComponent,
		ReviewsTabComponent,
		ResultsTabComponent,
		GhostElementComponent,
		PreviewsTabComponent,
		SpeedmapsHorsesListComponent,
		HorseDetailsComponent,
		HorsePanelHeaderComponent,
		NotFoundComponent,
		SponsorsComponent,
		LandingComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		HttpClientModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		MomentModule,
		NgbModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		Nl2BrPipeModule,
		NgSelect2Module,
		RecaptchaModule,
		RecaptchaV3Module,
		RecaptchaFormsModule,
		CommonModule,
		NgCircleProgressModule.forRoot({
			radius: 30,
			space: -5,
			units: 'point',
			maxPercent: 100,
			outerStrokeWidth: 5,
			outerStrokeColor: '#67BE53',
			innerStrokeColor: '#e8e8ed',
			innerStrokeWidth: 5,
			animation: true,
			animateTitle: false,
			animationDuration: 1000,
			showUnits: false,
			showTitle: false,
			showSubtitle: true,
			showBackground: false,
			subtitleFontSize: '10px',
			subtitleFontWeight: 'bold',
		}),
		GoogleTagManagerModule.forRoot({
			id: 'GTM-P22GBQT',
		}),
		NgxPaginationModule,
		SlickCarouselModule,
		PerfectScrollbarModule,
		// AngularFireModule.initializeApp(environment.firebase, 'liveChat'),
		AngularFirestoreModule,
	],
	providers: [
		ConfirmationModalService,
		AlertModalService,
		{ provide: RECAPTCHA_SETTINGS, useValue: globalSettings },
		{ provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
		// { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcEIKwaAAAAAGOH-ra2K9ATS74_1TUJvU5p73Az' },
		// { provide: 'googleTagManagerId', useValue: 'GTM-P22GBQT' },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
