<div class="breadcrumbs_wrapper">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title">Sponsors</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/">Home</a></li>
							<li><a routerLink="/sponsors">Sponsors</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end container -->
</div>
<!-- end breadcrumbs_wrapper -->

<div class="sponsors_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-md-10 offset-md-1 sponsor_inner">
				<div class="introduction text-center">
					<p>
						Racing Central is proud to be sponsored by the following organisations
					</p>
				</div>
				<!-- end introduction -->
			</div>
		</div>
		<div class="row sponsors">
		
		    <div class="col-md-4 col_sponsor">
		    
		    	<a href="https://nmgs.com.au/" target="_blank">
		        <img
					class="search-img img-fluid"
					src="assets/images/sponsors/National-Make-Good-Solutions-Logo.svg"
					alt="Sponsors"
				/>
				</a>
				<a href="https://nmgs.com.au/" target="_blank">National Make Good Solutions</a>
		    
		    </div><!-- end col-md-4 col_sponsor -->
		
		</div><!-- end row -->
	</div>
</div>
