import { Component, OnInit, Input, Output, EventEmitter,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { TournamentService } from '../../services/tournaments.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalService } from '../../shared/confirmation-modal/confirmation-modal.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-verify-torunament-entrycode',
	templateUrl: './verify-torunament-entrycode.component.html',
	styleUrls: ['./verify-torunament-entrycode.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class VerifyTorunamentEntrycodeComponent implements OnInit {

	loading: any = false;
	@Input() tournament: any;
	@Input() fromParent: any;

	@Output() updateTournamentEvent: EventEmitter<any> = new EventEmitter<any>();
	submitted: any = false;
	user: any;
	token: any;
	error_message: any;
	entry_code:any;

	constructor(
		private router: Router,
		private TournamentService: TournamentService,
		private ConfirmationModalService: ConfirmationModalService,
		public activeModal: NgbActiveModal,
		private toastr: ToastrService,

	) {
	}

	ngOnInit(): void {

	}


	async onSubmit() {
		this.submitted = true;
		console.log('here' + this.fromParent)
		if (this.entry_code) {
		
			let data = {
				entry_code: this.entry_code,
				tournament_id: this.fromParent.tournament.id,
			};

			this.loading = true;

			try {
				await this.TournamentService.verifyTournamentEntryCode(this.fromParent.user.token, data).subscribe((data: any) => {
					if (data && data.status) {
						this.submitted = false;
						this.activeModal.close(true);
					} else {
						this.loading = false;
						this.error_message = data.message;
						this.submitted = true;
						this.toastr.error('Please enter valid entry code');
					}
				});
			} catch (error) {
				console.log(error);
				this.submitted = true;
				this.toastr.error('Please enter valid entry code');
			}
		} else {
			this.toastr.error('Please enter valid entry code');
		}
	}

	async showTermsPopup(){
		let message =
			'<div>' +
			'<p>By entering this tournament, you agree to the terms and conditions on our website</p>' +
			'</div>' +
			'<a target="_blank" class="terms_link" href="http://theraceclub.com.au/terms-and-conditions">View Terms & Conditions</a>';

		await this.ConfirmationModalService.confirm('Terms & Conditions', message)
			.then((confirmed) => {
			//	console.log('User confirmed:', confirmed);
				if (confirmed) this.agreeTerms(this.fromParent.tournament, 'tournament');
			})
			.catch(() =>
				console.log(
					'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
				)
			);
	}

	async agreeTerms(tournament: any, type: any) {
		let data = {
			id: tournament.id,
			type: type,
		};
		try {
			await this.TournamentService.agreeTerms(this.fromParent.user.token, data).subscribe((data: any) => {
				if (data && data.status) {
					if (type == 'tournament') {
						this.gotoSingleTournament(tournament);
					} else {
						this.gotoRoundRobinTournaments(tournament);
					}
				}
			});
		} catch (error) {
		//	console.log(error);
		}
	}

	decline(){
		this.activeModal.close(false);
	}

	gotoSingleTournament(tournament: any) {
		this.router.navigate(['/tournament/' + tournament.id + '/' + this.slugify(tournament.name)]);
	}

	gotoSingleTournamentView(tournamentView: any) {
		this.router.navigate(['/tournament/view/' + tournamentView.id + '/' + this.slugify(tournamentView.name)]);
	}

	
	gotoRoundRobinTournaments(roundRobinTournament: any) {
		this.router.navigate(['/tournament/round-robin/' + roundRobinTournament.id + '/' + this.slugify(roundRobinTournament.title)]);
	}

	gotoRoundRobinTournamentsView(roundRobinTournamentsView: any) {
		this.router.navigate(['/tournament/round-robin/view/' + roundRobinTournamentsView.id + '/' + this.slugify(roundRobinTournamentsView.title)]);
	}

	slugify(title: any) {
		// console.log(title, title.replace(/\s+/g, '-').toLowerCase());
		return title.replace(/\s+/g, '-').toLowerCase();
	}


}
