<div class="homepage_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-lg-10 offset-lg-1">
				<div class="row">
					<div class="col-lg-12 races_wrapper">
						<div class="panel">
							<div class="row n2j-filters" *ngIf="!loadingRaces">
								<div class="col col-md-9">
									<ngx-slick-carousel
										[config]="slideDaysConfig"
										class="nav"
										[ngClass]="'days-size-' + racesData.length"
									>
										<li ngxSlickItem *ngFor="let row of racesData; let i = index">
											<a [ngClass]="{ active: row.isSelect }" (click)="changeRace(row)">{{
												row?.day
											}}</a>
										</li>
									</ngx-slick-carousel>
								</div>

								<div
									class="
										view_links
										col-md-3
										align-self-center
										text-md-right text-left
										d-md-block d-none
									"
								>
									<a class="arrow_link small" routerLink="/form-guide"
										>View all races
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="41.896"
											height="30"
											viewBox="0 0 41.896 30"
										>
											<g
												class="Group_9062"
												data-name="Group 9062"
												transform="translate(-1588.104 -227)"
											>
												<circle
													class="Ellipse_86"
													data-name="Ellipse 86"
													cx="15"
													cy="15"
													r="15"
													transform="translate(1600 227)"
													fill="#6cfcfd"
												/>
												<g
													class="Group_540"
													data-name="Group 540"
													transform="translate(1588.109 236.846)"
												>
													<line
														class="Line_1"
														data-name="Line 1"
														x1="29.317"
														y1="0.145"
														transform="translate(0 5.478)"
														fill="none"
														stroke="#000"
														stroke-miterlimit="10"
														stroke-width="2"
													/>
													<path
														class="Path_1"
														data-name="Path 1"
														d="M24.331,17.232l5.355-5.623L24.331,5.986"
														transform="translate(-0.369 -5.986)"
														fill="none"
														stroke="#000"
														stroke-miterlimit="10"
														stroke-width="2"
													/>
												</g>
											</g>
										</svg>
									</a>
								</div>
							</div>

							<div class="row n2j-filters ghost" *ngIf="loadingRaces">
								<div class="col col-lg-12">
									<ul class="nav">
										<li>
											<div class="loading-text"></div>
										</li>
									</ul>
								</div>
							</div>

							<div class="row n2j-slider" *ngIf="selectedRace && !loadingRacesData">
								<div class="col-12">
									<ngx-slick-carousel
										class="carousel row"
										#slickModal="slick-carousel"
										[config]="slideRaceConfig"
									>
										<div
											ngxSlickItem
											*ngFor="let row of selectedRace.races; let i = index; first as isFirst"
											class="slide col"
										>
											<div class="single_race bottom-border">
												<div
													class="inner"
													[ngClass]="{ is_featured: row.is_featured }"
													routerLink="/form-guide/{{ row?.slug }}/{{ row?.raceno }}"
												>
													<div class="race-row row">
														<div class="col col_race race-col align-self-center">
															<h4 class="race_name">
																{{ (row?.track_name | slice: 0:7) + '..' }}
																R{{ row?.raceno }}
															</h4>
															<h5 class="team_name" *ngIf="row?.state">
																{{ row?.state }}
															</h5>
														</div>
														<!-- end col_race -->

														<div class="col col_time race-col align-self-center">
															<div
																id="{{ row?.event_id }}"
																class="time-pills"
																[ngClass]="{
																	green: row.status == 'FINAL',
																	grey:
																		(row.status != 'FINAL' || row.status == '') &&
																		row.seconds < -120,
																	'red to-blue':
																		row.seconds <= 300 && row.seconds >= -120,
																	blue: row.seconds > 300 && isFirst,
																	'grey to-blue': row.seconds > 300
																}"
															>
																<ng-template [ngIf]="isFirst">
																	<span
																		[counter]="row?.race_start_time"
																		[race_status]="row?.race_status"
																		[race]="row"
																		(TimerExpired)="timeexpired(row)"
																		(TimerBelowFiveMinutes)="
																			TimerBelowFiveMinutes(row)
																		"
																	></span>
																	<span>{{
																		row?.race_start_time
																			| amFromUtc
																			| amLocal
																			| amDateFormat: 'h:mma'
																	}}</span>
																</ng-template>
																<ng-template [ngIf]="!isFirst">
																	<span>{{
																		row?.race_start_time
																			| amFromUtc
																			| amLocal
																			| amDateFormat: 'h:mma'
																	}}</span>
																	<span
																		[counter]="row?.race_start_time"
																		[race_status]="row?.race_status"
																		[race]="row"
																		(TimerExpired)="timeexpired(row)"
																		(TimerBelowFiveMinutes)="
																			TimerBelowFiveMinutes(row)
																		"
																	></span>
																</ng-template>
															</div>
														</div>
														<!-- end col_time -->
													</div>
													<!-- end row -->
												</div>
												<!-- end inner -->
											</div>
											<!-- end single_race -->
										</div>
									</ngx-slick-carousel>
								</div>
							</div>

							<div class="row n2j-slider ghost" *ngIf="loadingRacesData || loadingRaces">
								<div class="col-12">
									<div class="row">
										<div class="slide col">
											<div class="single_race bottom-border">
												<div class="inner">
													<div class="race-row row">
														<div class="col col_race race-col align-self-center">
															<h4 class="race_name"><div class="loading-text"></div></h4>
															<h5 class="team_name"><div class="loading-text"></div></h5>
														</div>
														<!-- end col_race -->

														<div class="col col_time race-col align-self-center">
															<div class="loading-text"></div>
														</div>
														<!-- end col_time -->
													</div>
													<!-- end row -->
												</div>
												<!-- end inner -->
											</div>
											<!-- end single_race -->
										</div>

										<div class="slide col">
											<div class="single_race bottom-border">
												<div class="inner">
													<div class="race-row row">
														<div class="col col_race race-col align-self-center">
															<h4 class="race_name"><div class="loading-text"></div></h4>
															<h5 class="team_name"><div class="loading-text"></div></h5>
														</div>
														<!-- end col_race -->

														<div class="col col_time race-col align-self-center">
															<div class="loading-text"></div>
														</div>
														<!-- end col_time -->
													</div>
													<!-- end row -->
												</div>
												<!-- end inner -->
											</div>
											<!-- end single_race -->
										</div>

										<div class="slide col">
											<div class="single_race bottom-border">
												<div class="inner">
													<div class="race-row row">
														<div class="col col_race race-col align-self-center">
															<h4 class="race_name"><div class="loading-text"></div></h4>
															<h5 class="team_name"><div class="loading-text"></div></h5>
														</div>
														<!-- end col_race -->

														<div class="col col_time race-col align-self-center">
															<div class="loading-text"></div>
														</div>
														<!-- end col_time -->
													</div>
													<!-- end row -->
												</div>
												<!-- end inner -->
											</div>
											<!-- end single_race -->
										</div>

										<div class="slide col">
											<div class="single_race bottom-border">
												<div class="inner">
													<div class="race-row row">
														<div class="col col_race race-col align-self-center">
															<h4 class="race_name"><div class="loading-text"></div></h4>
															<h5 class="team_name"><div class="loading-text"></div></h5>
														</div>
														<!-- end col_race -->

														<div class="col col_time race-col align-self-center">
															<div class="loading-text"></div>
														</div>
														<!-- end col_time -->
													</div>
													<!-- end row -->
												</div>
												<!-- end inner -->
											</div>
											<!-- end single_race -->
										</div>

										<div class="slide col">
											<div class="single_race bottom-border">
												<div class="inner">
													<div class="race-row row">
														<div class="col col_race race-col align-self-center">
															<h4 class="race_name"><div class="loading-text"></div></h4>
															<h5 class="team_name"><div class="loading-text"></div></h5>
														</div>
														<!-- end col_race -->

														<div class="col col_time race-col align-self-center">
															<div class="loading-text"></div>
														</div>
														<!-- end col_time -->
													</div>
													<!-- end row -->
												</div>
												<!-- end inner -->
											</div>
											<!-- end single_race -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-xl-8 col_left">
						<div
							class="row previews_widget d-xl-none"
							*ngIf="previews?.length > 0 && !loadingTodaysPreviews"
						>
							<div class="col-12">
								<div class="row">
									<h2 class="heading col-6 align-self-center">Upcoming Previews</h2>
									<div class="view_links col-6 align-self-center text-right">
										<a class="title_link black no_after small" routerLink="/form-guide">
											View all
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="7.46"
												height="12.625"
												viewBox="0 0 7.46 12.625"
											>
												<path
													class="line_arrow"
													data-name="Path 393"
													d="M24.331,17.232l5.355-5.623L24.331,5.986"
													transform="translate(-23.607 -5.296)"
													fill="none"
													stroke="#000"
													stroke-miterlimit="10"
													stroke-width="2"
												/>
											</svg>
										</a>
									</div>
								</div>

								<div class="row">
									<div class="col-12 previews_single" *ngFor="let row of previews">
										<div class="panel">
											<div
												class="p-row row"
												routerLink="form-guide/{{ row.slug }}/{{ row.raceno }}"
											>
												<div
													class="col_title_link col align-self-center"
													routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}"
												>
													<a
														routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}"
														class="title_link small"
														>{{ row.venue }}</a
													>
												</div>

												<div class="col_next_race text-right col align-self-center" routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}">
													<a>
														<div class="next_race">
															<span class="next_text">Next Race</span>
															<span class="race_no align-middle"
																><p>{{ row.raceno }}</p></span
															>
														</div>
													</a>
												</div>
											</div>
										</div>
									</div>
									<!-- end previews_single -->
								</div>
							</div>
						</div>
						<!-- end previews_widget -->

						<!-- GHOST ELEMENT -->
						<div class="row previews_widget d-xl-none" *ngIf="loadingTodaysPreviews">
							<div class="col-12">
								<div class="row">
									<h2 class="heading col-lg-6 align-self-center"></h2>
								</div>
								<div class="row">
									<div class="col-12 previews_single" *ngFor="let number of [0, 1, 2, 3]">
										<div class="panel">
											<div class="p-row row">
												<div class="col_title_link col align-self-center">
													<div class="loading-text"></div>
												</div>
												<div class="col_next_race text-right col align-self-center">
													<div class="loading-text"></div>
												</div>
											</div>
										</div>
									</div>
									<!-- end previews_single -->
								</div>
							</div>
						</div>
						<!-- end previews_widget -->

						<div
							class="row limited_time_offer"
							*ngIf="!loadingOffers && offer && (offer.platform == 'BOTH' || offer.platform == 'WEBSITE')"
						>
							<div class="col-12">
								<div class="panel black">
									<div class="row">
										<div class="col-md-6 col_content">
											<div class="inner">
												<!-- <h2 class="title">Insert limited time offer headline here</h2> -->
												<h2 class="title">{{ offer?.heading }}</h2>

												<div class="body white">
													{{ offer?.description }}
												</div>

												<a
													*ngFor="let row of offer?.buttons"
													(click)="OfferClicked(row)"
													class="btn"
													>{{ row?.key }}</a
												>
											</div>

											<div
												class="image_mobile"
												[style.backgroundImage]="'url(' + offer?.image_url + ')'"
											></div>
										</div>

										<div class="col-md-6 col_img">
											<div
												class="inner"
												[style.backgroundImage]="'url(' + offer?.image_url + ')'"
											></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- end limited_time_offer -->

						<div class="row limited_time_offer" *ngIf="loadingOffers">
							<div class="col-12">
								<div class="panel black">
									<div class="row">
										<div class="col-md-6 col_content">
											<div class="inner">
												<!-- <h2 class="title">Insert limited time offer headline here</h2> -->
												<h2 class="title loading-text"></h2>

												<div class="body white loading-text"></div>

												<a class="btn loading-text"></a>
											</div>

											<div class="image_mobile"></div>
										</div>

										<div class="col-md-6 col_img">
											<div class="inner"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- end limited_time_offer -->

						<div
							class="news_vertical_widget horizontal d-xl-none row"
							*ngIf="news?.length > 0 && !loadingNews"
						>
							<div class="col-12">
								<div class="panel">
									<div class="row">
										<h2 class="heading col-sm-4 align-self-center">News</h2>
										<div class="view_links col-sm-8 align-self-center text-right d-none d-sm-block">
											<a class="arrow_link small" routerLink="/news" routerLink="/news"
												>View all news
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="41.896"
													height="30"
													viewBox="0 0 41.896 30"
												>
													<g
														class="Group_9062"
														data-name="Group 9062"
														transform="translate(-1588.104 -227)"
													>
														<circle
															class="Ellipse_86"
															data-name="Ellipse 86"
															cx="15"
															cy="15"
															r="15"
															transform="translate(1600 227)"
															fill="#6cfcfd"
														/>
														<g
															class="Group_540"
															data-name="Group 540"
															transform="translate(1588.109 236.846)"
														>
															<line
																class="Line_1"
																data-name="Line 1"
																x1="29.317"
																y1="0.145"
																transform="translate(0 5.478)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
															<path
																class="Path_1"
																data-name="Path 1"
																d="M24.331,17.232l5.355-5.623L24.331,5.986"
																transform="translate(-0.369 -5.986)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
														</g>
													</g>
												</svg>
											</a>
										</div>
									</div>

									<div class="row">
										<div
											class="col-sm-4 col-12 news_single bottom-border gradient"
											*ngFor="let row of news"
										>
											<article class="d-flex flex-column">
												<a routerLink="/news/{{ row.id }}/{{ row.slug }}">
													<div
														class="news_img" loading=”lazy”
														[style.backgroundImage]="'url(' + row.image_url + ')'"
													></div>
												</a>
												<div class="content flex-grow-1 d-flex flex-column">
													<div class="row title_wrap">
														<div class="col col-8 align-self-center">
															<h3>
																<a routerLink="/news/{{ row.id }}/{{ row.slug }}">{{
																	row?.title
																}}</a>
															</h3>
														</div>
														<div class="col col-4 pl-0 align-self-top text-right">
															<div class="date">
																{{ row?.created_at | date: 'd MMM y' }}
															</div>
														</div>
													</div>
													<div class="body" *ngIf="row?.short_description">
														{{
															row?.short_description.length > 80
																? (row?.short_description | slice: 0:80) + '..'
																: row?.short_description
														}}
													</div>
													<div class="read-link mt-auto">
														<a
															class="link small"
															routerLink="/news/{{ row.id }}/{{ row.slug }}"
															>Read more</a
														>
													</div>
												</div>
												<!-- end content -->
											</article>
										</div>
										<!-- end news_single -->
									</div>

									<div class="row">
										<div class="view_links col-lg-8 align-self-center text-left d-block d-sm-none">
											<a class="arrow_link small" routerLink="/news"
												>View more news
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="41.896"
													height="30"
													viewBox="0 0 41.896 30"
												>
													<g
														class="Group_9062"
														data-name="Group 9062"
														transform="translate(-1588.104 -227)"
													>
														<circle
															class="Ellipse_86"
															data-name="Ellipse 86"
															cx="15"
															cy="15"
															r="15"
															transform="translate(1600 227)"
															fill="#6cfcfd"
														/>
														<g
															class="Group_540"
															data-name="Group 540"
															transform="translate(1588.109 236.846)"
														>
															<line
																class="Line_1"
																data-name="Line 1"
																x1="29.317"
																y1="0.145"
																transform="translate(0 5.478)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
															<path
																class="Path_1"
																data-name="Path 1"
																d="M24.331,17.232l5.355-5.623L24.331,5.986"
																transform="translate(-0.369 -5.986)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
														</g>
													</g>
												</svg>
											</a>
										</div>
									</div>
									<!-- end row -->
								</div>
								<!-- end panel -->
							</div>
						</div>
						<!-- end news_wrapper -->

						<div class="row info_links_boxes" *ngIf="!loadingAllData">
							<div class="col-lg-3 col-6 info_links_box live_chat_col">
								<a routerLink="form-guide" class="panel black">
									<div class="svg_wrapper livechat">
										<svg xmlns="http://www.w3.org/2000/svg" width="23.391" height="26.12" viewBox="0 0 23.391 26.12">
										  <g id="Group_10094" data-name="Group 10094" transform="translate(0 0)">
										    <g id="Group_9044" data-name="Group 9044" transform="translate(1.787 1.47)" style="mix-blend-mode: lighten;isolation: isolate">
										      <path id="Path_8298" data-name="Path 8298" d="M460.321,1981.743c-3.335-4.922-.982-8.765-.982-8.765-.547-.161-2.3.984-2.3.984.984-3.605,6.394-6.672,6.394-6.672a6.247,6.247,0,0,0-2.294.218,10.644,10.644,0,0,1,9.838-2.676c.161-.925,2.842-2.564,2.842-2.564a5.085,5.085,0,0,0-.384,1.749,3.667,3.667,0,0,1,1.149-.984,8.424,8.424,0,0,0,0,3.6c1.42,1.422,3,6.341,3,6.341l.6.874c.766,1.095.484,2.816-.274,3.223-2.841,1.533-3.115-1.257-3.115-1.257-2.35-.274-4.1-2.9-4.1-2.9-3.166,1.638-1.4,6.263-.036,8.038,1.757,2.277.47,5.957.47,5.957S463.614,1986.606,460.321,1981.743Z" transform="translate(-457.044 -1962.267)" fill="red"/>
										    </g>
										    <g id="Group_9044-2" data-name="Group 9044" transform="translate(0)" style="mix-blend-mode: lighten;isolation: isolate">
										      <path id="Path_8298-2" data-name="Path 8298" d="M460.321,1981.743c-3.335-4.922-.982-8.765-.982-8.765-.547-.161-2.3.984-2.3.984.984-3.605,6.394-6.672,6.394-6.672a6.247,6.247,0,0,0-2.294.218,10.644,10.644,0,0,1,9.838-2.676c.161-.925,2.842-2.564,2.842-2.564a5.085,5.085,0,0,0-.384,1.749,3.667,3.667,0,0,1,1.149-.984,8.424,8.424,0,0,0,0,3.6c1.42,1.422,3,6.341,3,6.341l.6.874c.766,1.095.484,2.816-.274,3.223-2.841,1.533-3.115-1.257-3.115-1.257-2.35-.274-4.1-2.9-4.1-2.9-3.166,1.638-1.4,6.263-.036,8.038,1.757,2.277.47,5.957.47,5.957S463.614,1986.606,460.321,1981.743Z" transform="translate(-457.044 -1962.267)" fill="aqua"/>
										    </g>
										  </g>
										</svg>

									</div>
									<!-- end svg_wrapper -->
									<h3 class="title">Form Guide</h3> </a
								><!-- end inner -->
							</div>
							<!-- end info_links_box -->
							<!--<div class="col-lg-3 col-6 info_links_box tournament_col">
								<a routerLink="tournaments" class="panel black">
									<div class="svg_wrapper tournaments">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="112.768"
											height="101.99"
											viewBox="0 0 112.768 101.99"
										>
											<path
												id="Path_8333"
												data-name="Path 8333"
												d="M146.917,55.807a3.967,3.967,0,0,0-2.838-1.195H129.7c.071-1.928.111-3.915.111-5.973H57.465c0,2.058.04,4.045.111,5.973H43.2a3.968,3.968,0,0,0-3.967,4.059c.407,17.754,8.976,32.641,21.829,37.928a29.107,29.107,0,0,0,8.691,2.069c5.792,8.088,12.373,11.626,16.749,13.223l.007,17.326H69.174v10.795H63.448v6.053h60.386v-6.049H118.1v-10.8H100.767l-.172-17.327h.176c4.376-1.6,10.961-5.133,16.756-13.226a29.1,29.1,0,0,0,8.691-2.069c12.853-5.286,21.422-20.174,21.83-37.928A3.968,3.968,0,0,0,146.917,55.807ZM64.083,89.26c-9-3.7-15.3-13.959-16.653-26.71H58.092c1.072,11.309,3.441,20.077,6.449,26.881C64.389,89.372,64.234,89.322,64.083,89.26Zm59.116,0c-.15.062-.306.112-.458.171,3.007-6.8,5.376-15.572,6.449-26.881h10.662C138.5,75.3,132.2,85.557,123.2,89.26Z"
												transform="translate(-35.279 -44.076)"
												fill="red"
												style="mix-blend-mode: lighten; isolation: isolate"
											/>
											<path
												id="Path_8334"
												data-name="Path 8334"
												d="M146.917,55.807a3.967,3.967,0,0,0-2.838-1.195H129.7c.071-1.928.111-3.915.111-5.973H57.465c0,2.058.04,4.045.111,5.973H43.2a3.968,3.968,0,0,0-3.967,4.059c.407,17.754,8.976,32.641,21.829,37.928a29.107,29.107,0,0,0,8.691,2.069c5.792,8.088,12.373,11.626,16.749,13.223l.007,17.326H69.174v10.795H63.448v6.053h60.386v-6.049H118.1v-10.8H100.767l-.172-17.327h.176c4.376-1.6,10.961-5.133,16.756-13.226a29.1,29.1,0,0,0,8.691-2.069c12.853-5.286,21.422-20.174,21.83-37.928A3.968,3.968,0,0,0,146.917,55.807ZM64.083,89.26c-9-3.7-15.3-13.959-16.653-26.71H58.092c1.072,11.309,3.441,20.077,6.449,26.881C64.389,89.372,64.234,89.322,64.083,89.26Zm59.116,0c-.15.062-.306.112-.458.171,3.007-6.8,5.376-15.572,6.449-26.881h10.662C138.5,75.3,132.2,85.557,123.2,89.26Z"
												transform="translate(-39.234 -48.64)"
												fill="aqua"
												style="mix-blend-mode: lighten; isolation: isolate"
											/>
										</svg>
									</div>
									<h3 class="title">Tournaments</h3>
								</a>
							</div>-->
							
							<div class="col-lg-3 col-6 info_links_box live_yard_col">
								<a routerLink="liveyard" class="panel black">
									<div class="svg_wrapper liveyard">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="96.458"
											height="85.546"
											viewBox="0 0 96.458 85.546"
										>
											<g id="Group_8541" data-name="Group 8541" transform="translate(0)">
												<g id="Group_8536" data-name="Group 8536" transform="translate(0)">
													<path
														id="Path_443"
														data-name="Path 443"
														d="M-81.117,1654.57l-13.955-42.649-.063.013a13.122,13.122,0,0,0-12.206-8.307,13.121,13.121,0,0,0-13.12,13.121h0v18.572h-11.882v-18.572h0a13.121,13.121,0,0,0-13.12-13.121,13.122,13.122,0,0,0-12.206,8.307l-.063-.013-13.955,42.649a20.267,20.267,0,0,0-1.289,7.134,20.317,20.317,0,0,0,20.317,20.317,20.317,20.317,0,0,0,20.317-20.317v-2.272h11.882v2.272a20.317,20.317,0,0,0,20.317,20.317A20.317,20.317,0,0,0-79.828,1661.7,20.266,20.266,0,0,0-81.117,1654.57Zm-71.544,21.147a14.013,14.013,0,0,1-14.013-14.013,14.014,14.014,0,0,1,14.013-14.014,14.014,14.014,0,0,1,14.013,14.014A14.013,14.013,0,0,1-152.661,1675.717Zm52.516,0a14.013,14.013,0,0,1-14.013-14.013,14.014,14.014,0,0,1,14.013-14.014A14.014,14.014,0,0,1-86.132,1661.7,14.013,14.013,0,0,1-100.145,1675.717Z"
														transform="translate(172.978 -1603.626)"
														fill="aqua"
														style="mix-blend-mode: lighten; isolation: isolate"
													/>
													<path
														id="Path_444"
														data-name="Path 444"
														d="M-81.117,1654.57l-13.955-42.649-.063.013a13.122,13.122,0,0,0-12.206-8.307,13.121,13.121,0,0,0-13.12,13.121h0v18.572h-11.882v-18.572h0a13.121,13.121,0,0,0-13.12-13.121,13.122,13.122,0,0,0-12.206,8.307l-.063-.013-13.955,42.649a20.267,20.267,0,0,0-1.289,7.134,20.317,20.317,0,0,0,20.317,20.317,20.317,20.317,0,0,0,20.317-20.317v-2.272h11.882v2.272a20.317,20.317,0,0,0,20.317,20.317A20.317,20.317,0,0,0-79.828,1661.7,20.266,20.266,0,0,0-81.117,1654.57Zm-71.544,21.147a14.013,14.013,0,0,1-14.013-14.013,14.014,14.014,0,0,1,14.013-14.014,14.014,14.014,0,0,1,14.013,14.014A14.013,14.013,0,0,1-152.661,1675.717Zm52.516,0a14.013,14.013,0,0,1-14.013-14.013,14.014,14.014,0,0,1,14.013-14.014A14.014,14.014,0,0,1-86.132,1661.7,14.013,14.013,0,0,1-100.145,1675.717Z"
														transform="translate(176.286 -1596.475)"
														fill="red"
														style="mix-blend-mode: lighten; isolation: isolate"
													/>
												</g>
											</g>
										</svg>
									</div>
									<!-- end svg_wrapper -->
									<h3 class="title">Live Yard</h3> </a
								><!-- end inner -->
							</div>
							<!-- end info_links_box -->

							<div class="col-lg-3 col-6 info_links_box tournament_col">
								<a routerLink="jumpouts" class="panel black">
									<div class="svg_wrapper jumpouts">
										<svg id="Group_10095" data-name="Group 10095" xmlns="http://www.w3.org/2000/svg" width="33.701" height="25.205" viewBox="0 0 33.701 25.205">
										  <g id="Group_9486" data-name="Group 9486" style="mix-blend-mode: lighten;isolation: isolate">
										    <path id="Path_8560" data-name="Path 8560" d="M7205.118,3726.034a4.058,4.058,0,0,1,1.345-1.322,5.318,5.318,0,0,1,1.165-1.968,8.875,8.875,0,0,1,3.213-2.876c1.043-.476,2.522-1.219,3.355-1.643a8.316,8.316,0,0,0,.715.957c.084.1.19.228.256.318a5.074,5.074,0,0,1-.834,1.173h0a3.932,3.932,0,0,0-.28.364q-.314.353-.593.669c-1.071-.147-2.635-.155-3.5.806a16.074,16.074,0,0,1-2.487,2.3,3.541,3.541,0,0,1-.648,2.33c-.751.751-2.253.388-2.253.388A5.759,5.759,0,0,1,7205.118,3726.034Z" transform="translate(-7204.572 -3705.571)" fill="aqua" fill-rule="evenodd"/>
										    <path id="Path_8561" data-name="Path 8561" d="M6280.448,3201.363a2.289,2.289,0,0,1,.218-.3c3.01-3.478-.379-2.687-.6-6.271a3.208,3.208,0,0,0-1.4-2.256,3.251,3.251,0,0,1-1.134,1.388,4.354,4.354,0,0,1-1.819.677s.055,1.134-.856,1.333a1.547,1.547,0,0,1-1.854-1.576,2.472,2.472,0,0,1,.891-1.594,7.447,7.447,0,0,1,.574-1.95,11,11,0,0,1,1.5-2.676,4.086,4.086,0,0,0,.111-1.588,4.47,4.47,0,0,0-.289-1.254,3.815,3.815,0,0,1,.962,1.048,5.813,5.813,0,0,0-.3-1.394,10.347,10.347,0,0,1,1.3.988,2.491,2.491,0,0,1,.745,1,8.127,8.127,0,0,1,4.264.069,7.807,7.807,0,0,1,3.076,1.964,3.043,3.043,0,0,0-.977-.422,2.866,2.866,0,0,0-.919-.054c.2.106.418.227.64.364a16.382,16.382,0,0,1,4.971,3.134,17.651,17.651,0,0,0-2.455-.956q.177.211.349.44a5.913,5.913,0,0,0,2.287,1.073l12.709.537-11.7,1.572,8.5.83-8.994,1.851,5.909.664a13.106,13.106,0,0,0-7.024,1.97,12.352,12.352,0,0,0-2.268,1.854,36.431,36.431,0,0,0-4.05,1.314c-2.19.674-3.27,1.5-2.91,2.153a4.237,4.237,0,0,0,1.06,1.525c.537.431,1.062.917,1.062.917s.306-.317.494-.474a2.411,2.411,0,0,1,.706-.274,2.05,2.05,0,0,1,.971.788,2.25,2.25,0,0,1,.445,1.322,5.5,5.5,0,0,1-3.966-.982c-2.07-1.4-3.532-2.773-2.753-3.809C6278.338,3203.75,6279.483,3202.444,6280.448,3201.363Z" transform="translate(-6269.74 -3184.956)" fill="aqua" fill-rule="evenodd"/>
										  </g>
										  <g id="Group_9491" data-name="Group 9491" transform="translate(1 1)" style="mix-blend-mode: lighten;isolation: isolate">
										    <path id="Path_8560-2" data-name="Path 8560" d="M7205.118,3726.034a4.058,4.058,0,0,1,1.345-1.322,5.318,5.318,0,0,1,1.165-1.968,8.875,8.875,0,0,1,3.213-2.876c1.043-.476,2.522-1.219,3.355-1.643a8.316,8.316,0,0,0,.715.957c.084.1.19.228.256.318a5.074,5.074,0,0,1-.834,1.173h0a3.932,3.932,0,0,0-.28.364q-.314.353-.593.669c-1.071-.147-2.635-.155-3.5.806a16.074,16.074,0,0,1-2.487,2.3,3.541,3.541,0,0,1-.648,2.33c-.751.751-2.253.388-2.253.388A5.759,5.759,0,0,1,7205.118,3726.034Z" transform="translate(-7204.572 -3705.571)" fill="red" fill-rule="evenodd"/>
										    <path id="Path_8561-2" data-name="Path 8561" d="M6280.448,3201.363a2.289,2.289,0,0,1,.218-.3c3.01-3.478-.379-2.687-.6-6.271a3.208,3.208,0,0,0-1.4-2.256,3.251,3.251,0,0,1-1.134,1.388,4.354,4.354,0,0,1-1.819.677s.055,1.134-.856,1.333a1.547,1.547,0,0,1-1.854-1.576,2.472,2.472,0,0,1,.891-1.594,7.447,7.447,0,0,1,.574-1.95,11,11,0,0,1,1.5-2.676,4.086,4.086,0,0,0,.111-1.588,4.47,4.47,0,0,0-.289-1.254,3.815,3.815,0,0,1,.962,1.048,5.813,5.813,0,0,0-.3-1.394,10.347,10.347,0,0,1,1.3.988,2.491,2.491,0,0,1,.745,1,8.127,8.127,0,0,1,4.264.069,7.807,7.807,0,0,1,3.076,1.964,3.043,3.043,0,0,0-.977-.422,2.866,2.866,0,0,0-.919-.054c.2.106.418.227.64.364a16.382,16.382,0,0,1,4.971,3.134,17.651,17.651,0,0,0-2.455-.956q.177.211.349.44a5.913,5.913,0,0,0,2.287,1.073l12.709.537-11.7,1.572,8.5.83-8.994,1.851,5.909.664a13.106,13.106,0,0,0-7.024,1.97,12.352,12.352,0,0,0-2.268,1.854,36.431,36.431,0,0,0-4.05,1.314c-2.19.674-3.27,1.5-2.91,2.153a4.237,4.237,0,0,0,1.06,1.525c.537.431,1.062.917,1.062.917s.306-.317.494-.474a2.411,2.411,0,0,1,.706-.274,2.05,2.05,0,0,1,.971.788,2.25,2.25,0,0,1,.445,1.322,5.5,5.5,0,0,1-3.966-.982c-2.07-1.4-3.532-2.773-2.753-3.809C6278.338,3203.75,6279.483,3202.444,6280.448,3201.363Z" transform="translate(-6269.74 -3184.956)" fill="red" fill-rule="evenodd"/>
										  </g>
										</svg>

									</div>
									<h3 class="title">Jump Outs</h3>
								</a>
							</div>
							<!-- end info_links_box -->

							<div class="col-lg-3 col-6 info_links_box blackbook_col">
								<a routerLink="blackbook" class="panel black">
									<!--<div class="icon book" style="background-image: url('assets/images/icons/blackbook.png');"></div>-->
									<div class="svg_wrapper book">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="138.664"
											height="105.15"
											viewBox="0 0 138.664 105.15"
										>
											<g id="Group_8542" data-name="Group 8542" transform="translate(0)">
												<g id="Group_8535" data-name="Group 8535" transform="translate(0)">
													<g
														id="Group_8529"
														data-name="Group 8529"
														style="mix-blend-mode: lighten; isolation: isolate"
													>
														<path
															id="Path_438"
															data-name="Path 438"
															d="M351.578,347.2h-.011a4.609,4.609,0,0,0-3.277,1.358,4.657,4.657,0,0,0-1.376,3.32v70.14A4.694,4.694,0,0,0,351.6,426.7c10.887.026,29.13,2.3,41.712,15.462v-73.4a4.464,4.464,0,0,0-.643-2.368c-10.328-16.634-30.176-19.161-41.089-19.187Zm0,0"
															transform="translate(-331.583 -347.202)"
															fill="aqua"
														/>
														<path
															id="Path_439"
															data-name="Path 439"
															d="M557.511,422.02V351.878a4.654,4.654,0,0,0-1.377-3.318,4.6,4.6,0,0,0-3.277-1.358h-.01c-10.911.026-30.759,2.552-41.087,19.187a4.471,4.471,0,0,0-.643,2.368v73.4c12.583-13.169,30.824-15.438,41.713-15.464a4.693,4.693,0,0,0,4.682-4.674Zm0,0"
															transform="translate(-442.126 -347.202)"
															fill="aqua"
														/>
														<path
															id="Path_440"
															data-name="Path 440"
															d="M581.523,396.7h-3.391v58.642A11.969,11.969,0,0,1,566.2,467.281c-9.236.022-24.463,1.827-35.246,12.034,18.651-4.567,38.313-1.6,49.518.954a4.676,4.676,0,0,0,5.723-4.556V401.377a4.68,4.68,0,0,0-4.676-4.676Zm0,0"
															transform="translate(-455.484 -380.525)"
															fill="aqua"
														/>
														<path
															id="Path_441"
															data-name="Path 441"
															d="M308.068,455.344V396.7h-3.391A4.681,4.681,0,0,0,300,401.377v74.335a4.678,4.678,0,0,0,5.723,4.558c11.207-2.555,30.869-5.523,49.52-.956C344.459,469.108,329.231,467.3,320,467.281a11.969,11.969,0,0,1-11.929-11.938Zm0,0"
															transform="translate(-300 -380.525)"
															fill="aqua"
														/>
													</g>
													<g
														id="Group_8530"
														data-name="Group 8530"
														transform="translate(7.95 5.285)"
														style="mix-blend-mode: lighten; isolation: isolate"
													>
														<path
															id="Path_438-2"
															data-name="Path 438"
															d="M351.578,347.2h-.011a4.609,4.609,0,0,0-3.277,1.358,4.657,4.657,0,0,0-1.376,3.32v70.14A4.694,4.694,0,0,0,351.6,426.7c10.887.026,29.13,2.3,41.712,15.462v-73.4a4.464,4.464,0,0,0-.643-2.368c-10.328-16.634-30.176-19.161-41.089-19.187Zm0,0"
															transform="translate(-331.583 -347.202)"
															fill="red"
														/>
														<path
															id="Path_439-2"
															data-name="Path 439"
															d="M557.511,422.02V351.878a4.654,4.654,0,0,0-1.377-3.318,4.6,4.6,0,0,0-3.277-1.358h-.01c-10.911.026-30.759,2.552-41.087,19.187a4.471,4.471,0,0,0-.643,2.368v73.4c12.583-13.169,30.824-15.438,41.713-15.464a4.693,4.693,0,0,0,4.682-4.674Zm0,0"
															transform="translate(-442.126 -347.202)"
															fill="red"
														/>
														<path
															id="Path_440-2"
															data-name="Path 440"
															d="M581.523,396.7h-3.391v58.642A11.969,11.969,0,0,1,566.2,467.281c-9.236.022-24.463,1.827-35.246,12.034,18.651-4.567,38.313-1.6,49.518.954a4.676,4.676,0,0,0,5.723-4.556V401.377a4.68,4.68,0,0,0-4.676-4.676Zm0,0"
															transform="translate(-455.484 -380.525)"
															fill="red"
														/>
														<path
															id="Path_441-2"
															data-name="Path 441"
															d="M308.068,455.344V396.7h-3.391A4.681,4.681,0,0,0,300,401.377v74.335a4.678,4.678,0,0,0,5.723,4.558c11.207-2.555,30.869-5.523,49.52-.956C344.459,469.108,329.231,467.3,320,467.281a11.969,11.969,0,0,1-11.929-11.938Zm0,0"
															transform="translate(-300 -380.525)"
															fill="red"
														/>
													</g>
												</g>
											</g>
										</svg>
									</div>
									<!-- end svg_wrpaper book -->
									<h3 class="title">Blackbook</h3> </a
								><!-- end inner -->
							</div>
							<!-- end info_links_box -->
						</div>
						<!-- end info_links_boxes -->

						<!-- GHOST ELEMENT -->
						<div class="row info_links_boxes" *ngIf="loadingAllData">
							<div class="col-lg-3 col-sm-6 info_links_box" *ngFor="let number of [0, 1, 2, 3]">
								<a href="#" class="panel black loading-text"> </a
								><!-- end inner -->
							</div>
							<!-- end info_links_box -->
						</div>
						<!-- end info_links_boxes -->

						<div class="row results_wrapper" *ngIf="results?.length > 0 && !loadingResults">
							<div class="col-12">
								<div class="panel with_inner_content">
									<div class="row">
										<h2 class="heading col-6 align-self-center">Results</h2>
										<div class="view_links col-6 align-self-center text-right d-none d-md-block">
											<a class="arrow_link small" routerLink="results"
												>See all results
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="41.896"
													height="30"
													viewBox="0 0 41.896 30"
												>
													<g
														class="Group_9062"
														data-name="Group 9062"
														transform="translate(-1588.104 -227)"
													>
														<circle
															class="Ellipse_86"
															data-name="Ellipse 86"
															cx="15"
															cy="15"
															r="15"
															transform="translate(1600 227)"
															fill="#6cfcfd"
														/>
														<g
															class="Group_540"
															data-name="Group 540"
															transform="translate(1588.109 236.846)"
														>
															<line
																class="Line_1"
																data-name="Line 1"
																x1="29.317"
																y1="0.145"
																transform="translate(0 5.478)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
															<path
																class="Path_1"
																data-name="Path 1"
																d="M24.331,17.232l5.355-5.623L24.331,5.986"
																transform="translate(-0.369 -5.986)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
														</g>
													</g>
												</svg>
											</a>
										</div>
									</div>

									<div class="row">
										<div
											class="results_single"
											*ngFor="let row of results; let isFirst = first"
											[ngClass]="isFirst ? 'col-md-8 col-sm-12' : 'col-md-4 col-sm-6'"
											routerLink="/results/{{ row?.meeting_id }}"
										>
											<a routerLink="/results/{{ row?.meeting_id }}">
												<div class="panel">
													<div
														*ngIf="row?.image_url"
														class="bg_image"
														[style.backgroundImage]="'url(' + row?.image_url + ')'"
													></div>
													<!-- end bg_image -->
													<div *ngIf="!row?.image_url" class="bg_image default"></div>
													<!-- end bg_image -->

													<div class="inner">
														<div class="inner">
															<a class="title_link white no_after">
																{{ row.venue }}
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="7.46"
																	height="12.625"
																	viewBox="0 0 7.46 12.625"
																>
																	<path
																		class="line_arrow"
																		data-name="Path 393"
																		d="M24.331,17.232l5.355-5.623L24.331,5.986"
																		transform="translate(-23.607 -5.296)"
																		fill="none"
																		stroke="#fff"
																		stroke-miterlimit="10"
																		stroke-width="2"
																	/>
																</svg>
															</a>
															<div class="date">
																{{ row.race_start_time | date: 'dd MMMM yyyy' }}
															</div>
															<!-- 13 november 2020  -->
														</div>
														<!-- end inner -->
													</div>
													<!-- end inner -->
												</div>
												<!-- end panel -->
											</a>
										</div>
										<!-- end results_single -->

										<div class="view_links col-12 align-self-center text-right d-md-none">
											<a class="arrow_link small" routerLink="results"
												>See all results
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="41.896"
													height="30"
													viewBox="0 0 41.896 30"
												>
													<g
														class="Group_9062"
														data-name="Group 9062"
														transform="translate(-1588.104 -227)"
													>
														<circle
															class="Ellipse_86"
															data-name="Ellipse 86"
															cx="15"
															cy="15"
															r="15"
															transform="translate(1600 227)"
															fill="#6cfcfd"
														/>
														<g
															class="Group_540"
															data-name="Group 540"
															transform="translate(1588.109 236.846)"
														>
															<line
																class="Line_1"
																data-name="Line 1"
																x1="29.317"
																y1="0.145"
																transform="translate(0 5.478)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
															<path
																class="Path_1"
																data-name="Path 1"
																d="M24.331,17.232l5.355-5.623L24.331,5.986"
																transform="translate(-0.369 -5.986)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
														</g>
													</g>
												</svg>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- end results_wrapper -->

						<!-- GHOST ELEMENT -->
						<!-- <div class="row results_wrapper" *ngIf="loadingResults"> -->
						<div class="row results_wrapper" *ngIf="loadingResults">
							<div class="col-12">
								<div class="panel with_inner_content">
									<div class="row">
										<h2 class="heading col-lg-6 align-self-center">Results</h2>
									</div>

									<div class="row">
										<div
											class="results_single"
											[ngClass]="[isFirst ? 'col-md-8 col-sm-12' : 'col-md-4 col-sm-6']"
											*ngFor="let number of [0, 1, 2, 3, 4]; let i = index; first as isFirst"
										>
											<div class="panel grey">
												<div class="inner">
													<div class="inner">
														<div class="loading-text"></div>
														<div class="date"><div class="loading-text"></div></div>
													</div>
													<!-- end inner -->
												</div>
												<!-- end inner -->
											</div>
											<!-- end panel -->
										</div>
										<!-- end results_single -->
									</div>
								</div>
							</div>
						</div>
						<!-- end results_wrapper -->

						<div class="row resources_widget" *ngIf="resources?.length > 0 && !loadingResources">
							<div class="col-12">
								<div class="panel with_inner_content">
									<div class="row">
										<h2 class="heading col-lg-6 align-self-center">Resources</h2>
										<div class="view_links col-lg-6 align-self-center text-right d-none d-md-block">
											<a class="arrow_link small" routerLink="/resources" routerLink="/resources"
												>View all resources

												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="41.896"
													height="30"
													viewBox="0 0 41.896 30"
												>
													<g
														class="Group_9062"
														data-name="Group 9062"
														transform="translate(-1588.104 -227)"
													>
														<circle
															class="Ellipse_86"
															data-name="Ellipse 86"
															cx="15"
															cy="15"
															r="15"
															transform="translate(1600 227)"
															fill="#6cfcfd"
														/>
														<g
															class="Group_540"
															data-name="Group 540"
															transform="translate(1588.109 236.846)"
														>
															<line
																class="Line_1"
																data-name="Line 1"
																x1="29.317"
																y1="0.145"
																transform="translate(0 5.478)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
															<path
																class="Path_1"
																data-name="Path 1"
																d="M24.331,17.232l5.355-5.623L24.331,5.986"
																transform="translate(-0.369 -5.986)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
														</g>
													</g>
												</svg>
											</a>
										</div>
									</div>

									<div class="row">
										<div
											class="col-md-4 resources_single d-flex flex-column"
											*ngFor="let row of resources"
										>
											<div class="panel grey flex-grow-1 d-flex flex-column">
												<h4 class="title">
													<a (click)="gotoSingleResource(row)">{{ row?.title }}</a>
												</h4>

												<div class="body" *ngIf="row?.short_description">
													{{
														row?.short_description.length > 80
															? (row?.short_description | slice: 0:80) + '..'
															: row?.short_description
													}}
												</div>

												<div class="read-link mt-auto">
													<a class="link small" (click)="gotoSingleResource(row)"
														>Read more</a
													>
												</div>
											</div>
											<!-- end panel -->
											<a (click)="gotoSingleResource(row)">
												<div
													class="img bottom-border gradient" loading=”lazy”
													[style.backgroundImage]="'url(' + row?.image_url + ')'"
												></div>
											</a>
										</div>
										<!-- end resources_single -->

										<div class="view_links col-lg-6 align-self-center text-right d-md-none">
											<a class="arrow_link small" routerLink="/resources"
												>View all resources

												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="41.896"
													height="30"
													viewBox="0 0 41.896 30"
												>
													<g
														class="Group_9062"
														data-name="Group 9062"
														transform="translate(-1588.104 -227)"
													>
														<circle
															class="Ellipse_86"
															data-name="Ellipse 86"
															cx="15"
															cy="15"
															r="15"
															transform="translate(1600 227)"
															fill="#6cfcfd"
														/>
														<g
															class="Group_540"
															data-name="Group 540"
															transform="translate(1588.109 236.846)"
														>
															<line
																class="Line_1"
																data-name="Line 1"
																x1="29.317"
																y1="0.145"
																transform="translate(0 5.478)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
															<path
																class="Path_1"
																data-name="Path 1"
																d="M24.331,17.232l5.355-5.623L24.331,5.986"
																transform="translate(-0.369 -5.986)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
														</g>
													</g>
												</svg>
											</a>
										</div>
									</div>
									<!-- end row -->
								</div>
							</div>
						</div>
						<!-- end resources_widget -->

						<!-- GHOST ELEMENT -->
						<div class="row resources_widget" *ngIf="loadingResources">
							<div class="col-12">
								<div class="panel with_inner_content">
									<div class="row">
										<h2 class="heading col-lg-6 align-self-center">Resources</h2>
									</div>

									<div class="row">
										<div class="col-md-4 resources_single" *ngFor="let number of [0, 1, 2]">
											<div class="panel grey">
												<h4 class="title"><div class="loading-text"></div></h4>

												<div class="body">
													<div class="loading-text"></div>
													<div class="loading-text"></div>
												</div>
											</div>
											<!-- end panel -->

											<div class="img bottom-border gradient loading-text"></div>
										</div>
										<!-- end resources_single -->
									</div>
									<!-- end row -->
								</div>
							</div>
						</div>
						<!-- end resources_widget -->

						<!--<div class="row col_analysis" *ngIf="!user">
							<div class="col-12">
								<div class="panel">
									<h2 class="heading">Get professional analysis & insights</h2>
									<div class="body">
										Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
										tempor invidunt ut labore et dolore magna aliquyam.
									</div>
									<a class="btn blue" routerLink="/signup">Sign up</a>
									<img class="img-fluid" src="assets/images/empty-placeholder.png" alt="TRC" />
								</div>
							</div>
						</div>-->
						<!-- end col_analysis -->
					</div>
					<!-- end col_left -->

					<div class="col-xl-4 col_right">
						<div
							class="row previews_widget d-none d-xl-block"
							*ngIf="previews?.length > 0 && !loadingTodaysPreviews"
						>
							<div class="col-12">
								<div class="row">
									<h2 class="heading col-lg-6 align-self-center">Upcoming previews</h2>
									<div class="view_links col-lg-6 align-self-center text-right">
										<a class="title_link black no_after small" routerLink="/form-guide">
											View all
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="7.46"
												height="12.625"
												viewBox="0 0 7.46 12.625"
											>
												<path
													class="line_arrow"
													data-name="Path 393"
													d="M24.331,17.232l5.355-5.623L24.331,5.986"
													transform="translate(-23.607 -5.296)"
													fill="none"
													stroke="#000"
													stroke-miterlimit="10"
													stroke-width="2"
												/>
											</svg>
										</a>
									</div>
								</div>

								<div class="row">
									<div class="col-12 previews_single" *ngFor="let row of previews">
										<div class="panel">
											<div
												class="p-row row"
												routerLink="form-guide/{{ row.slug }}/{{ row.raceno }}"
											>
												<div
													class="col_title_link col align-self-center"
													routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}"
												>
													<a
														routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}"
														class="title_link small"
														>{{ row.venue }}</a
													>
												</div>

												<div class="col_next_race text-right col align-self-center" routerLink="/form-guide/{{ row.slug }}/{{ row.raceno }}">
													<a>
														<div class="next_race">
															<span class="next_text">Next Race</span>
															<span class="race_no align-middle"
																><p>{{ row.raceno }}</p></span
															>
														</div>
													</a>
												</div>
											</div>
										</div>
									</div>
									<!-- end previews_single -->
								</div>
							</div>
						</div>
						<!-- end previews_widget -->

						<!-- GHOST ELEMENT -->
						<div class="row previews_widget" *ngIf="loadingTodaysPreviews">
							<div class="col-12">
								<div class="row">
									<h2 class="heading col-lg-6 align-self-center"></h2>
								</div>
								<div class="row">
									<div class="col-12 previews_single" *ngFor="let number of [0, 1, 2, 3]">
										<div class="panel">
											<div class="p-row row">
												<div class="col_title_link col align-self-center">
													<div class="loading-text"></div>
												</div>
												<div class="col_next_race text-right col align-self-center">
													<div class="loading-text"></div>
												</div>
											</div>
										</div>
									</div>
									<!-- end previews_single -->
								</div>
							</div>
						</div>
						<!-- end previews_widget -->

						<div
							class="row news_vertical_widget d-none d-xl-block"
							*ngIf="news?.length > 0 && !loadingNews"
						>
							<div class="col-12">
								<div class="panel">
									<div class="row">
										<h2 class="heading col-lg-4 align-self-center">News</h2>
										<div class="view_links col-lg-8 align-self-center text-right">
											<a class="arrow_link small" routerLink="/news"
												>View all news
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="41.896"
													height="30"
													viewBox="0 0 41.896 30"
												>
													<g
														class="Group_9062"
														data-name="Group 9062"
														transform="translate(-1588.104 -227)"
													>
														<circle
															class="Ellipse_86"
															data-name="Ellipse 86"
															cx="15"
															cy="15"
															r="15"
															transform="translate(1600 227)"
															fill="#6cfcfd"
														/>
														<g
															class="Group_540"
															data-name="Group 540"
															transform="translate(1588.109 236.846)"
														>
															<line
																class="Line_1"
																data-name="Line 1"
																x1="29.317"
																y1="0.145"
																transform="translate(0 5.478)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
															<path
																class="Path_1"
																data-name="Path 1"
																d="M24.331,17.232l5.355-5.623L24.331,5.986"
																transform="translate(-0.369 -5.986)"
																fill="none"
																stroke="#000"
																stroke-miterlimit="10"
																stroke-width="2"
															/>
														</g>
													</g>
												</svg>
											</a>
										</div>
									</div>

									<div class="row">
										<div
											class="col-xl-12 col-md-6 col-12 news_single bottom-border gradient"
											*ngFor="let row of news"
										>
											<article class="d-flex flex-column">
												<a routerLink="/news/{{ row.id }}/{{ row.slug }}">
													<div
														class="news_img"
														[style.backgroundImage]="'url(' + row.image_url + ')'"
													></div>
												</a>
												<div class="content flex-grow-1 d-flex flex-column">
													<div class="row title_wrap">
														<div class="col col-8 align-self-center">
															<h3>
																<a routerLink="/news/{{ row.id }}/{{ row.slug }}">{{
																	row?.title
																}}</a>
															</h3>
														</div>
														<div class="col col-4 pl-0 align-self-top text-right">
															<div class="date">
																{{ row?.created_at | date: 'd MMM y' }}
															</div>
														</div>
													</div>
													<div class="body" *ngIf="row?.short_description">
														{{
															row?.short_description.length > 80
																? (row?.short_description | slice: 0:80) + '..'
																: row?.short_description
														}}
													</div>
													<div class="read-link mt-auto">
														<a
															class="link small"
															routerLink="/news/{{ row.id }}/{{ row.slug }}"
															>Read more</a
														>
													</div>
												</div>
												<!-- end content -->
											</article>
										</div>
										<!-- end news_single -->
									</div>
								</div>
								<!-- end panel -->
							</div>
						</div>
						<!-- end news_wrapper -->

						<!-- GHOST ELEMENT -->
						<div class="row news_vertical_widget" *ngIf="loadingNews">
							<div class="col-12">
								<div class="panel">
									<div class="row">
										<h2 class="heading col-lg-4 align-self-center">News</h2>
									</div>

									<div class="row">
										<div
											class="col-lg-12 col-md-6 col-12 news_single bottom-border gradient loading"
											*ngFor="let number of [0, 1]"
										>
											<article>
												<div class="news_img loading-text"></div>
												<div class="content">
													<div class="row">
														<div class="col col-8 align-self-center">
															<h3><div class="loading-text"></div></h3>
														</div>
														<div class="col col-4 pl-0 align-self-top text-right">
															<div class="date"><div class="loading-text"></div></div>
														</div>
													</div>
													<div class="body">
														<div class="loading-text"></div>
													</div>
													<div class="loading-text"></div>
												</div>
												<!-- end content -->
											</article>
										</div>
										<!-- end news_single -->
									</div>
								</div>
								<!-- end panel -->
							</div>
						</div>
						<!-- end news_wrapper -->

						<div class="row download_app_widget">
							<div class="col-12">
								<div class="panel black">
									<h2 class="heading white">Download our app!</h2>
									<div class="body white">
										<span *ngIf="!user"
											><a href="/signup">Sign up</a> to become a member then download Racing Central from the App Store or Google Play Store.</span
										>
										<span *ngIf="user"
											>Download Racing Central from the App Store or Google Play Store.</span
										>
									</div>
									<div class="play_store_links">
										<a
											href="https://apps.apple.com/au/app/the-race-club/id1434221878"
											target="_blank"
										>
											<div class="svg_wrapper">
												<svg
													id="Group_255"
													data-name="Group 255"
													xmlns="http://www.w3.org/2000/svg"
													width="12.456"
													height="15.268"
													viewBox="0 0 12.456 15.268"
												>
													<path
														id="Path"
														d="M10.4,4.437a3.353,3.353,0,0,0,2.052,3.085A7.678,7.678,0,0,1,11.388,9.69c-.629.938-1.288,1.855-2.335,1.871-1.017.023-1.358-.6-2.525-.6s-1.541.58-2.517.62c-1,.037-1.758-1-2.41-1.93C.3,7.756-.714,4.306.646,1.958A3.738,3.738,0,0,1,3.79.045c1-.02,1.924.672,2.537.672s1.746-.828,2.927-.7A3.564,3.564,0,0,1,12.06,1.525,3.468,3.468,0,0,0,10.4,4.437Z"
														transform="translate(0 3.683)"
													/>
													<path
														id="Path-2"
														data-name="Path"
														d="M2.283,2.447A2.885,2.885,0,0,1,.007,3.521a3.245,3.245,0,0,1,.8-2.357A3.488,3.488,0,0,1,3.066,0a3.41,3.41,0,0,1-.784,2.447Z"
														transform="translate(6.2)"
													/>
												</svg>
											</div>
											<!-- end svg_wrapper -->
										</a>
										<a
											href="https://play.google.com/store/apps/details?id=io.ionic.raceclub&hl=en_AU&gl=US"
											target="_blank"
										>
											<div class="svg_wrapper">
												<svg
													id="Group_256"
													data-name="Group 256"
													xmlns="http://www.w3.org/2000/svg"
													width="12.842"
													height="14.272"
													viewBox="0 0 12.842 14.272"
												>
													<path
														id="Path"
														d="M4.738,1.5a.49.49,0,0,1,0,.849L1.954,3.956,0,2.067,2.138,0Z"
														transform="translate(7.859 5.119)"
													/>
													<path
														id="Path-2"
														data-name="Path"
														d="M9.131,4.865,7.082,6.846,0,0,6.27,3.213Z"
														transform="translate(0.425)"
													/>
													<path
														id="Path-3"
														data-name="Path"
														d="M0,6.745,6.977,0,8.843,1.8,6.177,3.342Z"
														transform="translate(0.529 7.527)"
													/>
													<path
														id="Path-4"
														data-name="Path"
														d="M.007,0,7.154,6.909,0,13.825Z"
														transform="translate(0 0.277)"
													/>
												</svg>
											</div>
											<!-- end svg_wrapper -->
										</a>
									</div>
									<img loading=”lazy”
										class="img-fluid floting_phone_img"
										src="assets/images/phone.png"
										alt="Racing Central App Mockups"
									/>
								</div>
							</div>
						</div>
						<!-- end download_app -->
					</div>
					<!-- end col_right -->
				</div>
				<!-- end row -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>
