import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class TournamentService {
	apiUrl = environment.serverUrl;
	constructor(private httpClient: HttpClient) {}

	/**
	 * get tournament.
	 */
	public getTournament(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'tournamentbyId', data);
	}

	/**
	 * get tournaments list.
	 */
	public getTournaments(token: any): Observable<any> {
		let data = {
			token: token,
		};
		return this.httpClient.post(this.apiUrl + 'tournaments', data);
	}

	/**
	 * save Selected Horse.
	 */
	public saveSelectedHorse(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'save/selectedhorse', data);
	}

	/**
	 * show Roun Robin Tournaments
	 */
	public showRounRobinTournaments(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'showRounRobinTournaments', data);
	}

	/**
	 * leaderboard tournaments
	 */
	public showTournamentLeaderboard(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'rounRobinTournamentLeaderboard', data);
	}

	/**
	 * show leaderboardRoun Robin Tournaments
	 */
	public showRounRobinTournamentLeaderboard(data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'rounRobinTournamentLeaderboard', data);
	}

	/**
	 * Verify entry code for tournament
	 */
	public verifyTournamentEntryCode(token: any, data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'verify-tournament-entry-code?token=' + token, data);
	}

	/**
	 * Agree Terms and Conditions
	 */
	public agreeTerms(token: any, data: any): Observable<any> {
		return this.httpClient.post(this.apiUrl + 'agree-terms?token=' + token, data);
	}

	/**
	 * Charge customer tournament fee
	 */
	chargeCustomerWithCard(data: any) {
		return this.httpClient.post(this.apiUrl + 'charge-customer-with-card', data);
	}
}
