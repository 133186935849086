<div class="breadcrumbs_wrapper bg-image" style="background-image: url('assets/images/horse-portrait.jpg')">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title">Tournaments</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/">Home</a></li>
							<li><a routerLink="/tournaments">Tournaments</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end breadcrumbs_wrapper -->

<div class="tournamentspage_wrapper">
	<div class="container" *ngIf='(tournaments?.length == 0 && roundRobinTournaments?.length == 0) && !loading'>
	
	    <div class="row">
			<div class="col-lg-8 offset-lg-2">
				<div class="panel locked_panel">
					<div class="top_text_wrapper">
						<h2 class="col-12 text-center heading">
							There are currently no tournaments open
						</h2>

						<div class="body text-center">
							<p *ngIf="user">Please check back at a later date.</p>
							<p *ngIf="!user">
								Create a FREE account to participate in tournaments once they become available
							</p>
							<a class="btn blue arrow_large" routerLink="/signup" *ngIf="!user">Sign up</a>
						</div>

						<div class="placeholder">
							<img class="img-fluid" src="assets/images/placeholders/liveyard-large.svg" alt="Upgrade" />
						</div>
					</div>
				</div>
			</div>
		</div>
	
	</div><!-- end container -->
	<div class="container">
		<div class="row">
			<div class="col-xl-10 offset-xl-1">
				<div class="row" *ngIf="!loading">
					<div class="tournaments_single_new col-lg-12" *ngFor="let row of tournaments">
						<div class="panel_wrap col-12">
							<div class="row row-eq-height">
								<div
									class="bg_image_behind"
                                    *ngIf="row?.imageType == 'png' && row?.image_url"
									[style.backgroundImage]="'url(' + row?.image_url + ')'"
								></div>
								<!-- end bg_image -->
								<div
									class="bg_image_behind overlay"
                                    *ngIf="row?.imageType == 'jpg' && row?.image_url"
									[style.backgroundImage]="'url(' + row?.image_url + ')'"
								></div>
								<!-- end bg_image -->
								<!-- <img class="img img-thumbnail" src="{{ row?.image_url }}" alt="TRC" /> -->

								<div class="col-lg-2 col d-none d-lg-block">
									<div
										class="bg_image offset_image"
                                        *ngIf="row?.imageType == 'png' && row?.image_url"
										[style.backgroundImage]="'url(' + row?.image_url + ')'"
									></div>
									<!-- end bg_image -->
									<div
										class="bg_image"
                                        *ngIf="row?.imageType == 'jpg' && row?.image_url"
										[style.backgroundImage]="'url(' + row?.image_url + ')'"
									></div>
									<!-- end bg_image -->
								</div>
								<!-- end col-lg-3 -->

								<div class="col-lg-3 col-md-4 col-sm-12 col-12 col_name col">
									<span class="value">{{ row?.name }}</span>
									<span class="label" *ngIf="!row?.is_private">Tournament</span>
									<span class="label" *ngIf="row?.is_private">Private Tournament</span>

								</div>
								<!-- end col_name -->

								<div class="col-lg-4 col-md-5 col-sm-12 col-12 col_info col">
									<div class="row">
										<div class="col-md-3 info_single">
											<span class="value">${{ row?.prize }}</span>
											<span class="label">Prize</span>
										</div>
										<div class="col-md-4 info_single">
											<span class="value" *ngIf="row?.entry_fee == 0">Free</span>
											<span class="value" *ngIf="row?.entry_fee > 0">${{ row?.entry_fee }}</span>
											<span class="label">Entry</span>
										</div>
										<div class="col-md-5 info_single">
											<span class="value">{{ row?.status }}</span>
											<span class="label">Status</span>
										</div>
									</div>
								</div>
								<!-- end col_info -->

								<div class="col-lg-3 col-md-3 col-12 col_btn col align-self-center">
									<a
										class="btn blue"
										*ngIf="
											!row?.cut_off_date_over && !row?.is_private &&
											(!row.tournamentUser ||
												(row.tournamentUser && row.tournamentUser.status != 'entered')) &&
											user
										"
										(click)="openTermsModal(row, 'tournament')"
										>Enter</a
									>
									<a
										class="btn blue"
										*ngIf="
											!row?.cut_off_date_over && row?.is_private &&
											(!row.tournamentUser || (row.tournamentUser && (!row.tournamentUser.status || row.tournamentUser.status === 'code_verified'))) &&
											user
										"
										(click)="openTermsModal(row, 'tournament')"
										>Enter</a
									>
									<a
										class="btn blue"
										*ngIf="
											!row?.cut_off_date_over &&
											row.tournamentUser &&
											(row.tournamentUser.status == 'entered' ||  row.tournamentUser.status == 'terms_agreed')  &&
											user
										"
										(click)="gotoSingleTournament(row)"
										>Edit Selections</a
									>
									<a
										class="btn white"
										*ngIf="
											row?.cut_off_date_over &&
											row.tournamentUser &&
											row.tournamentUser.status == 'entered' &&
											user
										"
										(click)="gotoSingleTournament(row)"
										>View Selections</a
									>
									<a class="btn" (click)="gotoSingleTournament(row)">View</a>
								</div>
								<!-- end col_btn -->

								<h4 class="pill {{ row?.alert }}" *ngIf="!row?.cut_off_date_over">
									Closing in {{ row?.time_remaining }}
								</h4>

								<h4 class="pill {{ row?.alert }}" *ngIf="row?.cut_off_date_over">Closed</h4>
							</div>
							<!-- end row -->
						</div>
						<!-- end panel_wrap -->

						<div class="cutt_off" *ngIf="!row?.cut_off_date_over">
							<!-- Competition cut off: Saturday 7th October 11:30pm EST -->
							<p>
								Competition cut off: {{ row.cut_off_date_time | amFromUtc  | amLocal | amDateFormat: 'DD MMM YYYY h:mm a' }}
							</p>
						</div>
					</div>

					<div class="tournaments_single_new col-lg-12" *ngFor="let row of roundRobinTournaments">
						<div class="panel_wrap col-12">
							<div class="row row-eq-height">
								<div
									class="bg_image_behind"
									*ngIf="row?.imageType == 'png' && row?.image_url"
									[style.backgroundImage]="'url(' + row?.image_url + ')'"
								></div>
								<!-- end bg_image -->
								<div
									class="bg_image_behind overlay"
									*ngIf="row?.imageType == 'jpg' && row?.image_url"
									[style.backgroundImage]="'url(' + row?.image_url + ')'"
								></div>
								<!-- end bg_image -->
								<!-- <img class="img img-thumbnail" src="{{ row?.image_url }}" alt="TRC" /> -->
					
								<div class="col-lg-2 col d-none d-lg-block">
									<div
										class="bg_image offset_image"
										*ngIf="row?.imageType == 'png' && row?.image_url"
										[style.backgroundImage]="'url(' + row?.image_url + ')'"
									></div>
									<!-- end bg_image -->
									<div
										class="bg_image"
										*ngIf="row?.imageType == 'jpg' && row?.image_url"
										[style.backgroundImage]="'url(' + row?.image_url + ')'"
									></div>
									<!-- end bg_image -->
								</div>
								<!-- end col-lg-3 -->
					
								<div class="col-lg-3 col-md-4 col-sm-12 col-12 col_name col">
									<span class="value">{{ row?.title }}</span>
									<span class="label">Round Robin</span>
								</div>
								<!-- end col_name -->
					
								<div class="col-lg-4 col-md-5 col-sm-12 col-12 col_info col">
									<div class="row">
										<div class="col-md-3 info_single">
											<span class="value">${{ row?.prize }}</span>
											<span class="label">Prize</span>
										</div>
										<div class="col-md-4 info_single">
											<span class="value">-</span>
											<span class="label">Entry</span>
										</div>
										<div class="col-md-5 info_single">
											<span class="value">{{ row?.status }}</span>
											<span class="label">Status</span>
										</div>
									</div>
								</div>
								<!-- end col_info -->
					
								<div class="col-lg-3 col-md-3 col-12 col_btn col align-self-center">
									<a
										class="btn blue"
										*ngIf="
											!row?.cut_off_date_over &&
											(!row.tournamentUser ||
												(row.tournamentUser && row.tournamentUser.status != 'entered')) &&
											user
										"
										(click)="openTermsModal(row, 'round-robin-tournament')"
										>Enter</a
									>
									<a
										class="btn blue"
										*ngIf="
											!row?.cut_off_date_over &&
											row.tournamentUser &&
											row.tournamentUser.status == 'entered' &&
											user
										"
										(click)="gotoRoundRobinTournaments(row)"
										>Edit Selections</a
									>
									<a
										class="btn white"
										*ngIf="
											row?.cut_off_date_over &&
											row.tournamentUser &&
											row.tournamentUser.status == 'entered' &&
											user
										"
										(click)="gotoRoundRobinTournaments(row)"
										>View Selections</a
									>
									<a class="btn" (click)="gotoRoundRobinTournaments(row)">View</a>
								</div>
								<!-- end col_btn -->
					
								<h4 class="pill {{ row?.alert }}" *ngIf="!row?.cut_off_date_over">
									Closing in {{ row?.time_remaining }}
								</h4>
					
								<h4 class="pill {{ row?.alert }}" *ngIf="row?.cut_off_date_over">Closed</h4>
							</div>
							<!-- end row -->
						</div>
						<!-- end panel_wrap -->
					
						<div class="cutt_off" *ngIf="!row?.cut_off_date_over">
							<!-- Competition cut off: Saturday 7th October 11:30pm EST -->
							<p>
								Competition cut off: {{ row.cut_off_date_time | amFromUtc  | amLocal | amDateFormat: 'DD MMM YYYY HH:mm' }}

							</p>
						</div>
					</div>
				</div>
				<!-- end row -->

				<!-- GHOST ELEMENT -->
				<div class="row" *ngIf="loading">
					<div class="tournaments_single_new col-lg-12" *ngFor="let numbers of [0,1,2,3,4]">
						<div class="panel_wrap col-12">
							<div class="row row-eq-height">

								<div class="col-lg-2 col d-none d-lg-block">
									<div class="bg_image offset_image loading-text"></div><!-- end bg_image -->
								</div>
								<!-- end col-lg-3 -->
					
								<div class="col-lg-3 col-md-4 col-sm-12 col-12 col_name col ghost">
									<span class="value mb-2"><div class="loading-text"></div></span>
									<span class="label"><div class="loading-text"></div></span>
								</div>
								<!-- end col_name -->
					
								<div class="col-lg-4 col-md-5 col-sm-12 col-12 col_info col">
									<div class="row">
										<div class="col-md-4 info_single" *ngFor="let numbers of [0,1,2]">
											<span class="value mb-2"><div class="loading-text"></div></span>
											<span class="label"><div class="loading-text"></div></span>
										</div>
									</div>
								</div>
								<!-- end col_info -->
					
								<div class="col-lg-3 col-md-3 col-12 col_btn col align-self-center">
									<a class="btn blue disabled loading-text">&nbsp;</a>
									<a class="btn disabled loading-text">&nbsp;</a>
								</div>
								<!-- end col_btn -->
							</div>
							<!-- end row -->
						</div>
						<!-- end panel_wrap -->
					</div>
				</div>
				<!-- end row -->
			</div>
			<!-- end col-xl-10 offset-xl-1 -->
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>
