<div class="onboarding_wrapper login">
	<div class="floating_logo">
		<a routerLink="/">
			<img src="assets/images/logos/logo-vertical-light.png"/>
		</a>
	</div>

	<div class="bg_image"></div>
	<!-- end bg_image -->
	<div class="bg_image mob"></div>
	<!-- end bg_image -->

	<div class="container-fluid outer">
		<div class="row">
			<div class="col_form text-center">
				<div class="inner">
					<h1 class="page_title white">Log in</h1>

					<div class="subheading">
						<p>Don’t have an account? <a routerLink="/signup">Sign up</a></p>
					</div>

					<form class="form_wrapper" [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="loginForm">
						<div class="form-row">
							<div class="form-group col-12">
								<input
									type="text"
									class="form-control"
									placeholder="Email Address"
									formControlName="email"
									[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
								/>
								<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
									<div *ngIf="f.email.errors.required">Email is required</div>
									<div *ngIf="f.email.errors.pattern">Email is invalid</div>
								</div>
							</div>

							<div class="form-group col-12">
								<input
									type="password"
									class="form-control"
									placeholder="Password"
									formControlName="password"
									[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
								/>
								<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
									<div *ngIf="f.password.errors.required">Password is required</div>
									<div *ngIf="f.password.errors.minlength">
										Password must be at least 6 characters
									</div>
								</div>
							</div>
							<!-- <div class="form-group col-12">
								<recaptcha
								[formControl]="myRecaptcha"
								(scriptLoad)="onScriptLoad()"
								(scriptError)="onScriptError()"
								></recaptcha>
							</div> -->

							<!-- <div class="g-recaptcha" data-sitekey="<sitekey>" 
								data-bind="recaptcha-submit" data-callback="submitForm">
							 </div> -->

							<!-- <div class="form-group col-12">
								<button class="btn blue arrow_large g-recaptcha" type="submit" data-sitekey="6LfcgakaAAAAAG25n7_8K0LLC4L3LOghiynUCmum" data-callback="onSubmit"  data-size="invisible">Sign in</button>
							</div> -->
							<!-- <div id='recaptcha' class="g-recaptcha"
								data-sitekey="6LfcgakaAAAAAG25n7_8K0LLC4L3LOghiynUCmum"
								data-callback="onSubmit"
								data-size="invisible">
							</div> -->

							<!-- <re-captcha
							[formControlName]="captcha"
							name="captcha"
							(resolved)="resolved($event)"
							(error)="errored($event)"
							errorMode="handled"
							required
							siteKey="6LfcgakaAAAAAG25n7_8K0LLC4L3LOghiynUCmum"
						  ></re-captcha>-->
							<!-- <div>
								<re-captcha (resolved)="resolved($event)" 
											formControlName="recaptchaReactive" 
											siteKey="6LfcgakaAAAAAG25n7_8K0LLC4L3LOghiynUCmum">  
								</re-captcha>
							  </div> -->
							<re-captcha
								#captchaRef="reCaptcha"
								size="invisible"
								resolved="resolved($event)"
								siteKey="{{ googleCaptchPublicKey }}"
							>
							</re-captcha>
							<!-- code below is original code dont modify -->
							<div class="form-group col-12">
								<button class="btn blue arrow_large" (click)="captchaRef.execute()" type="submit">
									Log in
								</button>
							</div>

							<div class="form-group col_forgot col-12">
								<a routerLink="/forgot-password">Forgot password?</a>
							</div>
						</div>
					</form>
				</div>
				<!-- end inner -->
			</div>
			<!-- end col_form -->
		</div>
	</div>
	<!-- end container-fluid -->
</div>
<!-- end signup_wrapper -->
