<ul class="accr-section">
	<li class="result-tables-wrap row" *ngFor="let row of form_guide_horses; let i = index">
		<div
			class="t-index col-1"
			[ngClass]="{
				green: row.horse_fp == 1,
				yellow: row.horse_fp == 2 || row.horse_fp == 3,
				grey: row.horse_fp > 3,
				Scratched: row.horse_status == 'Scratched' || row.horse_status == 'LateScratched'
			}"
		>
			<div class="inner">
				<div class="inner" *ngIf="row.horse_status != 'Scratched' && row.horse_status != 'LateScratched'">
					<span
						><strong *ngIf="row.horse_fp != 99">{{ row.horse_fp }}</strong
						><strong *ngIf="row.horse_fp == 99">-</strong></span
					>
					<span>of {{ form_guide_horses.length - scratched_horses }}</span>
				</div>
				<!-- end inner -->
				<div class="inner" *ngIf="row.horse_status == 'Scratched' || row.horse_status == 'LateScratched'">
					<span><strong>S</strong></span>
				</div>
				<!-- end inner -->
			</div>
			<!-- end inner -->
		</div>
		<!-- end t-index -->

		<div class="t-conents col-11 border-gradient with-padd">
			<div class="panel grey collapsable-section closed">
				<table
					horse-panel-header
					class="table"
					[row]="row"
					[i]="[i]"
					[user]="user"
					(getRaceHorseDetailsIn)="getRaceHorseDetailsInner($event)"
				></table>
				<!-- table -->

				<div
					horse-details
					class="collapsed-content"
					[row]="row"
					[user]="user"
					[bb_count]="bb_count"
					(openBlackbookIn)="OpenBlackbook($event)"
					(toggleAccordionIn)="toggleAccordionInner($event)"
				></div>
				<!-- collapsed-content -->
			</div>
		</div>
		<!-- end t-conents -->
	</li>
	<!-- end result-tables-wrap -->
</ul>
<!-- end accr-section -->
