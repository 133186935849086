import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-upgrade-modal',
	templateUrl: './upgrade-modal.page.html',
	styleUrls: ['./upgrade-modal.page.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class UpgradeModalPage implements OnInit {
	@Input() fromParent: any;
	type: any;
	user: any;

	constructor(private router: Router, private modalService: NgbModal) {
		// this.type = this.fromParent.type;
	}

	getUser() {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);

		if (this.user && this.user.subscription && this.user.subscription.status) {
			if (this.user.subscription.plan_name === 'Platinum') this.user.hasPremiumSubscription = true;
			this.user.subscription.active_features.forEach((element: any) => {
				if (element.slug == 'previews-and-reviews') this.user.hasPreviews = true;
				if (element.slug == 'liveyard') this.user.hasLiveYard = true;
			});
			if (this.user.subscription.legacy_plan) this.user.hasPreviews = true;
		}
	}

	ngOnInit() {
		//console.log(this.fromParent.type);
		this.getUser();
	}

	gotoSignUp() {
		localStorage.setItem('trc_skip_subscription', 'true');
		this.router.navigate(['/signup']);
		this.modalService.dismissAll();
	}

	gotoSubscribe() {
		localStorage.setItem('trc_skip_subscription', 'false');
		this.router.navigate(['/subscribe-plans']);
		this.modalService.dismissAll();
	}

	closemodal() {
		this.modalService.dismissAll();
	}
}
