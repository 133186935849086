<div
	*ngIf="type == 'form-guide'"
	class="tab-pane fade show active"
	id="form-guide"
	role="tabpanel"
	aria-labelledby="form-guide"
>
	<ul class="accr-section">
		<li class="collapsable-section no-gutters ghost closed row" *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7, 8]">
			<div class="t-index col-1">
				<div class="inner loading-text">
				</div>
				<!-- end inner -->
			</div>
			<table class="table col">
				<tbody class="horse-panel-header ghost">
					<!-- DESKTOP LAYOUT -->
					<tr class="d-none d-lg-block">
						<td style="width: 6%" class="tabno">
							<div class="jockey-img loading-text"></div>
							<h1 class="index_no loading-text"></h1>
						</td>
		
						<td style="width: 24%" class="td_name">
							<div class="player_name">
								<h3>
									<span class="index_no loading-text"></span>
								</h3>
							</div>
							<span class="player_attributes loading-text"></span>
						</td>
		
						<td style="width: 15%" class="form">
							<span class="small_space loading-text"></span>
							<span class="loading-text"></span>
						</td>
		
						<td style="width: 16%" class="trainer">
							<span class="small_space loading-text"></span>
							<span class="loading-text"></span>
						</td>
		
						<td style="width: 13%" class="trc_rating">
							<span class="loading-text rating_title"></span>
						</td>
		
						<td style="width: 20%" class="prices">
							<div class="bookmaker loading-text"></div>
							<span class="bookmaker-img small_space loading-text"></span>
							<span class="bookmaker-img loading-text"></span>
						</td>
					</tr>
		
					<!-- MOBILE LAYOUT -->
					<tr class="d-lg-none mobile_layout">
						<td style="width: 10%" class="tabno">
							<div class="jockey-img loading-text"></div>
							<h1 class="index_no"></h1>
						</td>
		
						<td style="width: 90%" class="td_name">
							<div class="player_name">
								<h3 class="loading-text">
									<span class="index_no"></span>
									<small></small>
								</h3>
		
								<span class="plus">
									<span class="toggleBtn loading-text"></span>
								</span>
							</div>
							<div class="inner">
								<div class="content">
									<table>
										<tr>
											<td>
												<span class="player_attributes loading-text"></span>
											</td>
											<td>
												<span class="small_space loading-text"></span>
											</td>
										</tr>
										<tr>
											<td>
												<span class="loading-text"></span>
											</td>
											<td>
												<span class="small_space loading-text"></span>
											</td>
										</tr>
									</table>
								</div>
								<!-- end content -->
		
								<div class="price_widget loading-text">
									<div class="gauge"></div>
									<!-- end gauge -->
		
									<div class="price">
										<span class="win"></span>
									</div>
									<!-- end price -->
								</div>
								<!-- end price -->
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</li>
		<!-- end collapsable-section -->
	</ul>
	<!-- end accr-section -->
</div>
<!-- end tab-pane -->

<div *ngIf="type == 'speed-maps-list'" class="speed_maps_horses_list_table ghost panel">
	<ul>
		<li>
			<table class="speed-maps-table" [ngClass]="{'reverse' : direction == 'LEFT'}">
				<tbody *ngFor="let numbers of [0,1,2,3,4,5,6,7,8,9]">
					<tr>
						<td class="index_col loading-text" *ngIf="direction == 'RIGHT'"></td>
						<td class="map_position_col loading-text" *ngIf="direction == 'RIGHT'"></td>

						<td class="progress_bar_col loading">
							<div class="progress loading-text" [ngClass]="{'d-flex alternate' : direction == 'LEFT'}"></div>
						</td>

						<td class="map_position_col loading-text" *ngIf="direction == 'LEFT'"></td>
						<td class="index_col loading-text alternate" *ngIf="direction == 'LEFT'"></td>
					</tr>
				</tbody>
			</table>
		</li>
	</ul>	
</div>
<!-- end speed_maps_horses_list_table -->

<div *ngIf="type == 'preview'" class="preview_wrapper">
	<div class="panel">
		<div class="row bets">
			<div class="col-lg-12 single_bet" *ngFor="let number of [0]">
				<div class="panel loading-text"></div>
			</div>
			<!-- end single_bet -->
		</div>
		<!-- end row bets -->
	</div>
	<!-- end panel -->
</div>
<!-- end preview_wrapper -->

<div *ngIf="type == 'preview2'" class="preview_wrapper">
	<div class="panel">
		<h2 class="preview_title"><strong>Preview:</strong></h2>

		<div class="top_text_wrapper">
			<h2 class="heading">Unlock professional analysis & insights</h2>

			<div class="body">
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
				labore et dolore magna aliquyam.
			</div>
			<a class="btn" routerLink="/signup" *ngIf="user && user.subscription && !user.subscription.status"
				>Purchase Day Pass</a
			>
			<a class="btn blue" routerLink="/signup" *ngIf="!user">Sign up</a>
		</div>

		<img class="img-fluid placeholder-img" src="assets/images/empty-placeholder-2.png" alt="TRC" />
	</div>
	<!-- end panel -->
</div>
<!-- end preview_wrapper -->

<div *ngIf="type == 'horse-details'" class="inner">
	<div class="top-table row">
		<div class="t-col-1 col-lg-3">
			<div class="panel d-flex align-items-center h-100 loading-text"></div>
		</div>
		<!-- end t-col-1 -->
		<div class="t-col-2 col-lg-9">
			<div class="panel h-100 loading-text"></div>
			<!-- end panel -->
		</div>
		<!-- end t-col-2 -->
	</div>
	<!-- end top-table -->

	<div class="live-yard-table row">
		<div class="t-col-1 col-lg-3">
			<div class="panel loading-text"></div>
			<!-- end panel -->
		</div>
		<!-- end t-col-1 -->

		<div class="t-col-2 col-lg-6">
			<div class="panel grey loading-text"></div>
			<!-- end panel -->
		</div>
		<!-- end t-col-2 -->

		<div class="t-col-3 col-lg-3 text-right">
			<div class="inner">
				<div class="inner">
					<div class="loading-text"></div>
				</div>
				<!-- end inner -->
			</div>
			<!-- end inner -->
		</div>
	</div>
	<!-- end live-yard-table -->

	<div class="info-table">
		<div class="t-row loading-text" *ngFor="let numbers of [0,1]"></div>
		<!-- end t-row -->
	</div>
	<!-- end info-table -->

	<div class="result-tables row">
		<div class="t-index col-1 loading-text"></div>
		<!-- end t-index -->

		<div class="t-conents t-conents-1 col-8">
			<div class="panel loading-text"></div>
			<!-- end panel -->
		</div>
		<!-- end t-conents -->

		<div class="t-conents t-conents-2 col-3">
			<div class="panel loading-text"></div>
			<!-- end panel -->
		</div>
		<!-- end t-conents -->
	</div>
	<!-- end result-tables -->
</div>
<!-- end inner -->

<div class="row" *ngIf="type == 'news'">
	<div class="col-xl-8 col_left">
		<div class="image_wrap bottom-border gradient loading-text">
			
		</div>

		<div class="content pad-left-right">
			<div class="row">
				<h3 class="content_title col-6">
					<strong class="loading-text"></strong>
				</h3>
				<div class="date col-6 loading-text" >
					
				</div>
			</div>

			<div class="body loading-text" >
			</div>
		</div>
		<!-- end content -->
	</div>
	<div class="col-xl-8 col_left is_race_review">
		<div class="content pad-left-right">
			<div class="row">
				<h3 class="content_title col-6">
					<strong class="loading-text"></strong>
				</h3>
				<div class="date col-6 loading-text">
					
				</div>
			</div>

			<div class="body semibold large loading-text">
				
			</div>

			<div class="race_preview_single">
				<h3 class="content_title">
					<strong class="loading-text"></strong>
				</h3>

				<h3 class="content_title">
					<strong class="loading-text"></strong>
				</h3>
			</div>
			<!-- end race_preview_single -->

			<div class="body loading-text">
				
			</div>
		</div>
	</div>
</div>
