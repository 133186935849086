import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';

import * as $ from 'jquery';
import { TweenLite, Cubic } from 'gsap';
import { FormGuideService } from '../services/form-guide.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-results',
	templateUrl: './results.component.html',
	styleUrls: ['./results.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ResultsComponent implements OnInit {
	loading: boolean = true;
	loadingresultdata: boolean = true;
	isSelect: any = 2;
	results: any;
	event_races: any;
	races: any;
	datepicker: any;
	valuedate = new Date();
	model: any = {};
	selectedDate: any = moment().local().format('YYYY-MM-DD');
	dateWording: string = 'Y-m-d';
	currentDateObj: any = {};
	meeting_ids: any = [];
	meeting_id: any;
	user: any;
	isSingleVenue: boolean = false;
	screenWidth:any;

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.screenWidth = window.innerWidth;
	}

	constructor(private FormGuideService: FormGuideService, private route: ActivatedRoute) {
		this.route.params.subscribe((params) => {
			this.meeting_id = params.meeting_id ? params.meeting_id : null;
			if(this.meeting_id) {
				this.isSingleVenue =true;
			}
			//console.log(this.meeting_id);
		});
		this.getScreenSize();
	}

	onSelect(sdate: any) {
		this.selectedDate = sdate.year + '-' + ('0' + sdate.month).slice(-2) + '-' + sdate.day;
		this.getResults();
	}

	ngOnInit() {
		this.getUser();
		if (this.meeting_id) this.getResultsforMeeting();
		else this.getResults();
	}

	getUser() {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);

		if (this.user && this.user.subscription) {
			if (this.user.subscription.plan_name === 'Platinum' || this.user.subscription.platinum_cancelling_or_override) this.user.hasPremiumSubscription = true;
			this.user.subscription.active_features.forEach((element: any) => {
				if (element.slug == 'previews-and-reviews') this.user.hasPreviews = true;
				if (element.slug == 'liveyard') this.user.hasLiveYard = true;
			});
			if (this.user.subscription.legacy_plan) this.user.hasPreviews = true;
			if (this.user.day_pass) this.user.hasPremiumSubscription = true;
		}
	}

	toggleAccordion(event: any) {
		var $this = $(event.currentTarget);
		if ($this.hasClass('showMore')) {
			var $content = $(event.currentTarget).closest('.panel').find('.hiddenContents');
			var marginTop = 0;
			if (window.innerWidth > 575) {
				var marginTop = 20;
			}
			TweenLite.set($content, {
				height: 'auto',
				marginTop: marginTop,
				opacity: 1,
			});
			TweenLite.from($content, 0.5, {
				height: 0,
				opacity: 0,
				marginTop: 0,
				ease: Cubic.easeInOut,
			});
			$content.removeClass('hiddenContents');
			$content.addClass('showContents');
			$this.addClass('showLess');
			$this.removeClass('showMore');
			$this.text('View less');
		} else {
			var $content = $(event.currentTarget).closest('.panel').find('.showContents');
			$content.addClass('hiddenContents');
			$content.removeClass('showContents');
			$this.addClass('showMore');
			$this.removeClass('showLess');
			$this.text('View full field');

			TweenLite.to($content, 0.5, {
				height: 0,
				opacity: 0,
				marginTop: 0,
				ease: Cubic.easeInOut,
			});
		}
	}

	getMax(arg1: any, arg2: any, arg3: any) {
		return Math.max(arg1, arg2, arg3);
	}

	changeTab(selected: any) {
		this.isSelect = selected;
		this.loadingresultdata = true;
		setTimeout(() => {
			this.loadingresultdata = false;
		}, 800);
	}

	async getResults() {
		this.loading = true;
		var data = {
			selected_date: this.selectedDate,
			timezoneOffset: new Date().getTimezoneOffset(),
		};
		this.FormGuideService.getResults(data).subscribe((data: any) => {
			//console.log(data);
			this.loading = false;

			if (data && data.status && data.results) {
				this.results = data.results ? data.results : [];
				this.meeting_ids = [];

				this.results.forEach((element: any) => {
					this.meeting_ids.push(element.meeting_id);
				});
				this.getallRaces();
			} else {
				this.results = [];
				this.loadingresultdata = false;
			}
		});
	}

	async getResultsforMeeting() {
		this.loading = true;
		var data = {
			meeting_id: this.meeting_id,
		};
		this.FormGuideService.getResultsforMeeting(data).subscribe((data: any) => {

			this.loading = false;

			if (data && data.status && data.results) {
				this.results = data.results ? data.results : [];
				this.meeting_ids = [];

				this.results.forEach((element: any) => {
					this.meeting_ids.push(element.meeting_id);
				});
				this.getallRaces();
			} else {
				this.results = [];
				this.loadingresultdata = false;
			}
		});
	}

	async getEventsRaces(meeting_id: any, isSelect: any) {
		this.loadingresultdata = true;
		this.isSelect = isSelect;

		var data = {
			meeting_id: null,
			selected_date: this.selectedDate,
		};

		if (meeting_id) {
			data = {
				meeting_id: meeting_id,
				selected_date: this.selectedDate,
			};
		}

		this.FormGuideService.getRaceResults(data).subscribe((data: any) => {
			if (data.status) {
				this.races = data.event_races;
				this.loadingresultdata = false;

				this.races.forEach((element: any) => {
					element.scratced_horses = 0;
					element.horses.forEach((horse: any) => {
						if (horse.horse_status == 'Scratched' || horse.horse_status == 'LateScratched') {
							element.scratced_horses = element.scratced_horses + 1;
						}
					});
				});
			}
		});
	}

	async getRacesforAllMeetings(){
		this.isSelect = 1;
		this.getallRaces();
	}

	async getallRaces() {
		this.loadingresultdata = true;
		let meeting_ids = this.meeting_ids;
		if(this.meeting_id && this.isSingleVenue) {
			this.isSelect = 2;
			this.isSingleVenue = false;
		}
		else {
			if(this.isSelect != 1) {
				this.isSelect = 2;
				meeting_ids = [this.meeting_ids[0]];
				this.meeting_id = this.meeting_ids[0];
			} else {
				this.isSelect = 1;
				this.meeting_id = 1;
			}
		}
		var data = {
			meeting_ids: meeting_ids,
			selected_date: this.selectedDate,
		};

		this.FormGuideService.getRaceResults(data).subscribe((data: any) => {
			if (data.status) {
				this.races = data.event_races;
				this.loadingresultdata = false;

				this.races.forEach((element: any) => {
					element.scratced_horses = 0;
					element.horses.forEach((horse: any) => {
						if (horse.horse_status == 'Scratched' || horse.horse_status == 'LateScratched') {
							element.scratced_horses = element.scratced_horses + 1;
						}
					});
				});
			}
			//console.log(data);
		});
	}

	async selectDropdownRace($event: any) {
	this.meeting_id = $event.target.value;
		if(this.meeting_id == 1) {
			this.isSelect = 1;
			this.getallRaces();
		} else {
			this.getEventsRaces($event.target.value, $event.target.selectedIndex + 1);
		}
	}

	onImgError(event: any, seq_no: any) {
		event.target.src = 'assets/images/jockeys/jockey' + seq_no + '.png';
	}
}
