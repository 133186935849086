import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PlanService } from '../services/plan.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCardComponent } from '../shared/add-card/add-card.component';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Options } from 'select2';
declare let ga: Function;

import * as moment from 'moment';
import { SegmentAnalyticsService } from '../services/segment-analysis.service';
declare var pinApi: any;
declare var fbq: any;


@Component({
	selector: 'app-checkout-rc',
	templateUrl: './checkout-rc.component.html',
	styleUrls: ['./checkout-rc.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CheckoutRCComponent implements OnInit {
	features: any;
	plans: any;
	feature_loading: boolean = true;
	cards_loading: boolean = true;
	plans_loading: boolean = true;
	plans_selected: boolean = false;
	selectedPlan: any;
	activeSlide: any = 'slide-1';
	name: any;
	cardnumber: any;
	expirationdate: any;
	cvc: any;
	output: any;
	ccicon: any;
	ccsingle: any;
	generatecard: any;
	selection: any;
	submitted: any = false;
	payment_gateway_response: any;
	pinpayment_response: any;
	user: any;
	selected_plan: any;
	final_selections: any;
	token: any;
	plan_id: any;
	loading_features: any = false;
	loading_plans: any = false;
	payment_progress: any = 0;
	features_count: any = 0;
	plans_count: any = 0;
	selected_features: any = [];
	premium_subscribed: any;
	plan_duration: any;
	cards: any;
	payment_method: any;
	loading: any;
	paymentForm: FormGroup = this.formBuilder.group({
		number: ['', [Validators.required]],
		name: ['', [Validators.required]],
		expiry_month: ['', [Validators.required]],
		cvc: ['', [Validators.required]],
		expiry_year: ['', [Validators.required]],
	});

	pinpayment_errors: any = {
		number: null,
		name: null,
		expiry_month: null,
		cvc: null,
		expiry_year: null,
	};

	couponCode: any;
	couponCodeError: any;
	couponApplied: any;
	coupon: any;
	showCouponForm: boolean = false;

	constructor(
		private PlanService: PlanService,
		private route: Router,
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toastr: ToastrService,
		private modalService: NgbModal,
		private segmentAnalyticsService: SegmentAnalyticsService
	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		this.token = this.user.token;

		if (this.user != null && this.user != 'undefined' && this.user.token) {
			this.token = this.user.token;
			this.selected_plan = localStorage.getItem('selected_plan');
			if (this.selected_plan) {
				this.selected_plan = JSON.parse(this.selected_plan);
				this.plan_duration = this.selected_plan.duration.duration;
			}
			this.final_selections = localStorage.getItem('final_selections');
			if (this.final_selections) {
				this.final_selections = JSON.parse(this.final_selections);
				if (this.final_selections.length === 3) {
					this.final_selections.splice(2, 1);
				}
			}
			console.log('final_selections', this.selected_plan)
			
			if (this.user && this.user.subscription && this.user.subscription.plan && this.user.subscription.status) {
				if (this.user.subscription.plan.premium_plan) {
					this.premium_subscribed = true;
				}
			}
		}
	}

	ngOnInit(): void {
		if (this.token) {
			this.getCards();
			this.getFeatures();
		}
	}
	get f() {
		return this.paymentForm.controls;
	}

	async getUser() {
		await this.userService.getAuthUser(this.token).subscribe((data: any) => {
			if (data) {
				if (data.result) {
					localStorage.setItem('trc_user', JSON.stringify(data.result));
				}
			}
		});
	}
	skip(){
		const price = this.selected_plan?.duration.price;
		const discountedPrice = this.selected_plan?.duration.discounted_price;
		const basePlan = this.final_selections.filter((item:any)=>{return item.base_plan ==1 && item.is_active ==1})
		const addonPlan = this.final_selections.filter((item:any)=>{return item.base_plan ==0 && item.is_active ==1})
		this.segmentAnalyticsService.track('Subscription Skipped', {
			"skipped_on_screen": "Confirm Subscription",
			"subscription_tier": basePlan[0]?.title,
			"add_on_tier": addonPlan[0]?.title,
			"subtotal":  this.couponApplied ? discountedPrice : price,
			"total": this.couponApplied ? discountedPrice : price
		  })
		
	}
	async getCards() {
		this.userService.getCards(this.token).subscribe((data: any) => {
			//console.log(data);
			if (data && data.result) {
				this.cards = data.result ? data.result : null;
			}
			this.cards_loading = false;
		});
	}

	async addCard() {
		let dialogRef = this.modalService.open(AddCardComponent, { size: 'custom', windowClass: 'login-modal' });

		dialogRef.result.then(
			(data: any) => {
				if (data) this.getCards();
			},
			(reason: any) => {
				if (reason) this.getCards();
			}
		);
	}

	async selectPaymentMethod(card: any) {
		this.payment_method = card;
	}

	async getFeatures() {
		this.feature_loading = true;
		await this.PlanService.getFeatures().subscribe((data: any) => {
			if (data) {
				if (data.features) {
					this.features = data.features;
					this.features_count = this.features.length;
				}
			}
			this.feature_loading = false;
		});
	}

	async getPlans(selected_features: any) {
		this.plans_loading = true;
		await this.PlanService.getPlans(selected_features).subscribe((data: any) => {
			if (data) {
				if (data.plans) {
					this.plans = data.plans;
					this.plans.forEach((element: any) => {
						element.checked = false;
					});
				}
			}
			this.plans_loading = false;
		});
	}

	featureSelected(index: any) {
		// this.features[index].checked = !this.features[index].checked;

		this.features.forEach((element: any) => {
			if (element.checked) this.selected_features.push(element.id);
		});
		if (this.selected_features.length > 0) this.getPlans(this.selected_features);
	}

	PlanSelected(index: any) {
		this.selectedPlan = this.plans[index];
	}

	selectPlan(plan: any, price: any) {
		if (this.selected_plan && this.selected_plan.price && this.selected_plan.price.id == price.id) {
			this.selected_plan.price = null;
		} else {
			this.selected_plan = plan;
			this.selected_plan.price = price;
		}
	}

	public findInvalidControls() {
		const invalid = [];
		const controls = this.paymentForm.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		return invalid;
	}

	async onSubmit() {
		this.loading = true;
		this.submitted = true;
		// stop here if form is invalid
		if (this.cards.length > 0) {
			if (!this.payment_method) {
				this.loading = false;
				this.toastr.warning('Please select payment method');
			} else {
				this.loading = false;
				this.subscribewithCard();
			}
		} else {
			this.loading = false;
			this.createCustomerwithCard();
		}
	}

	async createCustomerwithCard() {
		this.loading = true;
		if (this.paymentForm.invalid) {
			// //console.log(this.findInvalidControls());
			this.loading = false;
			return;
		}

		this.payment_progress = 0;

		setTimeout(function () {
			$('#theprogressbar')
				.attr('aria-valuenow', 10)
				.css('width', 10 + '%');
		}, 2000);

		this.pinpayment_response = await pinApi
			.createCardToken(this.paymentForm.value)
			.then(function (paymentForm: any) {
				return paymentForm;
				// this.handleSuccess(card);
			})
			.catch(function (e: any) {
				//console.log('Error: ', e);
				return e;
			});

		if (this.pinpayment_response && this.pinpayment_response.error) {
			setTimeout(function () {
				$('#theprogressbar')
					.attr('aria-valuenow', 20)
					.css('width', 20 + '%');
			}, 2000);

			this.pinpayment_response.messages.forEach((element: any) => {
				this.pinpayment_errors[element.param] = element.message;
			});
			this.loading = false;
		}

		setTimeout(function () {
			$('#theprogressbar')
				.attr('aria-valuenow', 30)
				.css('width', 30 + '%');
		}, 1000);

		if (this.pinpayment_response && !this.pinpayment_response.error) {
			let card = {
				card_token: this.pinpayment_response.token,
				plan_id: this.selected_plan.id,
				plan_price_id: this.selected_plan.duration.id,
				coupon : this.couponApplied ? this.couponCode : false
			};
			this.userService.createCustomerWithCard(card, this.token).subscribe((data: any) => {
				if (data && data.status) {
					this.user = data.user ? data.user : [];
					let subscription = data.subscription_response.original.subscription
						? data.subscription_response.original.subscription
						: null;
					let plan = data.subscription_response.original.plan
						? data.subscription_response.original.plan
						: null;
					let planPrice = data.subscription_response.original.planPrice
						? data.subscription_response.original.planPrice
						: null;
					let subscription_response = data.subscription_response.original
						? data.subscription_response.original
						: null;

					if (!subscription_response.status) {
						this.toastr.error(subscription_response.message);
					} else {
						this.getUser();
						setTimeout(function () {
							$('#theprogressbar')
								.attr('aria-valuenow', 100)
								.css('width', 100 + '%');
						}, 1000);
						this.sendGoogleTransaction(subscription, plan, planPrice);
						let subscription_tier
						if(subscription && plan && plan.title) {
							subscription_tier = plan.title + ' - ' + plan.duration;
						}
						window.analytics.identify(this.user.id, {
							email: this.user.email,
							name: this.user.name,
							subscription_tier: subscription_tier,
							first_name: this.user.name,
							last_name:  this.user.last_name,
							handle: this.user.display_name,
	
						});
						this.route.navigate(['/thank-you']);
					}

					this.loading = false;
				} else {
					alert('There is an issue with payment');
					this.loading = false;
				}
			});
		}
	}

	async subscribewithCard() {
		this.loading = true;
		let card = {
			card_token: this.payment_method.token,
			plan_id: this.selected_plan.id,
			plan_price_id: this.selected_plan.duration.id,
			coupon : this.couponApplied ? this.couponCode : false
		};
		this.userService.createSubscription(card, this.token).subscribe((data: any) => {
			if (data && data.status) {
				this.user = data.user ? data.user : [];
				let subscription = data.subscription ? data.subscription : null;
				let plan = data.plan ? data.plan : null;
				let planPrice = data.planPrice ? data.planPrice : null;

				this.getUser();
				this.loading = false;
				setTimeout(function () {
					$('#theprogressbar')
						.attr('aria-valuenow', 100)
						.css('width', 100 + '%');
				}, 1000);
				this.sendGoogleTransaction(subscription, plan, planPrice);
				let subscription_tier
				if(subscription && plan && plan.title) {
					subscription_tier = plan.title + ' - ' + plan.duration;
				}
				window.analytics.identify(this.user.id, {
					email: this.user.email,
					name: this.user.name,
					subscription_tier: subscription_tier,
					first_name: this.user.name,
					last_name:  this.user.last_name,
					handle: this.user.display_name,

				});
				this.subscribeSegment()
				if (typeof fbq !== 'undefined') {
					// Track the 'Purchase' event
					fbq('track', 'Purchase', {
					  value: planPrice,
					  currency: 'AUD'
					});
				  } else {
					console.error('Facebook Pixel is not loaded.');
				  }
				this.route.navigate(['/thank-you']);
			} else {
				this.toastr.error(data.message);
				this.loading = false;
			}
		});
	}

	async selectFeature(index: any) {
		//console.log(index);
		if (this.features[index].checked) {
			this.features[index].checked = false;
		} else {
			this.features[index].checked = true;
		}
	}

	async sendGoogleTransaction(subscription: any, plan: any, planPrice: any) {
		ga('require', 'ecommerce');

		ga('ecommerce:addTransaction', {
			id: subscription.pinpayments_subscrId, // Please populate this with a unique transaction ID
			affiliation: 'Racing Central Website', // Hardcoded.
			revenue: planPrice.price, // Price of package.
			tax: (planPrice.price / 11).toFixed(2), // Price divided by 11
			currency: 'AUD', // Hardcoded.
		});

		ga('ecommerce:addItem', {
			id: subscription.pinpayments_subscrId, // This should be the same unique ID as above
			name: plan.title + '(' + planPrice.duration + ')', // Dynamically populate this with the package name
			sku: planPrice.pinpayments_planId, // This should be the plan ID
			category: 'TRC Subscriptions', // Hardcoded.
			price: planPrice.price, // Price of package
			quantity: '1', // Hardcoded.
			currency: 'AUD', // Hardcoded.
		});

		ga('ecommerce:send');
	}

	async handleCoupon(apply: boolean = true) {
		if(apply) {
			// APPLY COUPON CALL HERE
			this.couponApplied = false;
			this.couponCodeError = "";
			console.log('this.selected_plan',this.selected_plan, this.couponCode)
			await this.userService.verifyCoupon(this.couponCode,this.selected_plan?.duration?.pinpayments_planId).subscribe((response: any) => {
				if (response?.status === 1) {
					this.couponApplied = true;
					const coupon = response.coupon;
					response?.status 
					this.selected_plan.duration.discounted_price = Number(this.selected_plan.duration.price) - (Number(this.selected_plan.duration.price) * coupon.percentage/100);
					this.selected_plan.duration.discounted_plan = coupon.plan_id;

				} else {
					this.couponApplied = false;
					this.couponCodeError = response.msg;
				}
			});
			// this.couponApplied = true;
		} else {
			// REMOVE COUPON CALL HERE
			this.couponApplied = false;
			this.couponCode = "";
			this.couponCodeError = "";
			this.selected_plan.duration.discounted_plan = this.selected_plan?.duration.pinpayments_planId;


		}

	}
	subscribeSegment(){
		const price = this.selected_plan?.duration.price;
		const discountedPrice = this.selected_plan?.duration.discounted_price;
		const basePlan = this.final_selections.filter((item:any)=>{return item.base_plan ==1 && item.is_active ==1})
		const addonPlan = this.final_selections.filter((item:any)=>{return item.base_plan ==0 && item.is_active ==1})
		this.segmentAnalyticsService.track('Subscription Confirmed', {
			"subscription_tier": basePlan[0]?.title,
			"add_on_tier": addonPlan[0]?.title,
			"subtotal":  this.couponApplied ? discountedPrice : price,
			"total": this.couponApplied ? discountedPrice : price
		  })
		
	}
	async toggleCouponForm() {
		this.showCouponForm = !this.showCouponForm;
	}
}
