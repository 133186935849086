import { Component, OnInit, Input } from '@angular/core';
import * as $ from 'jquery';
import { TweenLite, Cubic } from 'gsap';

@Component({
	selector: '[previews-tab]',
	templateUrl: './previews-tab.component.html',
	styleUrls: ['./previews-tab.component.scss'],
})
export class PreviewsTabComponent implements OnInit {
	@Input() user: any;
	@Input() race_preview: any;
	@Input() suggested_bets: any;
	@Input() top_selections:any;
	@Input() preview_loading: any;
	constructor() {}

	ngOnInit(): void {}

	formatName(name: any) {
		let copy_name = name;
		let regex = /^\s*(?:M(?:iss|rs?|s)|Dr|Rev)\b[\s.]*/;
		let pattern = regex.exec(name);
		return this.getInitials(name.replace(pattern, ''));
	}

	getInitials(name: any) {
		let initials = name.split(' ');
		initials = initials.shift().charAt(0) + '. ' + initials.toString().replace(',', ' ');
		return initials;
	}

	async toggleAccordion(event: any) {
		var $this = $(event.target),
			$content = $(event.target).closest('.collapsable-section').find('.collapsed-content');

		if ($this.closest('.collapsable-section').hasClass('closed')) {
			TweenLite.set($content, {
				height: 'auto',
				opacity: 1,
			});
			TweenLite.from($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
			$this.closest('.collapsable-section').removeClass('closed');
			$this.closest('.collapsable-section').find('.view_text').text('hide races');
			if($this.hasClass("toggleBtn")) {
				$this.addClass('expand');
			} else {
				$this.find('.toggleBtn').addClass('expand');
			}
		} else {
			$this.closest('.collapsable-section').addClass('closed');
			$this.closest('.collapsable-section').find('.view_text').text('view races');
			if($this.hasClass("toggleBtn")) {
				$this.removeClass('expand');
			} else {
				$this.find('.toggleBtn').removeClass('expand');
			}
			TweenLite.to($content, 0.5, {
				height: 0,
				opacity: 0,
				ease: Cubic.easeInOut,
			});
		}
	}

	onImgError(event: any, seq_no: any) {
		event.target.src = 'assets/images/jockeys/jockey' + seq_no + '.png';
	}
}
