import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ResourceService } from '../services/resource.service';
import { OffersService } from '../services/offers.service';
import { FormGuideService } from '../services/form-guide.service';
import { Router } from '@angular/router';
import { MetatagService } from '../services/metatag.service';

@Component({
	selector: 'app-resources',
	templateUrl: './resources.component.html',
	styleUrls: ['./resources.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ResourcesComponent implements OnInit {
	resources: any;
	offer: any;
	resourceId: any;
	quote: string = '';
	resourceSlug: any;
	featured_resource: any;
	loading: boolean = true;
	previews: any;
	loadingOffers: boolean = true;
	loadingTodaysPreviews: boolean = true;
	loadingFeaturedResource: boolean = true;
	loadingResourcesList: boolean = true;
	user: any;
	page:any=1;
	totalResourcesCount:any;

	constructor(
		private resourceService: ResourceService,
		private route: Router,
		private OffersService: OffersService,
		private FormGuideService: FormGuideService,
		private MetatagService: MetatagService
	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		this.MetatagService.updateMetatags('Horse Racing Resources', 'web site', 'Get the latest horse racing resources and tools right around the world at TheRaceClub. Be sure to stay up to date on trainers, horses, jockeys, tips on top bets and much more.','https://mobile.theraceclub.com.au/assets/images/the-race-club-facebook-og.png');
	}

	ngOnInit() {
		this.getresourcesList(this.page);
		this.getFeaturedresources();
		if (this.user) {
			this.getOffer();
		} else {
			this.getGuestOffer();
		}
		this.getTodaysPreviews();
	}

	getOffer() {
		this.OffersService.getOffer(this.user.token).subscribe((data: any) => {
			if (data && data.offer) {
				this.offer = data.offer ? data.offer : [];
				this.offer.buttons = JSON.parse(this.offer.buttonsmeta);
			}
			this.loadingOffers = false;
		});
	}

	getGuestOffer() {
		this.OffersService.getGuestOffer().subscribe((data: any) => {
			if (data && data.offer) {
				this.offer = data.offer ? data.offer : [];
				this.offer.buttons = JSON.parse(this.offer.buttonsmeta);
			}
			this.loadingOffers = false;
		});
	}

	getTodaysPreviews() {
		this.FormGuideService.getTodaysPreviews().subscribe((data: any) => {
			if (data.status && data.previews) {
				this.previews = data.previews ? data.previews : [];
				this.previews.sort(this.compare);
			}
			this.loadingTodaysPreviews = false;
		});
	}

	// Sort Upcoming Races
	compare(a: any, b: any) {
		if (a.race_start_time < b.race_start_time) {
			return -1;
		}
		if (a.race_start_time > b.race_start_time) {
			return 1;
		}
		return 0;
	}

	getresourcesList(page:any) {
		this.resourceService.getResourcesList(page).subscribe((data: any) => {
			if (data && data.resources) {
				this.resources = data.resources ? data.resources : [];
				this.totalResourcesCount = data.total;
			}
			this.loadingResourcesList = false;
		});
	}

	getFeaturedresources() {
		this.resourceService.getFeaturedResources().subscribe((data: any) => {
			if (data && data.resources) {
				this.featured_resource = data.resources ? data.resources[0] : [];
			}
			this.loadingFeaturedResource = false;
		});
	}

	gotoSingleResource(resource: any) {
		this.route.navigate(['/resource/' + resource.id + '/' + resource.slug]);
	}

	async OfferClicked(row: any) {
		if (row.link_type == 'url') {
			window.open(row.value);
		} else {
			this.route.navigate([row.value]);
		}
	}

	pageChanged(event: any) {
		this.page = event;
		this.loadingResourcesList = true;
		this.getresourcesList(event);
	}
}
