import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PlanService } from '../../services/plan.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Options } from 'select2';

import * as moment from 'moment';
declare var pinApi: any;

@Component({
	selector: 'app-add-card',
	templateUrl: './add-card.component.html',
	styleUrls: ['./add-card.component.scss'],
})
export class AddCardComponent implements OnInit {
	features: any;
	plans: any;
	feature_loading: boolean = true;
	plans_loading: boolean = true;
	plans_selected: boolean = false;
	selectedPlan: any;
	activeSlide: any = 'slide-1';
	name: any;
	cardnumber: any;
	expirationdate: any;
	cvc: any;
	output: any;
	ccicon: any;
	ccsingle: any;
	generatecard: any;
	selection: any;
	submitted: any = false;
	payment_gateway_response: any;
	pinpayment_response: any;
	user: any;
	selected_plan: any;
	token: any;
	plan_id: any;
	loading_features: any = false;
	loading_plans: any = false;
	loading: any = false;
	payment_progress: any = 0;
	features_count: any = 0;
	plans_count: any = 0;
	selected_features: any = [];

	paymentForm: FormGroup = this.formBuilder.group({
		number: ['', [Validators.required]],
		name: ['', [Validators.required]],
		expiry_month: ['', [Validators.required]],
		cvc: ['', [Validators.required]],
		expiry_year: ['', [Validators.required]],
	});

	pinpayment_errors: any = {
		number: null,
		name: null,
		expiry_month: null,
		cvc: null,
		expiry_year: null,
	};

	constructor(
		private PlanService: PlanService,
		private route: Router,
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toastr: ToastrService,
		private modalService: NgbModal
	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		this.token = this.user.token;
	}

	ngOnInit(): void {
		this.getUser();
	}
	get f() {
		return this.paymentForm.controls;
	}

	async getUser() {
		await this.userService.getAuthUser(this.token).subscribe((data: any) => {
			if (data) {
				if (data.result) {
					localStorage.setItem('trc_user', JSON.stringify(data.result));
				}
			}
		});
	}

	closemodal() {
		this.modalService.dismissAll(false);
	}

	public findInvalidControls() {
		const invalid = [];
		const controls = this.paymentForm.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		return invalid;
	}

	async onSubmit() {
		this.submitted = true;
		this.loading = true;
		// stop here if form is invalid
		if (this.paymentForm.invalid) {
			// console.log(this.findInvalidControls());
			this.loading = false;
			return;
		}

		this.payment_progress = 0;

		setTimeout(function () {
			$('#theprogressbar')
				.attr('aria-valuenow', 10)
				.css('width', 10 + '%');
		}, 2000);

		this.pinpayment_response = await pinApi
			.createCardToken(this.paymentForm.value)
			.then(function (paymentForm: any) {
				//console.log(paymentForm);
				return paymentForm;
				// this.handleSuccess(card);
			})
			.catch(function (e: any) {
				//console.log('Error: ', e);
				return e;
			});
			this.loading = false;

		if (this.pinpayment_response && this.pinpayment_response.error) {
			setTimeout(function () {
				$('#theprogressbar')
					.attr('aria-valuenow', 20)
					.css('width', 20 + '%');
			}, 2000);

			this.pinpayment_response.messages.forEach((element: any) => {
				this.pinpayment_errors[element.param] = element.message;
			});
		}

		setTimeout(function () {
			$('#theprogressbar')
				.attr('aria-valuenow', 30)
				.css('width', 30 + '%');
		}, 1000);
		
		if (this.pinpayment_response && !this.pinpayment_response.error) {
			let card = {
				card_token: this.pinpayment_response.token,
			};
			this.userService.addCard(card, this.token).subscribe((data: any) => {
				if (data && data.status) {
					this.user = data.user ? data.user : [];
					this.getUser();
					setTimeout(function () {
						$('#theprogressbar')
							.attr('aria-valuenow', 100)
							.css('width', 100 + '%');
					}, 1000);
					this.loading = false;
					this.toastr.success('Card has been added successfully.');
					this.modalService.dismissAll(true);
				} else {
					this.loading = false;
					this.toastr.error('Card could not be added successfully.');
				}
			});
		}
	}
}
