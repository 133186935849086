<!-- review tab -->
<div class="heading_table row" *ngIf="user && user.hasPreviews && race_review">
	<h2 class="heading col-12 align-self-center mb-4">Review</h2>
</div>
<!-- end heading_table -->

<div class="text_wrap row" *ngIf="user && user.hasPreviews && race_review">
	<div class="col-text col-xl-11 col-lg-11 col-md-11 col-sm-12 order-md-2">
		<div class="body panel grey bubble_chat bubble_white_bg bubble_left">
			{{ race_review.trc_review }}
		</div>
		<!-- end body -->
	</div>
	<!-- end col-text -->

	<div class="col-img col-xl-1 col-lg-1 col-md-1 col-sm-12 order-md-1 align-self-end">
		<img class="img-fluid profile-img" src="assets/images/profile.png" alt="TRC" />
	</div>
	<!-- end col-img -->
</div>

<div class="row race_reviews" *ngIf="user && user.hasPreviews && race_review">
	<h2 class="heading col-md-6 align-self-center">Runners to watch</h2>

	<div class="view_links col-md-6 align-self-center text-right">
		<a class="link" href="#">View form guide</a>
	</div>

	<div class="reviews_wrap col-12 mt-4">
		<div class="review-tables-wrap row" *ngFor="let row of runners_to_watch; let i = index">
			<div
				class="t-index col-1"
				[ngClass]="{
					green: row?.horse_fp == 1,
					yellow: row?.horse_fp == 2 || row?.horse_fp == 3,
					grey: row?.horse_fp > 3,
					Scratched: row?.horse_status == 'Scratched' || row?.horse_status == 'LateScratched'
				}"
			>
				<div class="inner">
					<div class="inner">
						<span
							><strong>{{ row?.horse_fp }}</strong></span
						>
						<span>of {{ race_review?.total_runners }}</span>
					</div>
					<!-- end inner -->
				</div>
				<!-- end inner -->
			</div>
			<!-- end t-index -->

			<div class="t-conents col-11 border-gradient with-padd">
				<div class="panel grey">
					<table class="table">
						<tbody class="horse-panel-header">
							<!-- DESKTOP LAYOUT -->
							<tr
								[ngClass]="{
									Scratched: row.horse_status == 'Scratched' || row.horse_status == 'LateScratched'
								}"
								class="d-none d-lg-block"
							>
								<td style="width: 8%" class="tabno">
									<img
										class="img-fluid jockey-img"
										[src]="row.silks"
										(error)="onImgError($event, i + 1)"
										alt="TRC"
									/>
									<h1 class="index_no">{{ i + 1 }}.</h1>
									<!-- <h1 class="index_no">{{ row.tabno }}.</h1> -->
								</td>

								<td style="width: 27%" class="td_name">
									<div class="player_name">
										<h3>
											<span class="index_no">{{ i + 1 }}.</span>{{ row.horse_name }}
											<small>({{ row.barrier }})</small>
											<div
												class="yard_pick circle"
												placement="top"
												ngbTooltip="Yard pick"
												*ngIf="row.yard_pick && user && user?.hasLiveYard"
											>
												<span>Y</span>
											</div>
											<!-- end yard_pick circle -->
											<div
												class="preview circle"
												placement="top"
												ngbTooltip="Preview pick (win)"
												*ngIf="row.win_bet && user && user?.hasPreviews"
											>
												<span>W</span>
												<!--<span>P</span>-->
												<!-- SHOW P IF THE PICK IS A PLACE -->
											</div>
											<!-- end yard_pick circle -->
											<div
												class="preview_place circle"
												placement="top"
												ngbTooltip="Preview pick (place)"
												*ngIf="row.place_bet && user && user?.hasPreviews"
											>
												<span>P</span>
												<!--<span>P</span>-->
												<!-- SHOW P IF THE PICK IS A PLACE -->
											</div>
											<div
											class="best_bet image_circle"
											placement="top"
											ngbTooltip="Best Bet"
											*ngIf="row.best_bet && user && user?.hasPreviews"
										>
											<span><img
												alt="TRC"
												class="img-fluid"
												src="assets/images/icons/best_bet.png"
											/></span>
											<!--<span>P</span>-->
											<!-- SHOW P IF THE PICK IS A PLACE -->
										</div>
										<div
										class="value_bet image_circle"
										placement="top"
										ngbTooltip="Value Bet"
										*ngIf="row.value_bet && user && user?.hasPreviews"
									>
										<span><img
											alt="TRC"
											class="img-fluid"
											src="assets/images/icons/value_bet.png"
										/></span>
										<!--<span>P</span>-->
										<!-- SHOW P IF THE PICK IS A PLACE -->
									</div>
											<div
												class="dual_nomination circle"
												placement="top"
												ngbTooltip="Dual acceptance"
											>
												<span>D</span>
											</div>
											<!-- end yard_pick circle -->
										</h3>
									</div>
									<span class="player_attributes"
										>{{ row.age }}YO{{ row.sex }} {{ row.colour }} {{ row.sire }} x
										{{ row.dam }}</span
									>
								</td>

								<td style="width: 15%" class="form">
									<span class="small_space"><strong>Last Race:</strong> {{ row.formLast4 }}</span>
									<span><strong>Career:</strong> {{ row.st_all }}</span>
								</td>

								<td style="width: 18%" class="trainer">
									<span class="small_space"><strong>T:</strong> {{ row.trainer }}</span>
									<span><strong>J:</strong> {{ row.jockey }}</span>
								</td>

								<td style="width: 12%" *ngIf="user && user.hasPreviews" class="trc_rating">
									<span class="rating active" *ngIf="row.last_race_trc_rating">{{
										row.last_race_trc_rating
									}}</span>
									<span class="rating active" *ngIf="!row.last_race_trc_rating">NYR</span>
									<span><strong>TRC Rating</strong></span>
								</td>

								<td style="width: 12%" *ngIf="!user || !user.hasPreviews" class="trc_rating locked">
									<span class="lock-icon">
										<img src="assets/images/icons/lock.svg" alt="locked" />
									</span>
									<span class="trc_rating">TRC Rating</span>
								</td>

								<td style="width: 20%" class="blackbook_btn text-right">
									<a class="btn large" (click)="OpenBlackbook('row')">Add to Blackbook</a>
								</td>
							</tr>

							<!-- MOBILE LAYOUT -->
							<tr
								[ngClass]="{
									Scratched: row.horse_status == 'Scratched' || row.horse_status == 'LateScratched'
								}"
								class="d-lg-none mobile_layout"
							>
								<td style="width: 10%" class="tabno">
									<img
										class="img-fluid jockey-img"
										[src]="row.silks"
										(error)="onImgError($event, i + 1)"
										alt="TRC"
									/>
									<h1 class="index_no">{{ i + 1 }}.</h1>
									<!-- <h1 class="index_no">{{ row.tabno }}.</h1> -->
								</td>

								<td style="width: 90%" class="td_name">
									<div class="player_name">
										<h3>
											<span class="index_no">{{ i + 1 }}.</span>{{ row.horse_name }}
											<small>({{ row.barrier }})</small>
											<div
												class="yard_pick circle"
												placement="top"
												ngbTooltip="Yard pick"
												*ngIf="row.yard_pick && user && user?.hasLiveYard"
											>
												<span>Y</span>
											</div>
											<!-- end yard_pick circle -->
											<div
												class="preview circle"
												placement="top"
												ngbTooltip="Preview pick (win)"
												*ngIf="row.win_bet && user && user?.hasPreviews"
											>
												<span>W</span>
												<!--<span>P</span>-->
												<!-- SHOW P IF THE PICK IS A PLACE -->
											</div>
											<!-- end yard_pick circle -->
											<div
												class="preview_place circle"
												placement="top"
												ngbTooltip="Preview pick (place)"
												*ngIf="row.place_bet && user && user?.hasPreviews"
											>
												<span>P</span>
												<!--<span>P</span>-->
												<!-- SHOW P IF THE PICK IS A PLACE -->
											</div>
											<div
												class="dual_nomination circle"
												placement="top"
												ngbTooltip="Dual acceptance"
											>
												<span>D</span>
											</div>
											<!-- end yard_pick circle -->
										</h3>
									</div>
									<div class="inner">
										<div class="content">
											<table>
												<tr>
													<td>
														<span class="player_attributes"
															>{{ row.age }}YO{{ row.sex }} {{ row.colour }}</span
														>
													</td>
													<td>
														<span class="small_space"
															><strong>L4:</strong> {{ row.formLast4 }}</span
														>
													</td>
													<td rowspan="2" class="blackbook_btn text-right">
														<a class="btn large" (click)="OpenBlackbook('row')"
															>Add to Blackbook
															<img
																src="assets/images/icons/book.svg"
																alt="Add to Blackbook"
														/></a>
													</td>
												</tr>
												<tr>
													<td>
														<span><strong>J:</strong> {{ row.jockey }}</span>
													</td>
													<td>
														<span class="small_space"
															><strong>T:</strong> {{ row.trainer }}</span
														>
													</td>
												</tr>
											</table>
										</div>
										<!-- end content -->
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<!-- table -->
				</div>
			</div>
			<!-- end t-conents -->
		</div>
		<!-- end review-tables-wrap -->
	</div>
</div>
<!-- end row -->

<div class="top_text_wrapper" *ngIf="(!user || !user.hasPreviews) && race_review">
	<h2 class="heading">Unlock professional analysis & insights</h2>

	<div class="body">
		Subscribe to the 'Platinum' or 'Previews & Analysis' package to access along with other exclusive content.
	</div>
	<a class="btn blue" routerLink="/signup" *ngIf="user && !user.hasPreviews">Unlock</a>
	<a class="btn blue" routerLink="/signup" *ngIf="!user">Sign up</a>

	<img class="img-fluid placeholder-img" src="assets/images/empty-placeholder-2.png" alt="TRC" />
</div>

<div class="top_text_wrapper" *ngIf="!race_review">
	<h2 class="heading">No reviews available</h2>
</div>
