import { Title, Meta } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetatagService {
  apiUrl = environment.serverUrl;

  constructor(
    private titleService: Title,
		private metaService: Meta,
    private httpClient: HttpClient
  ) { }

	public updateMetatags(title:any, type:any, description:any, image:any)  {

    let status = { "metatags" : "updated"};
    this.metaService.updateTag({ property: 'og:image', content: image });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:type', content: 'web site' });
    this.metaService.updateTag({ property: 'twitter:type', content: 'web site' });
    this.metaService.updateTag({ property: 'twitter:title', content: title});
    this.metaService.updateTag({ property: 'twitter:description', content: description});
    this.metaService.updateTag({ property: 'twitter:image', content: image});
    this.metaService.updateTag({ property: 'robots', content: 'index, follow'});
		return status;
	}
  
}




@Injectable({
	providedIn: 'root',
})
export class GeoIpService {

}
