import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[appAutofocus]',
})
export class AutofocusDirective {
	@Input() public appAutofocus: any;

	public constructor(private el: ElementRef) {}

	public ngAfterContentInit() {
		setTimeout(() => {
			this.el.nativeElement.focus();
		}, 500);
	}
}
