import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class EventService {
	userLogin = new BehaviorSubject(false);

	constructor() {}

	userLoggedin() {
		this.userLogin.next(true);
	}

	userLoggedout() {
		this.userLogin.next(false);
	}
}
