import { Injectable } from '@angular/core';
// import { AnalyticsBrowser } from '@segment/analytics-next';

@Injectable({
  providedIn: 'root',
})
export class SegmentAnalyticsService {
 analytics:any;

  constructor(
    // private segmentAnalytics: AnalyticsBrowser
  ) {
    // this.analytics = this.segmentAnalytics.load({ writeKey: 'QTgQ5zMFqbxaxGfR5vFIZ3nco3sgQCSK' })


  }

  track(event: string, properties?: {}) {
    // this.analytics.track(event, properties);
  }

  page(route: string, properties?: {}) {
    // this.analytics.page(route, properties);
  }

  identify(user?:any) {
    // if(user && user.id) {
    //   this.analytics.identify(user.id, {
    //     email: user.email,
    //     first_name: user.name,
    //     last_name: user.last_name,
    //     average_stake: user?.average_stake,
    //     avatar_url: user?.avatar_url,
    //     birth_date: user?.birth_date,
    //     racing_iq: user?.racing_iq

    //   });
    // } else {
    //   this.analytics.identify('anonymous', {});

    // }
  }
}