<div class="breadcrumbs_wrapper bg-image" style="background-image: url('assets/images/horse-portrait.jpg')">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title" *ngIf="!loading">{{ resource?.title }}</h1>
					<h1 class="page_title loading-text" *ngIf="loading">&nbsp;</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/" routerLink="/">Home</a></li>
							<li><a routerLink="/resources" routerLink="/resources">Resources</a></li>
							<!--<li>{{ resource?.title }}</li>-->
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end breadcrumbs_wrapper -->

<div class="resoucepage_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-lg-10 offset-lg-1">
				<div class="row">
					<div class="col-xl-8 col_left" *ngIf="!loading">
						<div class="image_wrap bottom-border gradient" *ngIf="resource?.image_url">
							<img
								src="{{ resource?.image_url }}"
								alt="{{ resource?.title }}"
								class="resource-image img-fluid"
							/>
						</div>

						<div class="content pad-left-right">
							<div class="row">
								<h3 class="content_title col-6">
									<strong>{{ resource?.author_name }}</strong>
								</h3>
								<div class="date col-6" *ngIf="resource?.created_at">
									{{ resource?.created_at | date }}
								</div>
							</div>

							<div class="body semibold large">
								{{ resource?.short_description }}
							</div>

							<div class="body" [innerHTML]="resource?.long_description"></div>
						</div>
						<!-- end content -->
					</div>
					<!-- end col_left -->

					<div class="col-xl-8 col_left" *ngIf="loading">
						<div class="image_wrap bottom-border gradient loading-text">
							
						</div>
				
						<div class="content pad-left-right">
							<div class="row">
								<h3 class="content_title col-6">
									<strong class="loading-text"></strong>
								</h3>
								<div class="date col-6 loading-text" >
									
								</div>
							</div>
				
							<div class="body loading-text" >
							</div>
						</div>
						<!-- end content -->
					</div><!-- GHOST ELEMENTS -->

					<div class="col-xl-4 col_right">
						<div class="row">
							<div class="col-12 shared_links" *ngIf="!loading">
								<div class="panel">
									<span class="text">Share to:</span>

									<div class="link_wrap">
										<span>
											<a
												href="http://www.facebook.com/share.php?u={{ pageUrl }}&amp;t={{
													resource?.title
												}}"
												title="Share on Facebook."
												target="_blank"
												><i class="fa fa-facebook-square"></i
											></a>
										</span>
										<span>
											<a
												href="http://www.linkedin.com/shareArticle?mini=true&amp;title={{
													resource?.title
												}}&amp;url={{ pageUrl }}"
												title="Share on LinkedIn"
												target="_blank"
												><i class="fa fa-linkedin-square"></i
											></a>
										</span>
										<span>
											<a
												href="http://twitter.com/intent/tweet?text={{ resource?.title }} - {{
													pageUrl
												}}"
												title="Tweet this!"
												target="_blank"
												><i class="fa fa-twitter"></i
											></a>
										</span>
										<span>
											<a
												href="mailto:?Subject={{ resource?.title }}&Body={{
													resource?.short_description
												}} {{ pageUrl }}"
												><i class="fa fa-envelope" aria-hidden="true"></i
											></a>
										</span>
									</div>
									<!-- end link_wrap -->
								</div>
							</div>

							<!-- GHOST ELEMENTS -->
							<div class="col-12 shared_links" *ngIf="loading">
								<div class="panel d-flex justify-content-between align-items-center">
									<span class="text loading-text"></span>
				
									<div class="link_wrap d-flex">
										<span class="loading-text" *ngFor="let number of [0, 1, 2, 3]"></span>
									</div>
									<!-- end link_wrap -->
								</div>
							</div>

							<div class="col-xl-12 col-md-6 featured_resource" *ngIf="featured_resource && !loadingFeaturedResource">
								<div class="inner">
									<div
										class="resource_img"
										[style.backgroundImage]="'url(' + featured_resource?.image_url + ')'"
									></div>
									<div class="content">
										<h3 class="new_title">{{ featured_resource?.title }}</h3>
										<div class="body">
											{{ featured_resource?.short_description }}
										</div>
										<a class="link small" (click)="gotoSingleresource(featured_resource)"
											>Read more</a
										>
									</div>
									<!-- end content -->

									<h4 class="pill">Featured Article</h4>
								</div>
							</div>
							<!-- end featured_resource -->

							<!-- GHOST ELEMENTS -->
							<div class="col-xl-12 col-md-6 featured_resource" *ngIf="loadingFeaturedResource">
								<div class="inner">
									<div class="resource_img loading-text"></div>
									<div class="content">
										<h3 class="new_title loading-text"></h3>
										<div class="body loading-text"></div>
									</div>
									<!-- end content -->

									<h4 class="pill">
										<span class="loading-text"></span>
									</h4>
								</div>
							</div>
							<!-- end featured_resource -->

							<div class="col-xl-12 col-md-6 limited_time_offer sidebar" *ngIf="!loadingOffers && offer">
								<div class="panel">
									<div class="col_content">
										<h2 class="title">{{ offer?.heading }}</h2>

										<div class="body white">
											{{ offer?.description }}
										</div>

										<a *ngFor="let row of offer?.buttons" (click)="OfferClicked(row)" class="btn">{{
											row?.key
										}}</a>
									</div>
								</div>
							</div>
							<!-- end limited_time_offer -->

							<!-- GHOST ELEMENTS -->
							<div class="col-xl-12 col-md-6 limited_time_offer sidebar" *ngIf="loadingOffers">
								<div class="panel">
									<div class="col_content">
										<h2 class="title loading-text"></h2>
				
										<div class="body white loading-text">
											
										</div>
				
										<a class="btn loading-text"></a>
									</div>
								</div>
							</div>
							<!-- end limited_time_offer -->

						</div>
					</div>
					<!-- end col_right -->
				</div>
				<!-- end row -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>
