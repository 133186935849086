<div class="row payment_wrapper">
	<div class="checkout_box row slides" *ngIf="!submitted">
		<div class="panel mt-4 col-12">
			<form class="form_wrapper" [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
				<h2 class="heading col-12 text-center mb-2">Pay Tournament Fee</h2>

				<div class="form-row mt-4">
					<div class="form-group col-12 grey">
						<input
							id="name"
							formControlName="name"
							maxlength="20"
							type="text"
							class="form-control"
							placeholder="Name"
						/>
						<div *ngIf="submitted && f.name.errors" class="text-danger">
							<div *ngIf="f.name.errors.required">Name is required</div>
							<div *ngIf="f.name.errors.pattern">Name is not valid</div>
						</div>
						<div *ngIf="submitted && pinpayment_response && pinpayment_response.error" class="text-danger">
							<div *ngIf="pinpayment_errors.name">{{ pinpayment_errors.name }}</div>
						</div>
					</div>
					<div class="form-group col-12 grey">
						<input
							id="number"
							type="text"
							formControlName="number"
							pattern="[0-9]*"
							class="form-control"
							placeholder="Card Number"
							inputmode="numeric"
						/>
						<div *ngIf="submitted && f.number.errors" class="text-danger">
							<div *ngIf="f.number.errors.required">Card Number is required</div>
							<div *ngIf="f.number.errors.pattern">Number is not valid</div>
						</div>
						<div *ngIf="submitted && pinpayment_response && pinpayment_response.error" class="text-danger">
							<div *ngIf="pinpayment_errors.number">{{ pinpayment_errors.number }}</div>
						</div>
					</div>

					<div class="form-group col-lg-6 col-md-6 grey">
						<div class="select_wrap">
							<select id="expiry_month" formControlName="expiry_month" class="form-control">
								<option value="">Expiry Year</option>
								<option value="01">Jan</option>
								<option value="02">Feb</option>
								<option value="03">Mar</option>
								<option value="04">Apr</option>
								<option value="05">May</option>
								<option value="06">Jun</option>
								<option value="07">Jul</option>
								<option value="08">Aug</option>
								<option value="09">Sep</option>
								<option value="10">Oct</option>
								<option value="11">Nov</option>
								<option value="12">Dec</option>
							</select>
						</div>
						<div *ngIf="submitted && f.expiry_month.errors" class="text-danger">
							<div *ngIf="f.expiry_month.errors.required">Expirty Month is required</div>
							<div *ngIf="f.expiry_month.errors.pattern">Expirty Month is not valid</div>
						</div>
						<div
							*ngIf="submitted && pinpayment_response && pinpayment_response.error"
							class="form-control"
							class="text-danger"
						>
							<div *ngIf="pinpayment_errors.expiry_month">
								{{ pinpayment_errors.expiry_month }}
							</div>
						</div>
					</div>
					<div class="form-group col-lg-6 col-md-6 grey">
						<div class="select_wrap">
							<select id="expiry_year" formControlName="expiry_year" class="form-control">
								<option value="">Expiry Year</option>
								<option value="2021">2021</option>
								<option value="2022">2022</option>
								<option value="2023">2023</option>
								<option value="2024">2024</option>
								<option value="2025">2025</option>
								<option value="2026">2026</option>
								<option value="2027">2027</option>
								<option value="2028">2028</option>
								<option value="2029">2029</option>
								<option value="2030">2030</option>
								<option value="2031">2031</option>
								<option value="2032">2032</option>
								<option value="2033">2033</option>
								<option value="2033">2033</option>
								<option value="2033">2033</option>
								<option value="2036">2036</option>
							</select>
						</div>
						<div *ngIf="submitted && f.expiry_year.errors" class="text-danger">
							<div *ngIf="f.expiry_year.errors.required">Expirty Year is required</div>
							<div *ngIf="f.expiry_year.errors.pattern">Expirty Year is not valid</div>
						</div>
						<div
							*ngIf="submitted && pinpayment_response && pinpayment_response.error"
							class="form-control"
							class="text-danger"
						>
							<div *ngIf="pinpayment_errors.expiry_year">{{ pinpayment_errors.expiry_year }}</div>
						</div>
					</div>

					<div class="form-group col-lg-6 col-md-6 grey">
						<input
							id="cvc"
							formControlName="cvc"
							placeholder="CVC"
							type="text"
							pattern="[0-9]*"
							class="form-control"
							inputmode="numeric"
						/>
						<div *ngIf="submitted && f.cvc.errors" class="text-danger">
							<div *ngIf="f.cvc.errors.required">CVC is required</div>
							<div *ngIf="f.cvc.errors.pattern">CVC is not valid</div>
						</div>
						<div *ngIf="submitted && pinpayment_response && pinpayment_response.error" class="text-danger">
							<div *ngIf="pinpayment_errors.cvc">{{ pinpayment_errors.cvc }}</div>
						</div>
					</div>
				</div>
				<div *ngIf="submitted && pinpayment_response && pinpayment_response.error" class="text-danger">
					<div>{{ pinpayment_response.error_description }}</div>
				</div>

				<div class="col_btn col-12 align-self-center text-center py-2">
					<a (click)="onSubmit()" class="btn large blue">Pay ${{ tournament.entry_fee }}</a>
				</div>
			</form>
			<!-- end form_wrapper -->
		</div>
		<!-- end panel -->
	</div>
	<!-- end checkout_box -->

	<div class="row processing_window slides" *ngIf="submitted">
		<div class="panel col-12">
			<div class="checkmark_wrap mb-2">
				<span class="checkmark"></span>
			</div>
			<!-- end checkmark_wrap -->

			<h2 class="heading text-center mb-2">Please wait</h2>

			<div class="body mb-5 text-center mb-2">
				<p>We are validating and processing your payment information.....</p>
			</div>

			<div class="progress skill-bar">
				<div
					class="progress-bar progress-bar-success"
					id="theprogressbar"
					role="progressbar"
					aria-valuenow="100"
					aria-valuemin="0"
					aria-valuemax="100"
				></div>
			</div>
		</div>
	</div>
	<!-- end processing_window -->
</div>
<!-- end payment_wrapper -->
