import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PlanService } from '../services/plan.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';
declare var pinApi: any;

@Component({
	selector: 'app-change-plan',
	templateUrl: './change-plan.component.html',
	styleUrls: ['./change-plan.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ChangePlanComponent implements OnInit {
	features: any;
	plans: any;
	feature_loading: boolean = true;
	plans_loading: boolean = true;
	plans_selected: boolean = false;
	selectedPlan: any;
	activeSlide: any = 'slide-1';
	name: any;
	cardnumber: any;
	expirationdate: any;
	cvc: any;
	output: any;
	ccicon: any;
	ccsingle: any;
	generatecard: any;
	selection: any;
	submitted: any = false;
	payment_gateway_response: any;
	pinpayment_response: any;
	user: any;
	selected_plan: any;
	token: any;
	plan_id: any;
	loading_features: any = false;
	loading_plans: any = false;
	payment_progress: any = 0;
	features_count: any = 0;

	paymentForm: FormGroup = this.formBuilder.group({
		number: ['', [Validators.required]],
		name: ['', [Validators.required]],
		expiry_month: ['', [Validators.required]],
		cvc: ['', [Validators.required]],
		expiry_year: ['', [Validators.required]],
	});

	pinpayment_errors: any = {
		number: null,
		name: null,
		expiry_month: null,
		cvc: null,
		expiry_year: null,
	};

	constructor(
		private PlanService: PlanService,
		private route: Router,
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toastr: ToastrService
	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		this.token = this.user.token;
	}

	ngOnInit(): void {
		this.getUser();
		this.getFeatures();
	}
	get f() {
		return this.paymentForm.controls;
	}

	async getUser() {
		await this.userService.getAuthUser(this.token).subscribe((data: any) => {
			if (data && data.result) {
				this.user = data.result;
				localStorage.setItem('trc_user', JSON.stringify(data.result));
			}
		});
	}

	async getFeatures() {
		this.feature_loading = true;
		await this.PlanService.getFeatures().subscribe((data: any) => {
			if (data) {
				if (data.features) {
					this.features = data.features;
					this.features_count = this.features.length;
				}
			}
			this.feature_loading = false;
		});
	}

	async getPlans(selected_features: any) {
		this.plans_loading = true;
		await this.PlanService.getPlans(selected_features).subscribe((data: any) => {
			if (data) {
				if (data.plans) {
					this.plans = data.plans;
					this.plans.forEach((element: any) => {
						element.checked = false;
					});
				}
			}
			this.plans_loading = false;
		});
	}

	featureSelected(index: any) {
		// this.features[index].checked = !this.features[index].checked;
		let selected_features: any = [];

		this.features.forEach((element: any) => {
			if (element.checked) selected_features.push(element.id);
		});
		if (selected_features.length > 0) this.getPlans(selected_features);
	}

	PlanSelected(index: any) {
		this.selectedPlan = this.plans[index];
	}

	selectPlan(plan: any, price: any) {
		this.selected_plan = plan;
		this.selected_plan.price = price;
	}

	async checkout() {
		localStorage.setItem('selected_plan', JSON.stringify(this.selectedPlan));
		this.route.navigate(['/make-payment']);
	}

	changeSlide(slide: any) {
		//console.log(slide);
		this.activeSlide = slide;
	}

	public findInvalidControls() {
		const invalid = [];
		const controls = this.paymentForm.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		return invalid;
	}

	async onSubmit() {
		this.submitted = true;
		// stop here if form is invalid
		if (this.paymentForm.invalid) {
			// //console.log(this.findInvalidControls());
			return;
		}

		this.changeSlide('slide-4');
		this.payment_progress = 0;

		setTimeout(function () {
			$('#theprogressbar')
				.attr('aria-valuenow', 10)
				.css('width', 10 + '%');
		}, 2000);

		this.pinpayment_response = await pinApi
			.createCardToken(this.paymentForm.value)
			.then(function (paymentForm: any) {
				//console.log(paymentForm);
				return paymentForm;
				// this.handleSuccess(card);
			})
			.catch(function (e: any) {
				//console.log('Error: ', e);
				return e;
			});

		if (this.pinpayment_response && this.pinpayment_response.error) {
			setTimeout(function () {
				$('#theprogressbar')
					.attr('aria-valuenow', 20)
					.css('width', 20 + '%');
			}, 2000);
			this.changeSlide('slide-3');

			this.pinpayment_response.messages.forEach((element: any) => {
				this.pinpayment_errors[element.param] = element.message;
			});
		}

		setTimeout(function () {
			$('#theprogressbar')
				.attr('aria-valuenow', 30)
				.css('width', 30 + '%');
		}, 1000);

		if (this.pinpayment_response && !this.pinpayment_response.error) {
			let card = {
				card_token: this.pinpayment_response.token,
				plan_id: this.selected_plan.price.id,
			};
			this.userService.createCustomerWithCard(card, this.token).subscribe((data: any) => {
				if (data && data.status) {
					this.user = data.user ? data.user : [];
					this.getUser();

					setTimeout(function () {
						$('#theprogressbar')
							.attr('aria-valuenow', 100)
							.css('width', 100 + '%');
					}, 1000);
					this.changeSlide('slide-5');
				} else {
					alert('There is an issue with payment');
					this.changeSlide('slide-4');
				}
			});
		}
	}

	async selectFeature(index: any) {
		if (this.features[index].checked) {
			this.features[index].checked = false;
		} else {
			this.features[index].checked = true;
		}

		//console.log(this.features);
		//console.log(this.features[index]);
	}

	async nextSlide() {
		if (this.activeSlide == 'slide-1') {
			let selected_features: any = [];
			this.features.forEach((element: any) => {
				if (element.checked) selected_features.push(element.id);
			});
			if (selected_features.length > 0) {
				this.getPlans(selected_features);
				this.changeSlide('slide-2');
			} else {
				this.toastr.warning('Please select one or more features so that we get best plans for you..');
			}
		} else if (this.activeSlide == 'slide-2') {
			if (!this.selected_plan || !this.selected_plan.price) {
				this.toastr.warning('Please select the plan to proceed.');
			} else {
				this.changeSlide('slide-3');
			}
		} else if (this.activeSlide == 'slide-3') {
			this.changeSlide('slide-4');
		}
	}

	async prevSlide() {
		if (this.activeSlide == 'slide-1') {
		} else if (this.activeSlide == 'slide-2') {
			this.changeSlide('slide-1');
		} else if (this.activeSlide == 'slide-3') {
			this.changeSlide('slide-2');
		} else if (this.activeSlide == 'slide-4') {
			this.changeSlide('slide-3');
		}
	}
}
