<div class="breadcrumbs_wrapper">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title">Contact us</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/">Home</a></li>
							<li><a routerLink="/contact">Contact us</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end container -->
</div>
<!-- end breadcrumbs_wrapper -->

<div class="contact_us_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-md-10 offset-md-1">
				<div class="panel" *ngIf="showForm">
					<form class="form_wrapper" [formGroup]="contactusForm" (ngSubmit)="onSubmit()">
						<div class="form-row">
							<div class="form-group grey col-sm-6">
								<label>First name</label>
								<input
									type="text"
									placeholder="First name"
									formControlName="firstName"
									class="form-control"
									pattern="[a-z,A-Z]*"
								/>
								<div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
									<div *ngIf="f.firstName.errors.required">First Name is required</div>
								</div>
							</div>
							<div class="form-group grey col-sm-6">
								<label>Last name</label>
								<input
									type="text"
									placeholder="Last name"
									formControlName="lastName"
									class="form-control"
									pattern="[a-z,A-Z]*"
								/>
								<div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
									<div *ngIf="f.lastName.errors.required">Last Name is required</div>
								</div>
							</div>
							<div class="form-group grey col-sm-6">
								<label>Email address</label>
								<input
									type="email"
									placeholder="Email address"
									formControlName="email"
									class="form-control"
								/>
								<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
									<div *ngIf="f.email.errors.required">Email is required</div>
									<div *ngIf="f.email.errors.pattern">Email is invalid</div>
								</div>
							</div>

							<div class="form-group grey col-sm-6">
								<label>Phone number</label>
								<input
									type="phone"
									placeholder="Phone number"
									formControlName="phone"
									class="form-control"
								/>
								<div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
									<div *ngIf="f.phone.errors.required">phone is required</div>
									<div *ngIf="f.phone.errors.pattern">phone is invalid</div>
								</div>
							</div>

							<div class="form-group grey col-12">
								<label>Enquiry type</label>
								<div class="select_wrap grey">
									<select formControlName="enquirytype" class="form-control">
										<option value="" default selected disabled>Enquiry type</option>
										<option value="General">General</option>
										<option value="Billing & Payments">Billing & Payments</option>
										<option value="Report an issue">Report an issue</option>
										<option value="Media & Sponsorships">Media & Sponsorships</option>
										<option value="Partnerships">Partnerships</option>
									</select>
								</div>

								<div *ngIf="submitted && f.enquirytype.errors" class="invalid-feedback">
									<div *ngIf="f.enquirytype.errors.required">Enquiry Type is required</div>
								</div>
							</div>

							<div class="form-group grey message col-12">
								<label>Message</label>
								<textarea
									placeholder="Write your message"
									formControlName="message"
									class="form-control"
								></textarea>
								<div *ngIf="submitted && f.message.errors" class="invalid-feedback">
									<div *ngIf="f.message.errors.required">Message is required</div>
								</div>
							</div>
							<!-- end form-group grey col-12 -->

							<!--<div *ngIf="submitted && !response.status" class="invalid-feedback">
								<div>{{response.message}}</div>
							</div>-->

							<!--<div class="form-group grey form-check col-12">
								<input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input"/>
								<label for="acceptTerms" class="form-check-label">I have read and agree to the <a href="#">Terms & Conditions</a></label>
								<div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
									<div *ngIf="f.acceptTerms.errors.required">You must accept terms and conditions.</div>
								</div>
							</div>-->
							<re-captcha
								#captchaRef="reCaptcha"
								size="invisible"
								resolved="resolved($event)"
								siteKey="{{ googleCaptchPublicKey }}"
							>
							</re-captcha>
							<div class="form-group grey col-12">
								<!--<button class="btn blue arrow_large" [disabled]="loading" type="submit">-->
								<!--<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>-->
								<button
									class="btn blue large"
									[disabled]="loading"
									(click)="captchaRef.execute()"
									type="submit"
								>
									Submit <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
								</button>
							</div>
						</div>
					</form>
				</div>
				<!-- end panel -->

				<div class="panel contact_general" *ngIf="!showForm">
					<div class="inner">
						<div class="cell">
							<h4 class="subheading">
								Thank you for your enquiry. We will be in touch with you shortly.
							</h4>
							<a href="/" class="btn black">Return to home page</a>
						</div>
						<!-- end cell -->
					</div>
					<!-- end inner -->
				</div>
				<!-- end panel -->

				<div class="panel black contact_details">
					<div class="inner">
						<div class="cell">
							<span class="subheading">Enquiries</span>
							<a href="mailto:support@theraceclub.com.au">support@racingcentral.com.au</a>
						</div>
						<!-- end cell -->

						<div class="cell">
							<span class="subheading">Follow us</span>
							<a class="links" href="https://www.facebook.com/theraceclub.com.au" target="_blank"> Fb </a>
							<a class="links" href="https://www.instagram.com/theraceclub.com.au/" target="_blank">
								Ig
							</a>
						</div>
						<!-- end cell -->

						<div class="cell">
							<span class="subheading">ABN</span>
							<p>26 623 654 544</p>
						</div>
						<!-- end cell -->
					</div>
					<!-- end inner -->
				</div>
				<!-- end panel black -->
			</div>
		</div>
	</div>
</div>
<!-- end contact_us_wrapper -->
