<ul class="accr-section">
	<li class="collapsable-section closed" *ngFor="let row of jumpouts_horses; let i = index">
		<table class="table">
			<tbody>
				<tr>
					<td style="width: 27%">
						<h3 class="player_name">{{ row.jump }}</h3>
						<span>{{ row.runners }}</span>
					</td>
					<td style="width: 27%">
						<h3 class="player_name">{{ row.horse }}</h3>
						<span>{{ row.age }}{{ row.sex }}{{ row.colour }} {{ row.dam }}</span>
					</td>
					<td style="width: 15%">
						<span><strong>T:</strong> {{ row.trainer }}</span
						><span><strong>Date:</strong>{{ row.date }}</span>
					</td>
					<td style="width: 15%">
						<span><strong>Venue:</strong> {{ row.track }}</span
						><span><strong>Heat:</strong>{{ row.heat }}</span>
					</td>
					<td style="width: 15%">
						<span><strong>Distance:</strong> {{ row.distance }}</span
						><span><strong>Time:</strong>{{ row.time }}</span>
					</td>
				</tr>
			</tbody>
		</table>
		<!-- end table -->

		<div class="collapsed-content">
			<div class="inner">
				<div class="top-table row">
					<div class="t-col-1 col-lg-3">
						<div class="panel d-flex align-items-center h-100">
							<div class="inner">
								<span class="price">{{ row?.win3_current | currency: 'USD' }}</span>
								<span><strong>Fluctuations</strong></span>
								<span class="small" *ngIf="row?.win3_string"
									>{{ row?.win3_opn | currency: 'USD' }}, {{ row?.win3_9am | currency: 'USD' }},
									{{ row?.win3_current | currency: 'USD' }}</span
								>
								<div
									class="t-link"
									[ngClass]="{ up: row.win3_movement === 'UP', down: row.win3_movement == 'DOWN' }"
								></div>
							</div>
						</div>
					</div>
					<!-- end t-col-1 -->
					<div class="t-col-2 col-lg-9">
						<div class="panel h-100">
							<div class="row d-flex align-items-center h-100">
								<div class="col-6">
									<span><strong>Wins: </strong>-</span>
									<span><strong>Owners: </strong>{{ row?.owners }}</span>
								</div>
								<div class="col-3">
									<span
										><strong>Earnings: </strong
										>{{ row.PrizeTotal | currency: 'USD':'symbol':'1.0-0' }}</span
									>
									<span><strong>BM Rating: </strong>-</span>
								</div>
								<div class="col-3 text-right"></div>
							</div>
							<!-- end row -->
						</div>
						<!-- end panel -->
					</div>
					<!-- end t-col-2 -->
				</div>
				<!-- end top-table -->
			</div>
			<!-- end inner -->
		</div>
		<!-- collapsed-content  -->
	</li>
	<!-- end collapsable-section -->
</ul>
