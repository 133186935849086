<div class="onboarding_wrapper subscribe_wrapper section_wrapper">
	<div class="floating_logo">
		<a routerLink="/">
			<img src="assets/images/logos/logo-vertical-light.png"/>
		</a>
	</div>

	<div class="floating_skip">
		<a class="skip" routerLink="/">Skip</a>
	</div>
	<!-- end floating_skip -->

	<div class="bg_image"></div>
	<!-- end bg_image -->
	<div class="bg_image mob"></div>
	<!-- end bg_image -->

	<div class="onboard_wrap">
		<div class="row">
			<div class="col-12 text-center">
				<h2 class="heading">Unlock your exclusive foundation membership</h2>
				<div class="subheading">
					<p>Foundation memberships are exclusive and limited. It’s an incredible deal which gives you access to absolutely all our launch features at <strong>half the regular price</strong> for as long as you maintain your subscription.</p>
					<p>The Race Club has rebranded as "Racing Central" to support our vision of becoming punters' favourite destination for intuitive form, user-friendly tools, and intelligent tips.</p>
				</div>
			</div>
			<!-- end col-12 -->
		</div>
		<!-- end row -->

		<div class="row premium_wrapper" *ngIf="premium_plan">
			<div class="col-12 col_or text-center"></div>
			<!-- end col-12 col_or -->

			<div class="col-12">
				<div class="panel">
					<div class="row row-eq-height">
						<div class="col-md-10 col_content">
							<div class="bg_image"></div>
							<!-- end bg_image -->

							<div class="inner">
								<div class="row">
									<div class="col-12 col_heading">
										<h2 class="heading small">
											<span>{{ premium_plan?.title }}</span>
											<div class="badge white">
												<div class="svg_wrapper">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="12"
														height="12"
														viewBox="0 0 12 12"
													>
														<path
															id="Path_8396"
															data-name="Path 8396"
															d="M751.07,1362.637l2.485-1.227.89,2.625,2.625.89-1.227,2.485,1.227,2.485-2.625.89-.89,2.625-2.485-1.227-2.485,1.227-.89-2.625-2.625-.89,1.227-2.485-1.227-2.485,2.625-.89.89-2.625Z"
															transform="translate(-745.07 -1361.41)"
														/>
													</svg>
												</div>
												<!-- end svg_wrapper -->

												<span>All inclusive</span>
											</div>
											<div class="badge light_blue ml-2" *ngIf="premium_plan?.selected_plan?.trialDays">
												<span>{{premium_plan?.selected_plan.trialDays}} trial period</span>
											</div>
										</h2>

										<div
											class="price d-block d-md-none"
											*ngIf="
												premium_plan &&
												premium_plan?.selected_plan &&
												premium_plan?.selected_plan.price
											"
										>
											<span class="cost"
												><small class="dollar">$</small
												>{{ premium_plan?.selected_plan.price | number: '1.0-2' }}</span
											>
											<span class="frequency">/{{ premium_plan?.selected_plan?.duration }}</span>
										</div>
										<!-- end price -->
									</div>
									<!-- end col-12 -->
								</div>
								<!-- end row -->

								<div class="row">
									<!--<div class="col-md-12 col_description">
										<div class="body">
											<p>
												Subscribe to our Platinum package to access all areas of Racing Central.
												Includes the following features plus exclusive analysis and trial data*
											</p>
										</div>
									</div>-->
									<!-- end col-6 -->

									<div class="col-md-12 col_features">
										<ul class="features">
											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Tips and suggested bets</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Easy form<!--<small>(minimum 6 meetings per week)</small>--></span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Easy form plus</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Live yard premium</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Previews & selections</span>
											</li>
										</ul>

										<ul class="features">											
											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Jump outs</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Blackbook</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Chatroom</span>
											</li>

											<li>
												<img src="assets/images/icons/tick-circle.svg" alt="Checkmark">
												<span>Tournament access</span>
											</li>
										</ul>
										
										<div class="badge white">
											<div class="svg_wrapper">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="12"
													height="12"
													viewBox="0 0 12 12"
												>
													<path
														id="Path_8396"
														data-name="Path 8396"
														d="M751.07,1362.637l2.485-1.227.89,2.625,2.625.89-1.227,2.485,1.227,2.485-2.625.89-.89,2.625-2.485-1.227-2.485,1.227-.89-2.625-2.625-.89,1.227-2.485-1.227-2.485,2.625-.89.89-2.625Z"
														transform="translate(-745.07 -1361.41)"
													/>
												</svg>
											</div>
											<!-- end svg_wrapper -->

											<span>All inclusive</span>
										</div>

										<div class="badge light_blue ml-2" *ngIf="premium_plan?.selected_plan?.trialDays">
											<span>{{premium_plan?.selected_plan.trialDays}} trial period</span>
										</div>
									</div>
									<!-- end col-6 -->
								</div>
								<!-- end row -->

								<div class="row">
									<div class="cta col-12">
										<p class="first">
											Don’t miss out. Once sold, this offer is closed.
										</p>
										<a
											(click)="premiumCheckOut()"
											*ngIf="!premium_subscribed"
											class="btn blue large"
											>Subscribe</a
										>
										<a (click)="unSubscribe()" *ngIf="premium_subscribed" class="btn blue large"
											>Unsubscribe</a
										>
										<p class="second" style="font-size: 12px;">
											Don’t miss out. Once sold, this offer is closed.
										</p>
									</div>
									<!-- end cta -->
								</div>
								<!-- end row -->
							</div>
							<!-- end panel -->
						</div>
						<!-- end col-md-9 -->

						<div class="col-md-2 col_price_info d-none d-sm-block">
							<div class="inside">

								<div class="middle_area" *ngIf="premium_plan">
									<div class="price">
										<span class="cost"
											><small class="dollar">$</small
											>{{ premium_plan?.selected_plan.price | number: '1.0-2' }}</span
										>
										<span class="frequency">/{{ premium_plan?.selected_plan.duration }}</span>
									</div>
									<!-- end price -->
								</div>
								<!-- end middle_area -->
							</div>
							<!-- end inside -->
						</div>
						<!-- end col-md-3 -->
					</div>
					<!-- end row -->
				</div>
				<!-- end panel -->
			</div>
			<!-- end col-12 -->
		</div>
		<!-- end row premium_wrapper -->

		<!--<div class="row landing_content_1">
			<div class="col-12">
				<h2 class="heading">What have we been building?</h2>
					<p>
						Well, I’m glad you asked. We’ve spent the best part of 12 months rebuilding our products from the ground up to deliver a new platform that we believe surpasses anything currently available on the market. Our Mission? To put professional level form tools in the hands of everyday punters.</p>
				<h2 class="heading">What’s New</h2>

			</div>

			<div class="col-lg-6 colbox">
			
				<div class="innerbox">
				
				    <h4>Easy Form</h4>
					<p>The ground-breaking form reference tool that allows you to quickly digest each runner’s suitability to a race in an easy-to-follow, sequential colour-coded process.</p>
					<h5>Key metrics isolated with everything you need at a glance</h5>
					<ul>
						<li>Racing Central Ratings</li>
						<li>Distance, Map and Tempo suitability</li>
						<li>Easy Yard</li>
						<li>Runner Comments</li>
					</ul>
				
				</div>
			    
			
			</div>

			<div class="col-lg-6">
			
				<div class="innerbox">
				
				    <h4>Easy Form Plus</h4>
					<p>The game-changing tool that allows you to do form and create your own ratings like the pros in minutes. Now you can find your own winners and bet with confidence using a proven and repeatable process built on years of reliable data. No more scrounging around your whatsapp for tips and definitely no more mugging on next to jump flame emojis from your bookie.</p>
				
				</div>
			    
			
			</div>

		</div>
		

		<div class="row landing_content_2">
		
		    <div class="col-12">

		    	<div class="video-container">
				    <iframe src="https://www.youtube.com/embed/qrFXdzcS7os?si=mgZhSuTWhi2fk8WV" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>

		    
		    </div>
		
		</div>-->

		<!-- Ghost Element-->
		<div
			class="row features_wrapper slides"
			*ngIf="feature_loading"
			[ngClass]="{ active: activeSlide == 'slide-1' }"
		>
			<div class="features_links_box py-2 col-12">
				<div class="panel grey p-2 loading">
					<div class="icon tour loading-text"></div>
					<h3 class="title d-block m-auto loading-text"></h3>
					<p class="description loading-text mt-5"></p>
					<p class="description loading-text mt-2"></p>
				</div>
				<!-- end inner -->
			</div>
			<!-- end features_links_box -->
			<!-- <div class="features_links_box py-2 col-lg-6 col-md-6"> -->
				<!-- <div class="panel grey p-2 loading">
					<div class="icon tour loading-text"></div>
					<h3 class="title d-block m-auto loading-text"></h3>
					<p class="description loading-text mt-5"></p>
					<p class="description loading-text mt-2"></p>
				</div> -->
				<!-- end inner -->
			<!-- </div> -->
			<!-- end features_links_box -->
		</div>
		<!-- end features_wrapper -->
	</div>
	<!-- end onboard_wrap -->
</div>
<!-- section_wrapper -->
