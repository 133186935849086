<div class="breadcrumbs_wrapper">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title">Live Yard</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/">Home</a></li>
							<li><a routerLink="/liveyard">Live Yard</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end container -->
</div>
<!-- end breadcrumbs_wrapper -->

<div class="liveyardpage_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-lg-10 offset-lg-1">
				<div class="introduction text-center">
					<p>
						Racing Central’s “Live Yard” takes Mounting Yard Analysis to a new level. The product is unique
						in the fact that our Yard Analysts assess each runner in every race, portraying that information
						in a detailed yet easy to use format. Yard reports are critical and in an industry first, TRC
						provide all commentary and scores through the form guide.
					</p>
				</div>
				<!-- end introduction -->

				<div class="row" *ngIf="LiveyardsAvailable && user && user.hasLiveYard">
					<div class="col-xl-3 col-lg-4 col_left d-none d-lg-block mb-4 mb-lg-0">
						<div class="sidebar_menu" *ngIf="!loading && tracks">
							<div class="sticky-sidemenu">
								<ul class="panel">
									<li [ngClass]="{ active: isSelect == 1 }" (click)="getLiveyards(null, 1)">All</li>
									<li
										[ngClass]="{ active: isSelect == i + 2 }"
										(click)="getLiveyards(row?.track_code, i + 2)"
										*ngFor="let row of tracks; let i = index"
									>
										{{ row?.track_code }}
									</li>
								</ul>
							</div>
						</div>

						<!-- GHOST ELEMENT -->
						<div class="sidebar_menu" *ngIf="loading">
							<div class="sticky-sidemenu">
								<ul class="panel">
									<li *ngFor="let number of [0, 1, 2, 3, 4, 5, 6]" class="loading-state">
										<div class="loading-text"></div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<!-- end col_left -->

					<div class="col-12 mobile_dropdown mb-4 d-lg-none" *ngIf="!loading && tracks">
						<div class="select_wrap white">
							<select (change)="selectTrack($event)" class="form-control">
								<option value="">All</option>
								<option value="{{ row?.track_code }}" *ngFor="let row of tracks; let i = index">
									{{ row?.track_code }}
								</option>
							</select>
						</div>
					</div>
					<!-- end mobile_dropdown -->

					<div class="col-xl-9 col-lg-8 col_right">
						<ul class="accr-section" *ngIf="!loadingRacedata">
							<li class="collapsable-section closed panel" *ngFor="let row of liveyards; let i = index">
								<h2 class="heading">
									<div class="heading_wrap">
										<div class="wrap_inner">
											<span class="track_code">{{ row?.track_code }}</span>
											<div class="race_name">Race {{ row?.raceno }}</div>
										</div>
									</div>
									<div class="pill-mobile">
										<div
												class="time-pills"
												[attr.data-counter-id]="row?.event_id"
												(click)="$event.stopPropagation();toggleTime($event);"
												[ngClass]="{'green': row.race_status == 'FINAL', 'grey': (row.race_status != 'FINAL' || row.race_status == '') && row.seconds < -120, 'red to-blue': row.seconds <= 300 && row.seconds >= -120,'blue': row.seconds > 300 && row.show_counter, 'grey to-blue': row.seconds > 300}"
											>
											<ng-template [ngIf]="row.race_status == 'FINAL' || ((row.race_status != 'FINAL' || row.race_status == '') && row.seconds < -120)">
												<span
													[counter]="row?.race_start_time"
													[race_status]="row?.race_status"
													[race]="row"
													(TimerExpired)="timeexpired(row)"
													(TimerBelowFiveMinutes)="TimerBelowFiveMinutes(row)"
												></span>
												<span *ngIf="!row.show_pending"
													>{{ row?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
													}}</span
												>
												<span *ngIf="row.show_pending">Pending</span>
											</ng-template>
											<ng-template [ngIf]="row.race_status != 'FINAL' && row.seconds > -120">
												<span *ngIf="row.show_pending">Pending</span>
												<span *ngIf="!row.show_pending"
													>{{ row?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
													}}</span
												>
												<span
													[counter]="row?.race_start_time"
													[race]="row"
													[race_status]="row?.race_status"
													(TimerExpired)="timeexpired(row)"
												></span>
											</ng-template>
										</div>
									</div>
									<div class="toggleMobile">
										<div class="toggleBtn" (click)="getLiveyardsHorses($event, row.id, i)"></div>
									</div>
								</h2>

								<div class="heading_table">
									<div class="col_img">
										<div class="analyst-img">
											<img [src]="row?.analyst_avatar" />
										</div>
									</div>
									<div class="cell_wrap bubble_chat bubble_left bubble_white_bg bubble_lite">
										<div class="row">
											<div class="col_comment col-xl-7 col-lg-12 col-md-7 align-self-center">
												<div class="cell w-100">
													{{ row?.racenote }}
													<strong *ngIf="row?.racenote && row?.analyst">- {{ row?.analyst }}</strong>
												</div>
											</div>
											<div
												class="
													col_picks col-xl-5 col-lg-12 col-md-5
													text-xl-right text-lg-center text-md-right text-center
													align-self-center
												"
											>
												<div class="cell row col_img_mobile">
													<div class="analyst-img" >
														<img [src]="row?.analyst_avatar" />
													</div>
													<strong *ngIf="row?.racenote && row?.analyst">{{ row?.analyst }}</strong>
												</div>
												<div class="cell row">
													<div
														*ngIf="row?.for_1 !== ' '"
														class="pick col-md-3 col-sm-2 col-3"
													>
														<span>{{ row.for_1 }}</span>
													</div>
													<div
														*ngIf="row?.for_2 !== ' '"
														class="pick col-md-3 col-sm-2 col-3"
													>
														<span>{{ row.for_2 }}</span>
													</div>
													<div
														*ngIf="row?.for_3 !== ' '"
														class="pick col-md-3 col-sm-2 col-3"
													>
														<span>{{ row.for_3 }}</span>
													</div>
													<div
														*ngIf="row?.for_4 !== ' '"
														class="pick col-md-3 col-sm-2 col-3"
													>
														<span>{{ row.for_4 }}</span>
													</div>
													<div
														*ngIf="row?.againsts_1 !== ' '"
														class="
															pick
															col-md-3 col-sm-2 col-3
															mt-xl-3 mt-lg-2 mt-md-3 mt-sm-2 mt-2
														"
													>
														<span class="against">{{ row.againsts_1 }}</span>
													</div>
													<div
														*ngIf="row?.againsts_2 !== ' '"
														class="
															pick
															col-md-3 col-sm-2 col-3
															mt-xl-3 mt-lg-2 mt-md-3 mt-sm-2 mt-2
														"
													>
														<span class="against">{{ row.againsts_2 }}</span>
													</div>
													<div
														*ngIf="row?.againsts_3 !== ' '"
														class="
															pick
															col-md-3 col-sm-2 col-3
															mt-xl-3 mt-lg-2 mt-md-3 mt-sm-2 mt-2
														"
													>
														<span class="against">{{ row.againsts_3 }}</span>
													</div>
													<div
														*ngIf="row?.againsts_4 !== ' '"
														class="
															pick
															col-md-3 col-sm-2 col-3
															mt-xl-3 mt-lg-2 mt-md-3 mt-sm-2 mt-2
														"
													>
														<span class="against">{{ row.againsts_4 }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col_btn">
										<span class="toggleBtn" (click)="getLiveyardsHorses($event, row.id, i)"></span>
									</div>
								</div>

								<div class="collapsed-content">
									<div *ngIf="!loadingHorses[i]">
										<table
											class="table"
											*ngFor="let horse of row?.liveyard_horses"
										>
											<tbody>
												<tr
													[ngClass]="{
														Scratched:
															horse.horse_status == 'Scratched' ||
															horse.horse_status == 'LateScratched'
													}"
												>
													<td class="tabno">
														<img
															class="img-fluid jockey-img"
															[src]="horse?.silks"
															(error)="onImgError($event, row.tabno)"
														/>
													</td>
													<td class="td_name">
														<div class="player_name">
															<h3>
																{{ horse?.tabno }}. {{ horse.horsename }}
																<span>({{ horse.barrier }})</span>
																<div class="inner">
																	<div
																		class="yard_pick circle"
																		[ngClass]="{
																			win:
																				horse?.tabno == row.for_1 ||
																				horse?.tabno == row.for_2 ||
																				horse?.tabno == row.for_3 ||
																				horse?.tabno == row.for_4,
																			against:
																				horse?.tabno == row.againsts_1 ||
																				horse?.tabno == row.againsts_2 ||
																				horse?.tabno == row.againsts_3 ||
																				horse?.tabno == row.againsts_4
																		}"
																		placement="top"
																		ngbTooltip="Yard pick"
																		*ngIf="
																			horse.predicted_place &&
																			horse.predicted_place != ' '
																		"
																	>
																		<span>Y</span>
																	</div>
																	<!-- end yard_pick circle -->
																	<div
																		class="preview circle"
																		placement="top"
																		ngbTooltip="Preview pick (win)"
																		*ngIf="horse.win_bet"
																	>
																		<span>W</span>
																	</div>
																	<!-- end yard_pick circle -->
																	<div
																		class="preview_place circle"
																		placement="top"
																		ngbTooltip="Preview pick (win)"
																		*ngIf="horse.place_bet"
																	>
																		<span>P</span>
																	</div>

																	<div
																	class="best_bet image_circle"
																	placement="top"
																	ngbTooltip="Best Bet"
																	*ngIf="row.best_bet && user && user?.hasPreviews"
																>
																	<span><img
																		alt="TRC"
																		class="img-fluid"
																		src="assets/images/icons/best_bet.png"
																	/></span>
																	<!--<span>P</span>-->
																	<!-- SHOW P IF THE PICK IS A PLACE -->
																</div>
																<div
																	class="value_bet image_circle"
																	placement="top"
																	ngbTooltip="Value Bet"
																	*ngIf="row.value_bet && user && user?.hasPreviews"
																>
																	<span><img
																		alt="TRC"
																		class="img-fluid "
																		src="assets/images/icons/value_bet.png"
																	/></span>
																	<!--<span>P</span>-->
																	<!-- SHOW P IF THE PICK IS A PLACE -->
																</div>
																	
																	<!-- end yard_pick circle -->
																	<div
																		class="dual_nomination circle"
																		placement="top"
																		ngbTooltip="Dual nomination"
																		*ngIf="horse.dual_nom == 'D'"
																	>
																		<span>D</span>
																	</div>
																	<!-- end yard_pick circle -->
																</div>
																<!-- inner -->
															</h3>
														</div>
														<span class="comment" *ngIf="horse?.comment && horse?.comment != ' '">{{ horse?.comment.split(',').join(', ') }}</span>
														<!--<span class="t-body">J: {{horse.jockey}}</span>-->
													</td>

													<td class="yard_health_rating">
														<div class="row">
															<div class="col-6">
																<div class="panel">
																	<div class="row">
																		<div class="col-xl-4 col-lg-12 col-md-4 col-circle">
																			<circle-progress
																				class="fast"
																				[responsive]="true"
																				[outerStrokeColor]="
																					percentToColor(
																						horse.lastcomment_score,
																						100,
																						0
																					)
																				"
																				[percent]="horse.lastcomment_score"
																				[subtitleFormat]="formatSubtitle"
																			></circle-progress>
																		</div>
																		<div
																			class="
																				col-xl-8 col-lg-12 col-md-8 col-circle-info
																				align-self-center
																			"
																		>
																			<p
																				placement="top"
																				(click)="openInstructions()"
																			>
																				<strong
																					>Previous <strong>Score</strong></strong
																				>
																			</p>
																		</div>
																	</div>
																</div>
																<!-- end panel -->
															</div>
															<!-- end t-col-1 -->

															<div class="col-6">
																<div class="panel">
																	<div class="row">
																		<div class="col-xl-4 col-lg-12 col-md-4 col-circle">
																			<circle-progress
																				class="fast"
																				[responsive]="true"
																				[outerStrokeColor]="
																					percentToColor(
																						horse.final_score,
																						100,
																						0
																					)
																				"
																				[percent]="horse.final_score"
																				[subtitleFormat]="formatSubtitle"
																			></circle-progress>
																		</div>
																		<div
																			class="
																				col-xl-8 col-lg-12 col-md-8 col-circle-info
																				align-self-center
																			"
																		>
																			<p
																				placement="top"
																				(click)="openInstructions()"
																			>
																				<strong
																					>Parade <strong>Rating</strong></strong
																				>
																			</p>
																		</div>
																	</div>
																</div>
																<!-- end panel -->
															</div>
															<!-- end t-col-1 -->
														</div>
														<!-- end row -->
													</td>

													<td class="prices">
														<div class="d-md-block d-none">
															<img
																class="img-fluid bookmaker"
																src="assets/images/bookmakers/unibet-tile2.png"
																alt="TRC"
															/>
															<span class="bookmaker-img small_space"
																><strong>Win:</strong>
																{{ horse?.win3_current | currency: 'USD' }}</span
															>
															<span class="bookmaker-img"
																><strong>Place:</strong
																>{{ horse?.plc3_current | currency: 'USD' }}</span
															>
														</div>
														<div class="d-block d-md-none">
															<div class="top">
																<img
																	class="img-fluid topsport"
																	src="assets/images/icons/topsport.svg"
																	alt="Top Sport"
																/>
															</div>
															<div class="bottom">
																{{ horse?.win3_current | currency: 'USD' }}
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<!-- end table -->

									<!-- GHOST ELEMENT -->
									<div *ngIf="loadingHorses[i] == 'loading'">
										<table
											class="table"
											*ngFor="let number of [0, 1, 2, 3, 4, 5, 6]"
										>
											<tbody>
												<tr>
													<td class="tabno">
														<img
															class="img-fluid jockey-img"
															src="http://d36frgpkvm82k8.cloudfront.net/187541_1_6_64x64.png"
														/>
													</td>
													<td class="td_name">
														<div class="player_name">
															<h3 class="loading-text"></h3>
														</div>
														<span class="comment loading-text"></span>
													</td>

													<td class="yard_health_rating">
														<div class="row">
															<div class="col-6" *ngFor="let number of [0, 1]">
																<div class="panel">
																	<div class="row">
																		<div class="col-xl-4 col-lg-12 col-md-4 col-circle">
																			<div class="loading-circle"></div>
																		</div>
																		<div
																			class="
																				col-xl-8 col-lg-12 col-md-8 col-circle-info
																				align-self-center
																			"
																		>
																			<p class="loading-text"></p>
																		</div>
																	</div>
																</div>
																<!-- end panel -->
															</div>
															<!-- end col-6 -->
														</div>
														<!-- end row -->
													</td>

													<td class="prices">
														<span class="bookmaker-img small_space loading-text"></span>
														<span class="bookmaker-img loading-text"></span>
													</td>
												</tr>
											</tbody>
										</table>
										<!-- end table -->
									</div>
								</div>
								<!-- collapsed-content  -->

								<h4
									class="pill time-pills align-self-center"
									[ngClass]="{
										red: row?.seconds <= 300 && row?.seconds > 0,
										grey: row?.seconds <= 0,
										black: row?.seconds > 300,
										green: row?.race_status == 'FINAL'
									}"
								>
									<span [counter]="row?.race_start_time" [race_status]="row?.race_status"></span>

									<span>{{
										row?.race_start_time | amFromUtc | amLocal | amDateFormat: 'h:mma'
									}}</span>
								</h4>
							</li>
						</ul>

						<div
							class="separator"
							*ngIf="pending_liveyards?.length && liveyards?.length && !loadingRacedata"
						></div>

						<h3 *ngIf="pending_liveyards?.length && !loadingRacedata" class="heading mb-3">
							Pending Races
						</h3>

						<ul class="accr-section" *ngIf="!loadingRacedata">
							<li
								class="collapsable-section pending_section closed panel"
								*ngFor="let row of pending_liveyards; let i = index"
							>
								<div class="heading_table">
									<div class="col_img pending_img">
										<div class="analyst-img">
											<img [src]="row?.analyst_avatar" />
										</div>
									</div>
									<h2 class="col_heading heading">
										<span class="track_code">{{ row?.track_code }}</span>
										<div class="race_name px-1 pending_race_label"><span>Race</span><span>R</span> {{ row?.raceno }}</div>
									</h2>
									<div class="pill-mobile">
										<div
												class="time-pills"
												[attr.data-counter-id]="row?.event_id"
												(click)="$event.stopPropagation();toggleTime($event);"
												[ngClass]="{'green': row.race_status == 'FINAL', 'grey': (row.race_status != 'FINAL' || row.race_status == '') && row.seconds < -120, 'red to-blue': row.seconds <= 300 && row.seconds >= -120,'blue': row.seconds > 300 && row.show_counter, 'grey to-blue': row.seconds > 300}"
											>
											<ng-template [ngIf]="row.race_status == 'FINAL' || ((row.race_status != 'FINAL' || row.race_status == '') && row.seconds < -120)">
												<span
													[counter]="row?.race_start_time"
													[race_status]="row?.race_status"
													[race]="row"
													(TimerExpired)="timeexpired(row)"
													(TimerBelowFiveMinutes)="TimerBelowFiveMinutes(row)"
												></span>
												<span *ngIf="!row.show_pending"
													>{{ row?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
													}}</span
												>
												<span *ngIf="row.show_pending">Pending</span>
											</ng-template>
											<ng-template [ngIf]="row.race_status != 'FINAL' && row.seconds > -120">
												<span *ngIf="row.show_pending">Pending</span>
												<span *ngIf="!row.show_pending"
													>{{ row?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
													}}</span
												>
												<span
													[counter]="row?.race_start_time"
													[race]="row"
													[race_status]="row?.race_status"
													(TimerExpired)="timeexpired(row)"
												></span>
											</ng-template>
										</div>
									</div>
								</div>

								<h4
									class="pill time-pills align-self-center"
									[ngClass]="{
										red: row?.seconds <= 300 && row?.seconds > 0,
										grey: row?.seconds <= 0,
										black: row?.seconds > 300,
										green: row?.race_status == 'FINAL'
									}"
								>
									<span [counter]="row?.race_start_time" [race_status]="row?.race_status"></span>

									<span>{{
										row?.race_start_time | amFromUtc | amLocal | amDateFormat: 'h:mma'
									}}</span>
								</h4>
							</li>
						</ul>

						<div
							class="separator"
							*ngIf="
								past_liveyards?.length &&
								(liveyards?.length || pending_liveyards?.length) &&
								!loadingRacedata
							"
						></div>

						<h3 *ngIf="past_liveyards?.length && !loadingRacedata" class="heading mb-3">Past Races</h3>

						<ul class="accr-section" *ngIf="!loadingRacedata && past_liveyards?.length">
							<li
								class="collapsable-section closed panel"
								*ngFor="let row of past_liveyards; let i = index"
							>
								<h2 class="heading">
									<div class="heading_wrap">
										<div class="wrap_inner">
											<span class="track_code">{{ row?.track_code }}</span>
											<div class="race_name">Race {{ row?.raceno }}</div>
										</div>
									</div>
									<div class="pill-mobile">
										<div
												class="time-pills"
												[attr.data-counter-id]="row?.event_id"
												(click)="$event.stopPropagation();toggleTime($event);"
												[ngClass]="{'green': row.race_status == 'FINAL', 'grey': (row.race_status != 'FINAL' || row.race_status == '') && row.seconds < -120, 'red to-blue': row.seconds <= 300 && row.seconds >= -120,'blue': row.seconds > 300 && row.show_counter, 'grey to-blue': row.seconds > 300}"
											>
											<ng-template [ngIf]="row.race_status == 'FINAL' || ((row.race_status != 'FINAL' || row.race_status == '') && row.seconds < -120)">
												<span
													[counter]="row?.race_start_time"
													[race_status]="row?.race_status"
													[race]="row"
													(TimerExpired)="timeexpired(row)"
													(TimerBelowFiveMinutes)="TimerBelowFiveMinutes(row)"
												></span>
												<span *ngIf="!row.show_pending"
													>{{ row?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
													}}</span
												>
												<span *ngIf="row.show_pending">Pending</span>
											</ng-template>
											<ng-template [ngIf]="row.race_status != 'FINAL' && row.seconds > -120">
												<span *ngIf="row.show_pending">Pending</span>
												<span *ngIf="!row.show_pending"
													>{{ row?.race_start_time | amFromUtc | amLocal | amDateFormat:'HH:mm'
													}}</span
												>
												<span
													[counter]="row?.race_start_time"
													[race]="row"
													[race_status]="row?.race_status"
													(TimerExpired)="timeexpired(row)"
												></span>
											</ng-template>
										</div>
									</div>
									<div class="toggleMobile">
										<div
											class="toggleBtn"
											(click)="getpastLiveyardsHorses($event, row.id, i)"
										></div>
									</div>
								</h2>

								<div class="heading_table">
									<div class="col_img">
										<div class="analyst-img">
											<img [src]="row?.analyst_avatar" />
										</div>
									</div>
									<div class="cell_wrap bubble_chat bubble_left bubble_white_bg bubble_lite">
										<div class="row">
											<div class="col_comment col-xl-7 col-lg-12 col-md-7 align-self-center">
												<div class="cell w-100">
													{{ row?.racenote }}
													<strong *ngIf="row?.racenote && row?.analyst">- {{ row?.analyst }}</strong>
												</div>
											</div>
											<div
												class="
													col_picks col_picks_past col-xl-5 col-lg-12 col-md-5
													text-xl-right text-lg-center text-md-right text-center
													align-self-center
												"
											>
												<div class="cell row col_img_mobile">
													<div class="analyst-img">
														<img [src]="row?.analyst_avatar" />
													</div>
													<strong *ngIf="row?.racenote && row?.analyst">{{ row?.analyst }}</strong>
												</div>
												<div class="cell row">
													<div
														*ngIf="row?.for_1 && row?.for_1 !== ' '"
														class="pick col-md-3 col-sm-2 col-3"
													>
														<span
															[ngClass]="{
																green: row.for_1 == row.place_1,
																yellow:
																	row.for_1 == row.place_2 ||
																	row.for_1 == row.place_3 ||
																	row.for_1 == row.place_4
															}"
															>{{ row.for_1 }}</span
														>
													</div>
													<div
														*ngIf="row?.for_2 && row?.for_2 !== ' '"
														class="pick col-md-3 col-sm-2 col-3"
													>
														<span
															[ngClass]="{
																green: row.for_2 == row.place_1,
																yellow:
																	row.for_2 == row.place_2 ||
																	row.for_2 == row.place_3 ||
																	row.for_2 == row.place_4
															}"
															>{{ row.for_2 }}</span
														>
													</div>
													<div
														*ngIf="row?.for_3 && row?.for_3 !== ' '"
														class="pick col-md-3 col-sm-2 col-3"
													>
														<span
															[ngClass]="{
																green: row.for_3 == row.place_1,
																yellow:
																	row.for_3 == row.place_2 ||
																	row.for_3 == row.place_3 ||
																	row.for_3 == row.place_4
															}"
															>{{ row.for_3 }}</span
														>
													</div>
													<div
														*ngIf="row?.for_4 && row?.for_4 !== ' '"
														class="pick col-md-3 col-sm-2 col-3"
													>
														<span
															[ngClass]="{
																green: row.for_4 == row.place_1,
																yellow:
																	row.for_4 == row.place_2 ||
																	row.for_4 == row.place_3 ||
																	row.for_4 == row.place_4
															}"
															>{{ row.for_4 }}</span
														>
													</div>
													<div
														*ngIf="row?.againsts_1 && row?.againsts_1 !== ' '"
														class="
															pick
															col-md-3 col-sm-2 col-3
															mt-xl-3 mt-lg-2 mt-md-3 mt-sm-2 mt-2
														"
													>
														<span class="against">{{ row.againsts_1 }}</span>
													</div>
													<div
														*ngIf="row?.againsts_2 && row?.againsts_2 !== ' '"
														class="
															pick
															col-md-3 col-sm-2 col-3
															mt-xl-3 mt-lg-2 mt-md-3 mt-sm-2 mt-2
														"
													>
														<span class="against">{{ row.againsts_2 }}</span>
													</div>
													<div
														*ngIf="row?.againsts_3 && row?.againsts_3 !== ' '"
														class="
															pick
															col-md-3 col-sm-2 col-3
															mt-xl-3 mt-lg-2 mt-md-3 mt-sm-2 mt-2
														"
													>
														<span class="against">{{ row.againsts_3 }}</span>
													</div>
													<div
														*ngIf="row?.againsts_4 && row?.againsts_4 !== ' '"
														class="
															pick
															col-md-3 col-sm-2 col-3
															mt-xl-3 mt-lg-2 mt-md-3 mt-sm-2 mt-2
														"
													>
														<span class="against">{{ row.againsts_4 }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col_btn">
										<span
											class="toggleBtn"
											(click)="getpastLiveyardsHorses($event, row.id, i)"
										></span>
									</div>
								</div>

								<div class="collapsed-content">
									<div *ngIf="!loadingHorses[i]">
										<table class="table" *ngFor="let horse of row?.liveyard_horses">
											<tbody>
												<tr
													[ngClass]="{
														Scratched:
															horse.horse_status == 'Scratched' ||
															horse.horse_status == 'LateScratched'
													}"
												>
													<td class="tabno">
														<img
															class="img-fluid jockey-img"
															[src]="horse?.silks"
															(error)="onImgError($event, row.tabno)"
														/>
													</td>
													<td class="td_name">
														<div class="player_name">
															<h3>
																{{ horse?.tabno }}. {{ horse.horsename }}
																<span>({{ horse.barrier }})</span>
																<div class="inner">
																	<div
																		class="yard_pick circle"
																		[ngClass]="{
																			win:
																				horse?.tabno == row.for_1 ||
																				horse?.tabno == row.for_2 ||
																				horse?.tabno == row.for_3 ||
																				horse?.tabno == row.for_4,
																			against:
																				horse?.tabno == row.againsts_1 ||
																				horse?.tabno == row.againsts_2 ||
																				horse?.tabno == row.againsts_3 ||
																				horse?.tabno == row.againsts_4
																		}"
																		placement="top"
																		ngbTooltip="Yard pick"
																		*ngIf="
																			horse.predicted_place &&
																			horse.predicted_place != ' '
																		"
																	>
																		<span>Y</span>
																	</div>
																	<!-- end yard_pick circle -->
																	<div
																		class="preview circle"
																		placement="top"
																		ngbTooltip="Preview pick (win)"
																		*ngIf="horse.win_bet"
																	>
																		<span>W</span>
																	</div>
																	<!-- end yard_pick circle -->
																	<div
																		class="preview_place circle"
																		placement="top"
																		ngbTooltip="Preview pick (win)"
																		*ngIf="horse.place_bet"
																	>
																		<span>P</span>
																	</div>
																	<!-- end yard_pick circle -->
																	<div
																		class="dual_nomination circle"
																		placement="top"
																		ngbTooltip="Dual acceptance"
																		*ngIf="horse.dual_nom == 'D'"
																	>
																		<span>D</span>
																	</div>
																	<!-- end yard_pick circle -->
																</div>
																<!-- inner -->
															</h3>
														</div>
														<span class="comment">{{ horse?.comment.split(',').join(', ')  }}</span>
														<!--<span class="t-body">J: {{horse.jockey}}</span>-->
													</td>

													<td class="yard_health_rating">
														<div class="row">
															<div class="col-6">
																<div class="panel">
																	<div class="row">
																		<div class="col-xl-4 col-lg-12 col-md-4 col-circle">
																			<circle-progress
																				class="fast"
																				[responsive]="true"
																				[outerStrokeColor]="
																					percentToColor(
																						horse.lastcomment_score,
																						100,
																						0
																					)
																				"
																				[percent]="horse.lastcomment_score"
																				[subtitleFormat]="formatSubtitle"
																			></circle-progress>
																		</div>
																		<div
																			class="
																				col-xl-8 col-lg-12 col-md-8 col-circle-info
																				align-self-center
																			"
																		>
																			<p
																				placement="top"
																				(click)="openInstructions()"
																			>
																				<strong
																					>Previous <strong>Score</strong></strong
																				>
																			</p>
																		</div>
																	</div>
																</div>
																<!-- end panel -->
															</div>
															<!-- end t-col-1 -->

															<div class="col-6">
																<div class="panel">
																	<div class="row">
																		<div class="col-xl-4 col-lg-12 col-md-4 col-circle">
																			<circle-progress
																				class="fast"
																				[responsive]="true"
																				[outerStrokeColor]="
																					percentToColor(
																						horse.final_score,
																						100,
																						0
																					)
																				"
																				[percent]="horse.final_score"
																				[subtitleFormat]="formatSubtitle"
																			></circle-progress>
																		</div>
																		<div
																			class="
																				col-xl-8 col-lg-12 col-md-8 col-circle-info
																				align-self-center
																			"
																		>
																			<p
																				placement="top"
																				(click)="openInstructions()"
																			>
																				<strong
																					>Parade <strong>Rating</strong></strong
																				>
																			</p>
																		</div>
																	</div>
																</div>
																<!-- end panel -->
															</div>
															<!-- end t-col-1 -->
														</div>
														<!-- end row -->
													</td>

													<td class="prices">
														<div class="d-md-block d-none">
															<img
																class="img-fluid bookmaker"
																src="assets/images/bookmakers/unibet-tile2.png"
																alt="TRC"
															/>
															<span class="bookmaker-img small_space"
																><strong>Win:</strong>
																{{ horse?.win3_current | currency: 'USD' }}</span
															>
															<span class="bookmaker-img"
																><strong>Place:</strong
																>{{ horse?.plc3_current | currency: 'USD' }}</span
															>
														</div>
														<div class="d-block d-md-none">
															<div class="top">
																<img
																	class="img-fluid topsport"
																	src="assets/images/icons/topsport.svg"
																	alt="Top Sport"
																/>
															</div>
															<div class="bottom">
																{{ horse?.win3_current | currency: 'USD' }}
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
										<!-- end table -->
									</div>
									
									<!-- GHOST ELEMENT -->
									<div *ngIf="loadingHorses[i] == 'loading'">
										<table
											class="table"
											*ngFor="let number of [0, 1, 2, 3, 4, 5, 6]"
										>
											<tbody>
												<tr>
													<td class="tabno">
														<img
															class="img-fluid jockey-img"
															src="http://d36frgpkvm82k8.cloudfront.net/187541_1_6_64x64.png"
														/>
													</td>
													<td class="td_name">
														<div class="player_name">
															<h3 class="loading-text"></h3>
														</div>
														<span class="comment loading-text"></span>
													</td>

													<td class="yard_health_rating">
														<div class="row">
															<div class="col-6" *ngFor="let number of [0, 1]">
																<div class="panel">
																	<div class="row">
																		<div class="col-xl-4 col-lg-12 col-md-4 col-circle">
																			<div class="loading-circle"></div>
																		</div>
																		<div
																			class="
																				col-xl-8 col-lg-12 col-md-8 col-circle-info
																				align-self-center
																			"
																		>
																			<p class="loading-text"></p>
																		</div>
																	</div>
																</div>
																<!-- end panel -->
															</div>
															<!-- end col-6 -->
														</div>
														<!-- end row -->
													</td>

													<td class="prices">
														<span class="bookmaker-img small_space loading-text"></span>
														<span class="bookmaker-img loading-text"></span>
													</td>
												</tr>
											</tbody>
										</table>
										<!-- end table -->
									</div>
								</div>
								<!-- collapsed-content  -->

								<h4
									class="pill time-pills align-self-center"
									[ngClass]="{
										red: row?.seconds <= 300 && row?.seconds > 0,
										grey: row?.seconds <= 0,
										black: row?.seconds > 300,
										green: row?.race_status == 'FINAL'
									}"
								>
									<span [counter]="row?.race_start_time" [race_status]="row?.race_status"></span>

									<span>{{
										row?.race_start_time | amFromUtc | amLocal | amDateFormat: 'h:mma'
									}}</span>
								</h4>
							</li>
						</ul>

						<!-- GHOST ELEMENT -->
						<ul class="accr-section" *ngIf="loadingRacedata">
							<li class="collapsable-section closed panel" *ngFor="let number of [0, 1, 2]">
								<h2 class="heading"><div class="loading-text"></div></h2>

								<div class="heading_table">
									<div class="loading-text"></div>
								</div>
							</li>
						</ul>
					</div>
					<!-- end col_right -->
				</div>
				<!-- end row -->

				<!-- end liveyard_tab_content -->

				<!-- USER HAS ACCESS TO LIVEYARDS but no Liveyard & Liveyard Horse Data is present -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->
		</div>
		<!-- end row -->

		<div class="row" *ngIf="!LiveyardsAvailable || (user && !user?.hasLiveYard) || !user">
			<div class="col-lg-8 offset-lg-2">
				<div class="panel locked_panel" *ngIf="(user && !user?.hasLiveYard) || !user || !LiveyardsAvailable">
					<div class="top_text_wrapper">
						<h2 class="col-12 text-center heading" *ngIf="!LiveyardsAvailable">
							Live Yard is currently not scheduled
						</h2>
						<h2 class="col-12 text-center heading" *ngIf="LiveyardsAvailable">
							Unlock professional analysis & insights
						</h2>

						<div class="body text-center" *ngIf="(user && !user?.hasLiveYard) || !user">
							<p *ngIf="!LiveyardsAvailable">
								Subscribe to the 'Platinum' or 'Live Yard' package to unlock future Live Yard analysis
								along with other exclusive content.
							</p>
							<p *ngIf="LiveyardsAvailable">
								Subscribe to the 'Platinum' or 'Live Yard' package to access this as well as other
								exclusive content.
							</p>
							<a class="btn blue arrow_large" routerLink="/subscribe-plan" *ngIf="user">Unlock</a>
							<a class="btn blue arrow_large" routerLink="/signup" *ngIf="!user">Sign up</a>
						</div>

						<div class="placeholder">
							<img class="img-fluid" src="assets/images/placeholders/liveyard-large.svg" alt="Upgrade" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end container -->
</div>
<!-- end liveyardpage_wrapper -->
