import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
// import { RecaptchaErrorParameters } from "ng-recaptcha";
import { OnExecuteData, OnExecuteErrorData, ReCaptchaV3Service } from 'ng-recaptcha';
//   import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SegmentAnalyticsService } from 'src/app/services/segment-analysis.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	loginForm: FormGroup = this.formBuilder.group({
		email: [
			'',
			Validators.compose([
				Validators.required,
				Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
				Validators.minLength(1),
			]),
		],
		password: ['', [Validators.required, Validators.minLength(6)]],
	});
	loading = false;
	// myRecaptcha = new FormControl(false);
	error: string = '';
	submitted: any = false;
	response: any;
	// private Subscription: Subscription;
	public recentToken: string = '';
	googleCaptchPublicKey: any = environment.googleCaptchPublicKey;

	recaptchaAvailable = false;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toastr: ToastrService,
		private segmentAnalyticsService: SegmentAnalyticsService,

		// private recaptchaV3Service: ReCaptchaV3Service,
		private authenticationService: AuthenticationService // private singleExecutionSubscription: Subscription
	) {}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			email: [
				'',
				Validators.compose([
					Validators.required,
					Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
					Validators.minLength(1),
				]),
			],
			password: ['', [Validators.required, Validators.minLength(6)]],
		});
		// this.getRecaptchaToken('register');
	}
	ngOnDestroy() {}
	// getRecaptchaToken(action:any){

	// 	this.singleExecutionSubscription = this.recaptchaV3Service.execute(action)
	// 		.subscribe(response => {
	// 			this.recentToken = response;
	// 			this.recaptchaAvailable = true;
	// 		},error=>{
	// 		  console.log("error getting recaptcha");
	// 		//   this.OnDestroy()

	// 		});
	// }
	// public formatToken(token: string): string {
	// if (!token) {
	// 	return "(empty)";
	// }

	// return `${token.substr(0, 7)}...${token.substr(-7)}`;
	// }

	get f() {
		return this.loginForm.controls;
	}

	onSubmit() {
		this.submitted = true;
		console.log(this.loginForm.value);
		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}
		let data = {
			email: this.loginForm.value.email,
			password: this.loginForm.value.password,
		};

		// console.log(data);

		this.loading = true;
		this.userService.login(data).subscribe(
			(response: any) => {
				if (response.status) {
					console.log(response);
					localStorage.setItem('trc_user', JSON.stringify(response.user));
					this.segmentAnalyticsService.identify(response.user)

						  this.segmentAnalyticsService.track('User logged in', {
							"email" : response.user.email
	  						})
				
					this.router.navigate(['/account']);
					this.loading = false;
				} else {
					this.loading = false;
					console.log(response);
					this.toastr.error(response.message);
				}
				this.loading = false;
			},
			(error) => {
				console.log(error);
				this.loading = false;
			}
		);

		if (this.recaptchaAvailable) {
			// do we have recaptcha token?
			this.loginForm.value.rcToken = this.recentToken;
		}
	}

	// onScriptLoad() {
	//     console.log('Google reCAPTCHA loaded and is ready for use!')
	// }

	// onScriptError() {
	//     console.log('Something went long when loading the Google reCAPTCHA')
	// }
	// 	public captchaResponse = "";
	//   public resolved(captchaResponse: string): void {
	//     const newResponse = captchaResponse
	//       ? `${captchaResponse.substr(0, 7)}...${captchaResponse.substr(-7)}`
	//       : captchaResponse;
	//     this.captchaResponse += `${JSON.stringify(newResponse)}\n`;
	//   }

	//   public onError(errorDetails: RecaptchaErrorParameters): void {
	//     this.captchaResponse += `ERROR; error details (if any) have been logged to console\n`;
	//     console.log(`reCAPTCHA error encountered; details:`, errorDetails);
	//   }
	resolved(evt: any) {
		console.log(evt);
	}
}
