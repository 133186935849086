<div class="breadcrumbs_wrapper bg-image text-center" [style.backgroundImage]="'url(' + roundRobinTournament?.image_url + ')'">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner mx-auto">
					<h1 class="page_title">Round Robin Tournaments</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/">Home</a></li>
							<li><a routerLink="/tournaments">Tournaments</a></li>
							<li>{{ roundRobinTournament?.title }}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end breadcrumbs_wrapper -->

<div class="tournamentspage_wrapper">
	<div class="container">
		<div class="row">

			<div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 info_wrapper bottom-border gradient"
				*ngIf="!loading">
				<div class="panel">
					<h4 class="pill {{ roundRobinTournament?.alert }}" *ngIf="!roundRobinTournament?.cut_off_date_over">
						Closing in {{ roundRobinTournament?.time_remaining }}
					</h4>

					<h4 class="pill {{ roundRobinTournament?.alert }}" *ngIf="roundRobinTournament?.cut_off_date_over">
						Closed</h4>

					<div class="row">
						<div class="single_cell">
							<h2 class="heading">${{ roundRobinTournament?.prize }}</h2>
							<span class="cell_h">Prize</span>
						</div>

						<div class="single_cell" *ngIf="roundRobinTournament?.entry_fee && roundRobinTournament?.entry_fee > 0">
							<h2 class="heading">${{ roundRobinTournament?.entry_fee }}</h2>
							<span>Entry</span>
						</div>

						<div class="single_cell">
							<h2 class="heading">{{ roundRobinTournament?.status }}</h2>
							<span>Status</span>
						</div>

						<div class="single_cell">
							<h2 class="heading">{{ roundRobinTournament?.participants }}</h2>
							<span>Entrants</span>
						</div>

						<div class="single_cell align-self-center">
							<h2 class="heading"
								*ngIf="roundRobinTournament?.roundrobin_tournamentUser && roundRobinTournament?.roundrobin_tournamentUser.user_rank">
								{{ roundRobinTournament?.roundrobin_tournamentUser?.user_rank }}
							</h2>
							<h2 class="heading"
								*ngIf="!roundRobinTournament?.roundrobin_tournamentUser || !roundRobinTournament?.roundrobin_tournamentUser.user_rank">
								-</h2>
							<span>Ranked</span>
						</div>

						<div class="single_cell align-self-center">
							<a class="btn large black disabled"
								*ngIf="roundRobinTournament?.roundrobin_tournamentUser && roundRobinTournament?.roundrobin_tournamentUser.status == 'entered'">Entered</a>
							<a class="btn large black"
								*ngIf="!roundRobinTournament?.cut_off_date_over && (!roundRobinTournament?.roundrobin_tournamentUser || roundRobinTournament?.roundrobin_tournamentUser.status !== 'entered')">Enter
								Now</a>
						</div>
					</div>
					<!-- end row -->
				</div>
			</div>
		</div>

		<div class="row">

			<div class="col-lg-10 offset-lg-1" *ngIf="!loading">
				<div class="row">

					<div class="col-lg-7 col_right order-lg-2" *ngIf="roundRobinTournament?.description">
						<div class="panel h-100">
							<div class="message">
								{{ roundRobinTournament?.description }}
							</div>
						</div>
					</div><!-- col_right -->

					<div class="col-lg-5 col_left order-lg-1">
						<div class="panel h-100">
							<h2 class="heading">Leaderboard</h2>

							<div class="col-12 leaderboard-list user-self" *ngIf="roundRobinTournamentLeaderboard?.length > 0 && roundRobinTournamentUser?.points">
								<div class="row no-gutters">
									<div class="col col_avatar align-self-center">
										<div *ngIf="user.avatar" class="avatar_inner" [ngClass]="{'green': roundRobinTournamentUser?.user_rank == 1, 'yellow': roundRobinTournamentUser?.user_rank != 1}" [ngStyle]="{'background-image': 'url(' + storageUrl + 'users/' + user?.avatar + ')'}"></div>
										<div *ngIf="!user.avatar" class="avatar_inner with-initials" [ngClass]="{'green': roundRobinTournamentUser?.user_rank == 1, 'yellow': roundRobinTournamentUser?.user_rank != 1}">
											<span class="initials"><span>{{getName(user.name) | uppercase }}</span></span>
										</div>
									</div>
									<div class="col col_name align-self-center">
										<span *ngIf="!user?.display_name" class="name">{{ roundRobinTournamentUser?.user_rank }}. {{ user?.name }}</span>
										<span *ngIf="user?.display_name" class="rank"><span>{{ roundRobinTournamentUser?.user_rank }}.</span> {{ user?.display_name }}</span>
									</div>
									<div class="col col_points align-self-center">
										<span class="points">{{ roundRobinTournamentUser?.points }} points</span>
									</div>
								</div>
							</div>
	
							<div class="col-12 leaderboard-list" *ngIf="roundRobinTournamentLeaderboard?.length > 0">
								<div class="row no-gutters" *ngFor="let user of roundRobinTournamentLeaderboard" [ngClass]="{'self' : roundRobinTournamentUser?.id == user?.id}">
									<div class="col col_avatar align-self-center">
										<div *ngIf="user.avatar" class="avatar_inner" [ngClass]="{'green': user?.user_rank == 1, 'yellow': user?.user_rank != 1}" [ngStyle]="{'background-image': 'url(' + storageUrl + 'users/' + user?.avatar + ')'}"></div>
										<div *ngIf="!user.avatar" class="avatar_inner with-initials" [ngClass]="{'green': user?.user_rank == 1, 'yellow': user?.user_rank != 1}">
											<span class="initials"><span>{{getName(user.name) | uppercase }}</span></span>
										</div>
									</div>
									<div class="col col_name align-self-center">
										<span *ngIf="!user?.display_name" class="name">{{ user?.user_rank }}. {{ user?.name }}</span>
										<span *ngIf="user?.display_name" class="rank"><span>{{ user?.user_rank }}.</span> {{ user?.display_name }}</span>
									</div>
									<div class="col col_points align-self-center">
										<span class="points">{{ user?.points }} points</span>
									</div>
								</div>
							</div>
	
							<div class="col-12 leaderboard-list" *ngIf="roundRobinTournamentLeaderboard?.length == 0">
								<div class="row no-gutters">
									<p>
										Races will be automatically resulted, with the leaderboard being updated approximately 15 minutes post race results being ‘official’ by the governing body. The final tournament winners will be announced shortly proceeding the final official race result of the tournament.
									</p>
								</div>
							</div>
						</div>
					</div><!-- end col_left -->

					<div class="col-lg-12 col_bottom mt-4 order-lg-3">
						<div class="row">
							<div class="tournaments_single_new col-lg-12" *ngFor="let row of tournaments">
								<div class="panel_wrap col-12">
									<div class="row row-eq-height">
										<div
											class="bg_image_behind"
											*ngIf="row?.imageType == 'png' && row?.image_url"
											[style.backgroundImage]="'url(' + row?.image_url + ')'"
										></div>
										<!-- end bg_image -->
										<div
											class="bg_image_behind overlay"
											*ngIf="row?.imageType == 'jpg' && row?.image_url"
											[style.backgroundImage]="'url(' + row?.image_url + ')'"
										></div>
										<!-- end bg_image -->
										<!-- <img class="img img-thumbnail" src="{{ row?.image_url }}" alt="TRC" /> -->
							
										<div class="col-lg-2 col d-none d-lg-block">
											<div
												class="bg_image offset_image"
												*ngIf="row?.imageType == 'png' && row?.image_url"
												[style.backgroundImage]="'url(' + row?.image_url + ')'"
											></div>
											<!-- end bg_image -->
											<div
												class="bg_image"
												*ngIf="row?.imageType == 'jpg' && row?.image_url"
												[style.backgroundImage]="'url(' + row?.image_url + ')'"
											></div>
											<!-- end bg_image -->
										</div>
										<!-- end col-lg-3 -->
							
										<div class="col-lg-3 col-md-4 col-sm-12 col-12 col_name col">
											<span class="value">{{ row?.name }}</span>
											<span class="label">Tournament</span>
										</div>
										<!-- end col_name -->
							
										<div class="col-lg-4 col-md-5 col-sm-12 col-12 col_info col">
											<div class="row">
												<div class="col-md-3 info_single">
													<span class="value">${{ row?.prize }}</span>
													<span class="label">Prize</span>
												</div>
												<div class="col-md-4 info_single">
													<span class="value" *ngIf="row?.entry_fee == 0">Free</span>
													<span class="value" *ngIf="row?.entry_fee > 0">${{ row?.entry_fee }}</span>
													<span class="label">Entry</span>
												</div>
												<div class="col-md-5 info_single">
													<span class="value">{{ row?.status }}</span>
													<span class="label">Status</span>
												</div>
											</div>
										</div>
										<!-- end col_info -->
							
										<div class="col-lg-3 col-md-3 col-12 col_btn col align-self-center">
											<a
												class="btn blue"
												*ngIf="
													!row?.cut_off_date_over &&
													(!row.tournamentUser ||
														(row.tournamentUser && row.tournamentUser.status != 'entered')) &&
													user
												"
												(click)="agreeTerms(row)"
												>Enter</a
											>
											<a
												class="btn blue"
												*ngIf="
													!row?.cut_off_date_over &&
													row.tournamentUser &&
													row.tournamentUser.status == 'entered' &&
													user
												"
												(click)="gotoSingleTournament(row)"
												>Edit Selections</a
											>
											<a
												class="btn white"
												*ngIf="
													row?.cut_off_date_over &&
													row.tournamentUser &&
													row.tournamentUser.status == 'entered' &&
													user
												"
												(click)="gotoSingleTournament(row)"
												>View Selections</a
											>
											<a class="btn" (click)="gotoSingleTournamentView(row)">View</a>
										</div>
										<!-- end col_btn -->
							
										<h4 class="pill {{ row?.alert }}" *ngIf="!row?.cut_off_date_over">
											Closing in {{ row?.time_remaining }}
										</h4>
							
										<h4 class="pill {{ row?.alert }}" *ngIf="row?.cut_off_date_over">Closed</h4>
									</div>
									<!-- end row -->
								</div>
								<!-- end panel_wrap -->
							
								<div class="cutt_off" *ngIf="!row?.cut_off_date_over">
									<!-- Competition cut off: Saturday 7th October 11:30pm EST -->
									<p>
										Competition cut off: {{ row.cut_off_date_time | amFromUtc  | amLocal | amDateFormat: 'DD MMM YYYY HH:mm' }}
									</p>
								</div>
							</div>
							<!-- end tournaments_single_new -->
						</div>
						<!-- end row -->
					</div>
				</div>
			</div>

			<!-- GHOST ELEMENT -->
			<div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 info_wrapper bottom-border gradient" *ngIf="loading">
				<div class="panel loading-text"></div>
			</div>

			<!-- GHOST ELEMENT -->
			<div class="col-lg-10 offset-lg-1" *ngIf="loading">
				<div class="row">

					<div class="col-lg-7 col_right order-lg-2">
						<div class="panel h-100">
							<div class="body">
								<div class="loading-text"></div>
								<div class="loading-text"></div>
								<div class="loading-text"></div>
								<div class="loading-text"></div>
							</div>
						</div>
					</div><!-- col_right -->

					<div class="col-xl-5 col_left order-lg-1">
						<div class="panel">
							<h2 class="heading">Leaderboard</h2>

							<div
								class="row"
							>
								<div class="col-12 leaderboard-list" *ngFor="let numbers of [0]">
									<div class="inner">
										<div class="avatar_inner loading-text"></div>
										<span><div class="loading-text"></div></span>
										<span><div class="loading-text ml-3"></div></span>
									</div>
								</div>
							</div>
						</div>
					</div><!-- end col_left -->

					<div class="col-lg-12 col_bottom mt-4 order-lg-3">
						<div class="row">
							<div class="tournaments_single_new col-lg-12" *ngFor="let numbers of [0,1,2,3,4]">
								<div class="panel_wrap col-12">
									<div class="row row-eq-height">

										<div class="col-lg-2 col d-none d-lg-block">
											<div class="bg_image offset_image loading-text"></div><!-- end bg_image -->
										</div>
										<!-- end col-lg-3 -->
							
										<div class="col-lg-3 col-md-4 col-sm-12 col-12 col_name col ghost">
											<span class="value mb-2"><div class="loading-text"></div></span>
											<span class="label"><div class="loading-text"></div></span>
										</div>
										<!-- end col_name -->
							
										<div class="col-lg-4 col-md-5 col-sm-12 col-12 col_info col">
											<div class="row">
												<div class="col-md-4 info_single" *ngFor="let numbers of [0,1,2]">
													<span class="value mb-2"><div class="loading-text"></div></span>
													<span class="label"><div class="loading-text"></div></span>
												</div>
											</div>
										</div>
										<!-- end col_info -->
							
										<div class="col-lg-3 col-md-3 col-12 col_btn col align-self-center">
											<a class="btn blue disabled loading-text">&nbsp;</a>
											<a class="btn disabled loading-text">&nbsp;</a>
										</div>
										<!-- end col_btn -->
									</div>
									<!-- end row -->
								</div>
								<!-- end panel_wrap -->
							</div>
						</div>
					</div><!-- col_bottom -->

				</div>
				<!-- end row -->
			</div>

		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>