<app-header
	*ngIf="
		!hasRoute('/signup') &&
		!hasRoute('/login') &&
		!hasRoute('/forgot-password') &&
		!hasRoute('/change-password') &&
		!hasRoute('/subscribe-plan') &&
		!hasRoute('/makepayment') &&
		!hasRoute('/checkout') &&
		!hasRoute('/not-found') &&
		!hasRoute('/terms-app') &&
		!hasRoute('/coming-soon')
	"
></app-header>
<router-outlet></router-outlet>
<!-- <app-footer
	*ngIf="
		!hasRoute('/signup') &&
		!hasRoute('/login') &&
		!hasRoute('/forgot-password') &&
		!hasRoute('/change-password') &&
		!hasRoute('/subscribe-plan') &&
		!hasRoute('/makepayment') &&
		!hasRoute('/checkout') &&
		!hasRoute('/terms-app') &&
		!hasRoute('/coming-soon')
	"
></app-footer> -->
