import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TournamentService } from '../../services/tournaments.service';
import { Options } from 'select2';

import * as moment from 'moment';
declare var pinApi: any;

@Component({
	selector: 'app-tournament-pay',
	templateUrl: './tournament-pay.component.html',
	styleUrls: ['./tournament-pay.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TournamentPayComponent implements OnInit {
	@Input() tournament: any;
	@Output() showTournamentEvent: EventEmitter<any> = new EventEmitter<any>();

	name: any;
	cardnumber: any;
	expirationdate: any;
	cvc: any;
	output: any;
	ccicon: any;
	ccsingle: any;
	generatecard: any;
	selection: any;
	submitted: any = false;
	payment_gateway_response: any;
	pinpayment_response: any;
	user: any;
	token: any;
	payment_progress: any = 0;

	paymentForm: FormGroup = this.formBuilder.group({
		number: ['', [Validators.required]],
		name: ['', [Validators.required]],
		expiry_month: ['', [Validators.required]],
		cvc: ['', [Validators.required]],
		expiry_year: ['', [Validators.required]],
	});

	pinpayment_errors: any = {
		number: null,
		name: null,
		expiry_month: null,
		cvc: null,
		expiry_year: null,
	};

	constructor(
		private route: Router,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private tournamentService: TournamentService
	) {
		this.user = localStorage.getItem('trc_user');

		if (this.user != null && this.user != 'undefined') this.token = JSON.parse(this.user).token;
	}

	ngOnInit(): void {}

	get f() {
		return this.paymentForm.controls;
	}

	async onSubmit() {
		this.submitted = true;
		if (this.paymentForm.invalid) {
			return;
		}

		this.payment_progress = 0;

		setTimeout(function () {
			$('#theprogressbar')
				.attr('aria-valuenow', 10)
				.css('width', 10 + '%');
		}, 2000);

		this.pinpayment_response = await pinApi
			.createCardToken(this.paymentForm.value)
			.then(function (paymentForm: any) {
				//console.log(paymentForm);
				return paymentForm;
				// this.handleSuccess(card);
			})
			.catch(function (e: any) {
				//console.log('Error: ', e);
				return e;
			});

		if (this.pinpayment_response && this.pinpayment_response.error) {
			setTimeout(function () {
				$('#theprogressbar')
					.attr('aria-valuenow', 20)
					.css('width', 20 + '%');
			}, 2000);

			this.pinpayment_response.messages.forEach((element: any) => {
				this.pinpayment_errors[element.param] = element.message;
			});
		}

		setTimeout(function () {
			$('#theprogressbar')
				.attr('aria-valuenow', 30)
				.css('width', 30 + '%');
		}, 1000);

		if (this.pinpayment_response && !this.pinpayment_response.error) {
			let card = {
				card_token: this.pinpayment_response.token,
				tournament_id: this.tournament.id,
				token: this.token,
			};
			this.tournamentService.chargeCustomerWithCard(card).subscribe((data: any) => {
				if (data && data.status) {
					this.showTournamentEvent.emit(data.tournament_user);
					setTimeout(function () {
						$('#theprogressbar')
							.attr('aria-valuenow', 100)
							.css('width', 100 + '%');
					}, 1000);
				} else {
					alert('There is an issue with payment');
				}
			});
		}
	}
}
