import { Component, OnInit, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { UpgradeModalPage } from '../../shared/upgrade-modal/upgrade-modal.page';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalService } from '../../shared/alert-modal/alert-modal.service';
@Component({
	selector: '[liveyard-tab]',
	templateUrl: './liveyard-tab.component.html',
	styleUrls: ['./liveyard-tab.component.scss'],
})
export class LiveYardTabComponent implements OnInit {
	@Input() user: any;
	@Input() live_yard_horses: any;
	@Input() liveyard: any;
	@Input() liveyard_loading: any;
	screenWidth:any;

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.screenWidth = window.innerWidth;
	}

	constructor(private modalService: NgbModal, private AlertModalService: AlertModalService) {
		// console.log(this.liveyard_loading);
		this.getScreenSize();
	}

	ngOnInit(): void {
		console.log(this.liveyard_loading)
	}

	formatSubtitle = (percent: number) => {
		return percent;
	};

	percentToColor(percent: number, min: number, max: number): string {
		if (percent < 54) {
			return '#FF0000';
		} else if (percent >= 55 && percent <= 74) {
			return '#F5C61C';
		} else if (percent > 75) {
			return '#67BE53';
		}
		return '#67BE53';
	}

	showUpgradeModal(type: any) {
		const modalRef = this.modalService.open(UpgradeModalPage, {
			size: 'custom',
			windowClass: 'upgrade-modal',
		});

		let data = {
			type: type,
		};

		modalRef.componentInstance.fromParent = data;
		modalRef.result.then(
			(result) => {
				//console.log(result);
			},
			(reason) => {}
		);
	}

	async openInstructions() {
		let message =
			'<h5><strong>0-54</strong></h5>'+

			'<p class="info"> A rating in this range indicates the horses’ presentation or behaviour will likely hinder performance.</p>'+
	
			'<h5><strong>55-74</strong></h5>'+
	
			'<p class="info"> A rating in this range is neutral. Refer to in depth runner comments and weight to form as the main performance indicator.</p>'+
	
			'<h5><strong>75-100</strong></h5>'+
	
			'<p class="info"> A rating in this range indicates optimal performance. The horse is expected to run to or possibly exceed form indicators.</p>';

		await this.AlertModalService.confirm('Parade Rating', message)
		.then((confirmed) => {
			if (confirmed) {
				// donothing
			}else {
				// donothing
			}
		})
	}
}
