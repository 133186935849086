import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from 'src/environments/environment';
declare let ga: Function;

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ContactUsComponent implements OnInit {
	submitted = false;
	myFormData: any;
	showForm: any = true;
	googleCaptchPublicKey: any = environment.googleCaptchPublicKey;
	loading: any = false;
	user: any;

	contactusForm: FormGroup = this.formBuilder.group({
		firstName: ['', Validators.required],
		lastName: ['', Validators.required],
		email: [
			'',
			Validators.compose([
				Validators.required,
				Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
				Validators.minLength(1),
			]),
		],
		phone: [''],
		enquirytype: ['', Validators.required],
		message: ['', Validators.required],
	});

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private http: HttpClient,
		private userService: UserService,
		private toastr: ToastrService
	) {}

	get f() {
		return this.contactusForm.controls;
	}

	getUser() {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
	}

	onSubmit() {
		this.submitted = true;
		this.loading = true;
		//console.log('submitting form');
		if (this.contactusForm.invalid) {
			this.loading = false;
			return;
		}
		if (this.submitted) {
			this.myFormData = new FormData();

			this.myFormData.append('first_name', this.contactusForm.value.firstName);
			this.myFormData.append('last_name', this.contactusForm.value.lastName);
			this.myFormData.append('email', this.contactusForm.value.email);
			this.myFormData.append('phone', this.contactusForm.value.phone);
			this.myFormData.append('enquiry_type', this.contactusForm.value.enquirytype);
			this.myFormData.append('message', this.contactusForm.value.message);

			let subscription;
			let user_type;
			if (this.user && this.user.subscription) subscription = this.user.subscription.plan_name;
			else subscription = 'NA';
			if (this.user && this.user.payment_method == 'ios') user_type = 'ios';
			else user_type = 'web';
			this.myFormData.append('subscription', subscription);
			this.myFormData.append('user_type', user_type);
			if(this.user) {
				this.myFormData.append('user_id', this.user.id);
			}

			this.userService.contactSupport(this.myFormData).subscribe((data: any) => {
				if (data && data.status) {
					this.toastr.success(data.message);
					ga('send', {
						hitType: 'event',
						eventCategory: 'Website Contact Form',
						eventAction: 'Submission',
						eventLabel: this.contactusForm.value.enquirytype,
					});
					this.showForm = false;
				} else {
					this.toastr.error(data.message);
				}
				this.loading = false;
			});
		}
	}

	ngOnInit() {
		this.contactusForm = this.formBuilder.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			email: [
				'',
				Validators.compose([
					Validators.required,
					Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
					Validators.minLength(1),
				]),
			],
			enquirytype: ['', Validators.required],
			message: ['', Validators.required],
			phone: [''],
		});
	}

	async showContactUsForm() {
		this.showForm = !this.showForm;
	}
}
