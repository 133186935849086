import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NewsService } from '../services/news.service';
import { OffersService } from '../services/offers.service';

import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlackbookPopupComponent } from './../shared/blackbook-popup/blackbook-popup.component';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { MetatagService } from '../services/metatag.service';

@Component({
	selector: 'app-single-news',
	templateUrl: './single-news.component.html',
	styleUrls: ['./single-news.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SingleNewsComponent implements OnInit {
	news: any;
	quote: string = '';
	newsId: any;
	nwsSlug: any;
	featuredNews: any;
	pageUrl: any;
	loadingFeaturedNews: boolean = true;
	loading: boolean = true;
	user: any;
	offer: any;
	loadingOffers: boolean = true;
	loadingNews: boolean = true;
	latest_news: any = [];
	news_articles_count: any = 4;

	constructor(
		private NewsService: NewsService,
		private route: ActivatedRoute,
		private router: Router,
		private modalService: NgbModal,
		private OffersService: OffersService,
		private titleService: Title,
		private metaService: Meta,
		private MetatagService: MetatagService
	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);

	}

	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.newsId = params.id;
			this.nwsSlug = params.slug;
			this.getNewsDetails(this.newsId);
		});

		this.getFeaturedNews();
		if (this.user) {
			this.getOffer();
		} else {
			this.getGuestOffer();
		}

		this.pageUrl = environment.publicUrl +  this.router.url;
		//console.log(this.pageUrl);
	}

	ngAfterViewInit() {}

	getNewsDetails(newsId: any) {
		this.NewsService.getNewsDetails(newsId).subscribe((data: any) => {
			if (data && data.news) {
				this.news = data.news ? data.news : [];
				this.latest_news = data.latest_news ? data.latest_news : [];
				this.titleService.setTitle(this.news.title);
				this.MetatagService.updateMetatags(this.news.title, 'web site', this.news.short_description ,this.news.image_url);
				if(this.news.conclusion)
					this.news.conclusion = this.news.conclusion.replace(/\n/g, '<br />');
				if(this.news.short_description) 
					this.news.short_description = this.news.short_description.replace(/\n/g, '<br />');
				if(this.news.long_description) 
					this.news.long_description = this.news.long_description.replace(/href="\/storage/g, 'href="https://membersv2.theraceclub.com.au/storage');
					this.news.long_description = this.news.long_description.replace(/src="\/storage/g, 'src="https://membersv2.theraceclub.com.au/storage');
				}
			if (!this.news) {
				this.quote = 'no news are available';
			}
			setTimeout(() => {
				this.loading = false;
			}, 500);
		});
	}

	getFeaturedNews() {
		this.NewsService.getFeaturedNews().subscribe((data: any) => {
			if (data && data.news) {
				this.featuredNews = data.news ? data.news : [];
			}
			if (!this.news) {
				this.quote = 'no featured news are available';
			}
			this.loadingFeaturedNews = false;
		});
	}

	gotoSingleNews(news: any) {
		this.router.navigate(['/news/' + news.id + '/' + news.slug]);
	}

	async OpenBlackbook(horse: any) {
		const modalRef = this.modalService.open(BlackbookPopupComponent, {
			size: 'custom',
			windowClass: 'blackbook-modal',
			scrollable: true
		});

		let data = {
			horse: horse,
			user: this.user,
		};

		modalRef.componentInstance.fromParent = data;
		modalRef.result.then(
			(result) => {
				//console.log(result);
			},
			(reason) => {}
		);
	}

	getOffer() {
		this.OffersService.getOffer(this.user.token).subscribe((data: any) => {
			//console.log(data);

			if (data && data.offer) {
				this.offer = data.offer ? data.offer : [];
				this.offer.buttons = JSON.parse(this.offer.buttonsmeta);
				//console.log(this.offer);
			}
			this.loadingOffers = false;
		});
	}

	getGuestOffer() {
		this.OffersService.getGuestOffer().subscribe((data: any) => {
			if (data && data.offer) {
				this.offer = data.offer ? data.offer : [];
				this.offer.buttons = JSON.parse(this.offer.buttonsmeta);
			}
			this.loadingOffers = false;
		});
	}

	async OfferClicked(row: any) {
		if (row.link_type == 'url') {
			window.open(row.value);
		} else {
			this.router.navigate([row.value]);
		}
	}

	// async getBlackbookCount() {
	// 	this.BlackbookService.getblackbookNotes(this.selectedHorse, this.user.token).subscribe((data: any) => {
	// 		if (data) {
	// 			if (data.blackbook_entries.length != 0 && data.blackbook_entries != null) {
	// 				this.bb_count = 0;
	// 				data.blackbook_entries.forEach((element: any) => {
	// 					if (element.type == 'horse') this.bb_count++;
	// 					if (element.type == 'jockey') this.bb_count++;
	// 					if (element.type == 'trainer') this.bb_count++;
	// 				});
	// 				this.loadingNotes = false;
	// 			} else {
	// 				this.bb_count = 0;
	// 				this.loadingNotes = false;
	// 			}
	// 		} else {
	// 			this.loadingNotes = false;
	// 		}
	// 	});
	// }
}
