import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ResourceService } from '../services/resource.service';
import { Router, ActivatedRoute } from '@angular/router';
import { OffersService } from '../services/offers.service';
import { environment } from 'src/environments/environment';
import { MetatagService } from '../services/metatag.service';

@Component({
	selector: 'app-resource',
	templateUrl: './resource.component.html',
	styleUrls: ['./resource.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ResourceComponent implements OnInit {
	resource: any;
	quote: string = '';
	resourceId: any;
	nwsSlug: any;
	featured_resource: any;
	pageUrl: any;
	loadingFeaturedResource: boolean = true;
	loadingOffers: boolean = true;
	offer: any;
	user: any;
	loading: boolean = true;

	constructor(
		private resourceService: ResourceService,
		private route: ActivatedRoute,
		private router: Router,
		private OffersService: OffersService,
		private MetatagService: MetatagService,

	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
	}

	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.resourceId = params.id;
			this.nwsSlug = params.slug;
			//console.log(this.resourceId);
			this.getresourceDetails(this.resourceId);
		});

		this.getFeaturedResources();
		this.pageUrl = environment.publicUrl +  this.router.url;
		//console.log(this.pageUrl);
		if (this.user) {
			this.getOffer();
		} else {
			this.getGuestOffer();
		}
	}

	getresourceDetails(resourceId: any) {
		this.resourceService.getResourceDetails(resourceId).subscribe((data: any) => {
			if (data && data.resource) {
				this.resource = data.resource ? data.resource : [];
				if(this.resource.short_description) 
					this.resource.short_description = this.resource.short_description.replace(/\n/g, '<br />');
				this.MetatagService.updateMetatags(this.resource.title, 'web site', this.resource.short_description,this.resource.image_url)
			}
			if (!this.resource) {
				this.quote = 'no resource are available';
			}
			setTimeout(() => {
				this.loading = false;
			}, 500);
		});
	}

	getFeaturedResources() {
		this.resourceService.getFeaturedResources().subscribe((data: any) => {
			if (data && data.resources) {
				this.featured_resource = data.resources ? data.resources[0] : [];
			}
			this.loadingFeaturedResource = false;
		});
	}

	gotoSingleresource(resource: any) {
		this.router.navigate(['/resource/' + resource.id + '/' + resource.slug]);
	}

	getOffer() {
		this.OffersService.getOffer(this.user.token).subscribe((data: any) => {
			if (data && data.offer) {
				this.offer = data.offer ? data.offer : [];
				this.offer.buttons = JSON.parse(this.offer.buttonsmeta);
			}
			this.loadingOffers = false;
		});
	}

	getGuestOffer() {
		this.OffersService.getGuestOffer().subscribe((data: any) => {
			if (data && data.offer) {
				this.offer = data.offer ? data.offer : [];
				this.offer.buttons = JSON.parse(this.offer.buttonsmeta);
			}
			this.loadingOffers = false;
		});
	}

	async OfferClicked(row: any) {
		if (row.link_type == 'url') {
			window.open(row.value);
		} else {
			this.router.navigate([row.value]);
		}
	}
}
