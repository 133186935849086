<div class="breadcrumbs_wrapper">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner">
					<h1 class="page_title">Results</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/">Home</a></li>
							<li><a routerLink="/results">Results</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end container -->
</div>
<!-- end breadcrumbs_wrapper -->

<div class="resultspage_wrapper">
	<div class="container">
		<div class="row">
			<div class="col-lg-10 offset-lg-1">
				<div class="row">
					<div class="col-lg-3 col_left">
						<div class="sidebar_menu" *ngIf="!loading">
							<div class="sticky-sidemenu">
								<div class="panel">
									<div class="input-group picker_wrapper">
										<input
											class="form-control date_picker"
											placeholder="{{ valuedate | date: 'dd-MM-yyyy' }}"
											name="date"
											[(ngModel)]="datepicker"
											ngbDatepicker
											#date="ngbDatepicker"
											(dateSelect)="onSelect($event)"
										/>
										<div class="input-group-append picker_btn_wrapper">
											<button
												class="calendar date_picker_btn"
												(click)="date.toggle()"
												type="button"
											></button>
										</div>
									</div>

									<ul *ngIf="results" class="results-list d-none d-lg-block">
										<li [ngClass]="{ active: isSelect == 1 }" (click)="getRacesforAllMeetings()">
											All Meetings
										</li>
										<li
											[ngClass]="{ active: isSelect == i + 2 }"
											(click)="getEventsRaces(row.meeting_id, i + 2)"
											*ngFor="let row of results; let i = index"
										>
											{{ row.venue }}
										</li>
									</ul>
									<div class="select_wrap d-lg-none d-block"> 
										<select
											id="select_race" [ngModel]="meeting_id"
											class="form-control"
											(change)="selectDropdownRace($event)"
										>
											<option value="1">All Meetings</option>
											<option
												*ngFor="let row of results; let i = index"
												value="{{ row.meeting_id }}"
											>
												{{ row.venue }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>

						<!-- GHOST ELEMENT -->
						<div class="sidebar_menu" *ngIf="loading">
							<div class="sticky-sidemenu">
								<div class="panel">
									<div class="input-group picker_wrapper">
										<div class="form-control loading-text"></div>
									</div>
									<ul class="results-list d-none d-lg-block">
										<li *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7]" class="loading-li">
											<div class="loading-text"></div>
										</li>
									</ul>
									<div class="select_wrap d-lg-none d-block loading-text"></div>
								</div>
							</div>
						</div>
					</div>
					<!-- end col_left -->

					<div class="col-lg-9 col_right">
						<ul class="accr-section" *ngIf="!loadingresultdata && results.length">
							<li class="panel" *ngFor="let row of races">
								<h2 class="heading">
									<span>Race {{ row.raceno }}</span
									>{{ row?.venue }}
								</h2>
								<div class="row subheading_wrapper">
									<div class="col-sm-7">
										<h3 class="subheading">{{ row.name }}</h3>
									</div>
									<div class="col-sm-5">
										<a class="link" routerLink="/form-guide/{{ row?.slug }}">View form guide</a>
									</div>
								</div>

								<div class="heading_table mb-4">
									<div class="cell">
										<span>{{ row?.class_code }}{{ row?.class_other }}</span>
									</div>
									<div class="cell">
										<span>{{ row.distance }}m</span>
									</div>
									<div class="cell"><span>Rail: {{ row.rail }}</span></div>
									<div class="cell" *ngIf="row?.race_prizemoney">
										<span>Prize: {{ row?.race_prizemoney }} total</span>
									</div>
									<div class="cell button_cell">
										<span class="button black">{{
											row.race_start_time | amFromUtc | amLocal | amDateFormat: 'h:mm a'
										}}</span>
										<span class="button {{ row?.track_going | lowercase }}" *ngIf="row?.track_going"
											>{{ row?.track_going | titlecase }} {{ row?.track_rating }}</span
										>
									</div>
								</div>

								<ul class="accr-section">
									<li
										class="result-tables-wrap row"
										*ngFor="let horse of row.horses; let horseIndex = index"
										[ngClass]="{ hiddenContents: horseIndex > 2 }"
									>
										<div
											class="t-index col-1"
											[ngClass]="{
												green: horse.horse_fp == 1,
												yellow: horse.horse_fp == 2 || horse.horse_fp == 3,
												grey: horse.horse_fp > 3,
												Scratched:
													horse.horse_status == 'Scratched' ||
													horse.horse_status == 'LateScratched'
											}"
										>
											<div class="inner">
												<div
													class="inner"
													*ngIf="
														horse.horse_status != 'Scratched' &&
														horse.horse_status != 'LateScratched'
													"
												>
													<span
														><strong *ngIf="horse.horse_fp != 99">{{
															horse.horse_fp
														}}</strong
														><strong *ngIf="horse.horse_fp == 99">-</strong></span
													>
													<span>of {{ row.horses.length - row.scratced_horses }}</span>
												</div>
												<!-- end inner -->
												<div
													class="inner"
													*ngIf="
														horse.horse_status == 'Scratched' ||
														horse.horse_status == 'LateScratched'
													"
												>
													<span><strong>S</strong></span>
												</div>
												<!-- end inner -->
											</div>
											<!-- end inner -->
										</div>

										<div class="t-conents col-11 border-gradient with-padd">
											<div class="panel grey collapsable-section closed">
												<table class="table">
													<tbody class="horse-panel-header">
														<!-- DESKTOP LAYOUT -->
														<tr
															[ngClass]="{
																Scratched:
																	horse.horse_status == 'Scratched' ||
																	horse.horse_status == 'LateScratched'
															}"
															class="d-none d-md-block"
															*ngIf="screenWidth > 767"
														>
															<td class="tabno">
																<img
																	class="img-fluid jockey-img"
																	[src]="horse.silks"
																	alt="TRC"
																	(error)="onImgError($event, horse.tabno)"
																/>
																<h1 class="index_no">{{ horse.tabno }}.</h1>
															</td>

															<td class="td_name">
																<div class="player_name">
																	<h3>
																		<span class="index_no">{{ horse.tabno }}.</span>
																		{{ horse.horse_name }} ({{ horse.barrier }})

																		<div
																			class="yard_pick circle"
																			placement="top"
																			ngbTooltip="Yard pick"
																			*ngIf="
																				horse.yard_pick &&
																				user &&
																				user?.hasLiveYard
																			"
																		>
																			<span>Y</span>
																		</div>
																		<!-- end yard_pick circle -->
																		<div
																			class="preview circle"
																			placement="top"
																			ngbTooltip="Preview pick (win)"
																			*ngIf="
																				horse.win_bet &&
																				user &&
																				user?.hasPreviews
																			"
																		>
																			<span>W</span>
																		</div>
																		<!-- end yard_pick circle -->
																		<div
																			class="preview_place circle"
																			placement="top"
																			ngbTooltip="Preview pick (place)"
																			*ngIf="
																				horse.place_bet &&
																				user &&
																				user?.hasPreviews
																			"
																		>
																			<span>P</span>
																		</div>

																		<div
																		class="best_bet image_circle"
																		placement="top"
																		ngbTooltip="Best Bet"
																		*ngIf="row.best_bet && user && user?.hasPreviews"
																	>
																		<span><img
																			alt="TRC"
																			class="img-fluid"
																			src="assets/images/icons/best_bet.png"
																		/></span>
																		<!--<span>P</span>-->
																		<!-- SHOW P IF THE PICK IS A PLACE -->
																	</div>
																	<div
																		class="value_bet image_circle"
																		placement="top"
																		ngbTooltip="Value Bet"
																		*ngIf="row.value_bet && user && user?.hasPreviews"
																	>
																		<span><img
																			alt="TRC"
																			class="img-fluid"
																			src="assets/images/icons/value_bet.png"
																		/></span>
																		<!--<span>P</span>-->
																		<!-- SHOW P IF THE PICK IS A PLACE -->
																	</div>
																		<!-- end yard_pick circle -->
																		<div
																			class="dual_nomination circle"
																			placement="top"
																			ngbTooltip="Dual acceptance"
																			*ngIf="horse.dual_nom == 'D'"
																		>
																			<span>D</span>
																		</div>
																		<!-- end yard_pick circle -->
																	</h3>
																</div>
																<span class="player_attributes">
																	{{ horse.age }}YO{{ horse.sex }} {{ horse.colour }}
																	{{ horse.sire }} x {{ horse.dam }}
																</span>
															</td>

															<td class="trainer">
																<span class="small_space"
																	><strong>T:</strong> {{ horse.trainer }}</span
																>
																<span>
																	<strong>J:</strong> {{ horse.jockey }}
																	<span *ngIf="horse.weight"
																		>({{ horse.weight }}kg)</span
																	>
																</span>
															</td>

															<td class="form">
																<span class="small_space"
																	><strong>Top Tote:</strong>
																	{{
																		getMax(
																			horse.win3_current,
																			horse.win3_9am,
																			horse.win3_opn
																		) | currency: 'USD'
																	}}
																	/
																	{{
																		getMax(
																			horse.plc3_current,
																			horse.plc3_9am,
																			horse.plc3_opn
																		) | currency: 'USD'
																	}}
																</span>
																<span
																	><strong>SP:</strong>
																	{{ horse.win3_current | currency: 'USD' }}</span
																>
															</td>
														</tr>

														<!-- MOBILE LAYOUT -->
														<tr
															[ngClass]="{
																Scratched:
																	horse.horse_status == 'Scratched' ||
																	horse.horse_status == 'LateScratched'
															}"
															class="d-md-none mobile_layout"
															*ngIf="screenWidth <= 767"
														>
															<td style="width: 10%" class="tabno">
																<img
																	class="img-fluid jockey-img"
																	[src]="horse.silks"
																	(error)="onImgError($event, horse.tabno)"
																	alt="TRC"
																/>
															</td>

															<td style="width: 90%" class="td_name">
																<div class="player_name">
																	<h3>
																		<span class="index_no">{{ horse.tabno }}.</span
																		>{{ horse.horse_name }}
																		<small>({{ horse.barrier }})</small>
																		<div
																			class="yard_pick circle"
																			placement="top"
																			ngbTooltip="Yard pick"
																			*ngIf="horse.yard_pick"
																		>
																			<span>Y</span>
																		</div>
																		<!-- end yard_pick circle -->
																		<div
																			class="preview circle"
																			placement="top"
																			ngbTooltip="Preview pick (win)"
																			*ngIf="horse.win_bet"
																		>
																			<span>W</span>
																		</div>
																		<!-- end yard_pick circle -->

																		<div
																			class="preview_place circle"
																			placement="top"
																			ngbTooltip="Preview pick (place)"
																			*ngIf="horse.place_bet"
																		>
																			<span>P</span>
																		</div>
																		<!-- end yard_pick circle -->
																		<div
																			class="dual_nomination circle"
																			placement="top"
																			ngbTooltip="Dual acceptance"
																			*ngIf="horse.dual_nom == 'D'"
																		>
																			<span>D</span>
																		</div>
																		<!-- end yard_pick circle -->
																	</h3>
																</div>
																<div class="inner">
																	<div class="content">
																		<table>
																			<tr>
																				<td>
																					<span class="player_attributes"
																						>{{ horse.age }}YO{{
																							horse.sex
																						}}
																						{{ horse.colour }}</span
																					>
																				</td>
																				<td>
																					<span class="small_space"
																						><strong>Top Tote:</strong
																						>{{
																							getMax(
																								horse.win3_current,
																								horse.win3_9am,
																								horse.win3_opn
																							) | currency: 'USD'
																						}}
																						/
																						{{
																							getMax(
																								horse.plc3_current,
																								horse.plc3_9am,
																								horse.plc3_opn
																							) | currency: 'USD'
																						}}</span
																					>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<span
																						><strong>J:</strong
																						>{{ horse.jockey }}</span
																					>
																				</td>
																				<td>
																					<span class="small_space"
																						><strong>T:</strong
																						>{{ horse.trainer }}</span
																					>
																				</td>
																			</tr>
																		</table>
																	</div>
																	<!-- end content -->

																	<div
																		class="price_widget"
																		[ngClass]="{
																			up: horse.win3_movement === 'UP',
																			down: horse.win3_movement == 'DOWN'
																		}"
																	>
																		<div class="gauge">SP</div>
																		<!-- end gauge -->

																		<div class="price">
																			<span class="win">{{
																				horse?.win3_current | currency: 'USD'
																			}}</span>
																		</div>
																		<!-- end price -->
																	</div>
																	<!-- end price -->
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<!-- t-conents -->
									</li>
								</ul>

								<span class="togglebutton showMore" (click)="toggleAccordion($event)"
									>View full field</span
								>

								<h4 class="pill grey">
									{{ row.race_start_time | amFromUtc | amLocal | amDateFormat: 'hh:mma'  }}
								</h4>

							</li>
						</ul>

						<ul class="accr-section no_results" *ngIf="!loadingresultdata && results.length == 0">
							<li class="panel">No results available.</li>
						</ul>

						<!-- GHOST ELEMENT -->
						<ul class="accr-section" *ngIf="loadingresultdata">
							<li class="panel" *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7]">
								<h2 class="heading">
									<div class="loading-text"></div>
								</h2>
								<div class="row subheading_wrapper">
									<div class="col-8">
										<h3 class="subheading">
											<div class="loading-text"></div>
										</h3>
									</div>
									<div class="col-4 text-right"></div>
								</div>

								<div class="heading_table loading-text"></div>

								<div class="contents loading-text"></div>
							</li>
						</ul>
					</div>
					<!-- end col_right -->
				</div>
				<!-- end row -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>
<!-- end resultspage_wrapper -->
