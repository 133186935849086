import { Component, OnInit } from '@angular/core';
import { TournamentService } from '../services/tournaments.service';
import { ConfirmationModalService } from '../shared/confirmation-modal/confirmation-modal.service';
import { VerifyTorunamentEntrycodeComponent } from '../shared/verify-torunament-entrycode/verify-torunament-entrycode.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../services/user.service';



import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
	selector: 'app-tournaments',
	templateUrl: './tournaments.component.html',
	styleUrls: ['./tournaments.component.scss'],
})
export class TournamentsComponent implements OnInit {
	tournaments: any;
	roundRobinTournaments: any;
	loading: boolean = true;
	user: any;
	token: any;

	constructor(
		private TournamentService: TournamentService,
		private ConfirmationModalService: ConfirmationModalService,
		private route: Router,
		private modalService: NgbModal,
		private userService: UserService
	) {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);

		if (this.user != null && this.user != 'undefined') this.token = this.user.token;
		if (this.user && this.user.subscription && this.user.subscription.status) {
			if (this.user.subscription.plan_name === 'Platinum' || this.user.subscription.platinum_cancelling_or_override) this.user.hasPremiumSubscription = true;
			this.user.subscription.active_features.forEach((element : any) => {
				if (element.slug == 'previews-and-reviews') this.user.hasPreviews = true;
				if (element.slug == 'liveyard') this.user.hasLiveYard = true;
			});
			if (this.user.subscription.legacy_plan) this.user.hasPreviews = true;
			if (this.user.day_pass) this.user.hasPremiumSubscription = true;
		}
		//console.log(this.user);
		//console.log(this.token);
	}

	ngOnInit() {
		this.getTournaments();
	}

	getTournaments() {
		this.TournamentService.getTournaments(this.token).subscribe((data: any) => {
			//(data);
			if (data) {
				if (data.tournaments) {
					this.tournaments = data.tournaments;
					this.parseTournamentsData();
				}
				if (data.round_robin_tournaments) {
					this.roundRobinTournaments = data.round_robin_tournaments;
					this.parseRoundRobinTournamentsData();
				}
			}
			this.loading = false;
		});
	}

	parseTournamentsData() {
		var now = moment.utc().format('YYYY MM DD HH:mm:ss');
		this.tournaments.forEach((element: any) => {

			if (!element.cut_off_date_over) {
				element.alert = 'white';
				element.status = 'Open';

				// var diff = moment.duration(moment(element.cut_off_date_time).diff(moment.utc()));
				element.cut_off_date_time_local = moment.utc(element.cut_off_date_time).local();
				var diff = moment.duration(moment(element.cut_off_date_time_local).diff(moment.utc()));

				var duration = moment.duration(diff);
				var years = duration.years(),
					days = duration.days(),
					months = duration.months(),
					hrs = duration.hours(),
					mins = duration.minutes(),
					secs = duration.seconds();
				if (years > 0) {
					element.time_remaining = this.calculateTime(years, 'year');
				} else if (months > 0) {
					element.time_remaining = this.calculateTime(months, 'month');
				} else if (days > 0) {
					element.time_remaining = this.calculateTime(days, 'day');
				} else if (hrs > 0) {
					element.time_remaining = this.calculateTime(hrs, 'hour') + this.calculateTime(mins, 'min');
				} else if (mins > 0) {
					element.time_remaining = this.calculateTime(mins, 'min') + this.calculateTime(secs, 'sec');
					if (mins < 5) {
						element.alert = 'red';
					}
				} else {
					element.time_remaining = this.calculateTime(secs, 'sec');
					element.alert = 'red';
				}
			} else {
				element.alert = 'grey';
				element.time_remaining = null;
				if (element.show_results && element.cut_off_date_over) {
					element.status = 'Complete';
				} else if(!element.show_results && element.cut_off_date_over) {
					element.status = 'In Progress';
				} else {
					element.status = 'Open';
				}
			}
			// element.cut_off_date_time = moment(element.cut_off_date_time).local().format('YYYY MM DD HH:mm:ss');
            element.imageType = 'jpg';
            if(element.image_url != undefined && element.image_url.indexOf('png') != -1) {
                element.imageType = 'png';
            }
            if(element.image_url == undefined) {
                element.image_url = '/assets/images/horse.png';
                element.imageType = 'png';
            }
		});
	//	console.log(this.tournaments);
	}

	slugify(title: any) {
		// console.log(title, title.replace(/\s+/g, '-').toLowerCase());
		return title.replace(/\s+/g, '-').toLowerCase();
	}

	parseRoundRobinTournamentsData() {
		var now = moment.utc().format('YYYY MM DD HH:mm:ss');
		this.roundRobinTournaments.forEach((element: any) => {
			if (!element.cut_off_date_over) {
				element.alert = 'white';
				element.status = 'Open';

				var diff = moment.duration(moment(element.cut_off_date_time).diff(moment(now)));
				var duration = moment.duration(diff);
				var years = duration.years(),
					days = duration.days(),
					months = duration.months(),
					hrs = duration.hours(),
					mins = duration.minutes(),
					secs = duration.seconds();
				if (years > 0) {
					element.time_remaining = this.calculateTime(years, 'year');
				} else if (months > 0) {
					element.time_remaining = this.calculateTime(months, 'month');
				} else if (days > 0) {
					element.time_remaining = this.calculateTime(days, 'day');
				} else if (hrs > 0) {
					element.time_remaining = this.calculateTime(hrs, 'hour') + this.calculateTime(mins, 'min');
				} else if (mins > 0) {
					element.time_remaining = this.calculateTime(mins, 'min') + this.calculateTime(secs, 'sec');
					if (mins < 5) {
						element.alert = 'red';
					}
				} else {
					element.time_remaining = this.calculateTime(secs, 'sec');
					element.alert = 'red';
				}
			} else {
				element.alert = 'grey';
				element.time_remaining = null;
				if (element.show_results && element.cut_off_date_over) {
					element.status = 'Complete';
				} else if(!element.show_results && element.cut_off_date_over) {
					element.status = 'In Progress';
				} else {
					element.status = 'Open';
				}
			}
			// element.cut_off_date_time = moment(element.cut_off_date_time).local().format('YYYY MM DD HH:mm:ss');
            element.imageType = 'jpg';
            if(element.image_url != undefined && element.image_url.indexOf('png') != -1) {
                element.imageType = 'png';
            }
            if(element.image_url == undefined) {
                element.image_url = '/assets/images/horse.png';
                element.imageType = 'png';
            }
		});
	//	console.log(this.roundRobinTournaments);
	}

	calculateTime(counter: number, timeUnit: string) {
		if (counter === 1) {
			return counter + ' ' + timeUnit; // singular (1 hours)
		} else {
			let toReturn = counter + ' ' + timeUnit + 's ';
			return toReturn; // plural (2 hours)
		}
	}

	gotoSingleTournament(tournament: any) {
		this.route.navigate(['/tournament/' + tournament.id + '/' + this.slugify(tournament.name)]);
	}

	gotoSingleTournamentView(tournamentView: any) {
		console.log(tournamentView)
		this.route.navigate(['/tournament/view/' + tournamentView.id + '/' + this.slugify(tournamentView.name)]);
	}

	gotoRoundRobinTournaments(roundRobinTournament: any) {
		this.route.navigate(['/tournament/round-robin/' + roundRobinTournament.id + '/' + this.slugify(roundRobinTournament.title)]);
	}

	gotoRoundRobinTournamentsView(roundRobinTournamentsView: any) {
		this.route.navigate(['/tournament/round-robin/view/' + roundRobinTournamentsView.id + '/' + this.slugify(roundRobinTournamentsView.title)]);
	}

	async openTermsModal(tournament: any, type: any) {
		if (
			tournament.is_private &&
			type == 'tournament' &&
			tournament.tournamentUser &&
			tournament.tournamentUser.status != 'entered' &&
			tournament.tournamentUser.status != 'terms_agreed' &&
			tournament.tournamentUser.status != 'code_verified'
		) {
			let dialogSize: 'sm' | 'lg' = 'lg';
			const modalRef = this.modalService.open(VerifyTorunamentEntrycodeComponent, {
				size: dialogSize,
				backdrop: 'static',
				keyboard: false,
				centered: true,
			});
			modalRef.componentInstance.backdrop = 'static';
			modalRef.componentInstance.keyboard = false;
			modalRef.componentInstance.btnOkText = 'Enter';
			modalRef.componentInstance.btnCancelText = 'Cancel';
			let data = {
				tournament: tournament,
				user: this.user,
			};
			modalRef.componentInstance.fromParent = data;
			modalRef.result
				.then((result) => {
					if(result) {
						this.getTournaments();
						this.showTermsPopup(tournament, type);
					}
				})
				.catch((result) => {
					console.log('cancelling');
				});
			return;
		}

		if (
			tournament.tournamentUser &&
			(tournament.tournamentUser.status == 'entered' || tournament.tournamentUser.status == 'terms_agreed')
		) {
			if (type == 'tournament') {
				this.gotoSingleTournament(tournament);
				return;
			}
			if (type == 'round-robin-tournament') {
				this.gotoRoundRobinTournaments(tournament);
				return;
			}
		}

		this.showTermsPopup(tournament, type);
	}

	async showTermsPopup(tournament : any, type : any) {
		let message =
			'<div>' +
			'<p>By entering this tournament, you agree to the terms and conditions on our website</p>' +
			'</div>' +
			'<a target="_blank" class="terms_link" href="http://theraceclub.com.au/terms-and-conditions">View Terms & Conditions</a>';

		await this.ConfirmationModalService.confirm('Terms & Conditions', message)
			.then((confirmed) => {
				//	console.log('User confirmed:', confirmed);
				if (confirmed) this.agreeTerms(tournament, type);
			})
			.catch(() =>
				console.log(
					'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
				)
			);
	}

	async agreeTerms(tournament: any, type: any) {
		let data = {
			id: tournament.id,
			type: type,
		};
		try {
			await this.TournamentService.agreeTerms(this.token, data).subscribe((data: any) => {
				if (data && data.status) {
					if (type == 'tournament') {
						if (tournament.is_private) {
							this.verifyAuth();
						}
						this.gotoSingleTournament(tournament);
					} else {
						this.gotoRoundRobinTournaments(tournament);
					}
				}
			});
		} catch (error) {
		//	console.log(error);
		}
	}

	async verifyAuth() {
		await this.userService.getAuthUser(this.user.token).subscribe((data: any) => {
			if (data && data.result) {
				localStorage.setItem('trc_user', JSON.stringify(data.result));
			} else {
				localStorage.removeItem('trc_user');
			}
		},err => {
			localStorage.removeItem('trc_user');
		});
	}
}
