import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
	selector: '[results-tab]',
	templateUrl: './results-tab.component.html',
	styleUrls: ['./results-tab.component.scss'],
})
export class ResultsTabComponent implements OnInit {
	@Input() form_guide_horses: any;
	@Input() user: any;
	@Input() bb_count: any;
	@Input() scratched_horses: any;

	@Output() openBlackbookIn = new EventEmitter<{ row: object }>();
	@Output() getRaceHorseDetailsIn = new EventEmitter<{ e: object; horseId: any; i: any }>();
	@Output() toggleAccordionIn = new EventEmitter<boolean>(false);
	constructor() {}

	ngOnInit(): void {}

	formatSubtitle = (percent: number) => {
		return percent;
	};

	percentToColor(percent: number, min: number, max: number): string {
		if (percent < 54) {
			return '#FF0000';
		} else if (percent >= 55 && percent <= 74) {
			return '#F5C61C';
		} else if (percent > 75) {
			return '#67BE53';
		}
		return '#67BE53';
	}

	OpenBlackbook(horse: any) {
		this.openBlackbookIn.emit(horse);
	}

	getRaceHorseDetailsInner($event: any) {
		this.getRaceHorseDetailsIn.emit($event);
	}

	toggleAccordionInner($event: any) {
		this.toggleAccordionIn.emit($event);
	}
}
