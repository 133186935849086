import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';

import { ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
	changePasswordForm: FormGroup;
	errors: any;
	passwordToken: any;
	email: any;

	constructor(
		public fb: FormBuilder,
		route: ActivatedRoute,
		public UserService: UserService,
		private toastr: ToastrService,
		private router: Router
	) {
		this.changePasswordForm = this.fb.group({
			email: [''],
			password: [''],
			password_confirmation: [''],
			passwordToken: [''],
		});
		route.queryParams.subscribe((params) => {
			this.passwordToken = params.token;
			this.changePasswordForm.controls['passwordToken'].setValue(params['token']);
		});
		this.UserService.getResetemail(this.passwordToken).subscribe((data: any) => {
			if(data && data.length)
				this.email = data[0].email;
			else 
			this.router.navigate(['/404']);
		});
	}

	ngOnInit(): void {}

	onSubmit() {
		this.UserService.resetPassword(this.changePasswordForm.value).subscribe(
			(result) => {
				this.toastr.success('Your password has been changed successfully');
				this.changePasswordForm.reset();
				this.router.navigate(['/']);
			},
			(error) => {
				if(error && error.error && error.error.errors && error.error.errors.email && error.error.errors.email[0]) {
					this.toastr.error(error.error.errors.email[0]);
				}
				if(error && error.error && error.error.errors && error.error.errors.password && error.error.errors.password[0]) {
					this.toastr.error(error.error.errors.password[0]);
				}
				this.toastr.error(error.error.message);
				this.handleError(error);
			}
		);
	}

	handleError(error: any) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// client-side error
			errorMessage = `Error: ${error.error.message}`;
		} else {
			// server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}

		return throwError(errorMessage);
	}
}
