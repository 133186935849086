<div class="breadcrumbs_wrapper bg-image text-center" [style.backgroundImage]="'url(' + tournament?.image_url + ')'">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-10 offset-lg-1 align-self-center">
				<div class="inner mx-auto">
					<h1 class="page_title">{{ tournament?.name }}</h1>
					<div class="breadcrumbs">
						<ul>
							<li><a routerLink="/">Home</a></li>
							<li><a routerLink="/tournaments" routerLink="/tournaments">Tournaments</a></li>
							<li>{{ tournament?.name }}</li>
						</ul>
					</div>

					<div class="open_date" *ngIf="tournament?.cut_off_date_time">
						<span>{{ tournament?.cut_off_date_time | amFromUtc  | amLocal | amDateFormat: 'DD MMM YYYY'}}</span>
					</div>
					<!-- end open_date -->
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end breadcrumbs_wrapper -->

<div class="singletournamentpage_wrapper" >
	<div class="container">
		<div class="row" >
			<div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 info_wrapper bottom-border gradient" *ngIf="!loading">
				<div class="panel">
					<h4 class="pill {{ tournament?.alert }}" *ngIf="!tournament?.cut_off_date_over">
						Closing in {{ tournament?.time_remaining }}
					</h4>

					<h4 class="pill {{ tournament?.alert }}" *ngIf="tournament?.cut_off_date_over">Closed</h4>

					<div class="row">
						<div class="single_cell">
							<h2 class="heading">${{ tournament?.prize }}</h2>
							<span class="cell_h">Prize</span>
						</div>

						<div class="single_cell">
							<h2 class="heading">${{ tournament?.entry_fee }}</h2>
							<span>Entry</span>
						</div>

						<div class="single_cell">
							<h2 class="heading">{{ tournament?.status }}</h2>
							<span>Status</span>
						</div>

						<div class="single_cell">
							<h2 class="heading">{{ tournament?.participants }}</h2>
							<span>Entrants</span>
						</div>

						<div class="single_cell align-self-center">
							<h2 class="heading" *ngIf="tournamentUser && tournamentUser.user_rank">
								{{ tournamentUser?.user_rank }}
							</h2>
							<h2 class="heading" *ngIf="!tournamentUser || !tournamentUser.user_rank">-</h2>
							<span>Ranked</span>
						</div>

						<div class="single_cell align-self-center">
							<a class="btn large black disabled" *ngIf="tournamentUser && tournamentUser.status == 'entered'">Entered</a>
							<a class="btn large black" (click)="openTermsModal(tournament, tournamentUser, 'tournament')" *ngIf="!tournament?.cut_off_date_over && (!tournamentUser || tournamentUser.status !== 'entered')">Enter Now</a>
						</div>
					</div>
					<!-- end row -->
				</div>
			</div>
			<!-- end info_wrapper -->

			<!-- HIDDEN TEMPORARILY -->
			<div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 message_wrapper" *ngIf="tournament?.is_private && lock_private_tournament">
				<div class="panel">
					<div *ngIf="!private_tournament_code_verified && !tournament?.cut_off_date_over" align="center">
						<h3>Please enter your invite code in order to participate in this tournament.</h3>
						<br>
						<a class="btn large black justify-row-center" (click)="openTermsModal(tournament, tournamentUser, 'tournament')">Verify Code</a>
					</div>
					<div *ngIf="private_tournament_code_verified && !tournament?.cut_off_date_over" align="center">
						 <h3>You have verified code for this tournament. Please agree terms and conditions to enter the tournament.</h3>
						<br>
						 <a class="btn large black justify-row-center" (click)="openTermsModal(tournament, tournamentUser, 'tournament')">Enter Now</a>
					</div>
					<div *ngIf="tournament?.cut_off_date_over" align="center">
						<h3>{{tournament?.name}} is closed now.</h3>
					   <br>
				   </div>
				</div>
			</div>
			<!-- end col-lg-6 -->

			<div class="col-lg-10 offset-lg-1" *ngIf="((tournamentUser && tournamentUser?.status == 'entered') || tournament?.is_free) && !loading && (!tournament?.is_private || (tournament?.is_private && !lock_private_tournament))">
				<div class="row">
					<div class="col-xl-5 col_left">

						<div class="panel mb-3" *ngIf="tournament?.description">
							<div class="row">
								<div class="col-12 message">
									{{ tournament?.description }}
								</div>
							</div>
						</div>

						<div class="panel">
							<h2 class="heading">Leaderboard</h2>

							<div class="row" *ngIf="
									tournament?.users && tournament?.show_results
								">
								<div class="col-12 leaderboard-list" *ngFor="let user of tournament?.users">
									<div class="inner">
										<div *ngIf="user.avatar" class="avatar_inner" [ngClass]="{'green': user?.points >= 10,'yellow': user?.points <= 9}" [ngStyle]="{'background-image': 'url(' + storageUrl + 'users/' + user?.avatar + ')'}"></div>
										<div *ngIf="!user.avatar" class="avatar_inner with-initials" [ngClass]="{'green':  user?.points >= 10,'yellow': user?.points <= 9}">
											<span class="initials"><span>{{getName(user) | uppercase }}</span></span>
										</div>
										<span *ngIf="!user?.display_name">{{ user?.user_rank }}. {{ user?.name }}</span>
										<span *ngIf="user?.display_name">{{ user?.user_rank }}. {{ user?.display_name }}</span>
										<span class="points">{{ user?.points }} points</span>
									</div>
								</div>
							</div>

							<div class="row" *ngIf="tournament?.status != 'Complete' && !tournament?.show_results">
								<div class="col-12">
									<span class="message">Races will be automatically resulted, with the leaderboard being updated approximately 15 minutes post race results being ‘official’ by the governing body. The final tournament winners will be announced shortly proceeding the final official race result of the tournament.</span>
								</div>
							</div>
						</div>
					</div>
					<!-- end col_left -->

					<div class="col-xl-7 col_right">
					
						<ul class="accr-section" *ngIf="tracks.length > 0">
							<li class="collapsable-section closed panel" *ngFor="let track of tracks; let i = index">
								<div class="heading_table">
									<div class="cell">
										<span>{{ track?.track_code }}</span>
									</div>

									<div class="cell">
										<span>{{ track?.race_start_time | date: 'd MMMM y' }}</span>
									</div>
								
									<div class="cell right" *ngIf="track?.track_going">
										<span class="button rating {{ track?.track_going | lowercase }}">{{ track?.track_going | titlecase }}
											{{ track?.track_rating | lowercase }}
										</span>
									</div>
									<span class="toggleBtn" (click)="toggleAccordionTrack($event)"></span>
								</div>
								<div class="collapsed-content collapsed-content-parent">
									<ul class="accr-section pt-4">
										<li class="collapsable-section closed panel px-0" *ngFor="let row of track.races; let i = index">
											<h2 class="heading px-3">
												<span>Race {{ row?.raceno }}</span>
											</h2>

											<div class="heading_table">
												<div class="cell">
													<span>{{ row?.class }}</span>
												</div>
												<div class="cell">
													<span>{{ row?.distance }}m</span>
												</div>

												<div class="cell" *ngIf="row?.track_going">
													<span class="button rating {{ row?.track_going | lowercase }}">{{ row?.track_going | titlecase }}
														{{ row?.track_rating | lowercase }}
													</span>
												</div>

												<span class="toggleBtn" (click)="toggleAccordion($event)"></span>
											</div>

											<div class="collapsed-content">
												<table class="table" *ngFor="let horse of row.horses">
													<tbody>
														<tr [ngClass]="{ Scratched: horse.horse_status == 'Scratched' || horse.horse_status == 'LateScratched' }">
															<div class="row no-gutters">
																<div class="col col_img align-self-center">
																	<img class="img-fluid jockey-img" src="{{horse?.silks}}" alt="TRC" />
																</div><!-- col_img -->
																<div class="col col_index align-self-center">
																	<h1 class="index_no">{{ horse?.tabno }}.</h1>
																</div><!-- col_index -->
																<div class="col col_name align-self-center">
																	<div class="name_wrap">
																		<h3 class="player_name">
																			<span><span class="index_no">{{ horse?.tabno }}.</span> {{ horse?.horsename }}({{ horse?.barrier }})</span>
																			<div class="circles">
																				<div class="yard_pick circle" placement="top" ngbTooltip="Yard pick" *ngIf="horse?.yard_pick && user && user?.hasLiveYard">
																					<span>Y</span>
																				</div>
																				<!-- end yard_pick circle -->
																				<div class="preview circle" placement="top" ngbTooltip="Preview pick (win)" *ngIf="horse?.win_bet && user && user?.hasPreviews">
																					<span>W</span>
																					<!--<span>P</span>-->
																					<!-- SHOW P IF THE PICK IS A PLACE -->
																				</div>
																				<!-- end yard_pick circle -->
																				<div class="preview_place circle" placement="top" ngbTooltip="Preview pick (place)" *ngIf="horse?.place_bet && user && user?.hasPreviews">
																					<span>P</span>
																					<!--<span>P</span>-->
																					<!-- SHOW P IF THE PICK IS A PLACE -->
																				</div>
																				<!-- end yard_pick circle -->
					
																				<div class="dual_nomination circle" placement="top" ngbTooltip="Dual acceptance" *ngIf="horse?.dual_nom == 'D'">
																					<span>D</span>
																				</div>
																			</div>
																		</h3>
																		<span class="age"><strong>{{ horse?.age }}{{ horse?.sex }}{{ horse?.colour }}</strong></span>
																		<span class="jockey_name"><strong>J:</strong> {{ (formatName(horse?.jockey).length > 10)?
																			(formatName(horse?.jockey) | slice:0:8)+'..':(formatName(horse?.jockey))
																			}} <small>({{horse?.weight | number:'1.0-0' }}kg)</small></span>
																	</div>
																</div><!-- col_name -->
																<div class="col col_selection align-self-center">
																	<div class="radio_button_wrap" [ngClass]="{ disabled: disabledButton }">
																		<strong *ngIf="horse?.win3_current">${{horse?.win3_current}}</strong>
																		<label class="radio_container" *ngIf="horse?.win3_current">
																			<input id="checkmark-horse-{{ horse?.id }}"
																				type="radio"
																				value="{{ horse?.id }}" name="checkmark-horse-{{ horse?.tournament_race_id }}" (click)="
																					selectHorse(horse?.tournament_race_id, horse?.id)
																				" [disabled]="disabledButton" [checked]="
																					userTournamentHorsesIds[i] &&
																					userTournamentHorsesIds[i]
																						.tournament_race_horse_id == horse?.id
																				" />
																			<span class="checkmark"></span>
																		</label>
																	</div><!-- radio_button_wrap -->
																	<div *ngIf="
																			(row.winner_horse_id == horse.id ||
																				row.second_horse_id == horse.id ||
																				row.third_horse_id == horse.id) &&
																			tournament?.show_results
																		" class="horse_points mt-2">
																		<!-- <span *ngIf="row.winner_horse_id == horse.id" class="button">{{ row.horse_point }} points</span>
																		<span *ngIf="row.third_horse_id == horse.id" class="button">{{ row.third_horse_point }} points</span>
																		<span *ngIf="row.second_horse_id == horse.id" class="button">{{ row.second_horse_point }} points</span> -->

																		<span *ngIf="row.winner_horse_id == horse.id && row.selected_horse_id === row.winner_horse_id && row.selected_horse_points" class="button">{{ row.selected_horse_points | number:'0.0-0' }} points</span>
																		<span *ngIf="row.winner_horse_id == horse.id && row.selected_horse_id !== row.winner_horse_id && row.horse_point" class="button">{{ row.horse_point * 100 | number:'0.0-0' }} points</span>
		
																		<span *ngIf="row.third_horse_id == horse.id && !tournament.is_unibet_tournament" class="button">{{ row.third_horse_point }} points</span>
																		<span *ngIf="row.second_horse_id == horse.id && !tournament.is_unibet_tournament" class="button">{{ row.second_horse_point }} points</span>
		
																	</div>
																</div><!-- col_selection -->
															</div>
														</tr>
													</tbody>
												</table>
											</div>
											<!-- collapsed-content  -->

											<h4 class="pill grey">
												{{ row?.race_start_time  | amFromUtc  | amLocal | amDateFormat: 'h:mm a'}}
											</h4>
										</li>
									</ul>
								</div>
							</li>
							<li *ngIf="
									!tournament?.cut_off_date_over &&
									tournament?.show_results != 1 &&
									tournament?.status != 'Complete' &&
									!disabledButton
								">
								<button (click)="submitTournament()" class="btn large">SAVE</button>
							</li>
						</ul>
					</div>
					<!-- end col_right -->
				</div>
				<!-- end row -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->

			<!-- GHOST ELEMENT -->
			<div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 info_wrapper bottom-border gradient" *ngIf="loading">
				<div class="panel loading-text"></div>
			</div>

			<!-- GHOST ELEMENT -->
			<div class="col-lg-10 offset-lg-1" *ngIf="loading">
				<div class="row">
					<div class="col-xl-5 col_left">
						<div class="panel">

							<div class="row">
								<div class="col-12 leaderboard-list" *ngFor="let numbers of [0,1,2,3]">
									<div class="inner">
										<div class="avatar_inner loading-text"></div>
										<span>
											<div class="loading-text"></div>
										</span>
										<span>
											<div class="loading-text ml-3"></div>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- end col_left -->

					<div class="col-xl-7 col_right">
						<ul class="accr-section">
							<li class="collapsable-section closed panel">
								<h2 class="heading">
									<div class="loading-text"></div>
								</h2>

								<div class="heading_table loading-text"></div>
							</li>

							<li class="collapsable-section closed panel">
								<h2 class="heading">
									<div class="loading-text"></div>
								</h2>

								<div class="heading_table loading-text"></div>
							</li>

							<li class="collapsable-section closed panel">
								<h2 class="heading">
									<div class="loading-text"></div>
								</h2>

								<div class="heading_table loading-text"></div>
							</li>
						</ul>
					</div>
					<!-- end col_right -->
				</div>
				<!-- end row -->
			</div>
			<!-- end col-lg-10 offset-lg-1 -->
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>