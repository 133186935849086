import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PlanService {
	apiUrl = environment.serverUrl;
	constructor(private httpClient: HttpClient) {}

	getPlans(features: any) {
		let data = {
			features: features,
		};
		return this.httpClient.post(this.apiUrl + 'getplans', data);
	}

	getFeatures() {
		let data: any = {};
		return this.httpClient.post(this.apiUrl + 'getfeatures', data);
	}

	getFeaturesPlans() {
		let data: any = {};
		return this.httpClient.post(this.apiUrl + 'get-features-plans', data);
	}

	getPromotionalPlans() {
		let data: any = {};
		return this.httpClient.post(this.apiUrl + 'get-promotional-plans', data);
	}
	getFeaturesPlansWithItunesRc() {
		let data: any = {};
		return this.httpClient.post(this.apiUrl + 'get-features-plans-updated-rcplus', data);
	}
}
