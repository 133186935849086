import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { TweenLite, TweenMax, Cubic, Power4, TimelineMax } from 'gsap';
import { FormGuideService } from '../../services/form-guide.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateRef, ViewChild } from '@angular/core';
import { LoginPopupComponent } from '../login-popup/login-popup.component';
import { SignupPopupComponent } from '../signup-popup/signup-popup.component';
import { ChoicePopupComponent } from '../choice-popup/choice-popup.component';
import { EventService } from '../../services/event.service';
import { LiveyardsService } from '../../services/liveyards.service';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from '../../services/user.service';
import {ChangeDetectorRef} from '@angular/core';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	name: any;
	token: any;
	nextRace: any;
	time_now = moment.utc();
	user: any;
	LiveyardsAvailable: any = true;
	menuOpen = false;
	searchOpen = false;
	masterTimeline = new TimelineMax({
		paused: true,
		reversed: true,
	});

	constructor(
		private FormGuideService: FormGuideService,
		private EventService: EventService,
		private LiveyardsService: LiveyardsService,
		private modalService: NgbModal,
		private userService: UserService,
		public router: Router,
		private cd : ChangeDetectorRef
	) {
		this.getUser();
		// console.log(this.user);
		this.EventService.userLogin.subscribe((state) => {
			if (state) {
				this.getUser();
				// console.log('login');
			} else {
				this.getUser();
				// console.log('loggedout');
			}
		});

		this.router.events.subscribe((ev) => {
			if (ev instanceof NavigationEnd) {
				// If menu open during route change then collapse menu
				if (this.menuOpen) {
					this.animateMenu();
				}
			}
		});
	}

	ngOnInit() {
		this.getUser();
		this.getUpcomingRaces();
		// this.getLiveyardTracks();
		this.masterTimeline.add(this.fullMenu());
	}

	getUser() {
		this.user = localStorage.getItem('trc_user');
		this.user = JSON.parse(this.user);
		if (this.user && this.user.token) this.token = this.user.token;
	}

	async getUpcomingRaces() {
		this.FormGuideService.getUpcomingRaces().subscribe((data: any) => {
			if (data && data.nextRace) {
				this.nextRace = data.nextRace ? data.nextRace : null;
				this.nextRace.local_time = moment.utc(this.nextRace.race_start_time).local();
				this.nextRace.seconds = this.nextRace.local_time.diff(this.time_now, 'seconds');
			}
		});
	}

	async logout() {
		await window.analytics.track("Logged Out", {
			userId: this.user.id,
			properties: {
				name: this.user.name,
				email: this.user.email,
      		},
    	});
		await localStorage.removeItem('trc_user');
		this.EventService.userLoggedout();
		this.router.navigate(['/']);
	}

	TimerBelowFiveMinutes(race: any) {
		//console.log(race.event_id);
		$('#' + race.event_id).removeClass();
		$('#' + race.event_id).addClass('time-pills red to-blue');
	}

	timeexpired(race: any) {
		this.cd.detectChanges();
		this.getUpcomingRaces();
	}

	async login() {
		this.modalService.open(LoginPopupComponent, { size: 'custom', windowClass: 'login-modal' });
	}

	async signup() {
		this.modalService.open(SignupPopupComponent, { size: 'custom', windowClass: 'signup-modal', scrollable: true });
	}

	async loginorsignup() {
		this.modalService.open(ChoicePopupComponent, { size: 'custom', windowClass: 'choice-modal' });
	}

	async openFormGuide(race:any){
		window.open('/form-guide/'+ race.slug +'/' + race.raceno );
	}

	// async getLiveyardTracks() {
	// 	this.LiveyardsService.getLiveyardTracks().subscribe((data: any) => {
	// 		if (!data.status) {
	// 			this.LiveyardsAvailable = false;
	// 		} else {
	// 			this.LiveyardsAvailable = true;
	// 		}
	// 	});
	// }

	onResize() {
		// Collapse menu on resize
		if (this.menuOpen) {
			this.animateMenu();
		}
	}

	fullMenu() {
		var menuElements = jQuery('#mobile-menu-scroller .menu-element');
		var tl = new TimelineMax();
		tl.to($('#overlay-menu'), 0.2, { ease: Power4.easeInOut, zIndex: '990' })
			.addLabel('trigOverlays')
			.staggerFromTo(
				menuElements,
				0.4,
				{ ease: Power4.easeOut, opacity: 0, y: '-10%' },
				{ ease: Power4.easeOut, opacity: 1, y: '0%' },
				0.05,
				'trigOverlays'
			)
			.to($('#overlay'), 0.5, { ease: Power4.easeInOut, height: 'auto' }, 'trigOverlays');
		return tl;
	}

	animateMenu() {
		$('body').toggleClass('menu-open');
		if (this.menuOpen) {
			this.masterTimeline.reverse();
			this.menuOpen = false;
		} else {
			this.masterTimeline.play();
			this.menuOpen = true;
		}
	}

	searchToggle() {
		if (this.searchOpen) {
			TweenLite.to($('.search-box'), 0.5, { ease: Cubic.easeInOut, width: '0%', opacity: 0 });
			this.searchOpen = false;
		} else {
			TweenLite.to($('.search-box'), 0.5, {
				ease: Cubic.easeInOut,
				width: $('.topbar_wrapper .col_links').width(),
				opacity: 1,
			});
			$('.search-box input').focus();
			this.searchOpen = true;
		}
	}
	closePersistantBanner() {
		const banner = document.getElementById('BannerPC') as HTMLElement
		if(banner) {
			banner.classList.add('hide');
		}
	}

	navigateAppleStore(){
		window.open('https://apps.apple.com/au/app/racing-central/id1434221878', '_blank');

	}

	navigateGoogleStore(){
		window.open('https://play.google.com/store/apps/details?id=io.ionic.raceclub&pli=1', '_blank');

	}
}
